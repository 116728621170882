import { bottomLog, bottomThrow, defaultTimeProvider, filterOutFalsy } from "@eatbetter/common-shared";
import {
  Like,
  Comment,
  SocialPostId,
  TextPost,
  UserRecipeActionPost,
  SocialPost,
  NewRecipePost,
  SocialEntity,
  PostComments,
  SocialEntityId,
} from "@eatbetter/posts-shared";
import { AuthedUser } from "@eatbetter/users-shared";
import React, { useCallback, useState } from "react";
import { LayoutAnimation, StyleSheet, View } from "react-native";
import {
  usePost,
  usePostLiked,
  usePostLikes,
  usePostOwnerId,
  usePostSaveCount,
  usePostSourceRecipeId,
} from "../../lib/social/SocialSelectors";
import { deletePost, likeChangedClient } from "../../lib/social/SocialThunks";
import { useAuthedUser, useAuthedUserId } from "../../lib/system/SystemSelectors";
import { navTree, ShareViewRecipeScreenProps } from "../../navigation/NavTree";
import { NavApi, ScreenUtil, useScreen } from "../../navigation/ScreenContainer";
import { globalStyleColors, globalStyleConstants, globalStyles } from "../GlobalStyles";
import { Haptics } from "../Haptics";
import { IconLike, IconLikeFilled } from "../Icons";
import { Photo } from "../Photo";
import { Pressable } from "../Pressable";
import { Separator } from "../Separator";
import { SocialPostHeading } from "./SocialPostHeading";
import { Spacer } from "../Spacer";
import { TBody, TSecondary, TTertiary } from "../Typography";
import { FlexedSpinner } from "../Spinner";
import { InlineComment } from "../../screens/PostCommentsScreen";
import { AutoLink } from "../AutoLink";
import {
  Author,
  Book,
  Publisher,
  RecipeId,
  RecipeInfo,
  RecipeRating,
  RecipeSource,
  UserEnteredAttribution,
  UserRecipeStats,
} from "@eatbetter/recipes-shared";
import { RecipeAuthor, RecipePublisherOrBook, RecipeTitle } from "../recipes/RecipeTitleAndSource";
import { smallScreenBreakpoint, useResponsiveDimensions } from "../Responsive";
import { recipeHasUsablePhoto, RecipePhoto } from "../RecipePhoto";
import { getOptionsMenuHeight, OptionsMenu, OptionsMenuItem } from "../OptionsMenu";
import { useBottomSheet } from "../../screens/BottomSheetScreen";
import { maybePromptForReview } from "../../lib/system/SystemThunks";
import { analyticsEvent } from "../../lib/analytics/AnalyticsThunks";
import { useDispatch } from "../../lib/redux/Redux";
import { ContainerPadded } from "../Containers";
import { reportPostContextMenuItemTapped, reportPostLikesBarTapped } from "../../lib/analytics/AnalyticsEvents";
import { navToCookingSessionIfExists } from "../../navigation/NavThunks";
import { IconButton } from "../Buttons";
import { sharePostLink } from "../../lib/share/ShareThunks";
import { log } from "../../Log";
import { useReportContentIssueMenuItem } from "./ReportContentIssue";

const strings = {
  likeOld: {
    you: "You liked this",
    youAndOneOther: "You and 1 other liked this",
    youAndOthers: (count: number) => `You and ${count} others liked this`,
    others: (count: number) => `${count} liked this`,
    zeroLikes: "Be the first to like this!",
  },
  like: {
    zeroLikes: "Be the first to like this!",
    one: " like",
    multiple: " likes",
  },
  save: {
    one: " save",
    multiple: " saves",
  },
  comment: {
    one: " comment",
    multiple: " comments",
    seeAll: (count: number) => `See all ${count} comments`,
  },
  optionsMenu: {
    delete: "Delete Post",
  },
};

interface Props {
  postOrId: SocialPostId | SocialPost;
  isDetailView?: boolean;
}

export type LikeButtonPressedHandler = (postId: SocialPostId, alreadyLiked: boolean) => void;
export type PostInteractionHandler = (postId: SocialPostId) => void;

export function usePostOptionsMenu(postId: SocialPostId | undefined) {
  const screen = useScreen();
  const authedUser = useAuthedUserId();
  const postOwner = usePostOwnerId(postId);
  const postRecipe = usePostSourceRecipeId();

  const isOwnPost = !!postOwner && postOwner === authedUser;

  const canEdit = isOwnPost ? ("delete" as const) : undefined;
  const canReportContent = !isOwnPost ? ("flag" as const) : undefined;

  const menuItems = filterOutFalsy([canEdit, canReportContent]);
  const menuItemCount = menuItems.length;

  const onPressMoreMenu = useCallback<PostInteractionHandler>(
    id => {
      screen.nav.modal(navTree.get.screens.bottomSheet, {
        content: (
          <SocialPostMoreMenu
            nav={screen.nav}
            postOwnerId={postOwner}
            postId={id}
            recipeId={postRecipe}
            menuOptions={menuItems}
          />
        ),
        height: getOptionsMenuHeight(menuItemCount),
      });
    },
    [screen.nav.modal, menuItems, menuItemCount, postOwner, postRecipe]
  );

  if (menuItemCount > 0) {
    return onPressMoreMenu;
  }

  return undefined;
}

const SocialPostMoreMenu = React.memo(
  (props: {
    nav: NavApi;
    postId: SocialPostId;
    postOwnerId?: SocialEntityId;
    recipeId?: RecipeId;
    menuOptions: Array<"delete" | "flag">;
  }) => {
    const dispatch = useDispatch();
    const bottomSheet = useBottomSheet();

    const [waitingOnDelete, setWaitingOnDelete] = useState(false);

    const onPressDeletePost = useCallback(async () => {
      if (props.postId) {
        dispatch(analyticsEvent(reportPostContextMenuItemTapped({ action: "reportContent" })));
        await dispatch(deletePost(props.postId, setWaitingOnDelete));
        Haptics.feedback("operationSucceeded");
      }

      bottomSheet?.closeSheetAndGoBack();
    }, [props.postId, dispatch, setWaitingOnDelete, bottomSheet]);

    const reportContentIssueMenuItem = useReportContentIssueMenuItem({
      context: "post",
      contentOwnerId: props.postOwnerId,
      postId: props.postId,
      recipeId: props.recipeId,
      nav: props.nav,
      isFirst: props.menuOptions.includes("flag") && props.menuOptions.length === 1,
    });

    return (
      <OptionsMenu>
        {props.menuOptions.map(i => {
          switch (i) {
            case "delete": {
              return (
                <OptionsMenuItem
                  key={i}
                  isFirst
                  icon={"delete"}
                  text={strings.optionsMenu.delete}
                  onPress={onPressDeletePost}
                  waiting={waitingOnDelete}
                />
              );
            }
            case "flag": {
              return <React.Fragment key={i}>{reportContentIssueMenuItem}</React.Fragment>;
            }
            default:
              bottomThrow(i);
          }
        })}
      </OptionsMenu>
    );
  }
);

export function useLikeButtonCallback(screen: ScreenUtil, user?: AuthedUser) {
  const dispatch = useDispatch();
  const onPressLikeButton = useCallback<LikeButtonPressedHandler>(
    (postId, alreadyLiked) => {
      if (!user) {
        return;
      }
      if (alreadyLiked) {
        // Note: Redux and the API support unliking. The only thing stopping us from supporting it at this point
        // is handlign notifications - namely, deleting them.
        // Tracked in https://www.notion.so/deglaze/Bug-Unliking-a-post-isn-t-working-b8dab70c5f364ecd96a1ae34ddc73f09
        screen.nav.goTo("push", navTree.get.screens.likes, { postId });
        return;
      }

      Haptics.feedback("itemStatusChanged");
      dispatch(
        likeChangedClient(
          {
            postId,
            action: "like",
            time: defaultTimeProvider(),
          },
          screen.nav
        )
      );

      setTimeout(() => {
        dispatch(maybePromptForReview("Social Post Liked"));
      }, 500);
    },
    [screen.nav, user, dispatch]
  );

  return onPressLikeButton;
}

export function useLikesBarCallback(screen: ScreenUtil) {
  const dispatch = useDispatch();
  const onPressLikesBar = useCallback(
    (postId: SocialPostId) => {
      dispatch(analyticsEvent(reportPostLikesBarTapped()));
      screen.nav.goTo("push", navTree.get.screens.likes, { postId });
    },
    [dispatch, screen.nav.goTo]
  );

  return onPressLikesBar;
}

export function usePostSharedCallback() {
  const dispatch = useDispatch();
  const onSharePost = useCallback(
    async (postId: SocialPostId) => {
      await dispatch(sharePostLink({ postId }));
    },
    [dispatch]
  );

  return onSharePost;
}

export function useCommentButtonCallback(screen: ScreenUtil) {
  const onPressCommentButton = useCallback(
    (postId: SocialPostId) => {
      screen.nav.goTo("push", navTree.get.screens.postComments, { postId, focusInput: true });
    },
    [screen.nav.goTo]
  );

  return onPressCommentButton;
}

export function usePostPressedCallback(screen: ScreenUtil) {
  const dispatch = useDispatch();
  const onPressPost = useCallback(
    (postId: SocialPostId) => {
      if (!dispatch(navToCookingSessionIfExists({ type: "post", nav: screen.nav.switchTab, postId }))) {
        screen.nav.goTo("push", navTree.get.screens.postViewRecipe, { postId });
      }
    },
    [screen.nav.goTo, screen.nav.switchTab]
  );

  return onPressPost;
}

const inlineCommentCount = 2;

export const SocialPostComponent = React.memo((props: Props) => {
  const screen = useScreen();

  const post = usePost(props.postOrId);
  const liked = usePostLiked(post?.id);
  const user = useAuthedUser();

  const onPressShare = usePostSharedCallback();
  const onPressLikeButton = useLikeButtonCallback(screen, user);
  const onPressLikesBar = useLikesBarCallback(screen);
  const onPressComment = useCommentButtonCallback(screen);
  const onPressPost = usePostPressedCallback(screen);
  const onPressOptionsMenu = usePostOptionsMenu(post?.id);

  const onPressLike = useCallback(
    (alreadyLiked: boolean) => {
      if (post) {
        LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
        onPressLikeButton(post.id, alreadyLiked);
      }
    },
    [post]
  );

  if (!post) {
    return <FlexedSpinner debugText="SocialPost" />;
  }

  switch (post.type) {
    case "textPost": {
      return (
        <TextPostCard
          post={post}
          liked={liked}
          onPressPost={onPressComment}
          onPressOptionsMenu={onPressOptionsMenu}
          onPressShare={onPressShare}
          onPressLikesBar={onPressLikesBar}
          onPressLike={onPressLike}
          onPressComment={onPressComment}
        />
      );
    }
    case "userRecipeActionPost": {
      return (
        <CookPostCard
          post={post}
          liked={liked}
          isDetailView={props.isDetailView}
          onPressPost={onPressPost}
          onPressOptionsMenu={onPressOptionsMenu}
          onPressLikesBar={onPressLikesBar}
          onPressShare={onPressShare}
          onPressLike={onPressLike}
          onPressComment={onPressComment}
        />
      );
    }
    case "newRecipePost": {
      return (
        <NewRecipePostCard
          post={post}
          liked={liked}
          isDetailView={props.isDetailView}
          onPressPost={onPressPost}
          onPressOptionsMenu={onPressOptionsMenu}
          onPressLikesBar={onPressLikesBar}
          onPressShare={onPressShare}
          onPressLike={onPressLike}
          onPressComment={onPressComment}
        />
      );
    }
    default: {
      bottomLog(post, "SocialPostCard.render", log);
      return <></>;
    }
  }
});

interface SocialPostCardPropsBase<T extends SocialPost> {
  post: Omit<T, "likes">;
  liked: boolean;
  isDetailView?: boolean;
  onPressLike: (alreadyLiked: boolean) => void;
  onPressPost?: PostInteractionHandler;
  onPressOptionsMenu?: PostInteractionHandler;
  onPressLikesBar: PostInteractionHandler;
  onPressShare: PostInteractionHandler;
  onPressComment: PostInteractionHandler;
}

const CookPostCard = React.memo((props: SocialPostCardPropsBase<UserRecipeActionPost>) => {
  return (
    <RecipePostCard
      postType="userRecipeActionPost"
      postId={props.post.id}
      entity={props.post.user}
      recipeInfo={props.post.recipeInfo}
      onPressPost={props.onPressPost}
      onPressOptionsMenuButton={props.onPressOptionsMenu}
      onPressLikesBar={props.onPressLikesBar}
      onPressCommentButton={props.onPressComment}
      onPressLikeButton={props.onPressLike}
      onPressShare={props.onPressShare}
      isDetailView={!!props.isDetailView}
      userRecipeStats={props.post.userRecipeStats}
      recipeRating={props.post.rating}
      liked={props.liked}
      comments={props.post.comments}
    />
  );
});

const NewRecipePostCard = React.memo((props: SocialPostCardPropsBase<NewRecipePost>) => {
  return (
    <RecipePostCard
      postType="newRecipePost"
      postId={props.post.id}
      entity={props.post.entity}
      recipeInfo={props.post.recipeInfo}
      onPressPost={props.onPressPost}
      onPressOptionsMenuButton={props.onPressOptionsMenu}
      onPressLikesBar={props.onPressLikesBar}
      onPressCommentButton={props.onPressComment}
      onPressLikeButton={props.onPressLike}
      onPressShare={props.onPressShare}
      isDetailView={!!props.isDetailView}
      liked={props.liked}
      comments={props.post.comments}
    />
  );
});

interface RecipePostCardProps {
  postType: SocialPost["type"];
  postId: SocialPostId;
  entity: SocialEntity;
  recipeInfo: RecipeInfo;
  onPressPost?: PostInteractionHandler;
  onPressOptionsMenuButton?: PostInteractionHandler;
  onPressLikesBar: PostInteractionHandler;
  onPressCommentButton: PostInteractionHandler;
  onPressLikeButton: (alreadyLiked: boolean) => void;
  onPressShare: PostInteractionHandler;
  isDetailView: boolean;
  userRecipeStats?: Pick<UserRecipeStats, "cooked">;
  recipeRating?: RecipeRating;
  liked: boolean;
  comments: PostComments;
}

const RecipePostCard = React.memo((props: RecipePostCardProps) => {
  const onPressPost = useCallback(() => {
    props.onPressPost?.(props.postId);
  }, [props.onPressPost, props.postId]);

  const onPressOptionsMenuButton = useCallback(() => {
    props.onPressOptionsMenuButton?.(props.postId);
  }, [props.onPressOptionsMenuButton, props.postId]);

  const onPressLikeBar = useCallback(() => {
    props.onPressLikesBar(props.postId);
  }, [props.onPressLikesBar, props.postId]);

  const onPressComment = useCallback(() => {
    props.onPressCommentButton(props.postId);
  }, [props.onPressCommentButton, props.postId]);

  const onPressLike = useCallback(() => {
    props.onPressLikeButton(props.liked);
  }, [props.onPressLikeButton, props.liked]);

  const onPressShare = useCallback(() => {
    props.onPressShare(props.postId);
  }, [props.onPressShare, props.postId]);

  return (
    <View style={{ flex: 1 }}>
      <Pressable onPress={onPressPost} noFeedback singlePress>
        <View style={props.isDetailView ? styles.detail : styles.card}>
          <Spacer vertical={1} />
          <View style={{ paddingHorizontal: globalStyleConstants.unitSize }}>
            <SocialPostHeading
              type={props.postType}
              postId={props.postId}
              cookCount={props.userRecipeStats?.cooked}
              recipeRating={props?.recipeRating}
              entity={props.entity}
              onPressOptionsMoreButton={props.onPressOptionsMenuButton ? onPressOptionsMenuButton : undefined}
            />
          </View>
          <Spacer vertical={1} />
          <SocialPostRecipeCard recipeInfo={props.recipeInfo} />
          <Spacer vertical={1} />
          <LikesAndSavesBar
            postId={props.postId}
            liked={props.liked}
            onPressLikes={onPressLikeBar}
            onPressLike={onPressLike}
            onPressComment={onPressComment}
            onPressShare={onPressShare}
          />
          {props.comments.count > 0 && (
            <>
              <SocialPostInlineComments comments={props.comments.items} onPress={onPressComment} />
              <Spacer vertical={0.5} />
            </>
          )}
          <Spacer vertical={1} />
        </View>
      </Pressable>
      {!props.isDetailView && (
        <View style={{ paddingHorizontal: globalStyleConstants.minPadding }}>
          <Separator orientation="row" color={"rgba(0,0,0,0.1)"} />
        </View>
      )}
    </View>
  );
});

export interface SocialPostRecipeCardProps {
  recipeInfo: RecipeInfo;
}

export const SocialPostRecipeCard = React.memo((props: SocialPostRecipeCardProps) => {
  const dimensions = useResponsiveDimensions();
  const hasPhoto = recipeHasUsablePhoto(props.recipeInfo);

  const size = dimensions.isLargeScreen
    ? styles.recipePhotoLargeScreen
    : dimensions.isMediumScreen
    ? styles.recipePhotoMediumScreen
    : styles.recipePhotoSmallScreen;

  return (
    <View style={styles.recipeCard}>
      <View
        style={{
          paddingHorizontal: globalStyleConstants.unitSize,
          paddingTop: 16,
        }}
      >
        <RecipeTitleAndSource
          title={props.recipeInfo.title}
          author={props.recipeInfo.author}
          book={props.recipeInfo.book}
          publisher={props.recipeInfo.publisher}
          source={props.recipeInfo.source}
          userEnteredAttribution={props.recipeInfo.userEnteredAttribution}
        />
      </View>
      <Spacer vertical={1} />
      {hasPhoto && (
        <View style={[styles.recipePhoto, size]}>
          <RecipePhoto recipe={props.recipeInfo} sourceSize="w1290" />
        </View>
      )}
    </View>
  );
});

const TextPostCard = React.memo((props: SocialPostCardPropsBase<TextPost>) => {
  const onPressPost = useCallback(() => {
    props.onPressPost?.(props.post.id);
  }, [props.onPressPost, props.post.id]);

  const onPressOptionsMenuButton = useCallback(() => {
    props.onPressOptionsMenu?.(props.post.id);
  }, [props.onPressOptionsMenu, props.post.id]);

  const onPressLikeBar = useCallback(() => {
    props.onPressLikesBar(props.post.id);
  }, [props.onPressLikesBar, props.post.id]);

  const onPressComment = useCallback(() => {
    props.onPressComment(props.post.id);
  }, [props.onPressComment, props.post.id]);

  const onPressLike = useCallback(() => {
    props.onPressLike(props.liked);
  }, [props.onPressLike, props.liked]);

  const onPressShare = useCallback(() => {}, []);

  return (
    <View style={{ flex: 1 }}>
      <Pressable onPress={onPressPost} noFeedback singlePress>
        <View style={styles.card}>
          <Spacer vertical={1} />
          <View style={{ paddingHorizontal: globalStyleConstants.unitSize }}>
            <SocialPostHeading
              type="textPost"
              postId={props.post.id}
              entity={props.post.user}
              onPressOptionsMoreButton={props.onPressOptionsMenu ? onPressOptionsMenuButton : undefined}
            />
          </View>
          <Spacer vertical={1.5} />
          <TextPostBody body={props.post.body} />
          <Spacer vertical={1} />
          <LikesAndSavesBar
            postId={props.post.id}
            liked={props.liked}
            onPressLikes={onPressLikeBar}
            onPressLike={onPressLike}
            onPressComment={onPressComment}
            onPressShare={onPressShare}
          />
          {props.liked && <Spacer vertical={0.5} />}
          <SocialPostInlineComments comments={props.post.comments.items} onPress={onPressComment} />
          <Spacer vertical={1} />
        </View>
      </Pressable>
      {!props.isDetailView && (
        <View style={{ paddingHorizontal: globalStyleConstants.minPadding }}>
          <Separator orientation="row" color={"rgba(0,0,0,0.1)"} />
        </View>
      )}
    </View>
  );
});

export const TextPostBody = React.memo((props: { body: string }) => {
  return (
    <View
      style={{
        padding: globalStyleConstants.unitSize,
        borderRadius: 20,
        backgroundColor: globalStyleColors.white,
        ...globalStyles.shadowItem,
      }}
    >
      <Spacer vertical={0.5} />
      <AutoLink location="postText">
        <View style={{ flexShrink: 1 }}>
          <TBody adjustsFontSizeToFit numberOfLines={12} fixEmojiLineHeight>
            {props.body}
          </TBody>
        </View>
      </AutoLink>
    </View>
  );
});

const RecipeTitleAndSource = React.memo(
  (props: {
    title: string;
    source: RecipeSource;
    author?: Author;
    publisher?: Publisher;
    book?: Book;
    userEnteredAttribution: UserEnteredAttribution | undefined;
  }) => {
    const publisherOrBook = props.book ?? props.publisher;

    return (
      <>
        {(!!publisherOrBook || props.source.type === "userPhoto") && (
          <>
            <RecipePublisherOrBook
              author={props.author}
              book={props.book}
              userEnteredAttribution={props.userEnteredAttribution}
              publisher={props.publisher}
              sourceType={props.source.type}
              fontSize="secondary"
              numberOfLines={1}
              photoStyle="square"
            />
            <Spacer vertical={1.5} />
          </>
        )}
        <RecipeTitle title={props.title} fontSize="body" numberOfLines={6} />
        <Spacer vertical={0.5} />
        {!!props.author && (
          <RecipeAuthor
            author={props.author}
            userEnteredAttribution={props.userEnteredAttribution}
            sourceType={props.source.type}
            book={props.book}
            fontSize="secondary"
            numberOfLines={1}
          />
        )}
      </>
    );
  }
);

const SocialPostInlineComments = React.memo((props: { comments: Comment[]; onPress: () => void }) => {
  const screen = useScreen();
  const dispatch = useDispatch();

  const onPressInlineCommentRecipe = useCallback<(args: ShareViewRecipeScreenProps) => void>(
    args => {
      if (
        !dispatch(
          navToCookingSessionIfExists({ type: "share", nav: screen.nav.switchTab, recipeId: args.sourceRecipeId })
        )
      ) {
        screen.nav.goTo("push", navTree.get.screens.shareViewRecipe, args);
      }
    },
    [screen.nav.goTo, screen.nav.switchTab]
  );

  return (
    <>
      {props.comments.length > 0 && (
        <Pressable onPress={props.onPress} noFeedback>
          <ContainerPadded left={1} right={1}>
            {props.comments.slice(0, inlineCommentCount).map(c => {
              return (
                <View key={c.id}>
                  <Spacer vertical={0.5} />
                  <InlineComment comment={c} onPressRecipe={onPressInlineCommentRecipe} />
                </View>
              );
            })}
            {props.comments.length > inlineCommentCount && (
              <>
                <Spacer vertical={0.5} />
                <TSecondary opacity="light">{strings.comment.seeAll(props.comments.length)}</TSecondary>
              </>
            )}
          </ContainerPadded>
        </Pressable>
      )}
    </>
  );
});

const LikesAndSavesBar = React.memo(
  (props: {
    postId: SocialPostId;
    liked: boolean;
    onPressLikes: () => void;
    onPressLike: (alreadyLiked: boolean) => void;
    onPressComment: () => void;
    onPressShare: () => void;
  }) => {
    const authedUserId = useAuthedUserId();
    const likes = usePostLikes(props.postId);
    const saveCount = usePostSaveCount(props.postId);

    const authedUserIsOnlyLike = likes.length === 1 && likes[0]?.user.userId === authedUserId;

    return (
      <Pressable
        noFeedback
        singlePress
        disabled={likes.length === 0 || authedUserIsOnlyLike}
        onPress={props.onPressLikes}
      >
        <View style={styles.likesAndActions}>
          <View style={styles.likes}>
            {likes.length > 0 && (
              <>
                <LikeAvatars likes={likes} />
                <Spacer horizontal={1} />
              </>
            )}
            <LikesAndSavesText likeCount={likes.length} liked={props.liked} saveCount={saveCount} />
          </View>
          <PostActionButtons
            disableLikeButton={authedUserIsOnlyLike}
            liked={props.liked}
            onPressLike={props.onPressLike}
            onPressComment={props.onPressComment}
            onPressShare={props.onPressShare}
          />
        </View>
      </Pressable>
    );
  }
);

const LikeAvatars = React.memo((props: { likes: Like[] }) => {
  const overlapAmount = 6;

  const avatars = props.likes.slice(0, 3).map((l, idx) => {
    const shiftLeftAmount = -overlapAmount * idx;
    return (
      <View
        key={l.user.userId}
        style={{
          transform: [{ translateX: shiftLeftAmount }],
          shadowColor: globalStyleColors.colorGreyLight,
          shadowOpacity: 1,
          shadowRadius: 0,
          shadowOffset: { height: 0, width: -2 },
          backgroundColor: globalStyleColors.colorGreyLight,
          borderRadius: Number.MAX_SAFE_INTEGER,
        }}
      >
        <Photo style="avatarXsmall" source={l.user.photo} sourceSize="w288" />
      </View>
    );
  });

  return <View style={{ flexDirection: "row", marginRight: -overlapAmount * (avatars.length - 1) }}>{avatars}</View>;
});

const LikesAndSavesText = React.memo((props: { likeCount: number; liked: boolean; saveCount: number }) => {
  // if we have no activity, show the CTA to like the post
  if (props.likeCount === 0 && props.saveCount === 0) {
    return (
      <View>
        <TTertiary opacity="medium">{strings.like.zeroLikes}</TTertiary>
      </View>
    );
  }

  const likeCountText = props.likeCount + (props.likeCount === 1 ? strings.like.one : strings.like.multiple);
  const saveCountText = props.saveCount + (props.saveCount === 1 ? strings.save.one : strings.save.multiple);

  if (props.saveCount === 0) {
    return <LikeOrSaveCountText text={likeCountText} />;
  }

  if (props.likeCount === 0) {
    return <LikeOrSaveCountText text={saveCountText} />;
  }

  return (
    <View style={{ flexDirection: "row", alignItems: "center" }}>
      <LikeOrSaveCountText text={likeCountText} />
      <View style={{ height: "60%", paddingHorizontal: globalStyleConstants.minPadding }}>
        <Separator orientation="column" />
      </View>
      <LikeOrSaveCountText text={saveCountText} />
    </View>
  );
});

const LikeOrSaveCountText = (props: { text: string }) => {
  return (
    <View>
      <TSecondary opacity="medium">{props.text}</TSecondary>
    </View>
  );
};

const PostActionButtons = React.memo(
  (props: {
    disableLikeButton: boolean;
    liked: boolean;
    onPressLike: (alreadyLiked: boolean) => void;
    onPressComment: () => void;
    onPressShare: () => void;
  }) => (
    <View style={{ flexDirection: "row", width: "auto", alignItems: "center" }}>
      <ShareButton onPress={props.onPressShare} />
      <Spacer horizontal={globalStyleConstants.defaultPadding} unit="pixels" />
      <CommentButton onPress={props.onPressComment} />
      <Spacer horizontal={globalStyleConstants.defaultPadding} unit="pixels" />
      <LikeButton disableLikeButton={props.disableLikeButton} onPress={props.onPressLike} liked={props.liked} />
    </View>
  )
);

const ShareButton = React.memo((props: { onPress: () => void }) => {
  return <IconButton type="share" onPress={props.onPress} hitSlop={globalStyleConstants.unitSize} />;
});

const LikeButton = React.memo(
  (props: { disableLikeButton: boolean; liked: boolean; onPress: (alreadyLiked: boolean) => void }) => {
    const onPress = useCallback(() => {
      props.onPress(props.liked);
    }, [props.onPress, props.liked]);

    return (
      <Pressable
        onPress={onPress}
        disabled={props.disableLikeButton}
        noFeedback={props.disableLikeButton}
        hitSlop={globalStyleConstants.unitSize}
      >
        {props.liked ? <IconLikeFilled opacity="opaque" /> : <IconLike opacity="opaque" />}
      </Pressable>
    );
  }
);

const CommentButton = React.memo((props: { onPress: () => void }) => (
  <IconButton type="comment" onPress={props.onPress} hitSlop={globalStyleConstants.unitSize} singlePress />
));

const styles = StyleSheet.create({
  card: {
    flex: 1,
    paddingHorizontal: 8,
    borderTopLeftRadius: globalStyleConstants.unitSize,
    borderTopRightRadius: globalStyleConstants.unitSize,
    zIndex: 2,
  },
  detail: {
    flex: 1,
    paddingHorizontal: globalStyleConstants.unitSize,
    zIndex: 2,
  },
  likes: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  likesAndActions: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    height: 3 * globalStyleConstants.unitSize,
    paddingHorizontal: globalStyleConstants.unitSize,
  },
  recipeCard: {
    borderRadius: 20,
    backgroundColor: "white",
    shadowColor: "black",
    shadowOpacity: 0.2,
    shadowOffset: { width: 0, height: 6 },
    shadowRadius: 12,
  },
  recipePhoto: {
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
    overflow: "hidden",
  },
  recipePhotoSmallScreen: {
    height: 256,
  },
  recipePhotoMediumScreen: {
    height: smallScreenBreakpoint / 1.5,
  },
  recipePhotoLargeScreen: {
    height: smallScreenBreakpoint / 1.5,
  },
});

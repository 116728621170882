import { Reactor } from "../redux/Reactors";
import { shouldFetch } from "../redux/ShouldFetch";
import { getFutureMealPlan } from "./PlanningThunks";

const fetchMealPlanReactor: Reactor = state => {
  const fetchResult = shouldFetch("planning.mealPlan", state, s => s.planning.futurePlanMeta, {
    staleThresholdSeconds: 300,
  });

  if (fetchResult.now) {
    return {
      dispatch: getFutureMealPlan(),
    };
  }

  if (fetchResult.laterIn) {
    return {
      kickInMs: fetchResult.laterIn,
    };
  }

  return undefined;
};

export const planningReactors = [fetchMealPlanReactor];

import { getCreateSelectorWithCacheSize } from "../redux/CreateSelector.ts";
import {
  AppRecipeCollection,
  AppTagCollection,
  cookingAndShoppedCollectionId,
  RecipeCollectionId,
  RecipeTag,
} from "@eatbetter/recipes-shared";
import { RootState } from "../redux/RootReducer.ts";
import { bottomWithDefault } from "@eatbetter/common-shared";
import { selectSearchSession } from "../search/SearchSlice.ts";
import { RecipeFilters, selectLibraryFilterSession } from "../recipes/RecipesSlice.ts";
import { getAppFilterCollectionMeta } from "../recipes/AppFilterCollections.ts";
import { useSelector } from "../redux/Redux.ts";
import { useDeferredValue, useMemo } from "react";
import { isGlobalSearchSessionId, LibraryOrSearchSessionId } from "./LibraryAndSearchSessionIds.ts";

/**
 * This improves UI performance by deferring the expensive selectors until after the UI updates state - for example,
 * typing a character in the search input
 */
export const useDeferredFilters = (sessionId: LibraryOrSearchSessionId) => {
  const currentFilters = useSelector(s => selectFilters(s, sessionId)) ?? {};
  const deferredFilters = useDeferredValue(currentFilters);
  const deferred = currentFilters !== deferredFilters;

  return useMemo(() => {
    return { deferred, filters: deferredFilters };
  }, [deferred, deferredFilters]);
};

export const selectCollectionsById = getCreateSelectorWithCacheSize(1)(
  [s => selectKnownAndNonDeletedCollections(s)],
  collections => {
    const collectionsById: Record<RecipeCollectionId, AppRecipeCollection> = {};

    if (collections) {
      collections.collections.forEach(c => (collectionsById[c.id] = c));
    }

    return collectionsById;
  }
);

export const selectGetCollectionByTag: (s: RootState) => (t: RecipeTag) => AppTagCollection | undefined =
  getCreateSelectorWithCacheSize(1)([s => selectKnownAndNonDeletedCollections(s)], collections => {
    const systemMap: Record<string, AppTagCollection> = {};
    const userMap: Record<string, AppTagCollection> = {};

    collections?.collections.forEach(c => {
      if (c.type !== "tag") {
        return;
      }

      c.systemTags.forEach(st => (systemMap[st.toLowerCase()] = c));
      c.userTags.forEach(ut => (userMap[ut.toLowerCase()] = c));
    });

    return (t: RecipeTag) => {
      switch (t.type) {
        case "user":
          return userMap[t.tag.toLowerCase()];
        case "system":
          return systemMap[t.tag.toLowerCase()];
        default:
          return bottomWithDefault(t, undefined, "selectGetCollectionByTag");
      }
    };
  });

export function selectFilters(s: RootState, sessionId: LibraryOrSearchSessionId): RecipeFilters | undefined {
  if (isGlobalSearchSessionId(sessionId)) {
    return selectSearchSession(s.search, sessionId)?.filters;
  } else {
    return selectLibraryFilterSession(s.recipes, sessionId)?.filters;
  }
}

/**
 * Filters out deleted collections and unknown filter collections. This should be used for anything consuming collections below.
 */
export const selectKnownAndNonDeletedCollections = getCreateSelectorWithCacheSize(1)(
  [s => s.recipes.collections],
  collections => {
    if (!collections) {
      return undefined;
    }

    // Active recipes collection ID is special cased - it doesn't have a predicate that works off the recipe
    const filteredCollections =
      collections.collections.filter(
        c =>
          !c.deleted &&
          (c.type === "tag" || c.id === cookingAndShoppedCollectionId || !!getAppFilterCollectionMeta(c.id))
      ) ?? [];

    return {
      ...collections,
      collections: filteredCollections,
    };
  }
);

import React, { useCallback } from "react";
import { SearchViewRecipeScreenProps } from "../navigation/NavTree";
import { withScreenContainer } from "../navigation/ScreenContainer";
import { ViewRecipeScreenComponent, ViewRecipeScreenComponentProps } from "../components/ViewRecipeScreenComponent";
import { PartialRecipeId, RecipeId } from "@eatbetter/recipes-shared";
import { useDispatch } from "../lib/redux/Redux";
import { useIdempotentId } from "../lib/util/UseIdempotentId";
import { saveRecipeFromSearch } from "../lib/search/SearchThunks";
import { parseInteger } from "@eatbetter/common-shared";
import { GlobalSearchSessionId } from "../lib/composite/LibraryAndSearchSessionIds.ts";

// NOTE: This screen is very similar to PostViewRecipeScreen, ShareViewRecipeScreen
// Updates here may apply there as well.
export const SearchViewRecipeScreen = withScreenContainer(
  "SearchViewRecipeScreen",
  (props: SearchViewRecipeScreenProps) => {
    const dispatch = useDispatch();
    const [id, refreshId] = useIdempotentId<PartialRecipeId>();

    const onSaveRecipe = useCallback(async () => {
      await dispatch(saveRecipeFromSearch({ recipeId: props.recipeId, partialRecipeId: id }, props.searchSessionId));
      refreshId();
    }, [props.recipeId, id, refreshId]);

    return React.createElement<ViewRecipeScreenComponentProps>(ViewRecipeScreenComponent, {
      context: "search",
      recipeId: props.recipeId,
      onSaveRecipe,
      searchSessionId: props.searchSessionId,
      searchResultIndex: props.searchResultIndex,
    });
  },
  {
    serializer: {
      recipeId: s => s,
      searchSessionId: s => s,
      searchResultIndex: s => s.toString(),
    },
    parser: {
      recipeId: s => s as RecipeId,
      searchSessionId: s => s as GlobalSearchSessionId,
      searchResultIndex: s => parseInteger(s),
    },
  }
);

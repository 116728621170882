import React, { useCallback } from "react";
import { UserRecipeId } from "@eatbetter/recipes-shared";
import { StyleSheet, View } from "react-native";
import { useRecipeNotes } from "../../lib/recipes/RecipesSelectors";
import { globalStyleConstants, globalStyles } from "../GlobalStyles";
import { IconChevronRight } from "../Icons";
import { TSecondary } from "../Typography";
import { Spacer } from "../Spacer";
import { useScreen } from "../../navigation/ScreenContainer";
import { navTree, RecipeEditFieldLocation } from "../../navigation/NavTree";
import { Pressable } from "../Pressable";

const strings = {
  notes: "Notes",
  noNotes: "No notes.",
  addNew: "Add notes",
};

interface Props {
  recipeId: UserRecipeId;
  style?: "default" | "compact" | "textOnly";
  disabled?: boolean;
  location: RecipeEditFieldLocation;
  noShadow?: boolean;
}

export const RecipeNotes = (props: Props) => {
  const notes = useRecipeNotes(props.recipeId);
  const screen = useScreen();

  const onPress = useCallback(() => {
    screen.nav.goTo("push", navTree.get.screens.recipeNotesEdit, {
      recipeId: props.recipeId,
      recipeEditFieldLocation: props.location,
    });
  }, [screen.nav.goTo, props.location, props.recipeId]);

  const singleLineNotes = (
    <>
      {!notes && (
        <>
          <TSecondary opacity="light">{strings.addNew}</TSecondary>
          <Spacer horizontal={1} />
        </>
      )}
      {!!notes && (
        <>
          <Spacer horizontal={2} />
          <View style={{ flexShrink: 1 }}>
            <TSecondary numberOfLines={1} opacity="medium">
              {notes}
            </TSecondary>
          </View>
          <Spacer horizontal={1} />
        </>
      )}
    </>
  );

  if (props.style === "textOnly") {
    return singleLineNotes;
  }

  return (
    <Pressable
      style={[styles.container, props.noShadow ? {} : globalStyles.shadowItem]}
      onPress={onPress}
      noFeedback
      disabled={props.disabled}
    >
      <View style={styles.heading}>
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <TSecondary>{strings.notes}</TSecondary>
          <Spacer horizontal={0.5} />
          <PrivateBadge />
        </View>
        <View style={styles.headingContent}>
          {props.style === "compact" && singleLineNotes}
          <IconChevronRight />
        </View>
      </View>
      {props.style !== "compact" && (
        <>
          <Spacer vertical={1} />
          <View>
            {!notes && <TSecondary opacity="medium">{strings.noNotes}</TSecondary>}
            {!!notes && <TSecondary opacity="opaque">{notes}</TSecondary>}
          </View>
        </>
      )}
    </Pressable>
  );
};

const PrivateBadge = React.memo(() => {
  return (
    <View>
      <TSecondary opacity="light">{"(private)"}</TSecondary>
    </View>
  );
});

const styles = StyleSheet.create({
  container: {
    padding: globalStyleConstants.unitSize,
    backgroundColor: "white",
    borderRadius: 20,
  },
  heading: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    height: 32,
  },
  headingContent: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
  },
});

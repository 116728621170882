import React, { useCallback, useRef } from "react";
import { useScreen, withScreenContainer } from "../navigation/ScreenContainer";
import {
  EditUserRecipeOnSaveArgs,
  RecipeEditControl,
  useRecipeEditSaveHeader,
} from "../components/recipes/RecipeEditControl";
import { useIdempotentId } from "../lib/util/UseIdempotentId";
import { AddRecipeArgs, PartialRecipeId, RecipeCollectionId } from "@eatbetter/recipes-shared";
import { useDispatch } from "../lib/redux/Redux";
import { addManualRecipe, getSavedRecipeDraft } from "../lib/recipes/RecipesThunks";
import { ScreenView } from "../components/ScreenView";
import { addPhoto } from "../lib/photos/PhotoThunks";
import { debounce } from "lodash";
import { saveNewRecipeDraft } from "../lib/recipes/RecipesSlice";
import { useBottomTabBarDimensions } from "../navigation/TabBar";
import { useScreenHeaderDimensions } from "../components/ScreenHeaders";
import { ScrollView } from "react-native-gesture-handler";
import { useKeyboardLayoutAnimation } from "../components/Keyboard";
import { globalStyleConstants } from "../components/GlobalStyles";
import { RecipeAddManualScreenProps } from "../navigation/NavTree";

const strings = {
  title: "New Recipe",
  save: "Save",
};

export const RecipeAddManualScreen = withScreenContainer<RecipeAddManualScreenProps>(
  "RecipeAddManualScreen",
  props => {
    const dispatch = useDispatch();
    const screen = useScreen();
    const [newId, refreshId] = useIdempotentId<PartialRecipeId>();
    const savedDraft = useRef(dispatch(getSavedRecipeDraft())).current;

    const onSave = useCallback(
      async (args: EditUserRecipeOnSaveArgs) => {
        const newPhoto = args.newRecipePhoto ? await dispatch(addPhoto(args.newRecipePhoto)) : undefined;

        const addRecipeArgs: AddRecipeArgs = {
          id: newId,
          description: args.description,
          ingredients: args.ingredients,
          instructions: args.instructions,
          photo: newPhoto,
          title: args.title,
          time: args.time,
          recipeYield: args.recipeYield,
        };

        await dispatch(addManualRecipe(addRecipeArgs, props.collectionId));
        refreshId();

        // nav back on success
        // setImmediate prevents a react state update warning
        setImmediate(screen.nav.goBack);
      },
      [newId, refreshId, dispatch, screen.nav.goBack, props.collectionId]
    );

    const onDraftChange = useCallback(
      debounce((args: EditUserRecipeOnSaveArgs) => {
        dispatch(saveNewRecipeDraft(args));
      }, 1000),
      []
    );

    return React.createElement(RecipeAddManualScreenComponent, {
      onSave,
      onDraftChange,
      savedDraft,
    });
  },
  {
    parser: {
      collectionId: {
        fn: s => s as RecipeCollectionId,
        optional: true,
      },
    },
    serializer: {
      collectionId: {
        fn: s => s,
        optional: true,
      },
    },
  }
);

interface Props {
  onSave: (args: EditUserRecipeOnSaveArgs) => Promise<void>;
  onDraftChange: (args: EditUserRecipeOnSaveArgs) => void;
  savedDraft?: EditUserRecipeOnSaveArgs;
}

export const RecipeAddManualScreenComponent = (props: Props) => {
  const { headerHeight } = useScreenHeaderDimensions();
  const { bottomTabBarHeight } = useBottomTabBarDimensions();
  const keyboardHeight = useKeyboardLayoutAnimation();

  const { recipeEditRef, screenHeader } = useRecipeEditSaveHeader(strings.title);

  return (
    <ScreenView scrollView={false} paddingVertical={false} paddingHorizontal={false} header={screenHeader}>
      <ScrollView
        keyboardShouldPersistTaps="handled"
        contentContainerStyle={{
          paddingHorizontal: globalStyleConstants.defaultPadding,
          paddingTop: headerHeight + 1.5 * globalStyleConstants.unitSize,
          paddingBottom: (keyboardHeight > 0 ? keyboardHeight : bottomTabBarHeight) + 2 * globalStyleConstants.unitSize,
        }}
      >
        <RecipeEditControl
          ref={recipeEditRef}
          onSave={props.onSave}
          onChange={props.onDraftChange}
          initialDraftArgs={props.savedDraft}
          type="user"
          showSaveButton={false}
        />
      </ScrollView>
    </ScreenView>
  );
};

import { useCallback } from "react";
import { useBottomSheet } from "../../screens/BottomSheetScreen";
import { useDispatch } from "../../lib/redux/Redux";
import { analyticsEvent } from "../../lib/analytics/AnalyticsThunks";
import {
  reportLibraryRecipeContextMenuItemTapped,
  reportPostContextMenuItemTapped,
} from "../../lib/analytics/AnalyticsEvents";
import { NavApi } from "../../navigation/ScreenContainer";
import { navTree } from "../../navigation/NavTree";
import { OptionsMenuItem } from "../OptionsMenu";
import { bottomThrow } from "@eatbetter/common-shared";
import { RecipeId } from "@eatbetter/recipes-shared";
import { SocialEntityId, SocialPostId } from "@eatbetter/posts-shared";

const strings = {
  reportIssue: "Report Content Issue",
};

export function useReportContentIssueMenuItem(props: {
  context: "post" | "recipeDetail";
  recipeId?: RecipeId;
  postId?: SocialPostId;
  contentOwnerId?: SocialEntityId;
  nav: NavApi;
  isFirst: boolean;
}) {
  const dispatch = useDispatch();
  const bottomSheet = useBottomSheet();

  const onPressReportPost = useCallback(() => {
    bottomSheet?.closeSheetAndGoBack();

    switch (props.context) {
      case "post": {
        dispatch(analyticsEvent(reportPostContextMenuItemTapped({ action: "reportContent" })));
        break;
      }
      case "recipeDetail": {
        dispatch(analyticsEvent(reportLibraryRecipeContextMenuItemTapped({ action: "reportUserContentIssue" })));
        break;
      }
      default:
        bottomThrow(props.context);
    }
    setTimeout(() => {
      props.nav.goTo("push", navTree.get.screens.reportContentIssue, {
        recipeId: props.recipeId,
        postId: props.postId,
        contentOwnerId: props.contentOwnerId,
      }),
        300;
    });
  }, [props.nav.goTo, props.recipeId, props.postId, props.contentOwnerId, bottomSheet?.closeSheetAndGoBack]);

  const menuItem = (
    <OptionsMenuItem icon="flag" text={strings.reportIssue} onPress={onPressReportPost} isFirst={props.isFirst} />
  );

  return menuItem;
}

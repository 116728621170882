import { withScreenContainer } from "../navigation/ScreenContainer";
import { useCallback, useMemo, useRef, useState } from "react";
import { HeaderProps } from "../components/ScreenHeaders";
import { ScreenView } from "../components/ScreenView";
import { CalendarGrid, CalendarGridImperativeHandle } from "../components/CalendarGrid";
import { CalendarList, CalendarListImperativeHandle } from "../components/CalendarList";
import { RecipeInfo, RecipeId, UserPhotoSource, UserRecipeId } from "@eatbetter/recipes-shared";
import { defaultTimeProvider, EpochMs, getCalendarDayFromDate, UserId } from "@eatbetter/common-shared";
import { normalizeDateToMidnight, normalizeDateToMidnightEpochMs } from "../components/CalendarUtils";
import { Spacer } from "../components/Spacer";
import { LayoutAnimation, View } from "react-native";
import { useSharedValue } from "react-native-reanimated";
import { Haptics } from "../components/Haptics";
import { globalStyleColors } from "../components/GlobalStyles";
import { Pressable } from "../components/Pressable";
import { IconChevronDown, IconChevronUp } from "../components/Icons";
import React from "react";

const strings = {
  screenTitle: "Meal Plan",
  month: "Month",
  week: "Week",
};

// PLANNING-TODO
export interface PlanningNote {
  id: string;
  note: string;
}

// PLANNING-TODO
export type MealType = "breakfast" | "lunch" | "dinner" | "snack";

export type CalendarItem =
  | ({ type: "recipe"; id: string } & RecipeInfo & { mealType?: MealType })
  | ({ type: "note"; id: string } & PlanningNote & { mealType?: MealType });

export const PlanningHomeScreen = withScreenContainer("PlanningHomeScreen", () => {
  const [mode, setMode] = useState<"month" | "week">("week");

  const calendarListRef = useRef<CalendarListImperativeHandle>(null);
  const calendarGridRef = useRef<CalendarGridImperativeHandle>(null);

  // Ensure we use the normalized time without any time component
  const initialDate = useMemo(() => {
    const now = defaultTimeProvider();
    const normalized = normalizeDateToMidnight(new Date(now));
    return normalized.getTime() as EpochMs;
  }, []);

  const selectedDate = useSharedValue<EpochMs>(initialDate);

  const toggleCalendarMode = useCallback(
    (currentMode: "month" | "week") => {
      Haptics.feedback("itemStatusChanged");
      LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
      setMode(currentMode === "month" ? "week" : "month");
    },
    [setMode]
  );

  const onSelectDate = useCallback(
    (date: Date) => {
      const normalizedDate = normalizeDateToMidnight(date);
      selectedDate.value = normalizedDate.getTime() as EpochMs;
      calendarListRef.current?.scrollToDate(normalizedDate);
    },
    [selectedDate, calendarListRef]
  );

  const header = useMemo<HeaderProps>(() => {
    return {
      type: "custom",
      title: strings.screenTitle,
      style: "tabRoot",
      backgroundColor: "white",
      right: {
        type: "twoButtons",
        left: {
          type: "today",
          onPress: () => {
            Haptics.feedback("itemStatusChanged");
            const today = normalizeDateToMidnight(new Date());
            calendarGridRef.current?.scrollToDate(today);
            onSelectDate(today);
          },
        },
        right: {
          type: "menu",
          onPress: () => {},
        },
      },
    };
  }, [onSelectDate]);

  const onAddMeal = useCallback((_date: Date) => {}, []);

  const onScrollDateChanged = (date: EpochMs) => {
    "worklet";
    selectedDate.value = normalizeDateToMidnightEpochMs(date);
  };

  // Calculate dates for dot data using normalized dates
  const today = normalizeDateToMidnight(new Date());
  const tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1);
  const dayAfter = new Date(today);
  dayAfter.setDate(today.getDate() + 2);

  // Mock items data (interleaved recipes and notes)
  const items: Record<string, CalendarItem[]> = useMemo(() => {
    return {
      [getCalendarDayFromDate(tomorrow)]: [
        {
          type: "note",
          id: "note1",
          note: "Remember to buy ingredients for dinner",
        },
        {
          type: "recipe",
          id: "3" as RecipeId,
          title: "Dinner Recipe",
          source: {
            type: "userPhoto",
            userId: "user1" as UserId,
            recipeId: "3" as UserRecipeId,
            photos: [],
          } as UserPhotoSource,
          mealType: "dinner",
        },
        {
          type: "recipe",
          id: "1" as RecipeId,
          title: "Breakfast Recipe",
          source: {
            type: "userPhoto",
            userId: "user1" as UserId,
            recipeId: "1" as UserRecipeId,
            photos: [],
          } as UserPhotoSource,
        },
        {
          type: "recipe",
          id: "2" as RecipeId,
          title: "Lunch Recipe",
          source: {
            type: "userPhoto",
            userId: "user1" as UserId,
            recipeId: "2" as UserRecipeId,
            photos: [],
          } as UserPhotoSource,
        },
      ],
      [getCalendarDayFromDate(dayAfter)]: [
        {
          type: "note",
          id: "note2",
          note: "Weekly meal prep day",
        },
        {
          type: "recipe",
          id: "4" as RecipeId,
          title: "Lunch Recipe",
          source: {
            type: "userPhoto",
            userId: "user1" as UserId,
            recipeId: "4" as UserRecipeId,
            photos: [],
          } as UserPhotoSource,
          mealType: "lunch",
        },
      ],
    };
  }, []);

  return (
    <ScreenView header={header} scrollView={false} paddingHorizontal={false} paddingVertical={"headerAndBottomTabBar"}>
      <View>
        <CalendarGrid
          ref={calendarGridRef}
          mode={mode}
          onSelectDate={onSelectDate}
          selectedDate={selectedDate}
          scrollDirection="horizontal"
          dotData={{
            [getCalendarDayFromDate(tomorrow)]: {
              breakfast: true,
              lunch: true,
              dinner: true,
            },
            [getCalendarDayFromDate(dayAfter)]: {
              lunch: true,
            },
          }}
        />
      </View>
      <CalendarModeToggleButton mode={mode} onPress={toggleCalendarMode} />
      <Spacer vertical={1} />

      <View style={{ flex: 1 }}>
        <CalendarList
          ref={calendarListRef}
          onPressAdd={onAddMeal}
          items={items}
          onScrollDateChanged={onScrollDateChanged}
        />
      </View>
    </ScreenView>
  );
});

const CalendarModeToggleButton = React.memo(
  (props: { mode: "month" | "week"; onPress: (currentMode: "month" | "week") => void }) => {
    const onPress = useCallback(() => {
      props.onPress(props.mode);
    }, [props.mode, props.onPress]);

    return (
      <View style={{ zIndex: 10 }}>
        <View style={{ width: "100%" }}>
          <Pressable
            style={{
              position: "absolute",
              alignSelf: "center",
              borderRadius: 24,
              padding: 4,
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: globalStyleColors.white,
              borderColor: globalStyleColors.colorAccentCool,
              borderWidth: 0.5,
              shadowColor: "black",
              shadowOffset: { width: 0, height: 4 },
              shadowOpacity: 0.2,
              shadowRadius: 4,
            }}
            onPress={onPress}
            hitSlop={{ left: 18, right: 18, bottom: 18 }}
            noFeedback
          >
            {props.mode === "month" && <IconChevronUp strokeWidth={1.5} size={20} opacity="opaque" />}
            {props.mode === "week" && <IconChevronDown strokeWidth={1.5} size={20} opacity="opaque" />}
          </Pressable>
          <Spacer vertical={0.4} />
        </View>
      </View>
    );
  }
);

import { UserRecipeId } from "@eatbetter/recipes-shared";
import { useScrollToTop } from "@react-navigation/native";
import React, { useRef, useImperativeHandle, useMemo, useCallback, useEffect, useState } from "react";
import {
  LayoutAnimation,
  SectionList,
  SectionListData,
  SectionListProps,
  SectionListRenderItem,
  View,
} from "react-native";
import {
  ActiveRecipeListItem,
  DefaultRecipeListItem,
  RecipeListItem,
  RecipeListSections,
  RecipeListSort,
  useLastScrollListToTopAction,
} from "../../lib/composite/RecipeListSelectors";
import { useRecipe } from "../../lib/recipes/RecipesSelectors";
import { getPullToRefresh } from "../PullToRefresh";
import { useScreenElementDimensions } from "../ScreenView";
import { SectionHeading } from "../SectionHeading";
import { Spacer } from "../Spacer";
import { TBody, TSecondary } from "../Typography";
import { UserRecipePressedHandler, recipeCardConstants, RecipeLibraryCardLoading, RecipeCard } from "./RecipeCards";
import { useScreen } from "../../navigation/ScreenContainer";
import { navTree } from "../../navigation/NavTree";
import { globalStyleColors, globalStyleConstants } from "../GlobalStyles";
import { RecipeLibraryImperativeHandle, ScrollEventHandler } from "./RecipeLibraryInterfaces";
import { useDispatch } from "../../lib/redux/Redux";
import { navToCookingSessionIfExists } from "../../navigation/NavThunks";
import Reanimated from "react-native-reanimated";
import { pullToRefreshRecipes } from "../../lib/recipes/RecipesThunks";
import { getSortedByDisplayText } from "../../screens/RecipeListSortMenu";
import { SelectableRecipe } from "../SelectRecipeOverlay";
import { Haptics } from "../Haptics";
import { Pressable } from "../Pressable";
import { displayUnexpectedErrorAndLog } from "../../lib/Errors";
import { bottomThrow, switchReturn } from "@eatbetter/common-shared";
import { LibraryFilterSessionId, LibraryOrSearchSessionId } from "../../lib/composite/LibraryAndSearchSessionIds.ts";
import { withNavDelay } from "../../lib/util/WithNavDelay.ts";
import { Separator } from "../Separator.tsx";
import { bottomActionBarConstants } from "../BottomActionBar.tsx";

const strings = {
  setupShareExtension: {
    headline: "💡 Save any recipe to your library right\nfrom your browser",
    subhead: "",
    buttonText: "Show me",
  },
  resultCount: (count: number) => `${count} recipe${count !== 1 ? "s" : ""}`,
  sections: {
    cooking: "Cooking in Progress",
    shopped: "Recently Shopped",
    other: "All Other Recipes",
  },
  selectActionButton: {
    selectAll: "Select All",
    clear: "Unselect All",
  },
};

const AnimatedSectionList =
  Reanimated.createAnimatedComponent<SectionListProps<RecipeListItem & { isSelected?: boolean }, { title?: string }>>(
    SectionList
  );

type RecipeLibraryListViewData = SectionListData<RecipeListItem & { isSelected?: boolean }, { title?: string }>;

type BulkSelectAction = "selectAll" | "clear";
const bulkSelectMax = 300;

interface Props {
  sections: RecipeListSections;
  sessionId: LibraryFilterSessionId;
  resultCount: number;
  sort?: RecipeListSort;
  onScroll?: ScrollEventHandler;
  showShareExtensionTip?: boolean;
  selected?: Record<UserRecipeId, SelectableRecipe>;
  setSelected?: React.Dispatch<React.SetStateAction<Record<UserRecipeId, SelectableRecipe>>>;
  disableCookingSessionRecipes?: boolean;
}

/**
 * Provides the classic list view of the recipe library
 */
export const RecipeLibraryListView = React.forwardRef<RecipeLibraryImperativeHandle, Props>((props, ref) => {
  const dispatch = useDispatch();
  const screen = useScreen();
  const listRef = useRef<SectionList<any, any>>(null);
  useScrollToTop(listRef);

  const lastScrollListToTopAction = useLastScrollListToTopAction();
  const alreadyActedOnLastScrollToTopAction = useRef(lastScrollListToTopAction);
  // Scroll the list to the top if the user has taken an action that results in the item they were interacting with moving
  // this currently includes adding a recipe to the grocery list and starting a cooking session. We currently do *not*
  // do these for the inverse (removing from the list, ending a cooking session).
  useEffect(() => {
    // don't scroll if this is somehow updated when the user is on the screen, which
    // currently shouldn't be possible, but would be if we add quick actions on the list items
    if (!screen.nav.focused && lastScrollListToTopAction !== alreadyActedOnLastScrollToTopAction.current) {
      alreadyActedOnLastScrollToTopAction.current = lastScrollListToTopAction;
      listRef.current?.scrollToLocation({
        animated: false,
        itemIndex: 0,
        sectionIndex: 0,
        viewPosition: 1,
      });
    }
  }, [screen.nav.focused, lastScrollListToTopAction, alreadyActedOnLastScrollToTopAction]);

  const onPressRecipe = useCallback<UserRecipePressedHandler>(
    recipeId => {
      if (props.selected !== undefined && props.setSelected !== undefined) {
        // If props.selected is set, we're in bulk select mode. Toggle the selected state for this item. This necessarily
        // triggers a list re-render, but unchanged items should be memoized and refresh quickly. If this becomes a perf
        // issue, we can revisit.
        Haptics.feedback("itemStatusChanged");
        props.setSelected(prev => ({
          ...prev,
          [recipeId]: { recipeId, isSelected: !prev[recipeId]?.isSelected },
        }));
        return;
      }

      if (!dispatch(navToCookingSessionIfExists({ type: "library", nav: screen.nav.goTo, recipeId }))) {
        screen.nav.goTo("push", navTree.get.screens.recipeDetail, { recipeId: recipeId as UserRecipeId });
      }
    },
    [dispatch, screen.nav.goTo, props.setSelected]
  );

  const { cookingSessionRecipes, groceryListRecipes, otherRecipes } = props.sections;

  const data: RecipeLibraryListViewData[] = useMemo(() => {
    const getRecipeList = (items: ActiveRecipeListItem[] | DefaultRecipeListItem[]) => {
      // Check if we're in bulk select mode and if so, set the isSelected flag on each item so that the selection
      // indicators render
      if (props.selected !== undefined) {
        return items
          .map(i => ({ ...i, isSelected: !!props.selected?.[i.recipeId]?.isSelected }))
          .filter(i => !(props.disableCookingSessionRecipes && i.type === "active"));
      }
      return items;
    };

    const cookingAndShopped: RecipeLibraryListViewData[] = [
      {
        title: strings.sections.cooking,
        data: getRecipeList(cookingSessionRecipes),
      },
      {
        title: strings.sections.shopped,
        data: getRecipeList(groceryListRecipes),
      },
    ].filter(i => i.data.length > 0);

    const other: RecipeLibraryListViewData[] = [
      {
        title: cookingAndShopped.length > 0 ? strings.sections.other : undefined,
        data: getRecipeList(otherRecipes),
      },
    ].filter(i => i.data.length > 0);

    return [...cookingAndShopped, ...other];
  }, [cookingSessionRecipes, groceryListRecipes, otherRecipes, props.selected, props.disableCookingSessionRecipes]);

  const isInitialRender = useRef(true);

  const scrollToTop = useCallback(
    (opts?: { animated: boolean }) => {
      if (!listRef.current || data.length === 0) {
        return;
      }
      listRef.current.scrollToLocation({
        sectionIndex: 0,
        itemIndex: 0,
        viewPosition: 1,
        animated: opts?.animated,
      });
    },
    [listRef, data.length]
  );

  // Animate layout changes and scroll to top when results change
  // REVIEW - Because we trigger this off of result count, a household user adding a recipe
  // will cause this user's list to scroll to the top. Not ideal, but shouldn't be super common.
  useEffect(() => {
    if (screen.nav.focused && !isInitialRender.current) {
      LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);

      // Since this call is async to the native layer, wrap in a setTimeout so that it doesn't interfere with
      // layout update/animation
      setTimeout(scrollToTop);
    } else {
      // Don't start animating layout changes until the screen has finished mounting to avoid animation during nav
      withNavDelay(() => {
        isInitialRender.current = false;
      });
    }
  }, [props.resultCount]);

  const itemHeight = recipeCardConstants.verticalScrollCardHeight;
  const itemMargin = globalStyleConstants.minPadding;

  const renderItem: SectionListRenderItem<RecipeListItem & { isSelected?: boolean }> = useCallback(
    ({ item, index }) => {
      const leadingVerticalSpace = index !== 0 ? { marginTop: itemMargin } : {};

      return (
        <View style={[{ height: itemHeight, marginHorizontal: itemMargin }, leadingVerticalSpace]}>
          <UserRecipeListItemOrSpinner
            recipeId={item.recipeId}
            index={index}
            onPress={onPressRecipe}
            sessionId={props.sessionId}
            isSelected={item.isSelected}
          />
        </View>
      );
    },
    [onPressRecipe, itemHeight, itemMargin, props.sessionId]
  );

  // we need to make sure that the key changes if the sort order changes. See the comment on the maintainVisibleContentPosition
  // property of the section list below. We set this in RecipeListSelectors based on the section and sort value.
  const keyExtractor: (item: RecipeListItem, index: number) => string = useCallback(item => {
    return item.listKey;
  }, []);

  const renderSectionHeader = useCallback((info: { section: RecipeLibraryListViewData }) => {
    if (!info.section.title) {
      return null;
    }

    return (
      <View key={info.section.title}>
        <SectionHeading text={info.section.title} />
      </View>
    );
  }, []);

  // When we've only got one section and it's "other", we don't show a header and therefore adjust
  // section spacing to account for that.
  const otherSectionOnly = data.length === 1 && !data[0]?.title;

  const renderSectionFooter = useCallback(() => {
    if (otherSectionOnly) {
      return null;
    }

    return <Spacer vertical={1} />;
  }, [otherSectionOnly]);

  const sectionSeparator = useCallback(() => {
    if (otherSectionOnly) {
      return null;
    }

    return <Spacer vertical={1} />;
  }, [otherSectionOnly]);

  // Bulk select (part of list header)
  const [bulkSelectAction, setBulkSelectAction] = useState<BulkSelectAction>("selectAll");
  const onPressBulkSelectAction = useCallback(
    (action: BulkSelectAction) => {
      if (!props.setSelected) {
        displayUnexpectedErrorAndLog("onPressBulkSelectAction called but props.setSelected is falsy", {
          props,
          action,
        });
        return;
      }

      switch (action) {
        case "selectAll": {
          props.setSelected(prev => {
            const newSelected = { ...prev };
            const recipes: RecipeListItem[] = data.flatMap(i => i.data);

            for (let i = 0; i < Math.min(bulkSelectMax, recipes.length); i++) {
              const id = recipes[i]?.recipeId;
              if (!id) continue;
              newSelected[id] = { recipeId: id, isSelected: true };
            }
            return newSelected;
          });
          setBulkSelectAction("clear");
          break;
        }
        case "clear": {
          props.setSelected({});
          setBulkSelectAction("selectAll");
          break;
        }
        default:
          bottomThrow(action);
      }
    },
    [props.setSelected, setBulkSelectAction, data]
  );

  const listHeader = useCallback(() => {
    return (
      <ResultsHeader
        count={props.resultCount}
        sort={props.sort}
        selectAction={props.selected !== undefined ? bulkSelectAction : undefined}
        onPressSelectAction={props.selected !== undefined ? onPressBulkSelectAction : undefined}
      />
    );
  }, [props.resultCount, props.sort, bulkSelectAction, props.selected !== undefined, onPressBulkSelectAction]);

  const listFooter = useCallback(() => {
    return (
      <>
        {props.showShareExtensionTip && (
          <>
            {otherSectionOnly && <Spacer vertical={2} />}
            <View style={{ paddingHorizontal: 20 }}>
              <Spacer vertical={1} />
              <ShareExtensionSetup />
              <Spacer vertical={15} />
            </View>
          </>
        )}
        <Spacer vertical={2} />
      </>
    );
  }, [otherSectionOnly, props.showShareExtensionTip, bulkSelectAction, onPressBulkSelectAction]);

  const { bottomTabBarHeight, bottomNotchHeight, headerHeight: screenHeaderHeight } = useScreenElementDimensions();
  const paddingTop = screenHeaderHeight;

  const onPullToRefresh = useCallback(async () => {
    return dispatch(pullToRefreshRecipes());
  }, [dispatch]);

  const refreshControl = useMemo(() => {
    return getPullToRefresh(onPullToRefresh, paddingTop);
  }, [onPullToRefresh, paddingTop]);

  const contentContainerStyle = useMemo(() => {
    // Infer bottom padding based on whether we're in select mode or not
    const paddingBottom =
      (props.selected ? bottomNotchHeight + bottomActionBarConstants.height : bottomTabBarHeight) +
      3 * globalStyleConstants.unitSize;
    return { paddingBottom, paddingTop };
  }, [bottomNotchHeight, bottomTabBarHeight, paddingTop, !!props.selected]);

  useImperativeHandle(
    ref,
    () => ({
      scrollToTop,
    }),
    [scrollToTop]
  );

  return (
    <AnimatedSectionList
      ref={listRef}
      sections={data}
      renderItem={renderItem}
      keyExtractor={keyExtractor}
      renderSectionHeader={renderSectionHeader}
      renderSectionFooter={renderSectionFooter}
      SectionSeparatorComponent={sectionSeparator}
      ListHeaderComponent={listHeader}
      ListFooterComponent={listFooter}
      stickySectionHeadersEnabled={false}
      refreshControl={refreshControl}
      contentContainerStyle={contentContainerStyle}
      onScroll={props.onScroll}
      scrollEventThrottle={16}
      keyboardDismissMode="on-drag"
      keyboardShouldPersistTaps="handled"
      showsVerticalScrollIndicator={false}
      // ** 3/13/25 UPDATE  - stopped passing this param**
      // This was causing weird behavior when going from an empty list -> non-empty list (for example, search+filters that returned nothing and then something)
      // There was a weird scroll/animation.
      // Upon investigation, we discovered that we could no longer repro the issue that we added this for, even with it disabled.
      // One change is that we added a useEffect above that scrolls to the top on list count change.
      // Leaving this here for posterity in case we start repro'ing again.
      // This is the value we passed:
      // const maintainVisibleContentPosition = useRef({ minIndexForVisible: 0 }).current;
      //
      // ORIGINAL NOTES:
      // Without this, the list can get in a very weird state where it endlessly scrolls if in item changes position in the list
      // Specifically:
      // 1. Scroll deep in the list
      // 2. Take an action that would result in an item moving up the list order. This includes editing the recipe (before we changed behavior
      //    to keep list position in that case), adding a recipe to the grocery list, starting cooking, or the addition of a new recipe by another
      //    device in the household, or that the user is logged in to.
      // 3. Once nav'ing back to the list after #2, the list would scroll and scroll
      // This maintains the list position in the case of items being prepended. The docs specifically state that behavior can be wonky if an item is reordered
      // so we makes sure that the key changes in the case of order changing, which should make it appear as a new item in the list. See keyExtractor above.
      //maintainVisibleContentPosition={maintainVisibleContentPosition}
    />
  );
});

interface UserRecipeItemOrSpinnerProps {
  recipeId: UserRecipeId;
  index: number;
  onPress: UserRecipePressedHandler;
  sessionId: LibraryOrSearchSessionId;
  isSelected?: boolean;
}

const UserRecipeListItemOrSpinner = React.memo((props: UserRecipeItemOrSpinnerProps) => {
  const recipe = useRecipe(props.recipeId);
  if (!recipe) {
    return <RecipeLibraryCardLoading />;
  }
  return (
    <RecipeCard
      {...recipe}
      index={props.index}
      onPress={props.onPress}
      sessionId={props.sessionId}
      isSelected={props.isSelected}
    />
  );
});

const ResultsHeader = React.memo(
  (props: {
    count: number;
    sort?: RecipeListSort;
    selectAction?: BulkSelectAction;
    onPressSelectAction?: (action: BulkSelectAction) => void;
  }) => {
    const onPressSelectAction = useCallback(() => {
      if (!props.selectAction || !props.onPressSelectAction) {
        displayUnexpectedErrorAndLog("ResultsHeader: onPressSelectAction called but action or callback is falsy", {
          selectionAction: props.selectAction,
          onPressSelectAction: props.onPressSelectAction,
        });
        return;
      }
      Haptics.feedback("itemStatusChanged");
      props.onPressSelectAction(props.selectAction);
    }, [props.onPressSelectAction, props.selectAction]);

    return (
      <View
        style={{
          paddingHorizontal: globalStyleConstants.defaultPadding,
          paddingTop: 0.5 * globalStyleConstants.unitSize,
          paddingBottom: globalStyleConstants.unitSize,
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <TBody>
          <TBody opacity="dark">{strings.resultCount(props.count)}</TBody>
          {props.sort && <TBody opacity="dark">{getSortedByDisplayText(props.sort)}</TBody>}
        </TBody>
        {!!props.selectAction && props.count > 0 && (
          <Pressable onPress={onPressSelectAction} disabled={props.count === 0}>
            <TSecondary color={globalStyleColors.colorAccentCool} fontWeight="medium">
              {switchReturn(props.selectAction, {
                clear: strings.selectActionButton.clear,
                selectAll: strings.selectActionButton.selectAll,
              })}
            </TSecondary>
          </Pressable>
        )}
      </View>
    );
  }
);

const ShareExtensionSetup = React.memo(() => {
  const screen = useScreen();

  const onPressHowTo = useCallback(() => {
    screen.nav.modal(navTree.get.screens.onboardShareExtension);
  }, [screen.nav.modal]);

  return (
    <>
      <Separator orientation="row" />
      <Spacer vertical={1} />
      <TBody align="center">{strings.setupShareExtension.headline}</TBody>
      {/* <TSecondary lineHeight={24} align="center" opacity="opaque">
        {strings.setupShareExtension.subhead}
      </TSecondary> */}
      <Spacer vertical={0.5} />
      <TBody
        align="center"
        onPress={onPressHowTo}
        color={globalStyleColors.colorTextLink}
        fontWeight="medium"
        suppressHighlighting
      >
        {strings.setupShareExtension.buttonText}
      </TBody>
    </>
  );
});

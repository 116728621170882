import { EpochMs } from "@eatbetter/common-shared";

export function getDateDisplayString(
  dateMs: EpochMs,
  useRelativeLabels: boolean = true,
  showWeekday: boolean = false
): string {
  const { today, yesterday, date } = getDateParts(dateMs);

  if (useRelativeLabels && date.month === today.month && date.year === today.year) {
    if (date.day === today.day) {
      return "Today";
    }
    if (date.day === yesterday.day) {
      return "Yesterday";
    }
  }

  const options: Intl.DateTimeFormatOptions = {
    weekday: showWeekday ? "long" : undefined,
    month: "long",
    day: "numeric",
    year: "numeric",
  };

  return new Date(dateMs).toLocaleDateString(undefined, options);
}

export function getTimeSinceDisplayString(dateMs: EpochMs, showSeconds = true) {
  const today = Date.now();
  const delta = today - dateMs;

  const seconds = Math.floor(delta / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const weeks = Math.floor(days / 7);

  if (weeks > 0) {
    return `${weeks}w`;
  }

  if (days > 0) {
    return `${days}d`;
  }

  if (hours > 0) {
    return `${hours}h`;
  }

  if (minutes > 0) {
    return `${minutes}m`;
  }

  if (showSeconds) {
    return `${seconds}s`;
  } else {
    return "Just now";
  }
}

export function getDateParts(dateMs: EpochMs) {
  const getDateComponents = (date: number) => ({
    day: new Date(date).getDate(),
    month: new Date(date).getMonth(),
    year: new Date(date).getFullYear(),
  });

  const today = getDateComponents(Date.now());
  const yesterday = { ...today, day: today.day - 1 };
  const date = getDateComponents(dateMs);

  return { date, today, yesterday };
}

import React, { PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Keyboard, LayoutAnimation, StyleSheet, View } from "react-native";
import { Slider, SliderImperativeHandle } from "../Slider";
import { TBody, THeading2, TSecondary } from "../Typography";
import { Pressable } from "../Pressable";
import { IconSliders, IconUndo } from "../Icons";
import { globalStyleColors, globalStyleConstants } from "../GlobalStyles";
import { useScreen } from "../../navigation/ScreenContainer";
import { navTree } from "../../navigation/NavTree";
import { Spacer } from "../Spacer";
import { SectionHeading } from "../SectionHeading";
import { SegmentedControl, SegmentedControlProps } from "../SegmentedControl";
import { maxContentWidth } from "../Responsive";
import { UnitConversion } from "@eatbetter/items-shared";
import { bottomThrow, isNullOrUndefined } from "@eatbetter/common-shared";
import { Separator } from "../Separator";
import { RecipeYieldDisplay } from "./RecipeYield";
import { RecipeYield } from "@eatbetter/recipes-shared";
import { Haptics } from "../Haptics";
import { useUserSetting } from "../../lib/system/SystemSelectors.ts";
import { userSettingsUpdated } from "../../lib/system/SystemSlice.ts";
import { useDispatch } from "../../lib/redux/Redux.ts";
import { getScaleValueDisplay } from "../../lib/recipes/conversions/QuantityUnitDisplay.ts";
import { analyticsEvent } from "../../lib/analytics/AnalyticsThunks.ts";
import {
  reportScaleAndConvertButtonTapped,
  reportUnitConversionsUserSettingChange,
} from "../../lib/analytics/AnalyticsEvents.ts";
import { WalkthroughStep, WalkthroughStepProps } from "../Walkthrough.tsx";

const strings = {
  conversionLabels: {
    original: "Original",
    metric: "Metric",
    standard: "US Std",
  } satisfies Record<UnitConversion, string>,
  sheet: {
    title: "Scale & Convert Recipe",
    scale: "Scale",
    convert: "Unit Conversions",
  },
  scaleRecipe: "Scale & Convert",
};

export const scalingSymbol = "×";

const scalingSteps: Array<{ display?: string; value: number }> = [
  { display: "¼", value: 1 / 4 },
  { display: "⅓", value: 1 / 3 },
  { display: "½", value: 1 / 2 },
  { value: 1 },
  { display: "1½", value: 1.5 },
  { value: 2 },
  { display: "2½", value: 2.5 },
  { value: 3 },
  { display: "3½", value: 3.5 },
  { value: 4 },
  { value: 5 },
  { value: 6 },
  { value: 7 },
  { value: 8 },
];

/**
 * Matches the scaling step for the given scale value and if not found, returns the closest item. This way we can
 * use the slider down the line with arbitrary values and still position it reasonably.
 */
function getScalingStep(scale: number): { index: number; value: number; display: string } {
  // Tolerance for matching a value from scalingSteps
  const epsilon = 1e-4;

  // Find the index of the exact match, if any
  const exactMatchIndex = scalingSteps.findIndex(step => Math.abs(step.value - scale) < epsilon);

  const scalingStep = scalingSteps[exactMatchIndex];

  if (exactMatchIndex !== -1 && !isNullOrUndefined(scalingStep)) {
    // Exact match found
    return {
      index: exactMatchIndex,
      value: scalingStep.value,
      display: scalingStep.display ?? String(scalingStep.value),
    };
  } else {
    // No exact match; find the index of the closest step
    const closestIndex = scalingSteps.reduce((prevIndex, step, index) => {
      const prevStep = scalingSteps[prevIndex];
      if (isNullOrUndefined(prevStep)) {
        return prevIndex;
      }
      const prevDiff = Math.abs(prevStep.value - scale);
      const currentDiff = Math.abs(step.value - scale);
      return currentDiff < prevDiff ? index : prevIndex;
    }, 0);

    return {
      index: closestIndex,
      value: scale,
      display: getScaleValueDisplay(scale),
    };
  }
}

function getUnitConversionDisplayString(unitConversion: keyof typeof strings.conversionLabels): string {
  return strings.conversionLabels[unitConversion];
}

interface RecipeScalingControlProps {
  scale: number;
  onChangeScale: (v: number, persist: boolean) => void;
}

export const RecipeScalingControl = React.memo((props: RecipeScalingControlProps) => {
  const screen = useScreen();
  const scalingStep = useMemo(() => getScalingStep(props.scale), [props.scale]);

  const [sliderValue, setSliderValue] = useState(scalingStep.index);
  const sliderRef = useRef<SliderImperativeHandle>(null);

  useEffect(() => {
    // Keep the slider value in sync with the scale value if the scale changes while this instance
    // is backgrounded (e.g. changing the slider from the sheet but there is a slider in the background screen, such
    // as in the add to grocery screen)
    if (!screen.nav.focused) {
      sliderRef.current?.setSliderValue(scalingStep.index);
    }
  }, [scalingStep.index, screen.nav.focused]);

  const onChangeScale = useCallback(
    (index: number) => {
      const step = scalingSteps[index];
      if (step !== undefined) {
        LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
        // value of persist shouldn't matter here, so just defaulting to false
        props.onChangeScale(step.value, false);
      }
    },
    [props.onChangeScale]
  );

  const onPressResetScale = useCallback(() => {
    const scaleStepIndex = scalingSteps.findIndex(i => i.value === 1);
    Haptics.feedback("itemStatusChanged");
    onChangeScale(scaleStepIndex);
    setSliderValue(scaleStepIndex);

    // The slider is NOT a controlled component, so we need to call imperatively to set the value programmatically
    sliderRef.current?.setSliderValue(scaleStepIndex);
  }, [onChangeScale, setSliderValue]);

  return (
    <View style={[styles.controlWrap, { flex: 1, paddingHorizontal: 1.5 * globalStyleConstants.unitSize }]}>
      <View style={styles.scalingControl}>
        <View style={styles.scalingTextWrap}>
          <ScaleSymbol position="start" />
          <TBody fontWeight="medium" color={globalStyleColors.colorAccentCool}>
            {scalingStep.display}
          </TBody>
        </View>
        <Slider
          ref={sliderRef}
          minValue={0}
          maxValue={scalingSteps.length - 1}
          stepCount={scalingSteps.length - 1}
          onValueChange={onChangeScale}
          initialValue={sliderValue}
        />
      </View>
      {props.scale !== 1 && (
        <Pressable onPress={onPressResetScale} style={styles.undoButton}>
          <IconUndo size={15} color={globalStyleColors.colorAccentCool} strokeWidth={2.5} />
        </Pressable>
      )}
    </View>
  );
});

const ScaleSymbol = React.memo((props: { position: "start" | "end" }) => {
  return (
    <THeading2>
      {props.position === "end" && <THeading2> </THeading2>}
      <THeading2>{scalingSymbol}</THeading2>
      {props.position === "start" && <THeading2> </THeading2>}
    </THeading2>
  );
});

export interface ScalingAndConversionsButtonProps extends ScalingAndConversionsSheetProps {
  showLabel?: boolean;
  strokeWidth?: "normal" | "medium";
  inactiveColor?: string;
  disabled?: boolean;
  walkthrough?: WalkthroughStepProps;
}

export const ScalingAndConversionsButton = React.memo((props: ScalingAndConversionsButtonProps) => {
  const dispatch = useDispatch();
  const { navToScalingAndConversionsSheet } = useScalingAndConversionsSheet({
    recipeTitle: props.recipeTitle,
    recipeYield: props.recipeYield,
    scale: props.scale,
    onChangeScale: props.onChangeScale,
  });

  const recipeUnits = useUserSetting("unitConversion");

  const isScaled = props.scale !== 1;
  const isUnitConverted = recipeUnits !== "original";

  const strokeWidth = props.strokeWidth ?? "normal";
  const showBadge = isScaled || isUnitConverted;

  const inactiveColor = props.inactiveColor ?? globalStyleColors.colorAccentCool;
  const color = props.walkthrough ? "white" : showBadge ? globalStyleColors.colorAccentCool : inactiveColor;

  const onPress = useCallback(() => {
    dispatch(analyticsEvent(reportScaleAndConvertButtonTapped()));
    navToScalingAndConversionsSheet();
  }, [navToScalingAndConversionsSheet, dispatch]);

  const button = (
    <Pressable
      style={{ flexDirection: "row", alignItems: "center" }}
      onPress={onPress}
      noFeedback
      disabled={props.disabled}
    >
      <View>
        <IconSliders strokeWidth={strokeWidth === "medium" ? 2 : undefined} color={color} />
        {showBadge && (
          <View style={styles.buttonBadgeWrap}>
            <ModifiedBadge color={color} />
          </View>
        )}
      </View>
      {!!props.showLabel && (
        <>
          <Spacer horizontal={1} />
          <ScaledConvertedLabels scale={props.scale} conversion={recipeUnits} />
          {props.scale === 1 && recipeUnits === "original" && (
            <TSecondary color={globalStyleColors.colorAccentCool} fontWeight="medium">
              {strings.scaleRecipe}
            </TSecondary>
          )}
        </>
      )}
    </Pressable>
  );

  return (
    <>
      {!!props.walkthrough && <WalkthroughStep {...props.walkthrough}>{button}</WalkthroughStep>}
      {!props.walkthrough && button}
    </>
  );
});

export const ScaledConvertedLabels = React.memo((props: { scale?: number; conversion?: UnitConversion }) => {
  return (
    <View style={{ flexDirection: "row", alignItems: "center" }}>
      {!!props.scale && props.scale !== 1 && <ScaledOrConvertedLabel type="scaled" scale={props.scale} />}
      {!!props.scale && props.scale !== 1 && props.conversion !== "original" && <Spacer horizontal={0.5} />}
      {!!props.conversion && props.conversion !== "original" && (
        <ScaledOrConvertedLabel type="converted" units={props.conversion} />
      )}
    </View>
  );
});

type ScaledOrConvertedLabelProps =
  | { type: "scaled"; scale: number }
  | { type: "converted"; units: "metric" | "standard" };

const ScaledOrConvertedLabel = React.memo((props: ScaledOrConvertedLabelProps) => {
  const getLabelText = () => {
    switch (props.type) {
      case "scaled": {
        return `${scalingSymbol}${getScaleValueDisplay(props.scale)}`;
      }
      case "converted": {
        return getUnitConversionDisplayString(props.units);
      }
      default:
        bottomThrow(props);
    }
  };

  return (
    <View style={styles.scaledLabel}>
      <TSecondary fontWeight="medium" color={globalStyleColors.rgba("colorAccentCool", "opaque")}>
        {getLabelText()}
      </TSecondary>
    </View>
  );
});

export const ScalingInlineControl = React.memo(
  (props: RecipeScalingControlProps & ScalingAndConversionsButtonProps & { hideButton?: boolean }) => {
    return (
      <View style={{ flexDirection: "row", alignItems: "center" }}>
        <RecipeScalingControl scale={props.scale} onChangeScale={props.onChangeScale} />
        {!props.hideButton && (
          <>
            <Spacer horizontal={1.5} />
            <ScalingAndConversionsButton
              strokeWidth="medium"
              recipeTitle={props.recipeTitle}
              recipeYield={props.recipeYield}
              scale={props.scale}
              onChangeScale={props.onChangeScale}
            />
          </>
        )}
      </View>
    );
  }
);

const UnitConversionsControl = React.memo(() => {
  const dispatch = useDispatch();
  const unit = useUserSetting("unitConversion");

  const onChangeUnit = useCallback(
    (unitConversion: UnitConversion) => {
      dispatch(analyticsEvent(reportUnitConversionsUserSettingChange({ newValue: unitConversion })));
      dispatch(userSettingsUpdated({ unitConversion }));
    },
    [dispatch]
  );

  const segments = useMemo<SegmentedControlProps["segments"]>(() => {
    return [
      {
        label: strings.conversionLabels.original,
        isSelected: unit === "original",
        onPress: () => onChangeUnit("original"),
      },
      {
        label: strings.conversionLabels.metric,
        isSelected: unit === "metric",
        onPress: () => onChangeUnit("metric"),
      },
      {
        label: strings.conversionLabels.standard,
        isSelected: unit === "standard",
        onPress: () => onChangeUnit("standard"),
      },
    ];
  }, [unit, onChangeUnit]);

  return <SegmentedControl height="large" segments={segments} />;
});

export function useScalingAndConversionsSheet({
  recipeTitle,
  recipeYield,
  scale,
  onChangeScale,
}: ScalingAndConversionsSheetProps) {
  const screen = useScreen();

  const navToScalingAndConversionsSheet = useCallback(() => {
    screen.nav.modal(navTree.get.screens.bottomSheet, {
      content: (
        <ScalingAndConversionsSheet
          recipeTitle={recipeTitle}
          recipeYield={recipeYield}
          scale={scale}
          onChangeScale={onChangeScale}
        />
      ),
      height: getScalingAndConversionsSheetHeight({ recipeYield, recipeTitle }),
      backgroundColor: "white",
      adjustHeightForKeyboard: true,
    });
  }, [screen.nav.modal, scale, onChangeScale, recipeTitle, recipeYield]);

  return { navToScalingAndConversionsSheet };
}

function getScalingAndConversionsSheetHeight(args: {
  recipeTitle: string | undefined;
  recipeYield: RecipeYield | undefined;
}) {
  const baseHeight = 330;
  let height = baseHeight;

  if (args.recipeTitle) {
    height += 30;
  }
  if (args.recipeYield) {
    height += 18;
  }

  return height;
}

interface ScalingAndConversionsSheetProps extends RecipeScalingControlProps {
  recipeTitle: string | undefined;
  recipeYield: RecipeYield | undefined;
  /**
   * Used in cases where we don't want to update the backend on every stop on the scale gradient, but we still want to show the
   * values changing as the user moves the slider. This can be used to persist the final value.
   * @param scale
   */
  onScaleFinalized?: (scale: number) => void;
}

const ScalingAndConversionsSheet = React.memo((props: ScalingAndConversionsSheetProps) => {
  const recipeUnits = useUserSetting("unitConversion");

  const [recipeScale, setRecipeScale] = useState(props.scale);
  const recipeScaleRef = useRef(recipeScale);

  /**
   *  We want this to fire only when the component is dismounted, hence the lack of any deps
   *  We know the ref doesn't change, and we will assume the update function won't change either.
   */
  useEffect(() => {
    return () => {
      if (recipeScaleRef.current !== props.scale) {
        props.onChangeScale(recipeScaleRef.current, true);
      }
    };
  }, []);

  const onChangeRecipeScale = useCallback(
    (value: number) => {
      // keep the ref up to date for the useEffect above, where we call the latest value with persist = true
      recipeScaleRef.current = value;

      // this is for local state
      setRecipeScale(value);

      // this is for the caller of the sheet to update their non-persisted value. Since we are in a screen, they can't just
      // update the value.
      props.onChangeScale(value, false);
    },
    [setRecipeScale, recipeScaleRef, props.onChangeScale]
  );

  return (
    <Pressable onPress={() => Keyboard.dismiss()} noFeedback style={styles.sheetContainer}>
      <View style={styles.sheetMaxWidthContainer}>
        <View style={{ width: "100%", alignItems: "center" }}>
          <TBody fontWeight="medium">{strings.sheet.title}</TBody>
          {(!!props.recipeTitle || !!props.recipeYield) && <Spacer vertical={1.5} />}
          {!!props.recipeTitle && (
            <TBody fontWeight="medium" opacity="medium" numberOfLines={1}>
              {props.recipeTitle}
            </TBody>
          )}
          <Spacer vertical={0.25} />
          {!!props.recipeYield && (
            <RecipeYieldDisplay
              yield={props.recipeYield}
              recipeScale={recipeScale}
              recipeUnits={recipeUnits}
              fontSize="body"
              italic
              opacity="dark"
            />
          )}
        </View>
        <Spacer vertical={1} />
        <Separator orientation="row" />
        <Spacer vertical={2} />
        <SheetSection modified={recipeScale !== 1} sectionTitle={strings.sheet.scale} noBorder noPadding>
          <RecipeScalingControl scale={recipeScale} onChangeScale={onChangeRecipeScale} />
        </SheetSection>
        <Spacer vertical={2.5} />
        <SheetSection modified={recipeUnits !== "original"} sectionTitle={strings.sheet.convert} noPadding>
          <UnitConversionsControl />
        </SheetSection>
      </View>
    </Pressable>
  );
});

const SheetSection = React.memo(
  (props: PropsWithChildren<{ sectionTitle: string; modified: boolean; noPadding?: boolean; noBorder?: boolean }>) => {
    return (
      <>
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <SectionHeading text={props.sectionTitle} noPadding />
          {props.modified && (
            <>
              <Spacer horizontal={1} />
              <ModifiedBadge />
            </>
          )}
        </View>
        <Spacer vertical={1} />
        <View
          style={[
            styles.controlWrap,
            props.noBorder ? { borderWidth: 0 } : {},
            props.noPadding ? {} : { paddingHorizontal: 1.5 * globalStyleConstants.unitSize },
          ]}
        >
          {props.children}
        </View>
      </>
    );
  }
);

const ModifiedBadge = React.memo((props: { color?: string }) => {
  return <View style={[styles.badge, props.color ? { backgroundColor: props.color } : {}]} />;
});

const styles = StyleSheet.create({
  scalingControl: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
  },
  scalingTextWrap: {
    flexDirection: "row",
    alignItems: "center",
    width: 48,
  },
  sheetContainer: {
    paddingBottom: 7 * globalStyleConstants.unitSize,
    width: "100%",
    alignItems: "center",
  },
  sheetMaxWidthContainer: {
    width: "100%",
    maxWidth: maxContentWidth,
    paddingTop: globalStyleConstants.unitSize,
    paddingHorizontal: globalStyleConstants.defaultPadding,
  },
  controlWrap: {
    height: 48,
    borderWidth: 0.5,
    borderColor: globalStyleColors.rgba("colorAccentCool", "medium"),
    borderRadius: 48,
  },
  undoButton: {
    position: "absolute",
    alignItems: "center",
    justifyContent: "center",
    right: -6,
    top: -15,
    width: 26,
    height: 26,
    borderRadius: 26,
    backgroundColor: "white",
    shadowColor: "black",
    shadowOpacity: 0.4,
    shadowRadius: 4,
    shadowOffset: { height: 4, width: 0 },
  },
  scaledLabel: {
    height: 24,
    minWidth: 48,
    borderRadius: 24,
    paddingHorizontal: 12,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: globalStyleColors.rgba("colorAccentCool", "xlight"),
    borderWidth: 0.2,
    borderColor: globalStyleColors.rgba("colorAccentCool", "light"),
  },
  buttonBadgeWrap: {
    position: "absolute",
    top: 1,
    right: -1,
    zIndex: 1,
  },
  badge: {
    backgroundColor: globalStyleColors.rgba("colorAccentCool", "dark"),
    minWidth: 8,
    height: 8,
    borderRadius: 8,
  },
});

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Keyboard, LayoutAnimation, StyleSheet, View } from "react-native";
import { globalStyleColors, globalStyleConstants, globalStyles } from "../GlobalStyles";
import { TBody, TSecondary } from "../Typography";
import { Pressable } from "../Pressable";
import { AnimatedChevron } from "../AnimatedChevron";
import { Spacer } from "../Spacer";
import { CollectionGroupDisplay, RecipeCollectionGroupId } from "@eatbetter/recipes-shared";
import { IconAdd, IconCheck } from "../Icons";
import { Separator } from "../Separator";
import { useScreen } from "../../navigation/ScreenContainer";
import { navTree } from "../../navigation/NavTree";
import { bottomThrow, filterOutFalsy } from "@eatbetter/common-shared";
import { log } from "../../Log";
import { Haptics } from "../Haptics";

const strings = {
  noGroup: "(No Group)",
  createGroup: "Create New Group",
  createGroupPlaceholder: "Group name",
};

export type RecipeCollectionSelectedGroup =
  | { type: "existing"; id: RecipeCollectionGroupId }
  | { type: "new"; name: string };

interface RecipeCollectionGroupSelectProps {
  groups: CollectionGroupDisplay[];
  selected: RecipeCollectionSelectedGroup;
  setSelected: (value: RecipeCollectionSelectedGroup) => void;
  showCreateGroupOption?: boolean;
  disabled?: boolean;
  startExpanded?: boolean;
}

export const RecipeCollectionGroupSelect = React.memo<RecipeCollectionGroupSelectProps>(props => {
  const [isExpanded, setIsExpanded] = useState(!!props.startExpanded);
  const [newGroupName, setNewGroupName] = useState<string>();

  const groups = useMemo(() => {
    const filtered = props.groups.filter(g => !g.locked);
    if (filtered.length !== props.groups.length) {
      log.warn("Got locked group in RecipeCollectionGroupSelect. These should be filtered");
    }
    return filtered;
  }, [props.groups]);

  const toggleExpanded = useCallback(() => {
    Haptics.feedback("itemStatusChanged");
    Keyboard.dismiss();
    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
    setIsExpanded(prev => !prev);
  }, [setIsExpanded]);

  useEffect(() => {
    if (props.disabled) {
      setIsExpanded(false);
    }
  }, [props.disabled]);

  const onPressGroupOption = useCallback(
    (groupName: string) => {
      Haptics.feedback("itemStatusChanged");

      const existingGroup = groups.find(group => group.name === groupName);

      const selected: RecipeCollectionSelectedGroup | undefined = existingGroup
        ? { type: "existing", id: existingGroup.id }
        : newGroupName === groupName
        ? { type: "new", name: groupName }
        : undefined;

      if (selected) {
        LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
        props.setSelected(selected);
        setIsExpanded(false);
      }
    },
    [props.setSelected, setIsExpanded, groups, newGroupName]
  );

  const onSetNewGroupName = useCallback(
    (groupName: string) => {
      setNewGroupName(groupName);
      props.setSelected({ type: "new", name: groupName });
      setIsExpanded(false);
    },
    [setNewGroupName, props.setSelected, setIsExpanded]
  );

  const getSelectedGroupName = (): string => {
    switch (props.selected.type) {
      case "existing": {
        const selectedId = props.selected.id;
        const existingGroup = groups.find(i => i.id === selectedId);
        if (!existingGroup) {
          log.error("RecipeCollectionGroupSelect: selected group is missing in group set", {
            existingGroup,
            groups,
            selectedId,
            selected: props.selected,
          });
          return strings.noGroup;
        }
        return existingGroup.name;
      }
      case "new": {
        return props.selected.name;
      }
      default:
        bottomThrow(props.selected);
    }
  };

  return (
    <>
      <Pressable
        onPress={toggleExpanded}
        noFeedback={!props.disabled}
        style={globalStyles.singleLineTextInputWrap}
        disabled={props.disabled || isExpanded}
      >
        <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
          <View>
            <TBody>{getSelectedGroupName()}</TBody>
          </View>
          <Pressable onPress={toggleExpanded}>
            <AnimatedChevron isRotated={isExpanded} startPosition="up" />
          </Pressable>
        </View>
      </Pressable>
      <Spacer vertical={0.5} />
      {isExpanded && (
        <>
          <View style={styles.groupResultsWrap}>
            <SelectGroup
              groups={groups}
              selectedGroupName={getSelectedGroupName()}
              onPressGroupOption={onPressGroupOption}
              showCreateGroupOption={props.showCreateGroupOption}
              newGroupName={newGroupName}
              setNewGroupName={onSetNewGroupName}
            />
          </View>
        </>
      )}
    </>
  );
});

const SelectGroup = React.memo(
  (props: {
    showCreateGroupOption?: boolean;
    groups: CollectionGroupDisplay[];
    selectedGroupName: string;
    onPressGroupOption: (groupName: string) => void;
    newGroupName: string | undefined;
    setNewGroupName: (v: string) => void;
  }) => {
    const screen = useScreen();

    const displayGroups = useMemo(() => {
      return filterOutFalsy([
        props.selectedGroupName,
        props.selectedGroupName === props.newGroupName ? undefined : props.newGroupName,
        ...props.groups.filter(i => i.name !== props.selectedGroupName).map(i => i.name),
      ]);
    }, [props.selectedGroupName, props.newGroupName, props.groups]);

    const onPressCreateNewGroup = useCallback(() => {
      screen.nav.modal(navTree.get.screens.recipeCollectionRename, {
        screenTitle: strings.createGroup,
        initialValue: "",
        placeholderText: strings.createGroupPlaceholder,
        onSubmit: props.setNewGroupName,
        existingNames: props.groups.map(i => i.name),
      });
    }, [screen.nav.modal, props.setNewGroupName, props.groups]);

    return (
      <>
        {!!props.showCreateGroupOption && (
          <>
            <Pressable style={[styles.resultItem, { justifyContent: "flex-start" }]} onPress={onPressCreateNewGroup}>
              <IconAdd opacity="opaque" color={globalStyleColors.colorAccentCool} size={18} strokeWidth={1.5} />
              <Spacer horizontal={0.75} />
              <TSecondary color={globalStyleColors.colorAccentCool}>{strings.createGroup}</TSecondary>
            </Pressable>
            <Spacer vertical={1.5} />
            <Separator orientation="row" />
            <Spacer vertical={1.5} />
          </>
        )}
        {displayGroups.map((i, idx) => {
          return (
            <SelectGroupResultItem
              key={i}
              name={i}
              index={idx}
              isSelected={i === props.selectedGroupName}
              onPress={props.onPressGroupOption}
            />
          );
        })}
      </>
    );
  }
);

const SelectGroupResultItem = React.memo(
  (props: { isSelected: boolean; name: string; index: number; onPress: (groupName: string) => void }) => {
    const onPressItem = useCallback(() => {
      props.onPress(props.name);
    }, [props.onPress, props.name]);

    return (
      <>
        {props.index !== 0 && <Spacer vertical={1.5} />}
        <Pressable style={styles.resultItem} onPress={onPressItem}>
          <TBody>{props.name}</TBody>
          {props.isSelected && (
            <IconCheck opacity="opaque" strokeWidth={2.5} size={20} color={globalStyleColors.colorAccentCool} />
          )}
        </Pressable>
      </>
    );
  }
);

const styles = StyleSheet.create({
  groupResultsWrap: {
    ...globalStyles.shadowTextInput,
    borderRadius: globalStyleConstants.unitSize,
    backgroundColor: "white",
    paddingVertical: globalStyleConstants.defaultPadding,
    paddingHorizontal: globalStyleConstants.defaultPadding,
  },
  resultItem: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
});

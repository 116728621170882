import { isNullOrUndefined, TypedPrimitive } from "@eatbetter/common-shared";
import { StyleSheet } from "react-native";
import { log } from "../Log";

export const globalStyleConstants = {
  unitSize: 12,
  minPadding: 8,
  defaultPadding: 20,
  defaultBorderRadius: 20,
};

const colors = {
  black: "#000000",
  blackSoft: "#39342F",
  white: "#FFFFFF",
  red: "red",
  colorAction: "#1E3E32",
  colorError: "#FE4A49",
  colorGrey: "#F6F6F5",
  colorGreyLight: "#F6F6F5",
  colorGreyDark: "#D9D9D9",
  colorAccentCool: "#1E3E32",
  colorAccentMid: "#F9F0C7",
  colorAccentMidDark: "#D1CEBB",
  colorAccentWarmLight: "#ECCFCD",
  colorAccentWarm: "#F06E4E",
  colorNavigationTint: "#1E3E32",
  colorTimerInactive: "gray",
  colorTimerAction: "#FF007F",
  colorTextLink: "#007FFF",
  transparent: "transparent",
};

export type ColorKey = keyof typeof colors;

/** https://github.com/software-mansion/react-native-reanimated/issues/2609
 * Reanimated has a bug with traditional hex colors, so we're adding a wrapper around colors
 * that provides an "rgba" option without breaking/changing    */
export const globalStyleColors = { ...colors, rgba: colorAndOpacityToRGBA, rgb: colorAndOpacityToRGB };
export type RgbaColor = TypedPrimitive<"rgbaColor", string>;

/**
 * Returns the RGBA value for the specified color + opacity. This is useful when there isn't a separate opacity property
 * available, or when interpolating colors with reanimated (hex doesn't work there).
 */
function colorAndOpacityToRGBA(color: ColorKey, opacity?: keyof typeof Opacity | number): RgbaColor {
  const hex = globalStyleColors[color];

  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);
  const a = typeof opacity === "string" ? Opacity[opacity] : typeof opacity === "number" ? opacity : Opacity.opaque;

  return `rgba(${r}, ${g}, ${b}, ${a})` as RgbaColor;
}
/* *********************************************************************** */

/**
 * Returns a solid color (no opacity) but that has the same shade as it would with opacity applied over the
 * given background color (defaults to white). This is useful for showing disabled buttons but where you don't want
 * translucency so that content behind doesn't show through.
 */
function colorAndOpacityToRGB(
  color: ColorKey,
  opacity: keyof typeof Opacity | number,
  backgroundColor: ColorKey = "white"
): string {
  const foregroundRgba = colorAndOpacityToRGBA(color, opacity);
  const backgroundRgba = colorAndOpacityToRGBA(backgroundColor);

  const parseRgba = (rgba: string): [number, number, number, number] | undefined => {
    const match = rgba.match(/rgba?\((\d+), (\d+), (\d+),? ([\d.]+)?\)/);
    if (!match) {
      log.error("colorAndOpacityToRGB: Invalid RGBA string", {
        rgba,
        color,
        opacity,
        backgroundColor,
        foregroundRgba,
        backgroundRgba,
      });
      return undefined;
    }
    const [_, r, g, b, a = "1"] = match;
    if (isNullOrUndefined(r) || isNullOrUndefined(g) || isNullOrUndefined(b)) {
      log.error("colorAndOpacityToRGB: missing RGB value", {
        rgba,
        color,
        opacity,
        backgroundColor,
        foregroundRgba,
        backgroundRgba,
      });
      return undefined;
    }
    return [parseInt(r), parseInt(g), parseInt(b), parseFloat(a)];
  };

  const parsedForground = parseRgba(foregroundRgba);
  const parsedBackground = parseRgba(backgroundRgba) ?? [255, 255, 255];

  if (!parsedForground) {
    log.error("colorAndOpacityToRGB: could not parse specified color + opacity", {
      color,
      opacity,
      backgroundColor,
      foregroundRgba,
      backgroundRgba,
      parsedBackground,
    });
    return color;
  }

  const [fgR, fgG, fgB, fgA] = parsedForground;
  const [bgR, bgG, bgB] = parsedBackground;

  // Blend each channel
  const blendedR = Math.round(fgR * fgA + bgR * (1 - fgA));
  const blendedG = Math.round(fgG * fgA + bgG * (1 - fgA));
  const blendedB = Math.round(fgB * fgA + bgB * (1 - fgA));

  return `rgb(${blendedR},${blendedG},${blendedB})`;
}

const defaultBorderColor = globalStyleColors.colorGreyDark;
const thinBorder = 0.25;
const mediumBorder = 1;
const thickBorder = 2;

const shadowTextInput = {
  shadowColor: "black",
  shadowOpacity: 0.08,
  shadowOffset: { width: 0, height: 3 },
  shadowRadius: 6,
};

export const globalStyles = StyleSheet.create({
  shadow: {
    shadowOffset: { height: 8, width: 0 },
    shadowColor: "black",
    shadowOpacity: 0.4,
    shadowRadius: 6,
  },
  borderBottomBarThin: {
    borderRightWidth: thinBorder,
    borderRightColor: defaultBorderColor,
    borderLeftWidth: thinBorder,
    borderLeftColor: defaultBorderColor,
    borderTopWidth: thinBorder,
    borderTopColor: defaultBorderColor,
  },
  borderBottomBarThick: {
    borderTopWidth: thickBorder,
    borderTopColor: globalStyleColors.colorGreyDark,
  },
  shadowItem: {
    shadowColor: globalStyleColors.black,
    shadowOpacity: 0.1,
    shadowRadius: 12,
    shadowOffset: { width: 0, height: 4 },
  },
  shadowTextInput,
  separator: {
    borderTopWidth: mediumBorder,
    borderTopColor: globalStyleColors.colorGreyDark,
  },
  singleLineTextInputWrap: {
    flex: 1,
    height: 48,
    borderRadius: Number.MAX_SAFE_INTEGER,
    paddingHorizontal: globalStyleConstants.defaultPadding,
    paddingVertical: globalStyleConstants.minPadding,
    justifyContent: "center",
    backgroundColor: "white",
    ...shadowTextInput,
  },
});

export const Opacity = {
  transparent: 0,
  xxlight: 0.05,
  xlight: 0.1,
  light: 0.4,
  medium: 0.6,
  dark: 0.8,
  opaque: 1,
};

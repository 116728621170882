import { UserRecipeId } from "@eatbetter/recipes-shared";
import { RecipeNotesEdit } from "../components/recipes/RecipeNotesEdit";
import { ScreenView, useScreenElementDimensions } from "../components/ScreenView";
import { useRecipeTitle } from "../lib/recipes/RecipesSelectors";
import { withScreenContainer } from "../navigation/ScreenContainer";
import { RecipeEditFieldLocation, RecipeNotesEditScreenProps } from "../navigation/NavTree";
import { useKeyboardLayoutAnimation } from "../components/Keyboard";
import { ScrollView } from "react-native-gesture-handler";
import { globalStyleConstants } from "../components/GlobalStyles";

const strings = {
  screenTitle: "Notes",
};

export const RecipeNotesEditScreen = withScreenContainer(
  "RecipeNotesEditScreen",
  (props: RecipeNotesEditScreenProps) => {
    const recipeTitle = useRecipeTitle(props.recipeId);
    const { headerHeight, bottomTabBarHeight } = useScreenElementDimensions();
    const keyboardHeight = useKeyboardLayoutAnimation();

    return (
      <ScreenView
        header={{
          type: "custom",
          title: recipeTitle ?? strings.screenTitle,
        }}
        paddingVertical={false}
        paddingHorizontal={false}
        scrollView={false}
      >
        <ScrollView
          contentContainerStyle={{
            paddingTop: headerHeight + globalStyleConstants.unitSize,
            paddingBottom:
              (keyboardHeight > 0 ? keyboardHeight : bottomTabBarHeight) + 2 * globalStyleConstants.unitSize,
          }}
        >
          <RecipeNotesEdit recipeId={props.recipeId} location={props.recipeEditFieldLocation} autoFocusMode="none" />
        </ScrollView>
      </ScreenView>
    );
  },
  {
    serializer: {
      recipeId: s => s,
      recipeEditFieldLocation: s => s,
    },
    parser: {
      recipeId: s => s as UserRecipeId,
      recipeEditFieldLocation: s => s as RecipeEditFieldLocation,
    },
  }
);

import { RecipeCollectionId, UserRecipeId } from "@eatbetter/recipes-shared";
import { ScreenView } from "../components/ScreenView";
import { navTree, RecipeCollectionBulkActionScreenProps } from "../navigation/NavTree";
import { useScreen, withScreenContainer } from "../navigation/ScreenContainer";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { RecipeLibraryListView } from "../components/recipes/RecipeLibraryListView";
import { RecipeListSort, useFilteredRecipeListSections, useRecipeCount } from "../lib/composite/RecipeListSelectors";
import { useCollectionName } from "../lib/composite/CollectionsSelectors";
import { useRecipeLibraryScreenHeader } from "../components/recipes/RecipeLibraryScreenHeader";
import { TextInputHandle } from "../components/TextInput";
import { HeaderAnimationConfig, useHeaderScrollAnimation } from "../components/ScreenHeaders";
import { withNavDelay } from "../lib/util/WithNavDelay";
import { bottomThrow, switchReturn } from "@eatbetter/common-shared";
import { useDispatch } from "../lib/redux/Redux";
import { bulkEditRecipes } from "../lib/recipes/RecipesThunks";
import { displayUnexpectedErrorAndLog } from "../lib/Errors";
import { useBottomSheet } from "./BottomSheetScreen";
import { SelectableRecipe } from "../components/SelectRecipeOverlay";
import { InputAccessoryView } from "../components/InputAccessoryView";
import { BottomActionBar } from "../components/BottomActionBar";
import { Haptics } from "../components/Haptics";
import { Alert } from "../components/Alert/Alert";
import { useClonedLibraryFilterSession, useLibraryFilterSession } from "../lib/recipes/UseLibraryFilterSession.ts";
import { LibraryFilterSessionId } from "../lib/composite/LibraryAndSearchSessionIds.ts";
import { analyticsEvent } from "../lib/analytics/AnalyticsThunks.ts";
import { reportCollectionBulkActionScreenViewed, reportRecipeBulkAction } from "../lib/analytics/AnalyticsEvents.ts";

const strings = {
  actions: {
    addToCollection: {
      screenTitle: (collectionName: string) => `Add to ${collectionName}`,
      submit: (selectedCount: number) => getSelectedCountText({ selectedCount, actionText: "Add" }),
    },
    removeFromCollection: {
      screenTitle: (collectionName: string) => `Remove from ${collectionName}`,
      submit: (selectedCount: number) => getSelectedCountText({ selectedCount, actionText: "Remove" }),
    },
    deleteRecipes: {
      screenTitle: () => "Delete Recipes",
      submit: (selectedCount: number) => getSelectedCountText({ selectedCount, actionText: "Delete" }),
    },
  } satisfies Record<
    RecipeCollectionBulkActionScreenProps["action"],
    { screenTitle: (collectionName: string) => string; submit: (selectedCount: number) => string }
  >,
  confirmDeleteAlert: {
    title: (selectedCount: number) => getSelectedCountText({ selectedCount, actionText: "Delete" }) + "?",
    body: "Selected recipes will be permanently deleted from your library.",
  },
  searchPlaceholder: (collectionName: string) => `Search ${collectionName}`,
  allRecipes: "your library",
};

function getSelectedCountText(args: { selectedCount: number; actionText: string }) {
  return `${args.actionText} ${args.selectedCount} Recipe${args.selectedCount === 1 ? "" : "s"}`;
}

export const RecipeCollectionBulkActionScreen = withScreenContainer(
  "RecipeCollectionBulkActionScreen",
  (props: RecipeCollectionBulkActionScreenProps) => {
    const dispatch = useDispatch();
    const screen = useScreen();
    const collectionName = useCollectionName(props.collectionId);

    // to obey the rules of hooks, we create 2 sessions and choose the correct one below
    // we could ensure consistency by using a ref to save initial props, but this is not an expensive operation
    // if we are adding to the collection, we want to show all the recipes that are *not* in the session
    // if we are selecting recipes from the collection, we want to use whatever view the user was using when they chose to select+remove/delete
    // to do this, we clone the previous session. Any filter changes they make on this screen will not be reflected on the previous screen
    const excludedSession = useLibraryFilterSession({ collectionId: props.collectionId, action: "exclude" });
    const clonedSession = useClonedLibraryFilterSession(props.existingSessionId);

    const sessionId = switchReturn(props.action, {
      addToCollection: excludedSession,
      removeFromCollection: clonedSession,
      deleteRecipes: clonedSession,
    });

    const recipeList = useFilteredRecipeListSections(sessionId, props.sort ?? "default");
    const recipeCount = useRecipeCount(recipeList);

    const searchBoxRef = useRef<TextInputHandle>(null);
    const [headerAnimationRef, onScroll] = useHeaderScrollAnimation();

    const [selected, setSelected] = useState<Record<UserRecipeId, SelectableRecipe>>({});
    const [waitingSubmit, setWaitingSubmit] = useState(false);

    const selectedIds = Object.values(selected)
      .filter(i => !!i.isSelected)
      .map(i => i.recipeId);
    const selectedCount = selectedIds.length;

    // Analytics
    useEffect(() => {
      dispatch(
        analyticsEvent(
          reportCollectionBulkActionScreenViewed({
            collectionName,
            recipeCount: recipeCount,
            action: actionToAnalyticsAction(props.action),
            collectionId: props.collectionId,
          })
        )
      );
    }, []);

    const onCancel = useCallback(() => {
      screen.nav.goBack();
    }, [screen.nav.goBack]);

    const onConfirmDelete = useCallback(async () => {
      try {
        await dispatch(bulkEditRecipes({ type: "bulkDelete", recipeIds: selectedIds }, setWaitingSubmit));
        dispatch(
          analyticsEvent(
            reportRecipeBulkAction({
              action: actionToAnalyticsAction(props.action),
              collectionId: props.collectionId,
              collectionName,
              recipeCount,
              selectedCount,
            })
          )
        );
        Haptics.feedback("operationSucceeded");
        screen.nav.goBack();
      } catch (err) {
        displayUnexpectedErrorAndLog("Error dispatching bulkEditRecipes add action", err, {
          collectionId: props.collectionId,
          recipeIds: selectedIds,
        });
      }
    }, [dispatch, selectedIds, setWaitingSubmit, screen.nav.goBack, props.collectionId]);

    const onSubmit = useCallback(async () => {
      switch (props.action) {
        case "addToCollection": {
          try {
            await dispatch(
              bulkEditRecipes(
                { action: "add", collectionId: props.collectionId, recipeIds: selectedIds, type: "bulkAddRemoveTag" },
                setWaitingSubmit
              )
            );
            dispatch(
              analyticsEvent(
                reportRecipeBulkAction({
                  action: actionToAnalyticsAction(props.action),
                  collectionId: props.collectionId,
                  collectionName,
                  recipeCount,
                  selectedCount,
                })
              )
            );
            Haptics.feedback("operationSucceeded");
            screen.nav.goBack();
          } catch (err) {
            displayUnexpectedErrorAndLog("Error dispatching bulkEditRecipes add action", err, {
              collectionId: props.collectionId,
              recipeIds: selectedIds,
            });
          }
          break;
        }
        case "removeFromCollection": {
          try {
            await dispatch(
              bulkEditRecipes(
                {
                  type: "bulkAddRemoveTag",
                  action: "remove",
                  collectionId: props.collectionId,
                  recipeIds: selectedIds,
                },
                setWaitingSubmit
              )
            );
            dispatch(
              analyticsEvent(
                reportRecipeBulkAction({
                  action: actionToAnalyticsAction(props.action),
                  collectionId: props.collectionId,
                  collectionName,
                  recipeCount,
                  selectedCount,
                })
              )
            );
            Haptics.feedback("operationSucceeded");
            screen.nav.goBack();
          } catch (err) {
            displayUnexpectedErrorAndLog("Error dispatching bulkEditRecipes remove action", err, {
              collectionId: props.collectionId,
              recipeIds: selectedIds,
            });
          }
          break;
        }
        case "deleteRecipes": {
          Alert.alert(strings.confirmDeleteAlert.title(selectedCount), strings.confirmDeleteAlert.body, [
            {
              type: "cancel",
              onPress: () => {},
            },
            {
              type: "delete",
              onPress: onConfirmDelete,
            },
          ]);
          break;
        }
        default:
          bottomThrow(props.action);
      }
    }, [
      dispatch,
      props.action,
      props.collectionId,
      selectedIds,
      selectedCount,
      setWaitingSubmit,
      onConfirmDelete,
      screen.nav.goBack,
    ]);

    const headerAnimation: HeaderAnimationConfig = useMemo(() => {
      return {
        animationProgress: headerAnimationRef,
      };
    }, [headerAnimationRef]);

    const screenHeader = useRecipeLibraryScreenHeader({
      sessionId,
      title: strings.actions[props.action].screenTitle(collectionName),
      searchBoxRef,
      searchBoxPlaceholder: strings.searchPlaceholder(strings.allRecipes),
      animationConfig: headerAnimation,
      resultCount: recipeCount,
      sort: props.sort,
      onCancel,
    });

    return (
      <ScreenView header={screenHeader} isModal scrollView={false} paddingVertical={false} paddingHorizontal={false}>
        <RecipeLibraryListView
          resultCount={recipeCount}
          sections={recipeList}
          sort={props.sort}
          onScroll={onScroll}
          selected={selected}
          setSelected={setSelected}
          sessionId={sessionId}
          disableCookingSessionRecipes={props.action === "deleteRecipes"}
        />
        <InputAccessoryView>
          <BottomActionBar
            primaryAction={{
              type: "submit",
              actionText: strings.actions[props.action].submit(selectedCount),
              onPressAction: onSubmit,
              disabled: selectedCount === 0,
              waiting: waitingSubmit,
            }}
            disableSnapToBottom
          />
        </InputAccessoryView>
      </ScreenView>
    );
  },
  {
    parser: {
      action: s => s as RecipeCollectionBulkActionScreenProps["action"],
      collectionId: s => s as RecipeCollectionId,
      sort: s => s as RecipeListSort,
      existingSessionId: s => s as LibraryFilterSessionId,
    },
    serializer: {
      action: s => s,
      collectionId: s => s,
      sort: s => s,
      existingSessionId: s => s,
    },
  }
);

export function useCollectionBulkAction(props: {
  action: RecipeCollectionBulkActionScreenProps["action"];
  collectionId: RecipeCollectionId | undefined;
  sort?: RecipeListSort;
  existingSessionId: LibraryFilterSessionId;
}) {
  const bottomSheet = useBottomSheet();
  const screen = useScreen();

  const navToSelectRecipesScreen = useCallback(() => {
    const collectionId = props.collectionId;

    if (!collectionId) {
      return undefined;
    }

    bottomSheet?.closeSheetAndGoBack();
    withNavDelay(() =>
      screen.nav.modal(navTree.get.screens.recipeCollectionBulkAction, {
        collectionId,
        action: props.action,
        sort: props.sort ?? "default",
        existingSessionId: props.existingSessionId,
      })
    );
  }, [bottomSheet, props.action, props.collectionId, props.sort]);

  if (!props.collectionId) {
    return undefined;
  }

  return navToSelectRecipesScreen;
}

function actionToAnalyticsAction(action: RecipeCollectionBulkActionScreenProps["action"]) {
  switch (action) {
    case "addToCollection":
      return "add";
    case "deleteRecipes":
      return "delete";
    case "removeFromCollection":
      return "remove";
    default:
      bottomThrow(action);
  }
}

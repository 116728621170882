import { StyleSheet, Text, TextProps, View } from "react-native";
import { IconArrowDown, IconArrowUp, IconClock, IconProps } from "../components/Icons";
import React from "react";
import { globalStyleColors } from "../components/GlobalStyles";

const size = 24;

interface IconSortProps {
  direction: "asc" | "desc";
}

export const IconSortAlphaAsc = React.memo(() => {
  return <IconSortAlpha direction="asc" />;
});

export const IconSortAlphaDesc = React.memo(() => {
  return <IconSortAlpha direction="desc" />;
});

const IconSortAlpha = React.memo((props: IconSortProps) => {
  const color = globalStyleColors.colorAccentCool;

  const textStyleProps: TextProps["style"] = {
    fontSize: 12,
    fontWeight: "700",
    color,
  };

  return (
    <View style={styles.iconContainer}>
      <View style={{ justifyContent: "space-around" }}>
        <View>
          <Text allowFontScaling={false} style={textStyleProps}>
            A
          </Text>
        </View>
        <View>
          <Text allowFontScaling={false} style={textStyleProps}>
            Z
          </Text>
        </View>
      </View>
      <IconSortDirection direction={props.direction} />
    </View>
  );
});

export const IconSortTimeAsc = React.memo(() => {
  return <IconSortTime direction="asc" />;
});

export const IconSortTimeDesc = React.memo(() => {
  return <IconSortTime direction="desc" />;
});

const IconSortTime = React.memo((props: IconSortProps) => {
  const color = globalStyleColors.colorAccentCool;

  return (
    <View style={styles.iconContainer}>
      <IconClock size={16} color={color} opacity="opaque" strokeWidth={2} />
      <IconSortDirection direction={props.direction} />
    </View>
  );
});

const IconSortDirection = React.memo((props: Pick<IconSortProps, "direction">) => {
  const iconProps: IconProps = {
    size: 16,
    color: globalStyleColors.colorAccentCool,
    strokeWidth: 2,
  };

  return (
    <>
      {props.direction === "desc" && <IconArrowDown {...iconProps} />}
      {props.direction === "asc" && <IconArrowUp {...iconProps} />}
    </>
  );
});

const styles = StyleSheet.create({
  iconContainer: {
    width: size,
    height: size,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
});

import React, { useMemo } from "react";
import { StyleSheet, View } from "react-native";
import { globalStyleColors } from "./GlobalStyles";
import { Gradient } from "./Gradient/Gradient";

export const RadioButton = React.memo((props: { isSelected: boolean }) => {
  const gradientColors = useMemo(
    () => [globalStyleColors.rgba("colorAccentCool", "light"), globalStyleColors.colorAccentCool],
    []
  );

  return (
    <View style={styles.outline}>
      {props.isSelected && (
        <>
          <View style={styles.filledBackground}></View>
          <View style={styles.filled}>
            <Gradient type="topLeftToBottomRight" colors={gradientColors} />
          </View>
        </>
      )}
    </View>
  );
});

const styles = StyleSheet.create({
  outline: {
    borderRadius: Number.MAX_SAFE_INTEGER,
    borderWidth: 1,
    borderColor: globalStyleColors.rgba("colorAccentCool", "medium"),
    width: 24,
    height: 24,
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
  },
  filled: {
    width: "45%",
    height: "45%",
    borderRadius: Number.MAX_SAFE_INTEGER,
    overflow: "hidden",
    // backgroundColor: globalStyleColors.rgba("colorAccentCool", "opaque"),
  },
  filledBackground: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: globalStyleColors.rgba("colorAccentCool", "xxlight"),
  },
});

import { ThunkAction } from "../redux/Redux";
import {
  futurePlanRequested,
  futurePlanReceived,
  futurePlanErrored,
  planDataReceived,
  getFutureMealPlanStartDate,
  singlePlannedItemReceived,
} from "./PlanningSlice";
import { log } from "../../Log";
import { isLoading } from "../redux/ServerData";
import {
  GetMealPlanArgs,
  AddItemToPlanArgs,
  UpdatePlannedItemArgs,
  RemoveItemFromPlanArgs,
  GetPlannedItemArgs,
} from "@eatbetter/planning-shared";
import { SetWaitingHandler } from "../Types";

/**
 * Load the default start data for the calendar view, which is all future data, along with X days in the past
 */
export const getFutureMealPlan = (): ThunkAction<void> => {
  return async (dispatch, getState, deps) => {
    log.info("Thunk: getFutureMealPlan");

    const state = getState();
    if (isLoading("planning.futurePlanMeta", state, s => s.planning.futurePlanMeta)) {
      log.info("getFutureMealPlan called, but status is already loading");
      return;
    }

    try {
      const startTime = deps.time.epochMs();
      dispatch(futurePlanRequested(startTime));
      const start = getFutureMealPlanStartDate();
      const resp = await deps.api.withThrow().getMealPlan({ start });
      dispatch(futurePlanReceived({ startTime, data: resp.data }));
      dispatch(planDataReceived(resp.data));
    } catch (err) {
      log.errorCaught("Unexpected error fetching future meal plan", err);
      dispatch(futurePlanErrored());
    }
  };
};

export const getMealPlanData = (args: GetMealPlanArgs, setWaiting?: SetWaitingHandler): ThunkAction<void> => {
  return async (dispatch, _getState, deps) => {
    log.info("Thunk: getMealPlanData", { args });

    try {
      setWaiting?.(true);
      const resp = await deps.api.withThrow(setWaiting).getMealPlan(args);
      dispatch(planDataReceived(resp.data));
    } catch (err) {
      // we don't throw here since it would just display an error to the user as they scroll
      log.errorCaught("Unexpected error fetching future meal plan", err);
    } finally {
      setWaiting?.(false);
    }
  };
};

/**
 * Get a single planned item by ID
 */
export const getPlanItem = (args: GetPlannedItemArgs, setWaiting?: SetWaitingHandler): ThunkAction<void> => {
  return async (dispatch, _getState, deps) => {
    log.info("Thunk: getPlanItem", { args });

    try {
      setWaiting?.(true);
      const resp = await deps.api.withThrow(setWaiting).getPlannedItem(args);
      dispatch(singlePlannedItemReceived(resp.data));
    } finally {
      setWaiting?.(false);
    }
  };
};

/**
 * Add a new item to the meal plan
 */
export const addPlanItem = (args: AddItemToPlanArgs, setWaiting?: SetWaitingHandler): ThunkAction<void> => {
  return async (dispatch, _getState, deps) => {
    log.info("Thunk: addPlanItem", { args });

    try {
      setWaiting?.(true);
      const resp = await deps.api.withThrow(setWaiting).addItemToPlan(args);
      dispatch(singlePlannedItemReceived(resp.data));
    } finally {
      setWaiting?.(false);
    }
  };
};

/**
 * Update an existing planned item
 */
export const updatePlanItem = (args: UpdatePlannedItemArgs, setWaiting?: SetWaitingHandler): ThunkAction<void> => {
  return async (dispatch, _getState, deps) => {
    log.info("Thunk: updatePlanItem", { args });

    try {
      setWaiting?.(true);
      const resp = await deps.api.withThrow(setWaiting).updatePlannedItem(args);
      dispatch(singlePlannedItemReceived(resp.data));
    } finally {
      setWaiting?.(false);
    }
  };
};

/**
 * Remove an item from the meal plan
 */
export const deletePlanItem = (args: RemoveItemFromPlanArgs, setWaiting?: SetWaitingHandler): ThunkAction<void> => {
  return async (dispatch, _getState, deps) => {
    log.info("Thunk: deletePlanItem", { args });

    try {
      setWaiting?.(true);
      const resp = await deps.api.withThrow(setWaiting).removeItemFromPlan(args);
      dispatch(singlePlannedItemReceived(resp.data));
    } finally {
      setWaiting?.(false);
    }
  };
};

import React, { useCallback, useState } from "react";
import { useDispatch } from "../../lib/redux/Redux";
import { useScreen } from "../../navigation/ScreenContainer";
import { Haptics } from "../Haptics";
import { RecipeTagSelect, tagOrFilterIsSelected } from "./RecipeTagSelect";
import { addSearchTagFilter, removeSearchTagFilter } from "../../lib/search/SearchThunks";
import {
  RecipeTagOrFilter,
  useActiveTagsAndFilters,
  useTagsAndFiltersForFiltering,
} from "../../lib/composite/CollectionsSelectors.ts";
import { addTagOrFilterToLibraryFilter, removeTagOrFilterFromLibraryFilter } from "../../lib/recipes/RecipesSlice.ts";
import { isGlobalSearchSessionId, LibraryOrSearchSessionId } from "../../lib/composite/LibraryAndSearchSessionIds.ts";

export const RecipeFilterControl = React.memo((props: { sessionId: LibraryOrSearchSessionId }) => {
  const dispatch = useDispatch();
  const screen = useScreen();

  const groups = useTagsAndFiltersForFiltering(props.sessionId);
  const activeFilters = useActiveTagsAndFilters(props.sessionId);

  const [waiting, setWaiting] = useState<Record<string, boolean>>({});

  const toggle = useCallback(
    (tagOrFilter: RecipeTagOrFilter) => {
      Haptics.feedback("itemStatusChanged");

      const selected = tagOrFilterIsSelected(tagOrFilter, activeFilters);

      if (isGlobalSearchSessionId(props.sessionId)) {
        // global search context
        if (selected) {
          dispatch(removeSearchTagFilter({ tagOrFilter, sessionId: props.sessionId }));
        } else {
          dispatch(addSearchTagFilter({ tagOrFilter, sessionId: props.sessionId }));
        }
      } else {
        // user library context
        if (selected) {
          dispatch(removeTagOrFilterFromLibraryFilter({ tagOrFilter, sessionId: props.sessionId }));
        } else {
          dispatch(addTagOrFilterToLibraryFilter({ tagOrFilter, sessionId: props.sessionId }));
        }
      }
    },
    [activeFilters, dispatch, setWaiting, screen.nav.goBack]
  );

  return <RecipeTagSelect groups={groups} selected={activeFilters} onPressTag={toggle} waiting={waiting} />;
});

import React from "react";
import { GradientComponent, GradientProps } from "./GradientInterfaces";
import { View } from "react-native";
import { bottomThrow } from "@eatbetter/common-shared";
import { globalStyleColors } from "../GlobalStyles";

export const Gradient: GradientComponent = React.memo((props: GradientProps) => {
  const getGradient = (): string => {
    switch (props.type) {
      case "topToBottom": {
        return `linear-gradient(to bottom, ${props.colors?.[0] ?? globalStyleColors.white}, ${
          props.colors?.[1] ?? globalStyleColors.colorGreyDark
        })`;
      }
      case "topLeftToBottomRight": {
        return `linear-gradient(to bottom right, ${props.colors?.[0] ?? globalStyleColors.white}, ${
          props.colors?.[1] ?? globalStyleColors.colorGreyDark
        })`;
      }
      case "topRightToBottomLeft": {
        return `linear-gradient(to bottom left, ${props.colors?.[0] ?? globalStyleColors.white}, ${
          props.colors?.[1] ?? globalStyleColors.colorGreyDark
        })`;
      }
      case "shimmer": {
        return `linear-gradient(to right, ${props.colors?.[0] ?? globalStyleColors.rgba("white", "transparent")}, ${
          props.colors?.[1] ?? globalStyleColors.rgba("white", "light")
        }, ${props.colors?.[2] ?? globalStyleColors.rgba("white", "transparent")})`;
      }
      default:
        bottomThrow(props.type);
    }
  };

  const gradientStyle = {
    background: getGradient(),
    flex: 1,
  };

  return <View style={gradientStyle}>{props.children}</View>;
});

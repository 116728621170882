import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useResponsiveDimensions } from "./Responsive";
import { RootSiblingPortal } from "react-native-root-siblings";
import { StyleSheet, View } from "react-native";
import { Pressable } from "./Pressable";
import Animated, { SlideInDown } from "react-native-reanimated";
import { NewBadge } from "./Badges";
import { Spacer } from "./Spacer";
import { TBody, TSecondary } from "./Typography";
import { ButtonRectangle } from "./Buttons";
import { globalStyleColors, globalStyleConstants, globalStyles, Opacity } from "./GlobalStyles";
import { useScreen } from "../navigation/ScreenContainer";
import { useDispatch } from "../lib/redux/Redux";
import {
  getActiveNewFeatureAlert,
  getShowNewFeatureAlert,
  NewFeatureAlertType,
  reportNewFeatureAlertModal,
  setShowNewFeatureAlertDismissed,
} from "../lib/system/SystemThunks";
import { FeatureDemoSnippet } from "./FeatureDemoSnippet";
import { useAppFocused } from "../lib/system/SystemSelectors";

const strings = {
  newFeatureAlerts: {
    collections: {
      headline: "Recipe Collections",
      subheadline: "Now you can organize your library with Recipe Collections.",
    },
    scaling: {
      headline: "Scaling & Unit Conversions",
      subheadline: "Now you can scale and convert units on any recipe.",
    },
  } satisfies Record<NewFeatureAlertType, Pick<NewFeatureModalProps, "headline" | "subheadline">>,
  gotIt: "Got it",
};

export function useNewFeatureModal(): React.ReactNode {
  const dispatch = useDispatch();
  const screen = useScreen();
  const appFocused = useAppFocused();

  const [showNewFeatureFlag, setShowNewFeatureFlag] = useState(false);
  // Separate state for conditional rendering of the modal so that we can control/tweak the entry/exit
  const [showNewFeatureModal, setShowNewFeatureModal] = useState(false);

  // Load value from async storage to determine if we want to show the new feature modal
  // Note: we set the async storage key during onboarding (`OnboardingStartScreen.tsx`) to ensure that
  // this only gets shown to existing users.
  useEffect(() => {
    if (appFocused && screen.nav.focused) {
      dispatch(getShowNewFeatureAlert())
        .then(value => setShowNewFeatureFlag(value))
        .catch(() => {
          // thunk handles errors
        });
    }
  }, [appFocused, screen.nav.focused]);

  // Shows/hides the modal
  useEffect(() => {
    if (showNewFeatureFlag && screen.nav.focused) {
      // Slight delay for aesthetic purposes
      setTimeout(() => {
        dispatch(reportNewFeatureAlertModal("displayed"));
        setShowNewFeatureModal(true);
      }, 300);
    } else {
      setShowNewFeatureModal(false);
    }
  }, [showNewFeatureFlag]);

  const onDismiss = useCallback(async () => {
    // Create file in async storage to suppress showing again and update local state to hide modal
    await dispatch(setShowNewFeatureAlertDismissed());
    setShowNewFeatureFlag(false);
    dispatch(reportNewFeatureAlertModal("dismissed"));
  }, [dispatch, setShowNewFeatureFlag]);

  const activeAlertType = getActiveNewFeatureAlert();

  const modalProps: NewFeatureModalProps = {
    type: activeAlertType,
    headline: strings.newFeatureAlerts[activeAlertType].headline,
    subheadline: strings.newFeatureAlerts[activeAlertType].subheadline,
    onDismiss,
  };

  return useMemo(() => showNewFeatureModal && <NewFeatureModal {...modalProps} />, [showNewFeatureModal, modalProps]);
}

interface NewFeatureModalProps {
  type: NewFeatureAlertType;
  headline: string;
  subheadline: string;
  onDismiss: () => void;
}

const NewFeatureModal = React.memo((props: NewFeatureModalProps) => {
  const dimensions = useResponsiveDimensions();
  const messageWidth = Math.min(350, 0.75 * dimensions.width);

  return (
    <RootSiblingPortal>
      <View style={styles.modal}>
        <Pressable style={styles.background} onPress={props.onDismiss} />
        <Animated.View entering={SlideInDown.delay(200)} style={[styles.messageContainer, { width: messageWidth }]}>
          <NewBadge />
          <Spacer vertical={1.5} />
          <TBody fontWeight="medium" align="center">
            {props.headline}
          </TBody>
          <TSecondary align="center">{props.subheadline}</TSecondary>
          <Spacer vertical={1.5} />
          <FeatureDemoSnippet type={props.type} borderRadius />
          <Spacer vertical={1.5} />
          <GotItButton onPress={props.onDismiss} />
        </Animated.View>
      </View>
    </RootSiblingPortal>
  );
});

const GotItButton = React.memo((props: { onPress: () => void }) => {
  return (
    <View style={{ width: 48 }}>
      <ButtonRectangle type="secondary" title={strings.gotIt} size="small" onPress={props.onPress} />
    </View>
  );
});

const styles = StyleSheet.create({
  modal: {
    ...StyleSheet.absoluteFillObject,
    justifyContent: "center",
    alignItems: "center",
  },
  messageContainer: {
    backgroundColor: "white",
    alignItems: "center",
    justifyContent: "center",
    padding: globalStyleConstants.defaultPadding,
    borderRadius: globalStyleConstants.defaultBorderRadius,
    ...globalStyles.shadowItem,
    shadowOpacity: Opacity.medium,
  },
  background: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: globalStyleColors.rgba("black", "medium"),
  },
});

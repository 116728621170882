import { Reducer, useCallback, useMemo, useReducer, useRef, useState } from "react";
import { CollectionGroupData, useCollections } from "../../lib/composite/CollectionsSelectors";
import produce from "immer";
import { displayExpectedError, displayUnexpectedErrorAndLog } from "../../lib/Errors";
import { LayoutAnimation } from "react-native";
import { useDispatch } from "../../lib/redux/Redux";
import { editCollections } from "../../lib/recipes/RecipesThunks";
import {
  AppRecipeCollection,
  isStructuredRecipeError,
  RecipeCollectionGroupId,
  RecipeCollectionId,
  RecipeCollectionLayout,
} from "@eatbetter/recipes-shared";
import { bottomThrow, newId } from "@eatbetter/common-shared";
import { analyticsEvent } from "../../lib/analytics/AnalyticsThunks";
import {
  reportCollectionGroupRenamed,
  reportCollectionMovedToGroup,
  reportCollectionsEditModeEnded,
  reportDeleteCollection,
  reportHideUnhideCollection,
  reportRenameCollection,
} from "../../lib/analytics/AnalyticsEvents";

export interface RecipeCollectionsEditMode {
  moveCollectionUp: (groupIndex: number, index: number) => void;
  moveCollectionDown: (groupIndex: number, index: number) => void;
  moveGroupUp: (groupIndex: number) => void;
  moveGroupDown: (groupIndex: number) => void;
  renameGroup: (groupIndex: number, newName: string) => void;
  moveCollectionToGroup: (
    collectionId: RecipeCollectionId,
    toGroup: RecipeCollectionGroupId | { type: "new"; name: string }
  ) => Promise<void>;
  renameCollection: (collectionId: RecipeCollectionId, fromName: string, toName: string) => Promise<void>;
  deleteCollection: (collectionId: RecipeCollectionId) => Promise<void>;
  hideCollection: (collectionId: RecipeCollectionId) => Promise<void>;
  unhideCollection: (collectionId: RecipeCollectionId) => Promise<void>;
}

type CollectionsEditModeGroupActions =
  | { type: "reorderGroup"; fromIndex: number; toIndex: number }
  | { type: "renameGroup"; index: number; title: string }
  | { type: "reorderCollection"; groupIndex: number; fromIndex: number; toIndex: number };

type CollectionsEditModeActions = CollectionsEditModeGroupActions | { type: "startEditMode" } | { type: "endEditMode" };

interface CollectionsEditModeState {
  groupData: CollectionGroupData[] | undefined;
  isDirty: boolean;
}

type CollectionsEditModeReducer = Reducer<CollectionsEditModeState, CollectionsEditModeActions>;

/**
 * Provides collections data with edit mode callbacks. Internally manages collections layout draft + persistence.
 */
export function useCollectionsWithEditMode() {
  const dispatch = useDispatch();
  const collectionsDataPersisted = useCollections();

  const [waitingEndEdit, setWaitingEndEdit] = useState(false);

  const [collectionsEditModeDraft, collectionsEditModeDispatch] = useReducer<CollectionsEditModeReducer>(
    (current, action) => {
      return produce(current, draft => {
        switch (action.type) {
          case "startEditMode": {
            draft.groupData = collectionsDataPersisted;
            draft.isDirty = false;
            break;
          }
          case "endEditMode": {
            draft.groupData = undefined;
            draft.isDirty = false;
            break;
          }
          case "reorderCollection": {
            const group = verifyDefined("group", draft.groupData?.[action.groupIndex], action);

            if (action.toIndex < 0 || action.toIndex >= group.collections.length) {
              displayUnexpectedErrorAndLog("moveCollectionWithinSection: toIndex value is out of bounds", {
                action,
                draft,
              });
              break;
            }

            const [itemToMove] = group.collections.splice(action.fromIndex, 1);
            if (!itemToMove) {
              displayUnexpectedErrorAndLog(
                "moveCollectionWithinSection: collection at specified fromIndex is undefined",
                { action, draft }
              );
              break;
            }

            group.collections.splice(action.toIndex, 0, itemToMove);
            draft.isDirty = true;
            break;
          }
          case "reorderGroup": {
            const groups = verifyDefined("groups", draft.groupData, action);

            if (action.toIndex < 0 || action.toIndex >= groups.length) {
              displayUnexpectedErrorAndLog("moveGroup: toIndex value is out of bounds", {
                action,
                draft,
              });
              break;
            }

            const [groupToMove] = groups.splice(action.fromIndex, 1);
            if (!groupToMove) {
              displayUnexpectedErrorAndLog("moveGroup: group at specified fromIndex is undefined", { action, draft });
              break;
            }

            groups.splice(action.toIndex, 0, groupToMove);
            draft.isDirty = true;
            break;
          }
          case "renameGroup": {
            const group = verifyDefined("group", draft.groupData?.[action.index], action);
            if (!group.group.canRename) {
              displayUnexpectedErrorAndLog("renameGroup: canRename is false - this points to a bug in the UI", {
                group,
                action,
              });
              break;
            }

            group.group.name = action.title;
            draft.isDirty = true;
            break;
          }
          default:
            bottomThrow(action);
        }
      });
    },
    { groupData: undefined, isDirty: false }
  );

  // Return group data draft if we have one, otherwise return persisted data
  const collectionsData = collectionsEditModeDraft.groupData ?? collectionsDataPersisted;

  const editModeAcionsAnalytics = useRef({
    collectionMoveUp: 0,
    collectionMoveDown: 0,
    groupMoveUp: 0,
    groupMoveDown: 0,
  });

  const startEditMode = useCallback(() => {
    collectionsEditModeDispatch({ type: "startEditMode" });
  }, [collectionsEditModeDispatch]);

  const endEditMode = useCallback(async () => {
    dispatch(analyticsEvent(reportCollectionsEditModeEnded(editModeAcionsAnalytics.current)));

    // Don't make persist call unless changes were made (isDirty) to avoid waiting unnecessarily
    if (collectionsEditModeDraft.groupData && collectionsEditModeDraft.isDirty) {
      try {
        await dispatch(
          editCollections({ layout: getCollectionsLayout(collectionsEditModeDraft.groupData) }, setWaitingEndEdit)
        );
      } catch (err) {
        displayUnexpectedErrorAndLog("endEditMode: error caught dispatching editCollections", err, {
          collectionsEditModeDraft,
        });
      }
    }
    layoutAnimation();
    collectionsEditModeDispatch({ type: "endEditMode" });
  }, [
    collectionsEditModeDispatch,
    collectionsEditModeDraft.groupData,
    collectionsEditModeDraft.isDirty,
    setWaitingEndEdit,
    dispatch,
  ]);

  /**
   * We wrap the reducer so that we can also trigger side effects if needed for certain actions. We also provide
   * convenience callbacks to components (e.g. moveUp/Down) while keeping the reducer logic tight.
   */
  const editModeActions = useMemo<RecipeCollectionsEditMode | undefined>(() => {
    const collectionsDraft = collectionsEditModeDraft.groupData;
    if (!collectionsDraft) {
      return undefined;
    }

    // Create collection lookup to fetch analytics properties from each of the calls below
    const collectionsById: Record<RecipeCollectionId, AppRecipeCollection> = {};
    collectionsDraft.forEach(i =>
      i.collections.forEach(collection => (collectionsById[collection.collection.id] = collection.collection))
    );

    return {
      moveCollectionDown: (groupIndex, index) => {
        layoutAnimation();
        collectionsEditModeDispatch({
          type: "reorderCollection",
          groupIndex,
          fromIndex: index,
          toIndex: index + 1,
        });
        editModeAcionsAnalytics.current.collectionMoveDown += 1;
      },
      moveCollectionUp: (groupIndex, index) => {
        layoutAnimation();
        collectionsEditModeDispatch({
          type: "reorderCollection",
          groupIndex,
          fromIndex: index,
          toIndex: index - 1,
        });
        editModeAcionsAnalytics.current.collectionMoveUp += 1;
      },
      moveGroupDown: index => {
        layoutAnimation();
        collectionsEditModeDispatch({ type: "reorderGroup", fromIndex: index, toIndex: index + 1 });
        editModeAcionsAnalytics.current.groupMoveDown += 1;
      },
      moveGroupUp: index => {
        layoutAnimation();
        collectionsEditModeDispatch({ type: "reorderGroup", fromIndex: index, toIndex: index - 1 });
        editModeAcionsAnalytics.current.groupMoveUp += 1;
      },
      renameGroup: (index, groupName) => {
        layoutAnimation();
        collectionsEditModeDispatch({ type: "renameGroup", index, title: groupName });
        dispatch(analyticsEvent(reportCollectionGroupRenamed({ newName: groupName })));
      },
      moveCollectionToGroup: async (collectionId, toGroup) => {
        if (!collectionsDraft) {
          const errorMsg = "moveCollectionToGroup: collectionsDraft is undefined";
          displayUnexpectedErrorAndLog(errorMsg, {});
          throw new Error(errorMsg);
        }

        const layout = getCollectionsLayout(collectionsDraft);

        // Remove the collection from the current group
        const currentGroup = layout.groups.find(g => g.collections.includes(collectionId));

        if (!currentGroup) {
          const errorMsg = "moveCollectionToGroup: current group not found for collection";
          displayUnexpectedErrorAndLog(errorMsg, { collectionId, toGroup });
          throw new Error(errorMsg);
        }

        const idx = currentGroup.collections.indexOf(collectionId);

        if (idx === -1) {
          const errorMsg = "moveCollectionToGroup: collection not found in existing group";
          displayUnexpectedErrorAndLog(errorMsg, { collectionId, toGroup });
          throw new Error(errorMsg);
        }

        currentGroup.collections.splice(idx, 1);

        if (typeof toGroup === "string") {
          // Move to existing group: find target group and move the collection into it
          const targetGroup = layout.groups.find(g => g.id === toGroup);

          if (!targetGroup) {
            const errorMsg = "moveCollectionToGroup: target group not found";
            displayUnexpectedErrorAndLog(errorMsg, { collectionId, toGroup });
            throw new Error(errorMsg);
          }

          targetGroup.collections.push(collectionId);
        } else {
          // Move to new group: create a new group with the specified name and add the collection to it
          const groupId = `ug:${newId()}` as RecipeCollectionGroupId;
          layout.groups.push({ id: groupId, name: toGroup.name, collections: [collectionId] });
        }

        try {
          layoutAnimation();
          await dispatch(editCollections({ layout }, setWaitingEndEdit));

          dispatch(analyticsEvent(reportCollectionMovedToGroup({ collectionId, toGroupNameOrId: toGroup })));

          // Refresh draft but keep edit mode on.
          collectionsEditModeDispatch({ type: "startEditMode" });
        } catch (err) {
          displayUnexpectedErrorAndLog("moveCollectionToGroup: error caught dispatching editCollections", err, {
            collectionsDraft,
            collectionId,
            toGroup: typeof toGroup === "string" ? toGroup : toGroup.name,
          });
          throw err;
        }
      },

      renameCollection: async (collectionId, from, to) => {
        try {
          await dispatch(
            editCollections(
              {
                collection: { op: { type: "rename", id: collectionId, from, to } },
                layout: getCollectionsLayout(collectionsDraft),
              },
              setWaitingEndEdit
            )
          );

          const collection = collectionsById[collectionId];
          dispatch(analyticsEvent(reportRenameCollection({ collectionId, collectionName: collection?.name })));

          // Refresh draft but keep edit mode on
          collectionsEditModeDispatch({ type: "startEditMode" });
        } catch (err) {
          // THIS ERROR HANDLER IS DUPLICATED IN 3 PLACES. ANY CHANGES HERE MIGHT NEED TO BE MADE IN THOSE PLACES AS WELL
          // SEE CreateRecipeCollectionsScreen.tsx
          // SEE RecipeCollectionScreen.tsx
          if (isStructuredRecipeError(err) && err.data.code === "recipes/collectionNameInvalidError") {
            displayExpectedError(err.data.payload.userMessage);
          } else {
            displayUnexpectedErrorAndLog("renameCollection: error caught dispatching editCollections", err, {
              collectionsDraft,
              collectionId,
              from,
              to,
            });
          }

          // this is needed to prevent nav back after rename.
          throw err;
        }
      },
      deleteCollection: async collectionId => {
        try {
          await dispatch(
            editCollections(
              {
                collection: { op: { type: "delete", id: collectionId } },
                layout: getCollectionsLayout(collectionsDraft),
              },
              setWaitingEndEdit
            )
          );

          const collection = collectionsById[collectionId];
          dispatch(analyticsEvent(reportDeleteCollection({ collectionId, collectionName: collection?.name })));

          // Refresh draft but keep edit mode on
          collectionsEditModeDispatch({ type: "startEditMode" });
        } catch (err) {
          displayUnexpectedErrorAndLog("deleteCollection: error caught dispatching editCollections", err, {
            collectionsDraft,
            collectionId,
          });
          throw err;
        }
      },
      hideCollection: async collectionId => {
        try {
          await dispatch(
            editCollections(
              {
                collection: { op: { type: "hide", id: collectionId } },
                layout: getCollectionsLayout(collectionsDraft),
              },
              setWaitingEndEdit
            )
          );

          layoutAnimation();
          const collection = collectionsById[collectionId];
          dispatch(
            analyticsEvent(
              reportHideUnhideCollection({ action: "hide", collectionId, collectionSourceType: collection?.source })
            )
          );

          // Refresh draft but keep edit mode on
          collectionsEditModeDispatch({ type: "startEditMode" });
        } catch (err) {
          displayUnexpectedErrorAndLog("hideCollection: error caught dispatching editCollections", err, {
            collectionsDraft,
            collectionId,
          });
        }
      },
      unhideCollection: async collectionId => {
        try {
          await dispatch(
            editCollections(
              {
                collection: { op: { type: "unhide", id: collectionId } },
                layout: getCollectionsLayout(collectionsDraft),
              },
              setWaitingEndEdit
            )
          );

          layoutAnimation();
          const collection = collectionsById[collectionId];
          dispatch(
            analyticsEvent(
              reportHideUnhideCollection({ action: "unhide", collectionId, collectionSourceType: collection?.source })
            )
          );

          // Refresh draft but keep edit mode on
          collectionsEditModeDispatch({ type: "startEditMode" });
        } catch (err) {
          displayUnexpectedErrorAndLog("unhideCollection: error caught dispatching editCollections", err, {
            collectionsDraft,
            collectionId,
          });
        }
      },
    };
  }, [collectionsEditModeDispatch, collectionsEditModeDraft, setWaitingEndEdit]);

  return { collectionsData, editModeActions, startEditMode, endEditMode, waitingEndEdit };
}

/**
 * Transforms collections group data into layout object passed to edit collections endpoint
 */
export function getCollectionsLayout(groupData: CollectionGroupData[]): RecipeCollectionLayout {
  return {
    groups: groupData.map(i => ({
      id: i.group.id,
      name: i.group.name,
      collections: i.collections.map(i => i.collection.id),
    })),
  };
}

function layoutAnimation() {
  LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
}

function verifyDefined<T>(key: string, value: T | undefined, action: CollectionsEditModeActions): T {
  if (value === undefined) {
    throw new Error(`${JSON.stringify(action)}: ${key} is undefined`);
  }
  return value;
}

import { useCallback } from "react";
import { useScreen } from "../navigation/ScreenContainer";
import { navTree } from "../navigation/NavTree";
import { getOptionsMenuHeight, OptionsMenu, OptionsMenuItem } from "../components/OptionsMenu";
import React from "react";
import { useBottomSheet } from "./BottomSheetScreen";
import { RecipeListSort } from "../lib/composite/RecipeListSelectors";
import { Haptics } from "../components/Haptics";
import { useDispatch } from "../lib/redux/Redux";
import { analyticsEvent } from "../lib/analytics/AnalyticsThunks";
import { reportRecipeListSortChanged, reportRecipeListSortMenuTapped } from "../lib/analytics/AnalyticsEvents";

const strings = {
  header: "Sort By",
  sortOptions: {
    default: "Smart",
    alpha_asc: "Recipe Title (A to Z)",
    alpha_desc: "Recipe Title (Z to A)",
    dateCreated_desc: "Newest First",
    dateCreated_asc: "Oldest First",
  } satisfies Record<RecipeListSort, string>,
  sortedByDisplayText: {
    default: "",
    alpha_asc: ", by title (A to Z)",
    alpha_desc: ", by title (Z to A)",
    dateCreated_asc: ", oldest first",
    dateCreated_desc: ", newest first",
  } satisfies Record<RecipeListSort, string>,
  default: "(default)",
};

export function useRecipeListSortMenu(props: Partial<RecipeListSortMenuProps>) {
  const { activeSort, onChangeSort } = props;
  const dispatch = useDispatch();
  const screen = useScreen();

  const onChangeSortInternal = useCallback(
    (selectedSort: RecipeListSort) => {
      Haptics.feedback("itemStatusChanged");
      onChangeSort?.(selectedSort);
    },
    [onChangeSort]
  );

  const openCollectionsMenu = useCallback(() => {
    dispatch(analyticsEvent(reportRecipeListSortMenuTapped()));
    if (onChangeSort) {
      screen.nav.modal(navTree.get.screens.bottomSheet, {
        content: <RecipeListSortMenu activeSort={activeSort ?? "default"} onChangeSort={onChangeSortInternal} />,
        height: getOptionsMenuHeight(5, true),
      });
    }
  }, [screen.nav.modal, activeSort, onChangeSort, dispatch]);

  return onChangeSort ? openCollectionsMenu : undefined;
}

interface RecipeListSortMenuProps {
  activeSort: RecipeListSort;
  onChangeSort: (selectedOption: RecipeListSort) => void;
}

const RecipeListSortMenu = React.memo((props: RecipeListSortMenuProps) => {
  const dispatch = useDispatch();
  const screen = useScreen();
  const bottomSheet = useBottomSheet();

  const onPressOption = useCallback(
    (selectedOption: RecipeListSort) => {
      bottomSheet?.closeSheetAndGoBack();
      props.onChangeSort(selectedOption);
      dispatch(analyticsEvent(reportRecipeListSortChanged({ newSort: selectedOption })));
    },
    [screen.nav.goTo, bottomSheet, props.onChangeSort, dispatch]
  );

  return (
    <OptionsMenu header={strings.header}>
      {Object.entries(strings.sortOptions).map(([key, text], idx) => (
        <OptionsMenuItem
          key={key}
          isFirst={idx === 0}
          icon={props.activeSort === key ? "selected" : "unselected"}
          text={text}
          preview={idx === 0 ? strings.default : undefined}
          onPress={() => onPressOption(key as RecipeListSort)}
        />
      ))}
    </OptionsMenu>
  );
});

export function getSortedByDisplayText(sort: RecipeListSort) {
  return strings.sortedByDisplayText[sort];
}

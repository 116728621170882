import { bottomThrow } from "@eatbetter/common-shared";
import React, { PropsWithChildren } from "react";
import { StyleSheet, View } from "react-native";
import { useNotificationUnreadCount } from "../lib/notifications/NotificationsSelectors";
import { log } from "../Log";
import { globalStyleColors, globalStyleConstants } from "./GlobalStyles";
import {
  IconBell,
  IconBellFilled,
  IconEditSquare,
  IconMore,
  IconOpenLink,
  IconPlus,
  IconMenu,
  IconShare,
  IconSpeakerMuted,
  IconSpeakerOn,
  IconSettings,
  IconText,
  IconSearchUsers,
  IconSort,
} from "./Icons";
import { Pressable } from "./Pressable";
import { TBody } from "./Typography";
import { ButtonRectangle } from "./Buttons";
import { WalkthroughStep, WalkthroughStepProps } from "./Walkthrough";
import { Spinner } from "./Spinner";
import { ContainerPadded } from "./Containers";
import { ScalingAndConversionsButton, ScalingAndConversionsButtonProps } from "./recipes/ScalingAndConversions";
import { RecipeListSort } from "../lib/composite/RecipeListSelectors";
import { IconSortAlphaAsc, IconSortAlphaDesc, IconSortTimeAsc, IconSortTimeDesc } from "../screens/RecipeListSortIcons";

const strings = {
  done: "Done",
  cancel: "Cancel",
  deleteAll: "Clear all",
  invite: "Invite",
  save: "Save",
  signOut: "Sign out",
  signUp: "Join Free",
  today: "Today",
};

interface HeaderRightPropsBase<TType extends string> {
  type: TType;
  onPress: () => void;
  /**
   * This is set by the screen header - setting this prop has no effect. If you want to pass color in to a button,
   * use your own color prop.
   */
  tintColor?: string;
  marginRight?: number;
  disabled?: boolean;
  walkthrough?: WalkthroughStepProps;
  waiting?: boolean;
}

type AddProps = HeaderRightPropsBase<"add">;
type CancelProps = HeaderRightPropsBase<"cancel"> & { bold?: boolean; color?: string };
type DoneProps = HeaderRightPropsBase<"done"> & { bold?: boolean; color?: string };
type DeleteAllProps = HeaderRightPropsBase<"deleteAll">;
type EditProps = HeaderRightPropsBase<"edit">;
type FindUsersProps = HeaderRightPropsBase<"findUsers">;
type InviteProps = HeaderRightPropsBase<"invite">;
type MenuProps = HeaderRightPropsBase<"menu">;
type MoreMenuProps = HeaderRightPropsBase<"moreMenu">;
type NotificationCenterProps = HeaderRightPropsBase<"notificationCenter">;
type OpenLinkProps = HeaderRightPropsBase<"openLink">;
type SaveProps = HeaderRightPropsBase<"save"> & { waiting?: boolean };
type ScaleAndConvertProps = Omit<HeaderRightPropsBase<"scaleAndConvert">, "onPress"> & {
  scaleAndConvertProps: ScalingAndConversionsButtonProps;
};
type SettingsProps = HeaderRightPropsBase<"settings">;
type ShareProps = HeaderRightPropsBase<"share">;
type SignOutProps = HeaderRightPropsBase<"signOut">;
type SignUpProps = HeaderRightPropsBase<"signUp">;
type SortProps = HeaderRightPropsBase<"sort"> & { activeSort: RecipeListSort };
type SpeakerMutedProps = HeaderRightPropsBase<"speakerMuted">;
type SpeakerOnProps = HeaderRightPropsBase<"speakerOn">;
type TextSizeProps = HeaderRightPropsBase<"textSize">;
type TodayProps = HeaderRightPropsBase<"today">;

interface TwoButtonsProps extends Omit<HeaderRightPropsBase<"twoButtons">, "onPress"> {
  left: Exclude<HeaderRightProps, TwoButtonsProps | ThreeButtonsProps>;
  right: Exclude<HeaderRightProps, TwoButtonsProps | ThreeButtonsProps>;
}

interface ThreeButtonsProps extends Omit<HeaderRightPropsBase<"threeButtons">, "onPress"> {
  left: Exclude<HeaderRightProps, TwoButtonsProps | ThreeButtonsProps>;
  middle: Exclude<HeaderRightProps, TwoButtonsProps | ThreeButtonsProps>;
  right: Exclude<HeaderRightProps, TwoButtonsProps | ThreeButtonsProps>;
}

export type HeaderRightProps =
  | AddProps
  | CancelProps
  | DeleteAllProps
  | DoneProps
  | EditProps
  | FindUsersProps
  | InviteProps
  | MenuProps
  | MoreMenuProps
  | NotificationCenterProps
  | OpenLinkProps
  | SaveProps
  | ScaleAndConvertProps
  | SettingsProps
  | ShareProps
  | SignOutProps
  | SignUpProps
  | SortProps
  | SpeakerOnProps
  | SpeakerMutedProps
  | TextSizeProps
  | TodayProps
  | ThreeButtonsProps
  | TwoButtonsProps;

export const renderRightHeader = (props: HeaderRightProps) => {
  switch (props.type) {
    case "add": {
      return React.createElement(Add, props);
    }
    case "cancel": {
      return React.createElement(Cancel, props);
    }
    case "deleteAll": {
      return React.createElement(DeleteAll, props);
    }
    case "done": {
      return React.createElement(Done, props);
    }
    case "edit": {
      return React.createElement(Edit, props);
    }
    case "invite": {
      return React.createElement(Invite, props);
    }
    case "moreMenu": {
      return React.createElement(More, props);
    }
    case "notificationCenter": {
      return React.createElement(NotificationCenter, props);
    }
    case "openLink": {
      return React.createElement(OpenLink, props);
    }
    case "menu": {
      return React.createElement(Menu, props);
    }
    case "save": {
      return React.createElement(Save, props);
    }
    case "scaleAndConvert": {
      return React.createElement(ScaleAndConvert, props);
    }
    case "settings": {
      return React.createElement(Settings, props);
    }
    case "share": {
      return React.createElement(Share, props);
    }
    case "signOut": {
      return React.createElement(SignOut, props);
    }
    case "signUp": {
      return React.createElement(SignUp, props);
    }
    case "sort": {
      return React.createElement(Sort, props);
    }
    case "speakerMuted": {
      return React.createElement(SpeakerMuted, props);
    }
    case "speakerOn": {
      return React.createElement(SpeakerOn, props);
    }
    case "textSize": {
      return React.createElement(TextSize, props);
    }
    case "today": {
      return React.createElement(Today, props);
    }
    case "threeButtons": {
      return React.createElement(ThreeButtons, props);
    }
    case "twoButtons": {
      return React.createElement(TwoButtons, props);
    }
    case "findUsers": {
      return React.createElement(FindUsers, props);
    }
    default:
      bottomThrow(props, log);
  }
};

const Add = React.memo((props: AddProps) => {
  return (
    <RightHeaderButton {...props}>
      <IconPlus opacity="opaque" />
    </RightHeaderButton>
  );
});

const Cancel = React.memo((props: CancelProps) => {
  return <RightHeaderTextButton {...props} buttonText={strings.cancel} bold={props.bold} color={props.color} />;
});

const DeleteAll = React.memo((props: DeleteAllProps) => {
  return <RightHeaderTextButton {...props} buttonText={strings.deleteAll} />;
});

const Done = React.memo((props: DoneProps) => {
  return <RightHeaderTextButton {...props} buttonText={strings.done} bold={props.bold} color={props.color} />;
});

const Edit = React.memo((props: EditProps) => {
  return (
    <RightHeaderButton {...props}>
      <IconEditSquare opacity="opaque" />
    </RightHeaderButton>
  );
});

const Invite = React.memo((props: InviteProps) => {
  return (
    <View style={{ paddingRight: 8 }}>
      <ButtonRectangle size="small" type="submit" title={strings.invite} onPress={props.onPress} />
    </View>
  );
});

const Menu = React.memo((props: MenuProps) => {
  return (
    <RightHeaderButton {...props}>
      <IconMenu opacity="opaque" color={props.walkthrough ? "white" : props.tintColor} />
    </RightHeaderButton>
  );
});

const More = React.memo((props: MoreMenuProps) => {
  return (
    <RightHeaderButton {...props}>
      <IconMore size={28} opacity="opaque" />
    </RightHeaderButton>
  );
});

const NotificationCenter = React.memo((props: NotificationCenterProps) => {
  const badgeCount = useNotificationUnreadCount();

  return (
    <RightHeaderButton {...props}>
      {badgeCount > 0 && (
        <IconBellFilled badgeCount={badgeCount} color={props.walkthrough?.show ? "white" : props.tintColor} />
      )}
      {badgeCount < 1 && <IconBell opacity="opaque" />}
    </RightHeaderButton>
  );
});

const OpenLink = React.memo((props: OpenLinkProps) => (
  <RightHeaderButton {...props}>
    <IconOpenLink opacity="opaque" color={props.tintColor} />
  </RightHeaderButton>
));

const Save = React.memo((props: SaveProps) => {
  return (
    <>
      {!props.waiting && <RightHeaderTextButton {...props} buttonText={strings.save} bold />}
      {!!props.waiting && (
        <ContainerPadded right={1}>
          <Spinner />
        </ContainerPadded>
      )}
    </>
  );
});

const ScaleAndConvert = React.memo((props: ScaleAndConvertProps) => {
  const { scaleAndConvertProps, ...rightButtonProps } = props;

  return (
    <View
      style={[
        styles.rightHeaderButton,
        rightButtonProps.marginRight ? { marginRight: rightButtonProps.marginRight } : undefined,
      ]}
    >
      <ScalingAndConversionsButton
        {...scaleAndConvertProps}
        inactiveColor={rightButtonProps.tintColor}
        disabled={rightButtonProps.disabled}
        walkthrough={props.walkthrough}
      />
    </View>
  );
});

const Settings = React.memo((props: SettingsProps) => {
  return (
    <RightHeaderButton {...props}>
      <IconSettings opacity="opaque" />
    </RightHeaderButton>
  );
});

const SignOut = React.memo((props: SignOutProps) => {
  return <RightHeaderTextButton {...props} buttonText={strings.signOut} />;
});

const SignUp = React.memo((props: SignUpProps) => {
  return (
    <View style={{ paddingRight: 8 }}>
      <ButtonRectangle size="small" type="submit" shadow title={strings.signUp} onPress={props.onPress} />
    </View>
  );
});

const Sort = React.memo((props: SortProps) => {
  const getSortIcon = () => {
    switch (props.activeSort) {
      case "alpha_asc": {
        return <IconSortAlphaAsc />;
      }
      case "alpha_desc": {
        return <IconSortAlphaDesc />;
      }
      case "dateCreated_asc": {
        return <IconSortTimeAsc />;
      }
      case "dateCreated_desc": {
        return <IconSortTimeDesc />;
      }
      case "default": {
        return <IconSort opacity="opaque" />;
      }
      default:
        bottomThrow(props.activeSort);
    }
  };

  return <RightHeaderButton {...props}>{getSortIcon()}</RightHeaderButton>;
});

const Share = React.memo((props: ShareProps) => {
  return (
    <RightHeaderButton {...props}>
      <IconShare opacity="opaque" />
    </RightHeaderButton>
  );
});

const SpeakerOn = React.memo((props: SpeakerOnProps) => {
  return (
    <RightHeaderButton {...props}>
      <IconSpeakerOn opacity="opaque" color={globalStyleColors.colorTimerAction} />
    </RightHeaderButton>
  );
});

const SpeakerMuted = React.memo((props: SpeakerMutedProps) => {
  return (
    <RightHeaderButton {...props}>
      <IconSpeakerMuted opacity="opaque" color={props.tintColor} />
    </RightHeaderButton>
  );
});

const TextSize = React.memo((props: TextSizeProps) => {
  return (
    <RightHeaderButton {...props}>
      {/* Reducing the size of the icon just a bit to vertically match the hamburger menu icon */}
      <IconText opacity="opaque" color={props.tintColor} size={21} strokeWidth={1.5} />
    </RightHeaderButton>
  );
});

const Today = React.memo((props: TodayProps) => {
  return <RightHeaderTextButton {...props} buttonText={strings.today} bold />;
});

const FindUsers = React.memo((props: FindUsersProps) => {
  return (
    <RightHeaderButton {...props}>
      <IconSearchUsers opacity="opaque" />
    </RightHeaderButton>
  );
});

const TwoButtons = React.memo((props: TwoButtonsProps) => {
  const leftButton = renderRightHeader(props.left);
  const rightButton = renderRightHeader(props.right);

  const marginRightStyle = props.marginRight ? { marginRight: props.marginRight } : {};

  return (
    <View style={[{ flexDirection: "row", justifyContent: "space-between", alignItems: "center" }, marginRightStyle]}>
      {leftButton}
      {rightButton}
    </View>
  );
});

const ThreeButtons = React.memo((props: ThreeButtonsProps) => {
  const leftButton = renderRightHeader(props.left);
  const middleButton = renderRightHeader(props.middle);
  const rightButton = renderRightHeader(props.right);

  const marginRightStyle = props.marginRight ? { marginRight: props.marginRight } : {};

  return (
    <View style={[{ flexDirection: "row", justifyContent: "space-between", alignItems: "center" }, marginRightStyle]}>
      {leftButton}
      {middleButton}
      {rightButton}
    </View>
  );
});

interface RightHeaderTextButtonProps extends RightHeaderButtonProps {
  buttonText: string;
  color?: string;
  tintColor?: string;
  bold?: boolean;
}

const RightHeaderTextButton = React.memo((props: RightHeaderTextButtonProps) => {
  const { buttonText, color, tintColor, bold, ...buttonProps } = props;

  return (
    <RightHeaderButton {...buttonProps}>
      <TBody color={color ?? tintColor} fontWeight={bold ? "medium" : undefined}>
        {buttonText}
      </TBody>
    </RightHeaderButton>
  );
});

interface RightHeaderButtonProps {
  onPress: () => void;
  marginRight?: number;
  disabled?: boolean;
  walkthrough?: WalkthroughStepProps;
  noFeedback?: boolean;
  waiting?: boolean;
}

const RightHeaderButton = React.memo((props: PropsWithChildren<RightHeaderButtonProps>) => {
  const marginRightStyle = props.marginRight ? { marginRight: props.marginRight } : {};

  const button = (
    <Pressable
      style={[styles.rightHeaderButton, marginRightStyle]}
      singlePress
      onPress={props.onPress}
      disabled={props.disabled || props.waiting}
      noFeedback={props.noFeedback || props.waiting}
    >
      {!!props.waiting && <Spinner />}
      {!props.waiting && props.children}
    </Pressable>
  );

  return (
    <>
      {!!props.walkthrough && <WalkthroughStep {...props.walkthrough}>{button}</WalkthroughStep>}
      {!props.walkthrough && button}
    </>
  );
});

const styles = StyleSheet.create({
  rightHeaderButton: {
    paddingHorizontal: globalStyleConstants.unitSize,
    paddingRight: 8,
  },
});

import React, { PropsWithChildren } from "react";
import { ScrollView } from "react-native";
import { useScreenElementDimensions } from "../components/ScreenView";
import { globalStyleConstants } from "../components/GlobalStyles";
import { BottomActionBar, bottomActionBarConstants } from "../components/BottomActionBar";
import { smallScreenBreakpoint } from "../components/Responsive";
import { useKeyboardLayoutAnimation } from "../components/Keyboard";

interface Props {
  submitText: string;
  onPressSubmit: () => void;
  waiting?: boolean;
  submitDisabled?: boolean;
  maxWidth?: boolean;
  isModal?: boolean;
}

export const ScrollViewWithSubmit = React.memo((props: PropsWithChildren<Props>) => {
  const { headerHeight, modalHeaderHeight, bottomTabBarHeight } = useScreenElementDimensions();
  const keyboardHeight = useKeyboardLayoutAnimation();
  const maxWidth = props.maxWidth === false ? undefined : smallScreenBreakpoint;

  const screenHeaderHeight = props.isModal ? modalHeaderHeight : headerHeight;

  return (
    <>
      <ScrollView
        contentContainerStyle={{
          paddingTop: screenHeaderHeight + globalStyleConstants.unitSize,
          paddingBottom:
            Math.max(keyboardHeight, bottomTabBarHeight + bottomActionBarConstants.height) +
            2 * globalStyleConstants.unitSize,
          width: "100%",
          maxWidth,
          alignSelf: "center",
        }}
        showsVerticalScrollIndicator={false}
      >
        {props.children}
      </ScrollView>
      <BottomActionBar
        primaryAction={{
          actionText: props.submitText,
          onPressAction: props.onPressSubmit,
          waiting: props.waiting,
          disabled: props.submitDisabled,
        }}
      />
    </>
  );
});

import React, { useCallback, useMemo, useRef, forwardRef, useImperativeHandle } from "react";
import { StyleSheet, View, FlatList, ListRenderItem } from "react-native";
import { globalStyleColors, globalStyleConstants } from "./GlobalStyles";
import { Text } from "./Typography";
import { Pressable } from "./Pressable";
import { IconPlus } from "./Icons";
import {
  strings,
  isSameDay,
  addMonths,
  generateMonthCalendarData,
  isPastDate,
  normalizeDateToMidnight,
} from "./CalendarUtils";
import { RecipeCard, recipeCardConstants } from "./recipes/RecipeCards";
import { Spacer } from "./Spacer";
import Animated, { useAnimatedScrollHandler, useSharedValue } from "react-native-reanimated";
import { EpochMs, getCalendarDayFromDate } from "@eatbetter/common-shared";
import { Separator } from "./Separator";
import { CalendarNoteListItem } from "./CalendarNoteListItem";
import { CalendarItem } from "../screens/PlanningHomeScreen";

const constants = {
  infiniteScroll: {
    offset: 52, // Start in the middle (1 year back)
    totalCount: 104, // 2 years worth of days
  },
  layout: {
    headerHeight: globalStyleConstants.unitSize * 5,
    itemSpacing: globalStyleConstants.minPadding,
    recipeCardHeight: recipeCardConstants.verticalScrollCardHeight,
    noteHeight: globalStyleConstants.unitSize * 4,
  },
};

interface HeaderItem {
  type: "header";
  dateKey: string;
  date: Date;
}

interface ContentListItem {
  type: "content";
  dateKey: string;
  date: Date;
  items: CalendarItem[];
}

type ListItem = HeaderItem | ContentListItem;

type RenderItem = ListRenderItem<ListItem>;
type KeyExtractor = (item: ListItem, index: number) => string;

interface CalendarListProps {
  onPressAdd: (date: Date) => void;
  items: Record<string, CalendarItem[]>;
  onScrollDateChanged?: (date: EpochMs) => void;
}

export interface CalendarListImperativeHandle {
  scrollToDate: (date: Date) => void;
}

interface LayoutInfo {
  offset: number;
  isHeader: boolean;
  date: EpochMs;
}

export const CalendarList = React.memo(
  forwardRef<CalendarListImperativeHandle, CalendarListProps>((props, ref) => {
    const { onPressAdd: onAddMeal, items, onScrollDateChanged } = props;
    const flatListRef = useRef<FlatList>(null);
    const scrollY = useSharedValue(0);

    const renderHeader = useCallback(
      (date: Date) => {
        const isToday = isSameDay(date, new Date());
        const isPastDateValue = isPastDate(date);

        return (
          <View style={[styles.dayHeader, isToday && styles.todayHeader]}>
            <View style={{ paddingHorizontal: globalStyleConstants.minPadding }}>
              <Separator orientation="row" />
            </View>
            <View style={styles.dayHeaderContent}>
              <View>
                <Text
                  fontSize="secondary"
                  fontWeight={isToday ? "medium" : "normal"}
                  color={isToday ? globalStyleColors.colorAccentCool : undefined}
                  opacity={isPastDateValue ? "light" : "opaque"}
                >
                  {date.getDate()} {strings.daysOfWeek[date.getDay()]}
                </Text>
              </View>
              {isToday && (
                <View style={styles.todayContainer}>
                  <Text fontSize="secondary" fontWeight="medium" color={globalStyleColors.colorAccentCool}>
                    TODAY
                  </Text>
                </View>
              )}
              <Pressable onPress={() => onAddMeal(date)}>
                <IconPlus
                  size={22}
                  opacity={isPastDateValue ? "light" : "opaque"}
                  color={isToday ? globalStyleColors.colorAccentCool : globalStyleColors.black}
                />
              </Pressable>
            </View>
          </View>
        );
      },
      [onAddMeal]
    );

    useImperativeHandle(ref, () => ({
      scrollToDate: (date: Date) => {
        // Make sure to normalize the date
        const normalizedDate = normalizeDateToMidnight(date);
        const dateKey = getCalendarDayFromDate(normalizedDate);
        const index = listItems.findIndex(item => item.dateKey === dateKey);
        if (index !== -1) {
          flatListRef.current?.scrollToIndex({
            index,
            animated: false,
            viewPosition: 0,
          });
        }
      },
    }));

    const {
      items: listItems,
      headerIndices,
      todayIndex,
    } = useMemo(() => {
      const today = new Date();
      const startDate = addMonths(today, -12); // Start 1 year back
      const result: ListItem[] = [];
      const seenDates = new Set<string>();
      const headerIndices: number[] = [];
      let todayIndex = 0;

      // Generate days for each month in our range
      for (let monthOffset = 0; monthOffset < 24; monthOffset++) {
        const monthDate = addMonths(startDate, monthOffset);
        const monthDays = generateMonthCalendarData(monthDate);

        monthDays.forEach(dayInfo => {
          const dateKey = getCalendarDayFromDate(dayInfo.date);
          if (!seenDates.has(dateKey)) {
            if (isSameDay(dayInfo.date, today)) {
              todayIndex = result.length;
            }
            seenDates.add(dateKey);

            // Add header item
            headerIndices.push(result.length);
            result.push({
              type: "header",
              dateKey,
              date: dayInfo.date,
            });

            // Add content item if there are any items
            const dayItems = items[dateKey] || [];
            if (dayItems.length > 0) {
              result.push({
                type: "content",
                dateKey,
                date: dayInfo.date,
                items: dayItems,
              });
            }
          }
        });
      }

      return { items: result, headerIndices, todayIndex };
    }, [items]);

    // Modify the layoutInfo creation to use normalized dates
    const layoutInfo = useMemo(() => {
      const info: LayoutInfo[] = [];
      let currentOffset = 0;

      for (let i = 0; i < listItems.length; i++) {
        const item = listItems[i];
        if (!item) continue;

        // Make sure we normalize all dates for consistency
        const normalizedDate = normalizeDateToMidnight(item.date);

        info.push({
          offset: currentOffset,
          isHeader: item.type === "header",
          date: normalizedDate.getTime() as EpochMs,
        });

        if (item.type === "header") {
          currentOffset += constants.layout.headerHeight;
        } else {
          const itemCount = item.items.length;
          let totalHeight = 0;

          for (let j = 0; j < itemCount; j++) {
            const currentItem = item.items[j];
            if (!currentItem) continue;

            if (currentItem.type === "recipe") {
              totalHeight += constants.layout.recipeCardHeight;
            } else {
              totalHeight += constants.layout.noteHeight;
            }
            if (j < itemCount - 1) {
              totalHeight += constants.layout.itemSpacing;
            }
          }

          currentOffset += totalHeight + (itemCount > 0 ? constants.layout.itemSpacing * 2 : 0);
        }
      }

      return info;
    }, [listItems]);

    const renderContent = useCallback(
      (items: CalendarItem[]) => (
        <View
          style={{ paddingHorizontal: globalStyleConstants.minPadding, paddingVertical: constants.layout.itemSpacing }}
        >
          {items.map((item, index) => {
            if (!item) return null;
            return (
              <React.Fragment key={item.id}>
                {index > 0 && <Spacer vertical={constants.layout.itemSpacing} unit="pixels" />}
                {item.type === "recipe" ? (
                  <RecipeCard
                    {...item}
                    index={index}
                    scrollDirection="vertical"
                    sessionId={undefined}
                    onPressMoreMenu={() => {}}
                  />
                ) : (
                  <CalendarNoteListItem note={item} mealType={item.mealType} />
                )}
              </React.Fragment>
            );
          })}
        </View>
      ),
      []
    );

    const renderItem: RenderItem = useCallback(
      ({ item }: { item: ListItem }) => {
        if (item.type === "header") {
          return renderHeader(item.date);
        } else {
          return renderContent(item.items);
        }
      },
      [renderHeader, renderContent]
    );

    const keyExtractor: KeyExtractor = useCallback(item => {
      if (item.type === "header") {
        return `header-${item.dateKey}`;
      }
      return `content-${item.dateKey}`;
    }, []);

    const getItemLayout = useCallback(
      (_: unknown, index: number) => {
        const item = listItems[index];
        if (!item) return { length: 0, offset: 0, index };

        let height = 0;
        let offset = 0;

        // Calculate height for current item
        if (item.type === "header") {
          height = constants.layout.headerHeight;
        } else {
          const itemCount = item.items.length;
          let totalHeight = 0;

          for (let j = 0; j < itemCount; j++) {
            const currentItem = item.items[j];
            if (!currentItem) continue;

            if (currentItem.type === "recipe") {
              totalHeight += constants.layout.recipeCardHeight;
            } else {
              totalHeight += constants.layout.noteHeight;
            }
            if (j < itemCount - 1) {
              totalHeight += constants.layout.itemSpacing;
            }
          }

          height = totalHeight + (itemCount > 0 ? constants.layout.itemSpacing * 2 : 0);
        }

        // Calculate offset by summing heights of previous items
        for (let i = 0; i < index; i++) {
          const prevItem = listItems[i];
          if (!prevItem) continue;

          if (prevItem.type === "header") {
            offset += constants.layout.headerHeight;
          } else {
            const itemCount = prevItem.items.length;
            let totalHeight = 0;

            for (let j = 0; j < itemCount; j++) {
              const currentItem = prevItem.items[j];
              if (!currentItem) continue;

              if (currentItem.type === "recipe") {
                totalHeight += constants.layout.recipeCardHeight;
              } else {
                totalHeight += constants.layout.noteHeight;
              }
              if (j < itemCount - 1) {
                totalHeight += constants.layout.itemSpacing;
              }
            }

            offset += totalHeight + (itemCount > 0 ? constants.layout.itemSpacing * 2 : 0);
          }
        }

        return {
          length: height,
          offset,
          index,
        };
      },
      [listItems]
    );

    const scrollHandler = useAnimatedScrollHandler({
      onScroll: event => {
        scrollY.value = event.contentOffset.y;

        // Find the current header at the top of the viewport
        const currentOffset = event.contentOffset.y;
        let currentHeaderIndex = 0;

        // Binary search to find the first item that starts after our current offset
        let left = 0;
        let right = layoutInfo.length - 1;

        while (left <= right) {
          const mid = Math.floor((left + right) / 2);
          const midLayout = layoutInfo[mid];
          if (!midLayout) break;

          if (midLayout.offset <= currentOffset) {
            left = mid + 1;
          } else {
            right = mid - 1;
          }
        }

        // The header should be the last header before the found index
        for (let i = right; i >= 0; i--) {
          const layout = layoutInfo[i];
          if (!layout) break;

          if (layout.isHeader) {
            currentHeaderIndex = i;
            break;
          }
        }

        const currentLayout = layoutInfo[currentHeaderIndex];
        if (currentLayout && currentLayout.isHeader && onScrollDateChanged) {
          onScrollDateChanged(currentLayout.date);
        }
      },
    });

    return (
      <Animated.FlatList
        ref={flatListRef}
        data={listItems}
        renderItem={renderItem}
        keyExtractor={keyExtractor}
        style={styles.list}
        initialScrollIndex={todayIndex}
        getItemLayout={getItemLayout}
        windowSize={21}
        maxToRenderPerBatch={10}
        removeClippedSubviews
        stickyHeaderIndices={headerIndices}
        onScroll={scrollHandler}
        scrollEventThrottle={16}
        showsVerticalScrollIndicator={false}
      />
    );
  })
);

const styles = StyleSheet.create({
  list: {
    flex: 1,
  },
  dayHeader: {
    height: constants.layout.headerHeight,
    backgroundColor: globalStyleColors.rgba("colorGreyLight"),
    elevation: 2,
    zIndex: 1,
  },
  todayHeader: {},
  dayHeaderContent: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingVertical: globalStyleConstants.unitSize,
    paddingHorizontal: globalStyleConstants.defaultPadding,
  },
  todayContainer: {
    position: "absolute",
    left: 0,
    right: 0,
    alignItems: "center",
    justifyContent: "center",
  },
});

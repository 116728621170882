import React, { RefObject, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { useScreen, withScreenContainer } from "../navigation/ScreenContainer";
import { CookingSessionId } from "@eatbetter/cooking-shared";
import {
  useActiveCookingSessionId,
  useActiveSessionRecipeTitle,
  useCookingSessionAudioEnabled,
  useCookingSessionHasScalingInfo,
  useCookingSessionIds,
  useCookingSessionRecipeId,
  useCookingSessionRecipeScale,
  useCookingSessionRecipeTitle,
  useCookingSessionRecipeUnitConversion,
  useCookingSessionRecipeYield,
  useFocusInstructionRequest,
  useHaveUnreadNotes,
} from "../lib/cooking/CookingSessionsSelectors";
import { cookingSessionScaleUpdatedClient, focusInstructionCompleted } from "../lib/cooking/CookingSessionsSlice";
import { ScreenView } from "../components/ScreenView";
import { HeaderProps, useHeaderOffset } from "../components/ScreenHeaders";
import { useDispatch } from "../lib/redux/Redux";
import { InKitchenRecipe, InKitchenRecipeHandle } from "../components/recipes/InKitchenRecipe";
import { Freeze } from "react-freeze";
import { Haptics } from "../components/Haptics";
import { log } from "../Log";
import {
  changeActiveCookingSession,
  cookingSessionScreenUnmountedOrNavedAway,
  setAudioCookingSessionDisabled,
  setAudioCookingSessionEnabled,
} from "../lib/cooking/CookingSessionsThunks";
import { defaultTimeProvider, EpochMs, secondsBetween } from "@eatbetter/common-shared";
import { useCheckpointCompleted, useCookingSessionFontScale, useSystemSetting } from "../lib/system/SystemSelectors";
import { usePaywallDetection } from "../components/PaywallDetector";
import { WebViewNavigationStateChangeHandler } from "../components/WebView";
import { navTree } from "../navigation/NavTree";
import { displayUnexpectedErrorAndLog } from "../lib/Errors";
import { PixelRatio, View } from "react-native";
import { TBody, THeading2, TSecondary } from "../components/Typography";
import { Spacer } from "../components/Spacer";
import { globalStyleColors, globalStyleConstants } from "../components/GlobalStyles";
import { getRecipeInKitchenOptionsHeight, RecipeInKitchenOptions } from "../components/recipes/RecipeInKitchenOptions";
import { analyticsEvent } from "../lib/analytics/AnalyticsThunks";
import {
  reportCookingSessionMenuOpened,
  reportCookingSessionTextSizeChanged,
  reportCookingSessionTextSizeOpened,
  reportLibraryRecipeContextMenuItemTapped,
  reportRecipeInKitchenMenuOpened,
  reportRecipeScaleValueChanged,
} from "../lib/analytics/AnalyticsEvents";
import { Slider } from "../components/Slider";
import { checkpointsCompleted, cookingSessionFontScaleUpdated, userSettingsUpdated } from "../lib/system/SystemSlice";
import { shareLibraryRecipeLink } from "../lib/share/ShareThunks";
import { HeaderRightProps } from "../components/ScreenHeaderRightButtons";
import {
  ScalingAndConversionsButton,
  ScalingAndConversionsButtonProps,
  scalingSymbol,
  useScalingAndConversionsSheet,
} from "../components/recipes/ScalingAndConversions";
import { UnitConversion } from "@eatbetter/items-shared";
import { getScaleValueDisplay } from "../lib/recipes/conversions/QuantityUnitDisplay";
import { maxContentWidth, useResponsiveDimensions } from "../components/Responsive";
import { useWalkthrough, WalkthroughStepProps } from "../components/Walkthrough";
import { FeatureDemoSnippet } from "../components/FeatureDemoSnippet";
import { useRecipeSourceType } from "../lib/recipes/RecipesSelectors";

const strings = {
  textSizeSheet: {
    title: "Text Size",
  },
  walkthrough: {
    backButton: "Tap here to get back to\nyour recipe library",
    scale: "Tap here to scale your recipe",
    menu: "Tap here to adjust your text size, and more",
    endCooking: "When you're done cooking,\ntap here to end this\ncooking session",
  },
};

const walkthroughSteps = ["backButton", "scaling", "menu", "endCooking"] as const;

export const RecipeInKitchenScreen = withScreenContainer("RecipeInKitchenScreen", () => {
  const cookingSessionIds = useCookingSessionIds();
  const cookingSessionId = useActiveCookingSessionId();
  const recipeId = useCookingSessionRecipeId(cookingSessionId);
  const recipeSourceType = useRecipeSourceType(recipeId);

  const focusInstructionRequest = useFocusInstructionRequest();
  const audioEnabled = useCookingSessionAudioEnabled();
  const audioStartTime = useRef<number | undefined>();
  const inKitchenRef = useRef<InKitchenRecipeHandle>(null);
  const screen = useScreen();
  const dispatch = useDispatch();
  const externalShareEnabled = useSystemSetting("externalRecipeShare");
  const haveUnreadNotes = useHaveUnreadNotes(cookingSessionId);

  const { detectPaywallSignin } = usePaywallDetection(recipeId);

  const walkthroughCompleted = useCheckpointCompleted("cookingSessionWalkthroughCompleted");

  const onEndWalkthrough = useCallback(() => {
    dispatch(checkpointsCompleted(["cookingSessionWalkthroughCompleted"]));
  }, [dispatch]);

  const [firstRunCompleted, setFirstRunCompleted] = useState(walkthroughCompleted);

  useEffect(() => {
    if (!firstRunCompleted && screen.nav.focused) {
      setTimeout(
        () => screen.nav.modal(navTree.get.screens.inKitchenFirstRun, { onComplete: () => setFirstRunCompleted(true) }),
        300
      );
    }
  }, [firstRunCompleted, screen.nav.focused]);

  const walkthrough = useWalkthrough(
    "In Kitchen Screen",
    walkthroughSteps,
    firstRunCompleted && !walkthroughCompleted && screen.nav.focused,
    onEndWalkthrough
  );

  useEffect(() => {
    return () => dispatch(cookingSessionScreenUnmountedOrNavedAway());
  }, []);

  useEffect(() => {
    if (cookingSessionIds.length === 0 && screen.nav.focused) {
      screen.nav.goBack();
    }
  }, [cookingSessionIds.length, screen.nav.goBack, screen.nav.focused]);

  useEffect(() => {
    if (cookingSessionIds[0] && !cookingSessionId) {
      dispatch(changeActiveCookingSession({ cookingSessionId: cookingSessionIds[0]! }));
    }
  }, [cookingSessionIds, cookingSessionId]);

  useLayoutEffect(() => {
    if (focusInstructionRequest) {
      if (focusInstructionRequest.cookingSessionId !== cookingSessionId) {
        // first, focus the cooking session.
        // That will refire this use effect and we can then use the handle (only attached to the active session) to scroll
        if (cookingSessionIds.includes(focusInstructionRequest.cookingSessionId)) {
          dispatch(changeActiveCookingSession({ cookingSessionId: focusInstructionRequest.cookingSessionId }));
        } else {
          log.error(
            `focusInstructionRequest.cookingSessionId not present in cookingSessionIds: ${focusInstructionRequest.cookingSessionId}`
          );
          dispatch(focusInstructionCompleted());
        }
      } else {
        if (
          inKitchenRef.current &&
          inKitchenRef.current.getCookingSessionId() === focusInstructionRequest.cookingSessionId
        ) {
          // I'm not 100% sure why, but things were flakier without the setTimeout - the call would sometimes have no effect
          // and it resulted in an error relating to a reactor that I didnt' track down.
          setTimeout(
            () =>
              inKitchenRef.current!.focusInstruction(
                focusInstructionRequest.sectionId,
                focusInstructionRequest.instructionId
              ),
            0
          );
        } else {
          // NOTE: We are seeing this occassionally in logs, so I suspect there is a bug in the logic above
          log.error(
            `inKitchenRef.current either didn't exist or was an unexpected id: ${inKitchenRef.current?.getCookingSessionId()}`
          );
        }

        // in either case, we've done what we can
        dispatch(focusInstructionCompleted());
      }
    }
  }, [focusInstructionRequest, cookingSessionId, cookingSessionIds]);

  const switchCookingSession = useCallback(() => {
    if (cookingSessionId) {
      const index = cookingSessionIds.indexOf(cookingSessionId);
      if (index >= 0) {
        const newIndex = (index + 1) % cookingSessionIds.length;
        const newId = cookingSessionIds[newIndex];
        if (newId) {
          dispatch(changeActiveCookingSession({ cookingSessionId: newId }));
          Haptics.feedback("itemStatusChanged");
        }
      }
    }
  }, [cookingSessionIds, cookingSessionId, dispatch]);

  const onTapAudio = useCallback(() => {
    if (!audioEnabled) {
      dispatch(setAudioCookingSessionEnabled(inKitchenRef.current?.getCookingSessionId()))
        .then(d => {
          if (d.selectedInstruction && inKitchenRef.current) {
            inKitchenRef.current.focusInstruction(d.selectedInstruction.sectionId, d.selectedInstruction.instructionId);
          }
          audioStartTime.current = defaultTimeProvider();
        })
        .catch(err => {
          log.errorCaught("Unexpected error calling setAudioCookingsessionEnabled", err);
        });
    } else {
      const seconds = audioStartTime.current
        ? Math.floor(secondsBetween(audioStartTime.current as EpochMs, defaultTimeProvider()))
        : undefined;
      audioStartTime.current = undefined;
      dispatch(setAudioCookingSessionDisabled(seconds)).catch(err => {
        log.errorCaught("Unexpected error calling setAudioCookingsessionDisabled", err);
      });
    }
  }, [audioEnabled, dispatch]);

  const onPressShare = useCallback(async () => {
    if (!recipeId) {
      log.error("onPressShareButton: have cooking session ID but no recipe ID. This shouldn't be possible", {
        cookingSessionId,
        recipeId,
      });
      return;
    }

    if (!externalShareEnabled) {
      screen.nav.modal(navTree.get.screens.shareRecipe, { recipeId });
      return;
    }

    // External (web link) share
    try {
      await dispatch(shareLibraryRecipeLink({ recipeId, nav: screen.nav }));
    } catch (err) {
      displayUnexpectedErrorAndLog("Error dispatching shareRecipeLink in RecipeDetail", err, {
        userRecipeId: recipeId,
      });
    }
  }, [screen.nav, externalShareEnabled, recipeId, dispatch]);

  const onPressTextSize = useCallback(() => {
    dispatch(analyticsEvent(reportCookingSessionTextSizeOpened()));
    screen.nav.modal(navTree.get.screens.bottomSheet, {
      content: <TextSizeSheet />,
      height: 160,
      backgroundColor: "white",
    });
  }, [dispatch, screen.nav.modal]);

  const onPressReportIssue = useCallback(() => {
    if (!recipeId) {
      displayUnexpectedErrorAndLog("onPressReportIssue(): recipeId is falsy", undefined, {
        recipeId,
        cookingSessionId,
      });
      return;
    }
    dispatch(analyticsEvent(reportLibraryRecipeContextMenuItemTapped({ action: "reportIssue" })));
    setTimeout(() => screen.nav.goTo("push", navTree.get.screens.reportRecipeIssue, { recipeId, popCount: 1 }), 300);
  }, [screen.nav.goTo, recipeId]);

  const haveScalingData = useCookingSessionHasScalingInfo(cookingSessionId);
  const scalingFeatureEnabled = !!useSystemSetting("scalingAndConversion");
  const showScalingOption = haveScalingData && scalingFeatureEnabled;
  const recipeScale = useCookingSessionRecipeScale(cookingSessionId);
  const recipeUnits = useCookingSessionRecipeUnitConversion(cookingSessionId);
  const recipeTitle = useCookingSessionRecipeTitle(cookingSessionId);
  const recipeYield = useCookingSessionRecipeYield(cookingSessionId);

  const scalingProps: ScalingAndConversionsButtonProps = useMemo(() => {
    return {
      scale: recipeScale,
      unit: recipeUnits,
      recipeTitle,
      recipeYield,
      onChangeUnit: (value: UnitConversion) => {
        dispatch(userSettingsUpdated({ unitConversion: value }));
      },
      onChangeScale: (scale: number, persist: boolean) => {
        if (cookingSessionId) {
          dispatch(analyticsEvent(reportRecipeScaleValueChanged({ context: "cookingSession", scale })));
          dispatch(cookingSessionScaleUpdatedClient({ id: cookingSessionId, scale, persist }));
        }
      },
    };
  }, [recipeScale, recipeUnits, recipeTitle, recipeYield, cookingSessionId, dispatch]);

  const { navToScalingAndConversionsSheet } = useScalingAndConversionsSheet(scalingProps);

  const onPressMenu = useCallback(() => {
    if (!cookingSessionId) {
      displayUnexpectedErrorAndLog("onPressMenu: cookingSessionId is falsy", undefined, {
        cookingSessionId,
        recipeId,
      });
      return;
    }

    reportCookingSessionMenuOpened();
    screen.nav.modal(navTree.get.screens.bottomSheet, {
      content: (
        <RecipeInKitchenOptions
          cookingSessionId={cookingSessionId}
          onTapAudio={onTapAudio}
          onTapShare={onPressShare}
          onTapTextSize={onPressTextSize}
          onTapReportIssue={onPressReportIssue}
          scaleAndConvert={{
            icon: () => <ScalingAndConversionsButton {...scalingProps} disabled />,
            onPress: navToScalingAndConversionsSheet,
          }}
        />
      ),
      height: getRecipeInKitchenOptionsHeight(showScalingOption, recipeSourceType),
    });
    dispatch(analyticsEvent(reportRecipeInKitchenMenuOpened()));
  }, [
    dispatch,
    screen.nav.modal,
    cookingSessionId,
    onTapAudio,
    onPressShare,
    onPressTextSize,
    scalingProps,
    navToScalingAndConversionsSheet,
    showScalingOption,
    recipeSourceType,
  ]);

  if (!cookingSessionId) {
    // if the cooking session ID is undefined, one of two things will happen:
    // it will be set in the useEffect above or
    // if there are no cooking sessions, we will nav back
    return null;
  }

  if (!recipeId) {
    throw new Error("Have cooking session ID but no recipe ID. This shouldn't be possible");
  }

  return React.createElement<Props>(RecipeInKitchenScreenComponent, {
    activeCookingSessionId: cookingSessionId,
    audioEnabled,
    onTapAudio,
    onPressMenu,
    onPressTextSize,
    cookingSessionIds,
    switchCookingSession: cookingSessionIds.length > 1 ? switchCookingSession : undefined,
    selectedInKitchenRef: inKitchenRef,
    onWebViewNavigationStateChanged: detectPaywallSignin,
    haveUnreadNotes,
    scalingProps,
    showScalingOption: showScalingOption,
    walkthroughStep: walkthrough.currentStep,
    onWalkthroughNext: walkthrough.goToNextStep,
  });
});

interface Props {
  cookingSessionIds: CookingSessionId[];
  activeCookingSessionId: CookingSessionId;
  audioEnabled: boolean;
  onTapAudio: () => void;
  onPressMenu: () => void;
  onPressTextSize: () => void;
  switchCookingSession?: () => void;
  selectedInKitchenRef: RefObject<InKitchenRecipeHandle>;
  onWebViewNavigationStateChanged?: WebViewNavigationStateChangeHandler;
  haveUnreadNotes?: boolean;
  scalingProps: ScalingAndConversionsButtonProps;
  showScalingOption: boolean;
  walkthroughStep: (typeof walkthroughSteps)[number] | undefined;
  onWalkthroughNext: () => void;
}

const RecipeInKitchenScreenComponent = React.memo((props: Props) => {
  const recipeTitle = useActiveSessionRecipeTitle();
  const dimensions = useResponsiveDimensions();

  // -------- RENDER STARTS HERE --------

  const screenHeaderAnimationRef = useHeaderOffset();
  const header = useMemo<HeaderProps>(() => {
    const menu: HeaderRightProps = {
      type: "menu",
      onPress: props.onPressMenu,
      tintColor: props.walkthroughStep === "menu" ? "white" : undefined,
      walkthrough:
        props.walkthroughStep === "menu"
          ? {
              show: true,
              message: (
                <>
                  <TBody align="center" scale={dimensions.isSmallScreen ? 1 : 1.4} enableFontScaling="upOnly">
                    {strings.walkthrough.menu}
                  </TBody>
                  <Spacer vertical={1} />
                  <View style={{ maxWidth: 300 }}>
                    <FeatureDemoSnippet type="textSize" />
                  </View>
                </>
              ),
              onPressChildComponent: () => {},
              wobble: false,
              buttonText: "next",
              onPressButton: props.onWalkthroughNext,
            }
          : undefined,
    };

    const audio: HeaderRightProps = {
      type: "speakerOn",
      onPress: props.onTapAudio,
    };

    const scaleAndConvert: HeaderRightProps = {
      type: "scaleAndConvert",
      scaleAndConvertProps: props.scalingProps,
      walkthrough:
        props.walkthroughStep === "scaling"
          ? {
              show: true,
              message: (
                <>
                  <TBody
                    align="center"
                    scale={dimensions.isSmallScreen ? 1.1 : 1.4}
                    enableFontScaling="upOnly"
                    adjustsFontSizeToFit
                    numberOfLines={1}
                  >
                    {strings.walkthrough.scale}
                  </TBody>
                  <Spacer vertical={1} />
                  <View style={{ maxWidth: 350 }}>
                    <FeatureDemoSnippet type="scaling" />
                  </View>
                  <Spacer vertical={1} />
                </>
              ),
              onPressChildComponent: () => {},
              wobble: false,
              buttonText: "next",
              onPressButton: props.onWalkthroughNext,
            }
          : undefined,
    };

    const lineHeight = 20;

    const title = () => (
      <TBody
        adjustsFontSizeToFit
        numberOfLines={2}
        align="center"
        enableFontScaling="upOnly"
        scale={dimensions.defaultFontScale}
      >
        {props.scalingProps.scale !== 1 && (
          <>
            <TBody color={globalStyleColors.rgba("colorAccentCool", "medium")} lineHeight={lineHeight}>
              {"("}
            </TBody>
            <TBody
              color={globalStyleColors.colorAccentCool}
              highlightColor={globalStyleColors.rgba("colorAccentCool", "xxlight")}
              fontWeight="medium"
              lineHeight={lineHeight}
            >
              {`${scalingSymbol}${getScaleValueDisplay(props.scalingProps.scale)}`}
            </TBody>
            <TBody color={globalStyleColors.rgba("colorAccentCool", "medium")} lineHeight={lineHeight}>
              {")"}
            </TBody>
            <TBody lineHeight={lineHeight}> </TBody>
          </>
        )}
        <TBody lineHeight={lineHeight}>{recipeTitle ?? ""}</TBody>
      </TBody>
    );

    return {
      type: "custom",
      title,
      backgroundColor: "white",
      animationConfig: {
        animationProgress: screenHeaderAnimationRef,
      },
      backButtonWalkthrough:
        props.walkthroughStep === "backButton"
          ? {
              show: true,
              message: strings.walkthrough.backButton,
              onPressChildComponent: () => {},
              wobble: false,
              buttonText: "next",
              onPressButton: props.onWalkthroughNext,
            }
          : undefined,
      right: props.showScalingOption
        ? props.audioEnabled
          ? {
              type: "threeButtons",
              left: audio,
              middle: scaleAndConvert,
              right: menu,
            }
          : {
              type: "twoButtons",
              left: scaleAndConvert,
              right: menu,
            }
        : props.audioEnabled
        ? {
            type: "twoButtons",
            left: audio,
            right: menu,
          }
        : menu,
    };
  }, [
    recipeTitle,
    screenHeaderAnimationRef,
    props.audioEnabled,
    props.onTapAudio,
    props.onPressMenu,
    props.showScalingOption,
    props.scalingProps,
    props.walkthroughStep,
    props.onWalkthroughNext,
    dimensions.defaultFontScale,
  ]);

  const endCookingWalkthrough = useMemo<WalkthroughStepProps | undefined>(() => {
    if (props.walkthroughStep !== "endCooking") {
      return undefined;
    }

    return {
      show: true,
      message: strings.walkthrough.endCooking,
      buttonText: "gotIt",
      onPressButton: props.onWalkthroughNext,
      onPressChildComponent: () => {},
      wobble: false,
    };
  }, [props.walkthroughStep, props.onWalkthroughNext]);

  return (
    <ScreenView
      header={header}
      keepAwake
      scrollView={false}
      paddingHorizontal={false}
      paddingVertical={false}
      backgroundColor="white"
    >
      {props.cookingSessionIds.map(id => {
        // We want to keep the selected tab and scroll states, etc. so we can't let the sessions unmount
        // even if they aren't the one being currently viewed.
        // Freeze to the rescue
        return (
          <Freeze freeze={id !== props.activeCookingSessionId} key={id}>
            <InKitchenRecipe
              key={id}
              ref={id === props.activeCookingSessionId ? props.selectedInKitchenRef : undefined}
              cookingSessionId={id}
              screenHeaderAnimationRef={screenHeaderAnimationRef}
              switchCookingSession={props.switchCookingSession}
              onWebViewNavStateChanged={props.onWebViewNavigationStateChanged}
              haveUnreadNotes={props.haveUnreadNotes}
              endCookingWalkthrough={endCookingWalkthrough}
            />
          </Freeze>
        );
      })}
    </ScreenView>
  );
});

const TextSizeSheet = React.memo(() => {
  const dispatch = useDispatch();
  const cookingSessionFontScale = useCookingSessionFontScale();
  const systemFontScale = PixelRatio.getFontScale();
  const initialValue = cookingSessionFontScale ?? systemFontScale;

  const onSliderValueChange = useCallback(
    (value: number) => {
      dispatch(analyticsEvent(reportCookingSessionTextSizeChanged({ fontScale: value })));
      dispatch(cookingSessionFontScaleUpdated(value));
    },
    [dispatch]
  );

  return (
    <View style={{ padding: globalStyleConstants.defaultPadding }}>
      <View>
        <TBody align="center">{strings.textSizeSheet.title}</TBody>
      </View>
      <Spacer vertical={1.5} />
      <View style={{ flexDirection: "row", alignItems: "center", alignSelf: "center", maxWidth: maxContentWidth }}>
        <TSecondary>{"A"}</TSecondary>
        <Spacer horizontal={1.5} />
        <Slider
          stepCount={6}
          minValue={1}
          maxValue={2}
          initialValue={initialValue}
          onValueChange={onSliderValueChange}
        />
        <Spacer horizontal={1.5} />
        <THeading2>{"A"}</THeading2>
      </View>
    </View>
  );
});

import { useEffect, useRef } from "react";
import { useRecipeNotesEdit } from "../../lib/recipes/UseRecipeNotesEdit";
import { UserRecipeId } from "@eatbetter/recipes-shared";
import { StyleSheet, View } from "react-native";
import { TextInput, TextInputHandle } from "../TextInput";
import { TTertiary } from "../Typography";
import { Spinner } from "../Spinner";
import { globalStyleColors, globalStyleConstants } from "../GlobalStyles";
import { SectionHeading } from "../SectionHeading";
import { Spacer } from "../Spacer";
import { IconCircleCheck, IconCircleEx } from "../Icons";
import { RecipeEditFieldLocation } from "../../navigation/NavTree";
import { RecipeCookingHistory } from "./RecipeCookingHistory";

const strings = {
  notes: "Notes",
  error: "We're having trouble connecting right now.",
  saved: "Saved",
  placeholder: "Jot down private notes here. Only you can see this.",
};

export const RecipeNotesEdit = (props: {
  recipeId: UserRecipeId;
  location: RecipeEditFieldLocation;
  autoFocusMode: "none" | "instant" | "delayed";
}) => {
  const { text, setText, status } = useRecipeNotesEdit(props.recipeId, props.location);
  const notesInputRef = useRef<TextInputHandle>(null);

  useEffect(() => {
    // Without this, the keyboard interferes with the stack navigator push animation
    if (props.autoFocusMode === "delayed") {
      setTimeout(() => notesInputRef.current?.focus(), 600);
    }
  }, []);

  return (
    <View>
      <View style={styles.headingContainer}>
        <View>
          <SectionHeading text={strings.notes} noPadding />
        </View>
        <Spacer horizontal={1} />
        {status === "pending" && <Spinner />}
        {status === "error" && (
          <View style={{ flexShrink: 1, flexDirection: "row", alignItems: "flex-start" }}>
            <IconCircleEx size={18} />
            <Spacer horizontal={0.5} />
            <TTertiary errorText>{strings.error}</TTertiary>
          </View>
        )}
        {status === "saved" && (
          <View style={{ flexShrink: 1, flexDirection: "row", alignItems: "center" }}>
            <IconCircleCheck size={18} strokeWidth={2} color={globalStyleColors.colorAccentCool} opacity={"opaque"} />
            <Spacer horizontal={0.5} />
            <TTertiary actionText>{strings.saved}</TTertiary>
          </View>
        )}
      </View>
      <View style={styles.textInputWrapper}>
        <TextInput
          ref={notesInputRef}
          value={text}
          placeholderText={strings.placeholder}
          onChangeText={setText}
          multiline
          noBorder
          autoFocus={props.autoFocusMode === "instant"}
          noPadding
          minHeight={168}
        />
      </View>
      <Spacer vertical={2} />
      <RecipeCookingHistory recipeId={props.recipeId} />
    </View>
  );
};

const styles = StyleSheet.create({
  textInputWrapper: {
    backgroundColor: "white",
    padding: globalStyleConstants.unitSize,
    marginHorizontal: globalStyleConstants.defaultPadding,
    borderRadius: 20,
    borderWidth: 1,
    borderColor: globalStyleColors.rgba("blackSoft", "light"),
    maxHeight: 300,
  },
  headingContainer: {
    flex: 1,
    flexDirection: "row",
    alignItems: "flex-start",
    height: 3 * globalStyleConstants.unitSize,
    paddingHorizontal: globalStyleConstants.defaultPadding,
  },
});

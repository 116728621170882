import React, { useCallback, useImperativeHandle, useMemo, useRef } from "react";
import { SectionList, SectionListData, SectionListProps, SectionListRenderItem, View } from "react-native";
import Reanimated, { useAnimatedScrollHandler } from "react-native-reanimated";
import { RecipeLibraryImperativeHandle } from "./RecipeLibraryInterfaces";
import { useScrollToTop } from "@react-navigation/native";
import {
  AppUserRecipe,
  cookingAndShoppedCollectionId,
  RecipeCollectionId,
  UserRecipeId,
} from "@eatbetter/recipes-shared";
import { RecipeCardCarousel } from "./RecipeCardCarousel";
import { RecipePressedHandler } from "./RecipeCards";
import { useDispatch } from "../../lib/redux/Redux";
import { navToCookingSessionIfExists } from "../../navigation/NavThunks";
import { useScreen } from "../../navigation/ScreenContainer";
import { navTree } from "../../navigation/NavTree";
import { bottomNop, bottomThrow, filterOutFalsy } from "@eatbetter/common-shared";
import { getPullToRefresh } from "../PullToRefresh";
import { useScreenElementDimensions } from "../ScreenView";
import { Spacer } from "../Spacer";
import { useFilteredCookingSessionAndGroceryRecipes } from "../../lib/composite/RecipeListSelectors";
import { SectionHeading } from "../SectionHeading";
import { RecipeCollectionCard } from "./RecipeCollectionCard";
import { globalStyleColors, globalStyleConstants } from "../GlobalStyles";
import { ListItemReorderControl } from "../ListItemReorderControl";
import { Pressable } from "../Pressable";
import { IconMoreVertical } from "../Icons";
import { CollectionData, CollectionGroupData } from "../../lib/composite/CollectionsSelectors";
import { RecipeCollectionsEditMode } from "./RecipeCollectionsEdit";
import { getOptionsMenuHeight, OptionsMenu, OptionsMenuItem } from "../OptionsMenu";
import { useBottomSheet } from "../../screens/BottomSheetScreen";
import { withNavDelay } from "../../lib/util/WithNavDelay";
import { displayUnexpectedErrorAndLog } from "../../lib/Errors";
import { Haptics } from "../Haptics";
import { pullToRefreshRecipes } from "../../lib/recipes/RecipesThunks";
import { LibraryFilterSessionId } from "../../lib/composite/LibraryAndSearchSessionIds.ts";

const strings = {
  cookingInProgress: "Cooking in Progress",
  recentlyShopped: "Recently Shopped",
  sectionEditMenu: {
    rename: "Rename Collection Group",
  },
  sectionEditScreen: {
    title: "Rename Collection Group",
    placeholder: "Collection group name",
  },
};

type SectionType = "recipeCarousel" | "collectionList";

/**
 * Section data: used to render sections + headers
 */
interface SectionInfoBase<TType extends SectionType, TData> {
  sectionType: TType;
  sectionInfo: TData;
}
type CookingSessionOrGrocerySection = SectionInfoBase<"recipeCarousel", { sectionTitle: string }>;
type CollectionGroupSection = SectionInfoBase<
  "collectionList",
  {
    sectionIndex: number;
    canMoveUpDisabled: boolean;
    canMoveDownDisabled: boolean;
    groupData: Omit<CollectionGroupData, "collections">;
  }
>;
type SectionInfo = CookingSessionOrGrocerySection | CollectionGroupSection;

/**
 * Item data: user to render individual items in sections
 */
interface SectionItemBase<TType extends SectionType, TData> {
  sectionType: TType;
  sectionIndex: number;
  itemData: TData;
}
type CookingSessionOrGroceryRecipe = SectionItemBase<"recipeCarousel", AppUserRecipe[]>;
type CollectionItem = SectionItemBase<"collectionList", CollectionData>;
type SectionListItem = CookingSessionOrGroceryRecipe | CollectionItem;

/**
 * Composite type that joins all the above, passed in to the SectionList.data prop
 */
type LibraryCollectionsViewData = SectionListData<SectionListItem, SectionInfo>;

/**
 * Types for supporting SectionList callback props to enforce correctness
 */
type RenderSectionPart = (info: { section: LibraryCollectionsViewData }) => React.ReactElement;
type RenderSectionPartComponent = (props: {
  highlighted: boolean;
  section: SectionInfo;
  leadingSection: SectionInfo;
  trailingSection: SectionInfo;
  leadingItem: SectionListItem;
  trailingItem: SectionListItem;
}) => React.ReactElement;
type RenderSectionItem = SectionListRenderItem<SectionListItem, SectionInfo>;
type KeyExtractor = (item: SectionListItem | LibraryCollectionsViewData, index: number) => string;

/**
 * Reanimated wrapper for SectionList to support native onScroll + synced animation with screen header
 */
const AnimatedSectionList =
  Reanimated.createAnimatedComponent<SectionListProps<SectionListItem, SectionInfo>>(SectionList);

type ScrollEventHandler = ReturnType<typeof useAnimatedScrollHandler>;

interface RecipeLibraryCollectionsViewProps {
  collectionsData: CollectionGroupData[];
  editMode?: RecipeCollectionsEditMode;
  onScroll?: ScrollEventHandler;
  sessionId: LibraryFilterSessionId;
}

export const RecipeLibraryCollectionsView = React.forwardRef<
  RecipeLibraryImperativeHandle,
  RecipeLibraryCollectionsViewProps
>((props, ref) => {
  const dispatch = useDispatch();
  const screen = useScreen();
  const { bottomTabBarHeight, headerHeight } = useScreenElementDimensions();

  const { cookingSessionRecipes, groceryRecipes } = useFilteredCookingSessionAndGroceryRecipes(props.sessionId);
  const haveCookingSessionRecipes = cookingSessionRecipes.length > 0;

  const onPressChangeGroup = useCallback(
    (collectionId: RecipeCollectionId, collectionName: string) => {
      withNavDelay(() => {
        if (!props.editMode) {
          displayUnexpectedErrorAndLog("onPressChangeGroup called but props.editMode is undefined (this is a bug)", {
            editMode: props.editMode,
            collectionId,
            collections: props.collectionsData,
          });
          return;
        }

        screen.nav.modal(navTree.get.screens.recipeCollectionChangeGroup, {
          // IMPORTANT - THIS FILTER LOGIC IS DUPLICATED HERE AND CreateRecipeCollectionScreen.TSX
          // IF IT CHANGES HERE, IT SHOULD PROBABLY CHANGE THERE
          groups: props.collectionsData.filter(i => !i.group.locked).map(i => i.group),
          collectionId,
          collectionName,
          moveCollectionToGroup: props.editMode.moveCollectionToGroup,
        });
      });
    },
    [screen.nav.modal, props.collectionsData, props.editMode]
  );

  const contentContainerStyle = useMemo(() => {
    return { paddingBottom: bottomTabBarHeight, paddingTop: headerHeight };
  }, [bottomTabBarHeight, headerHeight]);

  const onPullToRefresh = useCallback(async () => {
    return dispatch(pullToRefreshRecipes());
  }, [dispatch]);

  const refreshControl = useMemo(() => {
    return getPullToRefresh(onPullToRefresh, headerHeight);
  }, [onPullToRefresh, headerHeight]);

  const sectionData = useMemo<LibraryCollectionsViewData[]>(() => {
    const haveCookingSessionOrGroceryRecipes = cookingSessionRecipes.length > 0 || groceryRecipes.length > 0;
    const haveCookingSessionAndGroceryRecipes = cookingSessionRecipes.length > 0 && groceryRecipes.length > 0;

    // Section title logic for cooking and/or shopped: if both exist, title is both concated with a +, otherwise
    // show the appropriate one (shopped or cooked)
    const cookingSessionOrGroceryRecipeSectionTitle = haveCookingSessionOrGroceryRecipes
      ? haveCookingSessionAndGroceryRecipes
        ? `${strings.cookingInProgress} + ${strings.recentlyShopped}`
        : cookingSessionRecipes.length > 0
        ? strings.cookingInProgress
        : strings.recentlyShopped
      : "";

    // we initially had a carousel of tall recipe cards for cooking in progress and recently shopped
    // we diabled this and opted to go with a collection because a single card looked weird (there
    // was a large blank space). Leaving the code for now in case we reconsider.
    const disableCarousel = true;

    return filterOutFalsy<LibraryCollectionsViewData>([
      // Shopped/cooked carousel data (if there is any)
      haveCookingSessionOrGroceryRecipes && !props.editMode && !disableCarousel
        ? {
            key: "cookingSessionAndGroceryRecipes",
            sectionType: "recipeCarousel",
            sectionInfo: { sectionTitle: cookingSessionOrGroceryRecipeSectionTitle },
            data: [
              {
                sectionType: "recipeCarousel",
                sectionIndex: 0,
                itemData: [...cookingSessionRecipes, ...groceryRecipes],
              },
            ],
          }
        : undefined,
      // Collections data: sections + items
      ...props.collectionsData.flatMap<LibraryCollectionsViewData>((group, groupIdx, groups) => {
        // we flatmap and return empty here instead of filtering beforehand because we need
        // the groupIdx to be correct
        if ((!props.editMode && !group.render) || (props.editMode && group.group.locked)) {
          return [];
        }

        // Determine if the given section group can move up or down by determining if it's not just the
        // first or last in the list, but also if there are "pinned" items before/after it. For example,
        // if it's 3rd in the list but the first two items are pinned, then the item can't be moved up.
        // Since we need to look deeply at the data and we have the inputs here, we compute here and pass the results
        // in the section data instead of trying to compute it in the header callbacks.
        const lastPinnedTopIdx = groups.findLastIndex(i => i.group.position?.pinned === "top");
        const moveableStartIdx = lastPinnedTopIdx < 0 ? 0 : lastPinnedTopIdx + 1;

        const lastPinnedBottomIdx = groups.findLastIndex(i => i.group.position?.pinned === "bottom");
        const moveableEndIdx = lastPinnedBottomIdx < 0 ? groups.length - 1 : lastPinnedBottomIdx - 1;

        const moveableList = groups.slice(moveableStartIdx, moveableEndIdx + 1);
        const moveableSectionIndex = moveableList.findIndex(i => i.group.id === group.group.id);

        const isFirstMoveable = moveableSectionIndex === 0;
        const isLastMoveable = moveableSectionIndex === moveableList.length - 1;

        return {
          key: `collection_key_${group.group.id}`,
          sectionType: "collectionList",
          sectionInfo: {
            groupData: {
              group: group.group,
              render: group.render,
            },
            sectionIndex: groupIdx,
            canMoveUpDisabled: isFirstMoveable,
            canMoveDownDisabled: isLastMoveable,
          },
          data: group.collections
            .filter(i => i.render || props.editMode)
            .map(i => {
              return {
                sectionType: "collectionList",
                sectionIndex: groupIdx,
                itemData: i,
              };
            }),
        };
      }),
    ]);
  }, [cookingSessionRecipes, groceryRecipes, props.collectionsData, props.editMode]);

  const onPressRecipe: RecipePressedHandler = useCallback(
    recipeId => {
      if (!dispatch(navToCookingSessionIfExists({ type: "search", nav: screen.nav.switchTab, recipeId }))) {
        screen.nav.goTo("push", navTree.get.screens.recipeDetail, {
          recipeId: recipeId as UserRecipeId,
        });
      }
    },
    [dispatch, screen.nav.goTo, screen.nav.switchTab]
  );

  const renderItem: RenderSectionItem = useCallback(
    ({ item, index, section }) => {
      switch (item.sectionType) {
        case "recipeCarousel": {
          return (
            <RecipeCardCarousel
              items={item.itemData.map((i, idx) => ({
                ...i,
                index: idx,
                onPress: onPressRecipe,
                sessionId: props.sessionId,
              }))}
            />
          );
        }
        case "collectionList": {
          // we put hidden collections at the end of the list. If the following item is hidden this can't be moved down
          const nextItem = section.data[index + 1];
          const nextItemHidden = nextItem?.sectionType === "collectionList" && !!nextItem.itemData.collection.hidden;

          return (
            <RecipeCollectionCard
              id={item.itemData.collection.id}
              name={item.itemData.collection.name}
              images={item.itemData.display.images}
              recipeCount={item.itemData.display.recipeCount}
              collectionType={item.itemData.collection.type}
              collectionSource={item.itemData.collection.source}
              canRename={item.itemData.collection.canRename}
              canDelete={item.itemData.collection.canDelete}
              canHide={item.itemData.collection.canHide}
              hidden={!!item.itemData.collection.hidden}
              createdByUserId={item.itemData.collection.creatorUserId}
              groupIndex={item.sectionIndex}
              index={index}
              editMode={
                props.editMode
                  ? {
                      moveCollectionUp: props.editMode.moveCollectionUp,
                      moveCollectionDown: props.editMode.moveCollectionDown,
                      renameCollection: props.editMode.renameCollection,
                      deleteCollection: props.editMode.deleteCollection,
                      hideCollection: props.editMode.hideCollection,
                      unhideCollection: props.editMode.unhideCollection,
                    }
                  : undefined
              }
              canMoveUpDisabled={index === 0 || !!item.itemData.collection.hidden}
              // hidden items are always at the end of the list and can't be moved
              // the last non-hidden item can't be moved down either
              canMoveDownDisabled={
                index === section.data.length - 1 || nextItemHidden || !!item.itemData.collection.hidden
              }
              onPressChangeGroup={onPressChangeGroup}
              isActive={item.itemData.collection.id === cookingAndShoppedCollectionId && haveCookingSessionRecipes}
            />
          );
        }
        default:
          bottomThrow(item);
      }
    },
    [onPressRecipe, props.editMode, onPressChangeGroup, haveCookingSessionRecipes]
  );

  const itemSeparator: RenderSectionPartComponent = useCallback(() => {
    return (
      <>
        <Spacer vertical={globalStyleConstants.minPadding} unit="pixels" />
      </>
    );
  }, []);

  const renderSectionHeader: RenderSectionPart = useCallback(
    info => {
      switch (info.section.sectionType) {
        case "recipeCarousel": {
          return (
            <SectionHeader
              title={info.section.sectionInfo.sectionTitle}
              canMoveUpDisabled
              canMoveDownDisabled
              index={0}
              editMode={undefined}
              pinned="top"
              canRename={false}
            />
          );
        }
        case "collectionList": {
          return (
            <SectionHeader
              title={info.section.sectionInfo.groupData.group.name}
              canMoveUpDisabled={info.section.sectionInfo.canMoveUpDisabled}
              canMoveDownDisabled={info.section.sectionInfo.canMoveDownDisabled}
              index={info.section.sectionInfo.sectionIndex}
              editMode={
                props.editMode
                  ? {
                      moveGroupUp: props.editMode.moveGroupUp,
                      moveGroupDown: props.editMode.moveGroupDown,
                      renameGroup: props.editMode.renameGroup,
                    }
                  : undefined
              }
              pinned={info.section.sectionInfo.groupData.group.position?.pinned}
              canRename={info.section.sectionInfo.groupData.group.canRename}
            />
          );
        }
      }
    },
    [props.editMode]
  );

  const renderSectionFooter: RenderSectionPart = useCallback((_info: { section: SectionInfo }) => {
    return <Spacer vertical={1} />;
  }, []);

  const listHeader: RenderSectionPartComponent = useCallback(() => {
    return (
      <>
        <Spacer vertical={1} />
      </>
    );
  }, []);

  const listFooter: RenderSectionPartComponent = useCallback(() => {
    return (
      <>
        <Spacer vertical={2} />
      </>
    );
  }, []);

  const sectionSeparator: RenderSectionPartComponent = useCallback(() => {
    return <Spacer vertical={1} />;
  }, []);

  const keyExtractor: KeyExtractor = useCallback((item, index) => {
    // There is something strange going on here when we set onViewableItemsChanged that definitely looks like a bug in SectionList.
    // When onViewableItemsChanged is set, on the initial render we get called multiple times here with the entire section data object
    // instead of the item data. If we ignore those, everything seems to work fine. It also doesn't happen on subsequent scrolls.
    if (!("itemData" in item)) {
      return `section-${item.sectionType}-${index}`;
    }

    switch (item.sectionType) {
      case "recipeCarousel": {
        return item.sectionType;
      }
      case "collectionList": {
        return item.itemData.collection.id;
      }
      default:
        bottomNop(item);
        return `unknown-item-type-${index}`;
    }
  }, []);

  const listRef = useRef<SectionList<any, any>>(null);
  useScrollToTop(listRef);

  useImperativeHandle(
    ref,
    () => ({
      scrollToTop: (opts?: { animated: boolean }) => {
        if (!listRef.current || sectionData.length === 0) {
          return;
        }
        listRef.current.scrollToLocation({
          sectionIndex: 0,
          itemIndex: 0,
          viewPosition: 1,
          animated: opts?.animated,
        });
      },
    }),
    [listRef, sectionData.length]
  );

  return (
    <AnimatedSectionList
      ref={listRef}
      sections={sectionData}
      renderItem={renderItem}
      keyExtractor={keyExtractor}
      ItemSeparatorComponent={itemSeparator}
      renderSectionHeader={renderSectionHeader}
      renderSectionFooter={renderSectionFooter}
      SectionSeparatorComponent={sectionSeparator}
      ListHeaderComponent={listHeader}
      ListFooterComponent={listFooter}
      stickySectionHeadersEnabled={false}
      refreshControl={refreshControl}
      contentContainerStyle={contentContainerStyle}
      onScroll={props.onScroll}
      scrollEventThrottle={16}
      keyboardDismissMode="on-drag"
      keyboardShouldPersistTaps="handled"
      showsVerticalScrollIndicator={false}
    />
  );
});

const SectionHeader = React.memo(
  (props: {
    title: string;
    canMoveUpDisabled: boolean;
    canMoveDownDisabled: boolean;
    index: number;
    editMode?: Pick<RecipeCollectionsEditMode, "moveGroupUp" | "moveGroupDown" | "renameGroup">;
    canRename?: boolean;
    pinned?: "top" | "bottom";
  }) => {
    const onPressMoveUp = useCallback(() => {
      props.editMode?.moveGroupUp(props.index);
    }, [props.index, props.editMode]);

    const onPressMoveDown = useCallback(() => {
      props.editMode?.moveGroupDown(props.index);
    }, [props.index, props.editMode]);

    const onRenameGroupName = useCallback(
      (value: string) => {
        props.editMode?.renameGroup(props.index, value);
      },
      [props.index, props.editMode]
    );

    const showReorderControls = !!props.editMode && !props.pinned;
    const showMenu = !!props.editMode && props.canRename;

    return (
      <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          {showReorderControls && (
            <>
              <Spacer horizontal={globalStyleConstants.defaultPadding} unit="pixels" />
              <ListItemReorderControl
                onPressMoveUp={onPressMoveUp}
                onPressMoveDown={onPressMoveDown}
                canMoveUpDisabled={props.canMoveUpDisabled}
                canMoveDownDisabled={props.canMoveDownDisabled}
              />
              <Spacer horizontal={1} />
            </>
          )}
          <SectionHeading text={props.title} noPadding={showReorderControls} />
        </View>
        {showMenu && (
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <SectionHeaderMoreMenu groupName={props.title} onChangeGroupName={onRenameGroupName} />
            <Spacer horizontal={globalStyleConstants.defaultPadding} unit="pixels" />
          </View>
        )}
      </View>
    );
  }
);

const SectionHeaderMoreMenu = React.memo((props: { groupName: string; onChangeGroupName: (value: string) => void }) => {
  const screen = useScreen();

  const onPress = useCallback(() => {
    screen.nav.modal(navTree.get.screens.bottomSheet, {
      content: <SectionHeaderOptionsSheet groupName={props.groupName} onChangeGroupName={props.onChangeGroupName} />,
      height: getOptionsMenuHeight(1, true),
    });
  }, [screen.nav.modal, props.groupName, props.onChangeGroupName]);

  return (
    <Pressable onPress={onPress}>
      <IconMoreVertical color={globalStyleColors.colorAccentCool} />
    </Pressable>
  );
});

const SectionHeaderOptionsSheet = React.memo(
  (props: { groupName: string; onChangeGroupName: (value: string) => void }) => {
    const screen = useScreen();
    const bottomSheet = useBottomSheet();

    const onPressRename = useCallback(() => {
      Haptics.feedback("itemStatusChanged");
      bottomSheet?.closeSheetAndGoBack();
      withNavDelay(() =>
        screen.nav.modal(navTree.get.screens.recipeCollectionRename, {
          screenTitle: strings.sectionEditScreen.title,
          placeholderText: strings.sectionEditScreen.placeholder,
          initialValue: props.groupName,
          onSubmit: props.onChangeGroupName,
        })
      );
    }, [bottomSheet, screen.nav.modal, props.groupName, props.onChangeGroupName]);

    return (
      <OptionsMenu header={props.groupName}>
        <OptionsMenuItem isFirst icon={"editPencil"} text={strings.sectionEditMenu.rename} onPress={onPressRename} />
      </OptionsMenu>
    );
  }
);

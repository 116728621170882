import React, { useCallback } from "react";
import { View } from "react-native";
import { PlanningNote, MealType } from "../screens/PlanningHomeScreen";
import { recipeCardConstants } from "./recipes/RecipeCards";
import { globalStyleConstants, globalStyles } from "./GlobalStyles";
import { IconMoreVertical, IconStickyNote } from "./Icons";
import { Spacer } from "./Spacer";
import { TSecondary } from "./Typography";
import { Pressable } from "./Pressable";
import { MealTypeLabel } from "./MealTypeLabel";

export const CalendarNoteListItem = React.memo((props: { note: PlanningNote; mealType?: MealType }) => {
  const onPress = useCallback(() => {
    // PLANNING-TODO
  }, []);

  const onPressMoreMenu = useCallback(() => {
    // PLANNING-TODO
  }, []);

  return (
    <Pressable
      onPress={onPress}
      noFeedback
      style={{
        ...globalStyles.shadowItem,
        padding: globalStyleConstants.minPadding,
        height: recipeCardConstants.verticalScrollCardHeight,
        width: "100%",
        borderRadius: globalStyleConstants.defaultBorderRadius,
        backgroundColor: "white",
      }}
    >
      <View style={{ flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
        <View style={{ flexShrink: 1, flexDirection: "row", alignItems: "center" }}>
          <View
            style={{
              width: 96,
              height: 96,
              borderRadius: globalStyleConstants.defaultBorderRadius,
              overflow: "hidden",
            }}
          >
            <IconStickyNote size={"100%"} />
          </View>
          <Spacer horizontal={1} />
          <View style={{ flexShrink: 1 }}>
            <TSecondary numberOfLines={3}>{props.note.note}</TSecondary>
          </View>
        </View>
        <Pressable
          style={{ marginLeft: globalStyleConstants.unitSize, paddingRight: 4 }}
          hitSlop={globalStyleConstants.unitSize}
          onPress={onPressMoreMenu}
        >
          <IconMoreVertical />
        </Pressable>
        {!!props.mealType && <MealTypeLabel mealType={props.mealType} />}
      </View>
    </Pressable>
  );
});

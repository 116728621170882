import { bottomWithDefault, EpochMs, UserId } from "@eatbetter/common-shared";
import { UserRecipeId } from "./RecipeTypes";
import { RecipeTag, RecipeTagId } from "./RecipeTagTypes";

export const allRecipesCollectionId: SystemRecipeCollectionId = "sc:filter:all_recipes";
export const cookingAndShoppedCollectionId: SystemRecipeCollectionId = "sc:filter:cooking_and_shopped";

/**
 * IMPORTANT: IDS CANNOT CHANGE. Otherwise, hidden collections might re-appear or other weirdness.
 * Order here determines the order the collections appear in the default layout.
 * IDs should start with sc:filter: for consistency and possible future checks.
 */
export const filterRecipeCollections = [
  { id: cookingAndShoppedCollectionId, name: "Cooking in Progress + Recently Shopped" },
  { id: allRecipesCollectionId, name: "All Recipes" },
  { id: "sc:filter:recently_added", name: "Recently Added" },
] satisfies Array<{ id: SystemRecipeCollectionId; name: string }>;

export const knownCollectionGroupIds = {
  activeRecipes: "sg:active_recipes",
  pinned: "sg:pinned",
  household: "sg:household",
} as const;

export type SystemFilterRecipeCollectionId = (typeof filterRecipeCollections)[number]["id"];

export interface AppCollectionManifest {
  /**
   * All collections avaialable to the user along with metadata about each.
   */
  collections: AppRecipeCollection[];

  /**
   * Layout information for the collections.
   */
  layout: RecipeCollectionLayoutWithMetadata;
}

export type RecipeCollectionId = UserRecipeCollectionId | SystemRecipeCollectionId;
export type UserRecipeCollectionId = `uc:${UserId}:${string}`;
export type SystemRecipeCollectionId = `sc:${string}`;
export type RecipeCollectionGroupId = UserRecipeCollectionGroupId | SystemRecipeCollectionGroupId;
export type UserRecipeCollectionGroupId = `ug:${string}`;
export type SystemRecipeCollectionGroupId = `sg:${string}`;

export function isUserRecipeCollectionId(id: RecipeCollectionId): id is UserRecipeCollectionId {
  return id.startsWith("uc:");
}
export function isSystemRecipeCollectionId(id: RecipeCollectionId): id is SystemRecipeCollectionId {
  return id.startsWith("sc:");
}

export function getRecipeTagForCollection(c: AppTagCollection): RecipeTag | undefined {
  switch (c.tagType) {
    case "user":
      return { type: "user", tag: c.name };
    case "system": {
      const tag = c.systemTags[0];
      // this should always be defined, but just in case...
      return tag ? { type: "system", tag } : undefined;
    }
    default:
      return bottomWithDefault(c.tagType, undefined, "getRecipeTagForCollection");
  }
}

export type AppRecipeCollection = AppTagCollection | AppFilterCollection;

/**
 * Tag collections can be applied by the user (or the system in the case of auto-tagging)
 */
export interface AppTagCollection extends AppCollectionBase {
  type: "tag";
  tagType: RecipeTag["type"];
  // during a rename operation, the recipe collection will contain both the old and new name
  // and we return them both so that we can map to the correct tag in the UI.
  // If there is no rename, this will be equal to the collection name.
  userTags: string[];

  // If the collection has been renamed, we pass this down so the client can update the library if library versino < lastRenameCompleted.
  // This is needed if the user does multiple renames in succession before the library is updated to reflect a previous rename.
  lastRenameCompleted?: EpochMs;

  // system tags are tagged with the ids, and not the string.
  // this will always be empty/undefined for user collections and always non-empty for system tags
  // For system tags, the FIRST item in this list is what the client should use when applying a tag
  systemTags: RecipeTagId[];

  // If the collection has been deleted recently, we will return it to the client
  deleted?: boolean;
}

/**
 * Filter collections are collections that match a filter. For example, "new recipes" might
 * match a filter of added in the last week. These cannot be applied by the user.
 * Currently, only system filter collections are supported.
 */
export interface AppFilterCollection extends AppCollectionBase {
  type: "filter";

  // these will never be deleted
  deleted?: undefined;
}

interface AppCollectionBase {
  id: RecipeCollectionId;
  name: string;
  source: "user" | "household" | "system";
  hidden?: boolean;
  creatorUserId?: UserId;
  canRename: boolean;
  canDelete: boolean;
  canHide: boolean;
  displayIfEmpty: boolean;
  created: EpochMs;
  version: EpochMs;
}

export interface RecipeCollectionLayoutWithMetadata {
  groups: CollectionGroupDisplay[];
}

export interface CollectionGroupDisplay {
  id: RecipeCollectionGroupId;
  name: string;
  type: "user" | "system";
  canRename: boolean;
  position?: { pinned: "top" | "bottom" };
  collections: Array<CollectionDisplay>;

  /**
   * If true, the UI should not remove this group, even if it's empty
   */
  retainIfEmpty?: boolean;

  /**
   * If true, the group should not show up in edit mode, and collections can't be removed
   * or added to the group. In other words, the user gets exactly what we give them.
   */
  locked?: boolean;
}

export interface CollectionDisplay {
  id: RecipeCollectionId;
}

export type BulkEditRecipesArgs = BulkEditRecipesAddRemoveTag | BulkEditRecipesDelete;

export interface BulkEditRecipesAddRemoveTag {
  type: "bulkAddRemoveTag";
  action: "add" | "remove";
  collectionId: RecipeCollectionId;
  recipeIds: UserRecipeId[];
}

export interface BulkEditRecipesDelete {
  type: "bulkDelete";
  recipeIds: UserRecipeId[];
}

/**
 * Operations to modify a collection or layout. Returns an updated AppCollectionManifest
 * This interface reflects that individual collection operations can occur after a user has
 * modified the layout, but not yet commited the changes by tapping done. For example:
 * 1. A user moves a collection position within a group
 * 2. User then taps the context menu for a different collection and hides it
 * At this point, we want to commit the hide change *and* the layout change so that rendering
 * can be driven off of the AppCollectionManifest.
 * Thinking of it another way, taking any individual action on a collection in edit mode is equivalent
 * to tapping "Done".
 */
export interface UpdateRecipeCollectionsArgs {
  /**
   * Operation on a specific collection
   */
  collection?: {
    op:
      | { type: "create"; name: string; group?: RecipeCollectionGroupId }
      | { type: "rename"; id: RecipeCollectionId; from: string; to: string }
      | { type: "delete"; id: RecipeCollectionId }
      | { type: "hide"; id: RecipeCollectionId }
      | { type: "unhide"; id: RecipeCollectionId };
  };

  /**
   * Operation to reorder layout.
   * Adding/deleting/renaming a collection group is accomplished with this interface, along with
   * reordering within a collection group.
   * Note that hiding a collection only affects layout but is accomplished with ModifyRecipeCollectionArgs to reflect
   * UI/backend pattern.
   */
  layout?: RecipeCollectionLayout;
}

export interface RecipeCollectionLayout {
  groups: RecipeCollectionLayoutGroup[];
}

export interface RecipeCollectionLayoutGroup {
  id: RecipeCollectionGroupId;
  name: string;
  collections: RecipeCollectionId[];
}

/**
 * THIS CANNOT CHANGE AND IS ONLY EXPOSED SO WE DON'T HAVE TO DO A BUNCH OF REFACTORING
 * FOR ADMIN TAG EDITING
 */
export const systemTagCollectionIdPrefix = "sc:tag:";

import { bottomThrow } from "@eatbetter/common-shared";
import React, { PropsWithChildren } from "react";
import { TBody } from "./Typography";
import { Pressable } from "./Pressable";
import { StyleSheet } from "react-native";
import { globalStyleConstants } from "./GlobalStyles";
import { Spinner } from "./Spinner";

const strings = {
  cancel: "Cancel",
};

interface HeaderLeftButtonPropsBase<TType extends string> {
  type: TType;
  onPress: () => void;
  disabled?: boolean;
  waiting?: boolean;
}

interface HeaderLeftTextButtonPropsBase<TType extends string> extends HeaderLeftButtonPropsBase<TType> {
  bold?: boolean;
  color?: string;
}

type CancelProps = HeaderLeftTextButtonPropsBase<"cancel">;

export type HeaderLeftProps = CancelProps;

export const renderHeaderLeft = (props: HeaderLeftProps) => {
  switch (props.type) {
    case "cancel": {
      return React.createElement(Cancel, props);
    }
    default:
      bottomThrow(props.type);
  }
};

const Cancel = React.memo((props: CancelProps) => {
  return <HeaderLeftTextButton {...props} buttonText={strings.cancel} bold={props.bold} color={props.color} />;
});

type HeaderLeftTextButtonProps = Omit<HeaderLeftTextButtonPropsBase<any>, "type"> &
  HeaderLeftButtonProps & {
    buttonText: string;
  };

const HeaderLeftTextButton = React.memo((props: HeaderLeftTextButtonProps) => {
  const { buttonText, color, bold, ...buttonProps } = props;

  return (
    <HeaderLeftButton {...buttonProps}>
      <TBody color={color} fontWeight={bold ? "medium" : undefined}>
        {buttonText}
      </TBody>
    </HeaderLeftButton>
  );
});

interface HeaderLeftButtonProps {
  onPress: () => void;
  disabled?: boolean;
  noFeedback?: boolean;
  waiting?: boolean;
}

const HeaderLeftButton = React.memo((props: PropsWithChildren<HeaderLeftButtonProps>) => {
  return (
    <Pressable
      style={styles.headerLeftButton}
      singlePress
      onPress={props.onPress}
      disabled={props.disabled || props.waiting}
      noFeedback={props.noFeedback || props.waiting}
    >
      {!!props.waiting && <Spinner />}
      {!props.waiting && props.children}
    </Pressable>
  );
});

const styles = StyleSheet.create({
  headerLeftButton: {
    paddingHorizontal: globalStyleConstants.unitSize,
    paddingRight: 8,
  },
});

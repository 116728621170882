import { ErrorData, isStructuredError, StructuredError } from "@eatbetter/common-shared";

/**
 *  used when we want to return an error with a message but no client logic is needed.
 *  This should only be used for infrequent errors - the app displays a reference code so it doesn't
 *  look great overall.
 */
export type RecipeGenericError = ErrorData<"recipes/generic">;
export type RecipeConflictError = ErrorData<"recipes/recipeConflict">;
export type RecipeNotFoundError = ErrorData<"recipes/recipeNotFound">;
export type RecipeCouldNotBeParsedError = ErrorData<"recipes/recipeCouldNotBeParsed">;
export type RecipeNoCanonicalUrlError = ErrorData<"recipes/recipeNoCanonicalUrlError", { hasRootPath?: boolean }>;
export type RecipeInvalidUrlError = ErrorData<"recipes/invalidUrlError">;
export type RecipeUnsupportedUrlError = ErrorData<"recipes/unsupportedUrlError">;
/**
 * Thrown if the collection is deleted or we can't find the given collection
 */
export type RecipeCollectionInvalidError = ErrorData<"recipes/collectionInvalidError">;
export type RecipeCollectionNameInvalidError = ErrorData<"recipes/collectionNameInvalidError", { userMessage: string }>;
export type RecipeImportQuotaExceededError = ErrorData<
  "recipes/importQuotaExceeded",
  {
    /**
     * This should be the same as what's on the overall structured error userMessage optional property,
     * but including it here to ensure it's set.
     */
    userMessage: string;
  }
>;

export type RecipeErrorTypes =
  | RecipeGenericError
  | RecipeConflictError
  | RecipeCouldNotBeParsedError
  | RecipeNotFoundError
  | RecipeNoCanonicalUrlError
  | RecipeInvalidUrlError
  | RecipeUnsupportedUrlError
  | RecipeImportQuotaExceededError
  | RecipeCollectionInvalidError
  | RecipeCollectionNameInvalidError;

export function isStructuredRecipeError(err: any): err is StructuredError<RecipeErrorTypes> {
  return isStructuredError(err) && err.data.code.startsWith("recipes/");
}

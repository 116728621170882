import React, { useEffect, useImperativeHandle, useRef } from "react";
import { TextInput, TextInputHandle } from "../TextInput";
import { View } from "react-native";
import { globalStyles } from "../GlobalStyles";

interface RecipeCollectionTitleInputProps {
  placeholderText: string;
  value: string | undefined;
  onChangeText: (v: string) => void;
  onSubmit?: () => void;
  autofocus?: boolean;
  editable?: boolean;
}

export const RecipeCollectionTitleInput = React.forwardRef<TextInputHandle, RecipeCollectionTitleInputProps>(
  (props, ref) => {
    const textInputRef = useRef<TextInputHandle>(null);

    useEffect(() => {
      if (props.autofocus) {
        setTimeout(() => textInputRef.current?.focus(), 650);
      }
    }, []);

    useImperativeHandle(ref, () => textInputRef.current!);

    return (
      <View style={globalStyles.singleLineTextInputWrap}>
        <TextInput
          ref={textInputRef}
          value={props.value}
          onChangeText={props.onChangeText}
          returnKeyType="done"
          blurOnSubmit
          onSubmit={props.onSubmit}
          placeholderText={props.placeholderText}
          noPadding
          noBorder
          editable={props.editable}
        />
      </View>
    );
  }
);

import React, { useCallback } from "react";
import { Opacity, globalStyleColors } from "./GlobalStyles";
import { LayoutAnimation, StyleSheet, View } from "react-native";
import { IconStarFilled } from "./Icons";
import { range, switchReturn } from "@eatbetter/common-shared";
import { Spacer } from "./Spacer";
import { TTertiary } from "./Typography";
import { Pressable } from "./Pressable";
import { Haptics } from "./Haptics";
import { IconButton } from "./Buttons";

interface StarRatingProps {
  value: number;
  onChangeValue?: (value: number | undefined) => void;
  style?: "compact" | "normal";
  size?: "small" | "medium" | "large";
  disabled?: boolean;
  clearable?: boolean;
}

export const StarRating = React.memo((props: StarRatingProps) => {
  const editMode = !!props.onChangeValue;

  const colorEmpty = globalStyleColors.colorGreyDark;
  const colorFilled = globalStyleColors.colorAccentCool;
  const style = props.style ?? "normal";

  const size = switchReturn(props.size ?? (editMode ? "large" : "small"), {
    small: 16,
    medium: 24,
    large: 32,
  });

  const getOpacity = (): keyof typeof Opacity => {
    switch (props.value) {
      case 0:
        return "opaque";
      case 1:
        return "medium";
      case 2:
        return "medium";
      case 3:
        return "medium";
      case 4:
        return "dark";
      case 5:
        return "opaque";
      default:
        return "opaque";
    }
  };
  const opacity = getOpacity();

  const onChangeValue = useCallback(
    (value: number | undefined) => {
      if (props.onChangeValue && value !== props.value) {
        LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
        Haptics.feedback("itemStatusChanged");
        props.onChangeValue(value);
      }
    },
    [props.value, props.onChangeValue]
  );

  const clearValue = useCallback(() => {
    onChangeValue(undefined);
  }, [onChangeValue]);

  return (
    <>
      {style === "compact" && (
        <View style={styles.compact}>
          <IconStarFilled size={16} color={globalStyleColors.blackSoft} opacity="light" />
          <Spacer horizontal={0.3} />
          <TTertiary opacity="dark" color={globalStyleColors.blackSoft}>
            {props.value}
          </TTertiary>
        </View>
      )}
      {style === "normal" && (
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          {range(1, 5).map(i => (
            <Pressable
              key={i}
              disabled={!editMode || props.disabled}
              noFeedback={!props.disabled}
              onPress={() => onChangeValue(i)}
            >
              <IconStarFilled
                opacity={i > props.value ? "opaque" : opacity}
                color={i > props.value ? colorEmpty : colorFilled}
                size={size}
              />
            </Pressable>
          ))}
          {props.clearable !== false && editMode && !props.disabled && props.value > 0 && (
            <>
              <Spacer horizontal={1} />
              <IconButton type="close" onPress={clearValue} size="small" />
            </>
          )}
        </View>
      )}
    </>
  );
});

const styles = StyleSheet.create({
  compact: {
    flexDirection: "row",
    alignItems: "flex-start",
  },
});

import { SyncThunkAction, ThunkAction } from "../redux/Redux";
import {
  clearSearchSession,
  newSearchStarted,
  nextResultsRequested,
  requestErrored,
  resultsReceived,
  searchCleared,
  searchTextChanged,
  selectSearchSession,
  suggestionsReceived,
  tagAddedToFilter,
  tagRemovedFromFilter,
} from "./SearchSlice";
import { SaveRecipeFromSearchArgs, SearchArgs, SearchQuery } from "@eatbetter/search-shared";
import { newId, switchReturn } from "@eatbetter/common-shared";
import { selectSearchQuery } from "./SearchSelectors";
import { SetWaitingHandler } from "../Types";
import { singleRecipeReceived } from "../recipes/RecipesSlice";
import {
  SearchQueryStartedFrom,
  reportRecipeAdded,
  reportSearchAddFilterButtonPressed,
  reportSearchCancelButtonTapped,
  reportSearchQuerySubmitted,
  reportSearchQuerySuggestionTapped,
  reportSearchResultsScrolled,
  reportSearchResultsViewed,
  reportSearchTagFilterUpdated,
} from "../analytics/AnalyticsEvents";
import { analyticsEvent } from "../analytics/AnalyticsThunks";
import { log } from "../../Log";
import { KnownAuthor, KnownAuthorId, KnownPublisher, KnownPublisherId } from "@eatbetter/recipes-shared";
import { NavApi } from "../../navigation/ScreenContainer";
import { navTree } from "../../navigation/NavTree";
import { displayUnexpectedErrorAndLog } from "../Errors";
import { RecipeTagOrFilter } from "../composite/CollectionsSelectors.ts";
import { GlobalSearchSessionId } from "../composite/LibraryAndSearchSessionIds.ts";

export const navToSearchQuery = (args: {
  nav: NavApi;
  from: SearchQueryStartedFrom;
  knownEntity?: KnownAuthor | KnownPublisher;
}): SyncThunkAction<void> => {
  return (_dispatch, _getState, _deps) => {
    const entityScreenArgs: { knownAuthorId?: KnownAuthorId; knownPublisherId?: KnownPublisherId } = args.knownEntity
      ? switchReturn(args.knownEntity?.type, {
          knownAuthor: { knownAuthorId: args.knownEntity.id as KnownAuthorId },
          knownPublisher: { knownPublisherId: args.knownEntity.id as KnownPublisherId },
        })
      : {};

    args.nav.goTo("push", navTree.get.screens.searchQuery, { ...entityScreenArgs, analyticsContext: args.from });
  };
};

export const searchAddFilterButtonPressed = (sessionId: GlobalSearchSessionId): SyncThunkAction<void> => {
  return (dispatch, getState, _deps) => {
    const state = getState();
    const searchSession = selectSearchSession(state.search, sessionId);
    const manifest = state.recipes.tagManifest;
    if (searchSession) {
      dispatch(analyticsEvent(reportSearchAddFilterButtonPressed({ sessionId, searchSession, manifest })));
    }
  };
};

export const querySuggestionPressed = (args: {
  sessionId: GlobalSearchSessionId;
  suggestionValue: string;
  suggestionIndex: number;
  suggestionType: string;
}): SyncThunkAction<void> => {
  return (dispatch, _getState, _deps) => {
    dispatch(
      analyticsEvent(
        reportSearchQuerySuggestionTapped({
          sessionId: args.sessionId,
          suggestionValue: args.suggestionValue,
          suggestionIndex: args.suggestionIndex,
          suggestionType: args.suggestionType,
        })
      )
    );
    dispatch(setSearchQueryFilter(args.sessionId, args.suggestionValue));
    dispatch(searchSubmitted(args.sessionId));
  };
};

export const searchResultsScrolled = (args: {
  sessionId: GlobalSearchSessionId;
  indexReached: number;
}): SyncThunkAction<void> => {
  return (dispatch, getState, _deps) => {
    const state = getState();
    const searchSession = selectSearchSession(state.search, args.sessionId);
    const manifest = state.recipes.tagManifest;
    if (searchSession) {
      dispatch(
        analyticsEvent(
          reportSearchResultsScrolled({
            searchSessionId: args.sessionId,
            searchSession,
            indexReached: args.indexReached,
            manifest,
          })
        )
      );
    }
  };
};

export const searchSubmitted = (sessionId: GlobalSearchSessionId): SyncThunkAction<void> => {
  return (dispatch, getState, _deps) => {
    const state = getState();
    const searchSession = selectSearchSession(state.search, sessionId);
    const isDefaultEntitySearch =
      (searchSession?.authorId || searchSession?.publisherId) &&
      !searchSession.filters.search &&
      !searchSession.filters.tags &&
      !searchSession.filters.filters;

    // Don't fire an analytics event when it's just the default search for an entity screen
    if (searchSession && !isDefaultEntitySearch) {
      const manifest = state.recipes.tagManifest;
      dispatch(analyticsEvent(reportSearchQuerySubmitted({ sessionId, searchSession, manifest })));
    }
    dispatch(newSearch(sessionId)).catch(() => {});
  };
};

export const searchResultsViewed = (args: {
  sessionId: GlobalSearchSessionId;
  serverRecipeResultCount?: number;
  entityResultCount?: number;
  libraryResultCount?: number;
}): SyncThunkAction<void> => {
  return (dispatch, getState, _deps) => {
    const state = getState();
    const searchSession = selectSearchSession(state.search, args.sessionId);
    if (!searchSession) {
      log.error(`No search session found for id ${args.sessionId} in searchResultsViewed`);
      return;
    }

    const manifest = state.recipes.tagManifest;
    dispatch(
      analyticsEvent(
        reportSearchResultsViewed({
          searchSessionId: args.sessionId,
          searchSession,
          manifest,
          serverRecipeResultCount: args.serverRecipeResultCount,
          entityResultCount: args.entityResultCount,
          libraryRecipeResultCount: args.libraryResultCount,
        })
      )
    );
  };
};

export const setSearchQueryFilter = (sessionId: GlobalSearchSessionId, text: string): SyncThunkAction<void> => {
  return (dispatch, getState, _deps) => {
    const s = getState();
    const session = selectSearchSession(s.search, sessionId);
    if (!session) {
      log.error(`No search session found for id ${sessionId} in setSearchQueryFilter`);
      return;
    }

    dispatch(searchTextChanged({ sessionId, text }));
    dispatch(getSuggestions(sessionId)).catch(() => {
      /* error handled in thunk */
    });
  };
};

const getSuggestions = (sessionId: GlobalSearchSessionId): ThunkAction<void> => {
  return async (dispatch, getState, deps) => {
    const state = getState();
    const session = selectSearchSession(state.search, sessionId);
    if (!session) {
      log.error(`No search session found for id ${sessionId} in setSearchQueryFilter`);
      return;
    }

    const queryAndFilters = selectSearchQuery(state, sessionId);
    const query = queryAndFilters?.query?.trim();
    if (!query || !queryAndFilters) {
      return;
    }

    if (session.suggestions[query]) {
      return;
    }

    try {
      const resp = await deps.api.withThrow().getSearchSuggestions({ query: queryAndFilters });
      dispatch(suggestionsReceived({ sessionId, suggestions: resp.data }));
    } catch (err) {
      log.errorCaught("Error fetching search suggestions", err, { queryAndFilters });
    }
  };
};

export const searchCancelButtonPressed = (sessionId: GlobalSearchSessionId): SyncThunkAction<void> => {
  return (dispatch, _getState, _deps) => {
    dispatch(analyticsEvent(reportSearchCancelButtonTapped({ sessionId })));
    dispatch(searchCleared({ sessionId }));
  };
};

export const addSearchTagFilter = (args: {
  tagOrFilter: RecipeTagOrFilter;
  sessionId: GlobalSearchSessionId;
}): SyncThunkAction<void> => {
  return (dispatch, getState, _deps) => {
    const state = getState();
    const tagManifest = state.recipes.tagManifest;

    dispatch(
      analyticsEvent(
        reportSearchTagFilterUpdated({
          type: "added",
          sessionId: args.sessionId,
          tagOrFilter: args.tagOrFilter,
          tagManifest,
        })
      )
    );
    dispatch(tagAddedToFilter({ tagOrFilter: args.tagOrFilter, sessionId: args.sessionId }));
    dispatch(newSearch(args.sessionId)).catch(() => {});
  };
};

export const removeSearchTagFilter = (args: {
  tagOrFilter: RecipeTagOrFilter;
  sessionId: GlobalSearchSessionId;
}): SyncThunkAction<void> => {
  return (dispatch, getState, _deps) => {
    const state = getState();
    const session = selectSearchSession(state.search, args.sessionId);
    if (!session) {
      log.error(`No search session found for id ${args.sessionId} in removeSearchTagFilter`);
      return;
    }

    const tagManifest = state.recipes.tagManifest;

    dispatch(
      analyticsEvent(
        reportSearchTagFilterUpdated({
          type: "removed",
          sessionId: args.sessionId,
          tagOrFilter: args.tagOrFilter,
          tagManifest,
        })
      )
    );
    dispatch(tagRemovedFromFilter({ tagOrFilter: args.tagOrFilter, sessionId: args.sessionId }));

    const updatedState = getState();
    const updatedFilters = selectSearchSession(updatedState.search, args.sessionId)?.filters;
    if (!updatedFilters) {
      log.error(`No search session filters found for id ${args.sessionId} in removeSearchTagFilter after dispatch`);
      return;
    }

    const hasActiveFilters =
      (updatedFilters.tags?.length ?? 0) > 0 || (updatedFilters.filters?.length ?? 0) > 0 || !!updatedFilters.search;

    // Don't kick off a new search if there are no search filters remaining after removing the specified tag. This will transition back
    // to the search query screen.
    if (hasActiveFilters) {
      dispatch(newSearch(args.sessionId)).catch(() => {});
    }
  };
};

export const fetchNextSearchResults = (sessionId: GlobalSearchSessionId): ThunkAction<void> => {
  return async (dispatch, getState, _deps) => {
    const state = getState();
    const search = selectSearchSession(state.search, sessionId);
    if (!search) {
      log.error(`No search session with id ${sessionId} in fetchNextSearchResults`);
      return;
    }

    const lastResults = search.requests.at(-1)?.results;

    if (!lastResults || !lastResults.next) {
      return;
    }

    const args: SearchArgs = {
      ...lastResults.args,
      start: lastResults.next.start,
      requestId: newId(),
      count: 10,
    };

    dispatch(nextResultsRequested({ args, sessionId }));
    await dispatch(makeRequest(args, sessionId));
  };
};

export const saveRecipeFromSearch = (
  args: Omit<SaveRecipeFromSearchArgs, "query">,
  sessionId: GlobalSearchSessionId,
  waiting?: SetWaitingHandler
): ThunkAction<void> => {
  return async (dispatch, getState, deps) => {
    const state = getState();
    const query: SearchQuery = selectSearchQuery(state, sessionId) ?? { tags: [] };
    const resp = await deps.api.withThrow(waiting).saveRecipeFromSearch({
      ...args,
      query,
    });

    dispatch(singleRecipeReceived(resp.data.recipe));

    const search = selectSearchSession(state.search, sessionId);
    const filters = search?.filters;
    const tagManifest = state.recipes.tagManifest;
    const event = reportRecipeAdded({
      recipe: resp.data.recipe,
      searchProps: filters && tagManifest ? { filters, tagManifest } : undefined,
      addedVia: "search",
    });
    dispatch(analyticsEvent(event));
  };
};

const newSearch = (sessionId: GlobalSearchSessionId): ThunkAction<void> => {
  return async (dispatch, getState, _deps) => {
    const s = getState();

    // skip the search if a user has a user tag - it will always return nothing.
    // this could be weird if the user has a custom tag that matches a system tag name - we could replace here if we wanted
    const tags = selectSearchSession(s.search, sessionId)?.filters.tags ?? [];
    if (tags.some(t => t.type === "user")) {
      dispatch(clearSearchSession(sessionId));
      return;
    }

    const query = selectSearchQuery(s, sessionId);

    if (!query) {
      log.error(`newSearch called with sessionId ${sessionId} but no query returned from selectSearchQuery`);
      return;
    }

    const args: SearchArgs = {
      requestId: newId(),
      query,
      start: 0,
      count: 20,
    };

    dispatch(newSearchStarted({ args, sessionId }));
    await dispatch(makeRequest(args, sessionId));
  };
};

const makeRequest = (args: SearchArgs, sessionId: GlobalSearchSessionId): ThunkAction<void> => {
  return async (dispatch, _getState, deps) => {
    try {
      const results = await deps.api.withThrow().search(args);
      dispatch(resultsReceived({ results: results.data, sessionId }));
    } catch (err) {
      // this is somewhat lame, but it seems like letting the user know something went wrong is better than doing nothing
      displayUnexpectedErrorAndLog("Unexpected error making search request", err, { args });
      dispatch(requestErrored({ requestId: args.requestId, sessionId }));
    }
  };
};

import React, { Dispatch, PropsWithChildren, useCallback, useMemo, useRef } from "react";
import {
  RecipeIngredient,
  RecipeIngredients,
  RecipeIngredientSection,
  RecipeInstruction,
  RecipeInstructions,
  RecipeInstructionSection,
} from "@eatbetter/recipes-shared";
import { LayoutAnimation, Platform, StyleSheet, View } from "react-native";
import { RecipeIngredientEdit } from "./RecipeIngredientEdit";
import { Pressable } from "../Pressable";
import { IconAdd, IconCircleMinus } from "../Icons";
import { Spacer } from "../Spacer";
import { RecipeIngredientEditActions, RecipeInstructionEditActions } from "./RecipeEditControl";
import { TextInputHandle } from "../TextInput";
import { ContainerPadded } from "../Containers";
import { ColorKey, globalStyleColors, globalStyleConstants } from "../GlobalStyles";
import { RecipeInstructionEdit } from "./RecipeInstructionEdit";
import { ParsedIngredientSummary } from "@eatbetter/items-shared";
import { Separator } from "../Separator";
import { RecipeEditTextInput } from "./RecipeEditTextInput";
import { ListItemReorderControl } from "../ListItemReorderControl";
import { TSecondary } from "../Typography";
import { switchReturn } from "@eatbetter/common-shared";
import { Haptics } from "../Haptics";
import { useRecipeEditRemoveItemConfirmation } from "./RecipeEditRemoveConfirmation";

const strings = {
  addIngredient: "Add ingredient",
  addInstruction: "Add instruction",
  addSection: "",
  insertSection: "",
  removeSection: "Remove section",
  removeSectionAlert: {
    title: "Remove Section?",
    description: "This action cannot be undone.",
    removeButton: "Remove",
  },
  sectionTitlePlaceholder: "Section title (optional)",
  insert: "Insert",
};

type SectionType = "ingredients" | "instructions";
type SectionDispatchType<T extends SectionType = SectionType> = T extends "ingredients"
  ? Dispatch<RecipeIngredientEditActions>
  : Dispatch<RecipeInstructionEditActions>;

interface RecipeSectionBase<TType extends SectionType> {
  type: TType;
  recipeSection: TType extends "ingredients" ? RecipeIngredients : RecipeInstructions;
  dispatch: SectionDispatchType<TType>;
  admin: boolean;
  parsedIngredients?: ParsedIngredientSummary[];
}

type RecipeIngredientsSection = RecipeSectionBase<"ingredients">;
type RecipeInstructionsSection = RecipeSectionBase<"instructions">;

type Props = RecipeIngredientsSection | RecipeInstructionsSection;

export const RecipeSectionEdit = React.memo((props: Props) => {
  const add = useCallback(
    (index?: number) => {
      layoutAnimation();
      props.dispatch({ type: "addSection", atItemIndex: index });
    },
    [props.dispatch]
  );

  return (
    <>
      {props.recipeSection.sections.map((s, idx) => {
        return (
          <View key={s.id}>
            {idx !== 0 && <Spacer vertical={1} />}
            <AddItemButton index={idx} onPress={add} text={strings.insertSection} />
            <Spacer vertical={1} />
            {props.type === "ingredients" && (
              <RecipeSectionContentEdit
                type="ingredients"
                section={s as RecipeIngredientSection}
                dispatch={props.dispatch}
                sectionIndex={idx}
                sectionCount={props.recipeSection.sections.length}
                admin={props.admin}
                parsedIngredients={props.parsedIngredients}
              />
            )}
            {props.type === "instructions" && (
              <RecipeSectionContentEdit
                type="instructions"
                section={s as RecipeInstructionSection}
                dispatch={props.dispatch}
                sectionIndex={idx}
                sectionCount={props.recipeSection.sections.length}
                admin={props.admin}
              />
            )}
          </View>
        );
      })}
      <Spacer vertical={1} />
      <AddItemButton onPress={add} text={strings.addSection} />
    </>
  );
});

interface RecipeSectionContentBase<
  TType extends string,
  TSection extends RecipeIngredientSection | RecipeInstructionSection
> {
  type: TType;
  sectionIndex: number;
  sectionCount: number;
  section: TSection;
  admin: boolean;
  parsedIngredients?: ParsedIngredientSummary[];
  dispatch: TSection extends RecipeIngredientSection
    ? Dispatch<RecipeIngredientEditActions>
    : Dispatch<RecipeInstructionEditActions>;
}

type RecipeIngredientsSectionContent = RecipeSectionContentBase<"ingredients", RecipeIngredientSection>;
type RecipeInstructionsSectionContent = RecipeSectionContentBase<"instructions", RecipeInstructionSection>;

type SectionProps = RecipeIngredientsSectionContent | RecipeInstructionsSectionContent;

const RecipeSectionContentEdit = React.memo((props: SectionProps) => {
  const titleInputRef = useRef<TextInputHandle>(null);

  const updateTitle = (title: string) => {
    props.dispatch({ type: "updateSectionTitle", index: props.sectionIndex, title });
  };

  const addItem = useCallback(() => {
    layoutAnimation();
    props.dispatch({ type: "addItem", sectionIndex: props.sectionIndex });
  }, [props.dispatch, props.sectionIndex]);

  const removeSection = useCallback(() => {
    layoutAnimation();
    props.dispatch({ type: "removeSection", index: props.sectionIndex });
  }, [props.dispatch, props.sectionIndex]);

  const onRemoveSection = useRecipeEditRemoveItemConfirmation({
    type: "section",
    isEmpty: !props.section.title && !(props.section.items.length > 0 && props.section.items.some(i => i.text)),
    remove: removeSection,
  });

  const move = useCallback(
    (type: "up" | "down", fromIndex: number) => {
      const moveDelta = switchReturn(type, {
        up: -1,
        down: 1,
      });

      layoutAnimation();
      props.dispatch({ type: "moveSection", fromIndex, toIndex: fromIndex + moveDelta });
    },
    [props.dispatch]
  );

  const moveUp = useCallback(
    (index: number) => {
      move("up", index);
    },
    [move]
  );

  const moveDown = useCallback(
    (index: number) => {
      move("down", index);
    },
    [move]
  );

  const parsedIngredients: Record<string, ParsedIngredientSummary> = useMemo(() => {
    if (!props.parsedIngredients) {
      return {};
    }

    const record: Record<string, ParsedIngredientSummary> = {};
    props.parsedIngredients.forEach(p => {
      record[p.phrase] = p;
    });

    return record;
  }, [props.parsedIngredients]);

  return (
    <View style={styles.sectionContainer}>
      <SectionTitleEdit
        ref={titleInputRef}
        title={props.section.title}
        onChangeTitle={updateTitle}
        autofocus={false}
        index={props.sectionIndex}
        sectionCount={props.sectionCount}
        removeSection={onRemoveSection}
        onPressMoveUp={moveUp}
        onPressMoveDown={moveDown}
      />
      <ContainerPadded horizontal={1}>
        {props.section.items.map((item, idx, items) => {
          // add a new ingredient when the keyboard done button is pressed only if it's the last ingredient
          const addOnDone = idx === props.section.items.length - 1;
          // don't autofocus in the case that there is only 1 ingredient, and it's empty. This is the start state for a new recipe
          // and we don't want to take focus from the recipe name field.
          const autofocus = !(props.section.items.length === 1 && props.section.items[0]?.text === "");
          return (
            <View key={item.id}>
              <AddIngredientOrInstruction
                sectionIndex={props.sectionIndex}
                itemIndex={idx}
                dispatch={props.dispatch}
                key={`add-${idx}`}
              />
              {props.type === "ingredients" && (
                <RecipeIngredientEdit
                  ingredient={item as RecipeIngredient}
                  key={item.id}
                  dispatch={props.dispatch}
                  sectionIndex={props.sectionIndex}
                  index={idx}
                  ingredientCount={items.length}
                  addOnDone={addOnDone}
                  onAdd={addItem}
                  autofocus={autofocus}
                  admin={props.admin}
                  parsedIngredient={parsedIngredients[item.text]}
                />
              )}
              {props.type === "instructions" && (
                <RecipeInstructionEdit
                  instruction={item as RecipeInstruction}
                  key={item.id}
                  dispatch={props.dispatch}
                  sectionIndex={props.sectionIndex}
                  index={idx}
                  instructionCount={items.length}
                  addOnDone={addOnDone}
                  onAdd={addItem}
                  autofocus={autofocus}
                  admin={props.admin}
                />
              )}
            </View>
          );
        })}
      </ContainerPadded>
      <ContainerPadded horizontal={1}>
        <AddIngredientOrInstruction
          sectionIndex={props.sectionIndex}
          itemIndex={Math.max(props.section.items.length, 0)}
          dispatch={props.dispatch}
        />
      </ContainerPadded>
    </View>
  );
});

interface AddItemProps {
  dispatch: SectionDispatchType;
  sectionIndex: number;
  itemIndex: number;
}

const AddIngredientOrInstruction = React.memo((props: AddItemProps) => {
  const add = () => {
    layoutAnimation();
    props.dispatch({ type: "addItem", sectionIndex: props.sectionIndex, atItemIndex: props.itemIndex });
  };

  return <AddItemButton onPress={add} />;
});

const AddItemButton = React.memo((props: { onPress: (index?: number) => void; index?: number; text?: string }) => {
  const color: ColorKey = "colorAccentCool";
  const separatorColor = globalStyleColors.rgba(color, "xlight");
  const textColor = globalStyleColors[color];

  const onPress = useCallback(() => {
    props.onPress(props.index);
  }, [props.onPress]);

  return (
    <Pressable style={{ marginVertical: globalStyleConstants.minPadding }} onPress={onPress}>
      <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
        <View style={{ flex: 1 }}>
          <Separator orientation="row" color={separatorColor} />
        </View>
        <View
          style={{
            marginLeft: globalStyleConstants.unitSize,
            marginRight: globalStyleConstants.minPadding,
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <View>
            <IconAdd color={textColor} opacity="opaque" size={18} />
          </View>
          {!!props.text && (
            <View style={{ paddingLeft: globalStyleConstants.minPadding }}>
              <TSecondary color={textColor}>{props.text}</TSecondary>
            </View>
          )}
        </View>
        <View style={{ flex: 1 }}>
          <Separator orientation="row" color={separatorColor} />
        </View>
      </View>
    </Pressable>
  );
});

const SectionTitleEdit = React.forwardRef<
  TextInputHandle,
  {
    title?: string;
    onChangeTitle: (title: string) => void;
    autofocus: boolean;
    index: number;
    sectionCount: number;
    removeSection: () => void;
    onPressMoveUp: (index: number) => void;
    onPressMoveDown: (index: number) => void;
  }
>((props, ref) => {
  const moveUp = useCallback(() => {
    props.onPressMoveUp(props.index);
  }, [props.onPressMoveUp, props.index]);

  const moveDown = useCallback(() => {
    props.onPressMoveDown(props.index);
  }, [props.onPressMoveDown, props.index]);

  return (
    <View style={styles.sectionTitle}>
      {props.sectionCount > 1 && (
        <>
          <RemoveSectionButton onPress={props.removeSection} />
          <Spacer horizontal={1} />
        </>
      )}
      <RecipeEditTextInput
        ref={ref}
        value={props.title}
        onChangeText={props.onChangeTitle}
        placeholderText={strings.sectionTitlePlaceholder}
      />
      {props.sectionCount > 1 && (
        <>
          <Spacer horizontal={1} />
          <ListItemReorderControl
            onPressMoveUp={moveUp}
            onPressMoveDown={moveDown}
            canMoveUpDisabled={props.index === 0}
            canMoveDownDisabled={props.index === props.sectionCount - 1}
          />
        </>
      )}
    </View>
  );
});

const RemoveSectionButton = React.memo((props: { onPress: () => void }) => {
  return (
    <FormButton onPress={props.onPress}>
      <IconCircleMinus opacity="opaque" color={"red"} size={globalStyleConstants.unitSize * 1.5} />
    </FormButton>
  );
});

const FormButton = React.memo((props: PropsWithChildren<{ onPress: () => void }>) => {
  return (
    <View>
      <Pressable onPress={props.onPress} style={styles.formButton}>
        {props.children}
      </Pressable>
    </View>
  );
});

function layoutAnimation() {
  if (Platform.OS === "web") {
    return;
  }
  Haptics.feedback("itemStatusChanged");
  LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
}

const styles = StyleSheet.create({
  sectionTitle: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    padding: globalStyleConstants.unitSize,
    borderTopLeftRadius: globalStyleConstants.defaultPadding,
    borderTopRightRadius: globalStyleConstants.defaultPadding,
    backgroundColor: globalStyleColors.colorGreyLight,
  },
  sectionContainer: {
    borderWidth: 1,
    borderRadius: globalStyleConstants.defaultBorderRadius,
    borderColor: globalStyleColors.colorGreyDark,
  },
  formButton: {
    flexDirection: "row",
    alignItems: "center",
  },
});

import { ComponentType, FunctionComponent, ReactNode } from "react";
import { NativeStackNavigationOptions, NativeStackScreenProps } from "@react-navigation/native-stack";
import { BottomTabNavigationOptions } from "@react-navigation/bottom-tabs";
import { OptionalKeys, RequiredKeys } from "@eatbetter/common-shared";

export type NavAction = "push" | "replace" | "reset";

export type GetNavStackComponents = () => NavStackComponents;

export interface NavStackComponents {
  getNavigator: (children: ReactNode, options?: ScreenOptionsOrFunc) => JSX.Element;
  getScreen: (
    name: string,
    comp: ComponentType<any>,
    options: ScreenOptionsOrFunc | undefined,
    getId: ((rp: { params: object | undefined }) => string) | undefined
  ) => JSX.Element;
}

export type ScreenComponent = FunctionComponent<NativeStackScreenProps<Record<string, object | undefined>>>;

/**
 * Returned by withScreenContainer.
 * It's a functional component with a serializer property that can
 * be used to serialize params to build the URL for the screen
 */
export interface ScreenWithUrlPropsConverter<TProps> extends ScreenComponent {
  serializeProps: boolean;
  serializer?: Serializer<TProps>;
  parser?: Parser<TProps>;
  nameRemapping?: Partial<Record<keyof TProps, string>>;
}

export interface RouteInfo {
  name: string;
  navAction?: NavAction;
}

export type ScreenOptionsOrFunc = NativeStackNavigationOptions | ((ri: RouteInfo) => NativeStackNavigationOptions);

// SCREEN TYPES
// Navigable screens must be addressable by URL which implies their props are serializable
// NonNavigable screens do not have a unique URL and can have non-serializable props

export type NavStack = "root" | "home" | "recipes" | "lists" | "planning" | "profile" | "allTabs";

interface NavigableOptions {
  /**
   * If true (default), user will be redirected to the sign-in screen if they are not signed-in.
   * If web/deepLink is also true, the user will be returned to the screen after successful auth
   */
  authRequired: boolean;

  /**
   * Whether the screen should be accessible on web. Default is false. If false, screen container
   * will redirect the user home.
   */
  web: boolean;

  /**
   * Whether the screen can be deep-linked in the app. Default is false. Note that this is separate from
   * non-navigable to allow for screens have different addressability between app and web. For example, we could have a
   * deeplink screen meant for the app only (deeplink:true, web: false), or a screen for web only (web: true, deeplink false)
   */
  deeplink: boolean;
}

type ScreenIdFunction<TProps> = (props: TProps) => Array<string | number | boolean | undefined>;

interface ScreenTypeBase<TProps> {
  component: ScreenWithUrlPropsConverter<TProps>;
  options?: ScreenOptionsOrFunc;

  /**
   * When handling deep links, we need to determine if we are already on the screen we want to navigate to so
   * we don't nav to the same screen again. By default, we won't nav if the screen name is the same.
   * If this function is set, it will be used for comparison purposes.
   * For example, if the deep link is for the recipe detail screen, we want to navigate even if the user is on
   * the recipe detail screen, as long as the recipe ID isn't the same. So this function could be props => [props.recipeId]
   */
  getScreenId?: ScreenIdFunction<TProps>;
}

interface NavScreenBase<TProps> extends ScreenTypeBase<TProps> {
  stacks: Array<NavStack>;
  isModal?: undefined;
}

/**
 * A screen and the relevant path and query string information
 */
export interface NavScreen<TProps> extends NavScreenBase<TProps>, NavigableOptions {
  path: string;
  pathType: "relative" | "absolute";
}

export interface NonNavigableScreen<TProps> extends NavScreenBase<TProps> {
  path: undefined;
}

interface ModalScreenBase<TProps> extends ScreenTypeBase<TProps> {
  isModal: true;
}

export interface ModalNavScreen<TProps> extends ModalScreenBase<TProps>, NavigableOptions {
  path: string;
  pathType?: undefined;
}

export interface ModalNonNavigableScreen<TProps> extends ModalScreenBase<TProps> {
  // not using ? here to make it so that it must explicitly be declared undefined and not just forgotten
  path: undefined;
}

export type ScreenType<T> = NavigableScreenType<T> | NonNavigableScreenType<T>;
export type NavigableScreenType<T> = NavScreen<T> | ModalNavScreen<T>;
export type NonNavigableScreenType<T> = NonNavigableScreen<T> | ModalNonNavigableScreen<T>;

export const modalStackSuffix = "-ModalStack";
export const getHackModalScreenName = (screenName: string) => `${screenName}${modalStackSuffix}`;
export const isHackModalStack = (name: string): boolean => name.endsWith(modalStackSuffix);
export const getScreenNameFromReactNavigationRouteName = (screenName: string): string => {
  if (screenName.endsWith(modalStackSuffix)) {
    return screenName.substring(0, screenName.length - modalStackSuffix.length);
  }

  return screenName;
};

export interface TabScreen {
  path: string;
  options?: BottomTabNavigationOptions;
}

/**
 * Parser and serializer to convert props to and from a URL
 */
export interface PropsUrlConverter<T> {
  parser: Parser<T>;
  serializer: Serializer<T>;
  nameRemapping?: Partial<Record<keyof T, string>>;
}

export type ParamParser<T> = (str: string) => T;
export type ParamSerializer<T> = (t: T) => string;

export interface OptionalParamParser<T> {
  optional: true;
  fn: ParamParser<NonNullable<T>>;
}

export interface OptionalParamSerializer<T> {
  optional: true;
  fn: ParamSerializer<NonNullable<T>>;
}

export type Parser<T> = {
  [key in OptionalKeys<T>]-?: OptionalParamParser<T[key]>;
} & {
  [key in RequiredKeys<T>]-?: ParamParser<T[key]>;
};

export type Serializer<T> = {
  [key in OptionalKeys<T>]-?: OptionalParamSerializer<T[key]>;
} & {
  [key in RequiredKeys<T>]-?: ParamSerializer<T[key]>;
};

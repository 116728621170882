import { RecipeId } from "@eatbetter/recipes-shared";
import { ReportContentIssueProps, navTree } from "../navigation/NavTree";
import { useScreen, withScreenContainer } from "../navigation/ScreenContainer";
import { ScreenView } from "../components/ScreenView";
import { useCallback, useMemo, useState } from "react";
import { useDispatch } from "../lib/redux/Redux";
import { reportIssue } from "../lib/system/SystemThunks";
import { useSurveyOptions } from "../components/SurveyOptions";
import { HeaderProps } from "../components/ScreenHeaders";
import { ScrollView } from "react-native";
import { BottomActionBar } from "../components/BottomActionBar";
import { Haptics } from "../components/Haptics";
import { displayUnexpectedErrorAndLog } from "../lib/Errors";
import { TBody } from "../components/Typography";
import { Spacer } from "../components/Spacer";
import { ReportContentIssue } from "@eatbetter/composite-shared";
import { bottomThrow } from "@eatbetter/common-shared";
import { SocialEntityId, SocialPostId } from "@eatbetter/posts-shared";

const strings = {
  screenTitle: "Report a Content Issue",
  headline: 'Please choose an option, or describe in your own words by selecting "Other"',
  options: ["Inappropriate Content", "Copyrighted Content", "Other"] as const,
  submit: "Submit",
  next: "Next",
  otherTextInputPlaceholder: "Describe the content issue...",
};

const surveyData: Array<{ key: ReportContentIssue["issue"]; displayText: string }> = [
  {
    key: "inappropriate",
    displayText: strings.options[0],
  },
  {
    key: "copyright",
    displayText: strings.options[1],
  },
  {
    key: "other",
    displayText: strings.options[2],
  },
];

export const ReportContentIssueScreen = withScreenContainer<ReportContentIssueProps>(
  "ReportContentIssueScreen",
  props => {
    const screen = useScreen();
    const dispatch = useDispatch();

    const surveyOptions = useSurveyOptions("singleSelect", surveyData);
    const selectedOption = surveyOptions.selected[0];
    const renderSurveyOptions = surveyOptions.renderSurveyOptions;

    const [waiting, setWaiting] = useState(false);

    const selectedOptionType = useMemo(() => {
      if (!selectedOption) {
        return null;
      }
      if (selectedOption.index === surveyData.findIndex(i => i.key === "other")) {
        return "other";
      }
      return "default";
    }, [selectedOption]);

    const submit = useCallback(
      async (otherText?: string) => {
        try {
          if (!selectedOption) {
            throw new Error("ReportUserRecipeContentIssueScreen: submit() called but selectedOption is falsy");
          }

          await dispatch(
            reportIssue(
              {
                type: "contentIssue",
                recipeId: props.recipeId,
                contentOwnerId: props.contentOwnerId,
                postId: props.postId,
                issue: selectedOption.key,
                otherText,
              },
              setWaiting
            )
          );
        } catch (err) {
          displayUnexpectedErrorAndLog("ReportUserRecipeContentIssueScreen: error caught in submit()", err, {
            otherText,
            recipeId: props.recipeId,
          });

          if (otherText) {
            // Throw so we can catch and handle appropriately in the "other feedback" form
            throw err;
          }

          // Don't nav back on error
          return;
        }
        Haptics.feedback("operationSucceeded");
        screen.nav.goBack();
      },
      [dispatch, selectedOption, props.recipeId, props.contentOwnerId, props.postId, setWaiting, screen.nav.goBack]
    );

    const onSubmitOtherFeedback = useCallback(
      async (otherText: string) => {
        await submit(otherText);
      },
      [submit]
    );

    const onSubmit = useCallback(async () => {
      if (!selectedOptionType) {
        displayUnexpectedErrorAndLog(
          "onSubmit called but no survey option is selected",
          {},
          { selectedOptionType, selectedOption }
        );
        return;
      }

      switch (selectedOptionType) {
        case "other": {
          screen.nav.modal(navTree.get.screens.surveyOtherOptionForm, {
            screenTitle: strings.screenTitle,
            label: "",
            textInputPlaceholder: strings.otherTextInputPlaceholder,
            onSubmit: onSubmitOtherFeedback,
          });
          break;
        }
        case "default": {
          await submit();
          break;
        }
        default:
          bottomThrow(selectedOptionType);
      }
    }, [screen.nav.modal, selectedOptionType, onSubmitOtherFeedback, submit]);

    const header = useMemo<HeaderProps>(() => {
      return {
        type: "default",
        title: strings.screenTitle,
      };
    }, []);

    return (
      <ScreenView header={header} scrollView={false}>
        <ScrollView contentContainerStyle={{ flex: 1 }}>
          {/* <TBody align="center" fontWeight="medium" numberOfLines={1}>
            {recipeTitle}
          </TBody>
          <Spacer vertical={1} /> */}
          <TBody align="center" numberOfLines={2} adjustsFontSizeToFit>
            {strings.headline}
          </TBody>
          <Spacer vertical={2} />
          {renderSurveyOptions()}
          <Spacer vertical={2} />
        </ScrollView>
        <BottomActionBar
          primaryAction={{
            actionText: selectedOptionType === "other" && !waiting ? strings.next : strings.submit,
            onPressAction: onSubmit,
            disabled: !selectedOption,
            waiting,
          }}
          containerBackgroundColor="transparent"
        />
      </ScreenView>
    );
  },
  {
    serializer: {
      recipeId: {
        optional: true,
        fn: s => s,
      },
      contentOwnerId: {
        optional: true,
        fn: s => s,
      },
      postId: {
        optional: true,
        fn: s => s,
      },
    },
    parser: {
      recipeId: {
        optional: true,
        fn: s => s as RecipeId,
      },
      contentOwnerId: {
        optional: true,
        fn: s => s as SocialEntityId,
      },
      postId: {
        optional: true,
        fn: s => s as SocialPostId,
      },
    },
  }
);

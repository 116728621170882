import React from "react";
import { View } from "react-native";
import { Video } from "./Video";
import { globalStyleConstants } from "./GlobalStyles";

type SnippetType = "scaling" | "textSize" | "collections" | "createCollection" | "reorderCollections" | "filters";

interface Snippet {
  type: SnippetType;
  analyticsId: string;
  video: number;
  aspectRatio: number;
}

const snippets = {
  scaling: {
    analyticsId: "scaling_control_snippet",
    video: require("../assets/feature_snippets/scaling_demo.mp4"),
    aspectRatio: 300 / 200,
  },
  textSize: {
    analyticsId: "text_size_snippet",
    video: require("../assets/feature_snippets/text_size_demo.mp4"),
    aspectRatio: 1080 / 135,
  },
  collections: {
    analyticsId: "collections_control_snippet",
    video: require("../assets/feature_snippets/collections_demo.mp4"),
    aspectRatio: 300 / 200,
  },
  createCollection: {
    analyticsId: "create_collection_snippet",
    video: require("../assets/feature_snippets/create_collection_demo.mp4"),
    aspectRatio: 300 / 100,
  },
  reorderCollections: {
    analyticsId: "reorder_collections_snippet",
    video: require("../assets/feature_snippets/reorder_collections_demo.mp4"),
    aspectRatio: 300 / 200,
  },
  filters: {
    analyticsId: "filters_snippet",
    video: require("../assets/feature_snippets/filters_demo.mp4"),
    aspectRatio: 300 / 200,
  },
} satisfies Record<SnippetType, Omit<Snippet, "type">>;

interface Props {
  type: SnippetType;
  borderRadius?: boolean;
}

export const FeatureDemoSnippet = React.memo((props: Props) => {
  const snippet = snippets[props.type];

  return (
    <View
      style={[
        { width: "100%", aspectRatio: snippet.aspectRatio },
        props.borderRadius
          ? {
              borderRadius: globalStyleConstants.unitSize,
              overflow: "hidden",
            }
          : undefined,
      ]}
    >
      <Video
        videoAnalyticsId={snippet.analyticsId}
        videoSource={{
          type: "bundled",
          nodeRequireOutput: snippet.video,
        }}
        autoPlay
        isLooping
        playbackMode={{ type: "contained" }}
        resizeMode="cover"
        hidePlayButton
        backgroundColor="transparent"
        spinnerColor="dark"
        tapToExpand={false}
      />
    </View>
  );
});

import { PhotoRef } from "@eatbetter/photos-shared";
import React, { useCallback, useState } from "react";
import { StyleSheet, View } from "react-native";
import { globalStyleColors, globalStyleConstants, globalStyles, Opacity } from "../GlobalStyles";
import { Pressable } from "../Pressable";
import { Photo } from "../Photo";
import { Separator } from "../Separator";
import { Spacer } from "../Spacer";
import { Text, TextProps, TSecondary } from "../Typography";
import { PhotoPlaceholderType } from "../PhotoPlaceholder";
import { ListItemReorderControl } from "../ListItemReorderControl";
import { bottomThrow, switchReturn, UserId, Username } from "@eatbetter/common-shared";
import { IconHide, IconMoreVertical } from "../Icons";
import { AppRecipeCollection, RecipeCollectionId } from "@eatbetter/recipes-shared";
import { RecipeCollectionsEditMode } from "./RecipeCollectionsEdit";
import { getOptionsMenuHeight, OptionsMenu, OptionsMenuItem } from "../OptionsMenu";
import { useScreen } from "../../navigation/ScreenContainer";
import { navTree } from "../../navigation/NavTree";
import { useBottomSheet } from "../../screens/BottomSheetScreen";
import { Haptics } from "../Haptics";
import { RecipeCollectionContextMenuTitle } from "./RecipeCollectionContextMenuTitle";
import { useRenameRecipeCollection } from "../../screens/RecipeCollectionRenameScreen";
import { useDeleteCollection } from "./RecipeCollectionDeleteConfirmation";
import { useCollectionCreatedByOtherUser } from "../../lib/composite/CollectionsSelectors";

const strings = {
  recipeCount: (count: number) => `${count} recipe${count === 1 ? "" : "s"}`,
  collectionSourceContext: {
    user: "Created by you",
    systemTag: "Deglaze Smart AI Collection",
    systemFilter: "Created by Deglaze",
    household: (u?: Username) => (u ? `Created by @${u}` : "Household Collection"),
  },
  collectionOptions: {
    moveSection: "Move to Different Group",
    rename: "Rename",
    hide: "Hide",
    unhide: "Unhide",
    delete: "Delete",
  },
};

const recipeCollectionCardHeight = 128;
const editModeRecipeCollectionCardHeight = 96;

interface Props {
  id: RecipeCollectionId;
  name: string;
  groupIndex: number;
  index: number;
  canMoveUpDisabled: boolean;
  canMoveDownDisabled: boolean;
  canRename: boolean;
  canDelete: boolean;
  canHide: boolean;
  hidden: boolean;
  images: PhotoRef[];
  recipeCount: number;
  createdByUserId?: UserId;
  collectionType: AppRecipeCollection["type"];
  collectionSource: AppRecipeCollection["source"];
  editMode?: Pick<
    RecipeCollectionsEditMode,
    | "moveCollectionUp"
    | "moveCollectionDown"
    | "renameCollection"
    | "deleteCollection"
    | "hideCollection"
    | "unhideCollection"
  >;
  marginHorizontal?: boolean;
  showCollectionType?: boolean;
  onPressChangeGroup?: (collectionId: RecipeCollectionId, collectionName: string) => void;
  isActive?: boolean;
}

export const RecipeCollectionCard = React.memo((props: Props) => {
  const screen = useScreen();
  const createdByOtherUser = useCollectionCreatedByOtherUser(props.id, props.createdByUserId);

  const onPressCollection = useCallback(() => {
    screen.nav.goTo("push", navTree.get.screens.recipeCollection, { collectionId: props.id });
  }, [props.id, screen.nav.goTo]);

  const onPressMoveUp = useCallback(() => {
    Haptics.feedback("itemStatusChanged");
    props.editMode?.moveCollectionUp(props.groupIndex, props.index);
  }, [props.editMode, props.groupIndex, props.index]);

  const onPressMoveDown = useCallback(() => {
    Haptics.feedback("itemStatusChanged");
    props.editMode?.moveCollectionDown(props.groupIndex, props.index);
  }, [props.editMode, props.groupIndex, props.index]);

  /**
   * This is handled in the parent component because we have to pass in the entire group set / draft to a different
   * screen so that the user can select. This would essentially tie every individual item's rendering to the entire
   * list, which we don't want.
   */
  const onPressChangeGroup = useCallback(() => {
    props.onPressChangeGroup?.(props.id, props.name);
  }, [props.onPressChangeGroup, props.id, props.name]);

  const openRecipeCollectionOptionsSheet = useRecipeCollectionOptionsSheet({
    id: props.id,
    collectionName: props.name,
    collectionType: props.collectionType,
    collectionSource: props.collectionSource,
    onPressChangeGroup,
    canRename: props.canRename,
    canDelete: props.canDelete,
    canHide: props.canHide,
    hidden: props.hidden,
    editMode: props.editMode,
  });

  return (
    <Pressable onPress={onPressCollection} singlePress noFeedback disabled={!!props.editMode}>
      <View
        style={[
          styles.card,
          props.editMode ? { height: editModeRecipeCollectionCardHeight } : {},
          props.isActive ? { backgroundColor: globalStyleColors.colorAccentMid } : {},
          props.marginHorizontal === false ? {} : { marginHorizontal: globalStyleConstants.minPadding },
          props.hidden ? { backgroundColor: globalStyleColors.colorGreyLight } : {},
        ]}
      >
        {!!props.editMode && (
          <>
            <Spacer horizontal={4} unit="pixels" />
            <ListItemReorderControl
              onPressMoveUp={onPressMoveUp}
              onPressMoveDown={onPressMoveDown}
              canMoveUpDisabled={props.canMoveUpDisabled}
              canMoveDownDisabled={props.canMoveDownDisabled}
            />
            <Spacer horizontal={1} />
          </>
        )}
        {!props.editMode && <PhotoGrid images={props.images} hidden={props.hidden} isActive={props.isActive} />}
        <Spacer horizontal={2} />
        <CollectionPrimaryDetails
          title={props.name}
          recipeCount={props.recipeCount}
          collectionType={props.collectionType}
          collectionSource={props.collectionSource}
          showCollectionType={!!props.showCollectionType || !!props.editMode}
          hidden={props.hidden}
          editMode={!!props.editMode}
          createdByPhoto={createdByOtherUser?.photo}
          createdByUsername={createdByOtherUser?.username}
        />
        {!!props.editMode && (
          <>
            <Spacer horizontal={1} />
            <MoreMenu onPress={openRecipeCollectionOptionsSheet} />
            <Spacer horizontal={4} unit="pixels" />
          </>
        )}
      </View>
    </Pressable>
  );
});

interface PhotoGridProps {
  images: PhotoRef[];
  hidden?: boolean;
  isActive?: boolean;
}

const PhotoGrid = React.memo((props: PhotoGridProps) => {
  const separatorStyleProps = { color: "white", lineWidth: 2 };

  const renderSmallPhoto = (index: number) => {
    const getPlaceholderType = (): PhotoPlaceholderType => {
      switch (index) {
        case 0: {
          return "topLeftToBottomRight";
        }
        case 1: {
          return "topRightToBottomLeft";
        }
        case 2: {
          return "topLeftToBottomRight";
        }
        case 3: {
          return "topRightToBottomLeft";
        }
        default: {
          return "default";
        }
      }
    };

    return (
      <View style={{ flex: 1, aspectRatio: 1 }}>
        <Photo style="flexed" sourceSize="w1290" source={props.images[index]} placeholderStyle={getPlaceholderType()} />
      </View>
    );
  };

  const renderRow = (index: number) => {
    return (
      <View style={styles.photoRow}>
        {renderSmallPhoto(2 * index)}
        <Separator {...separatorStyleProps} orientation="column" />
        {renderSmallPhoto(2 * index + 1)}
      </View>
    );
  };

  const render4Grid = () => {
    return (
      <>
        {renderRow(0)}
        <Separator {...separatorStyleProps} orientation="row" />
        {renderRow(1)}
      </>
    );
  };

  return (
    <View style={[styles.photoGrid, props.hidden || props.isActive ? { opacity: Opacity.medium } : {}]}>
      {render4Grid()}
      {/* Moved hidden icon to title but leaving here in case we bring back photo */}
      {/* {props.hidden && (
        <>
          <View style={[StyleSheet.absoluteFill, { backgroundColor: "black", opacity: Opacity.medium }]} />
          <View
            style={{ position: "absolute", left: globalStyleConstants.unitSize, top: globalStyleConstants.unitSize }}
          >
            <IconHide color={"white"} strokeWidth={2} />
          </View>
        </>
      )} */}
    </View>
  );
});

const CollectionPrimaryDetails = React.memo(
  (props: {
    title: string;
    recipeCount: number;
    collectionType: Props["collectionType"];
    collectionSource: Props["collectionSource"];
    showCollectionType: boolean;
    createdByPhoto?: PhotoRef;
    createdByUsername?: Username;
    hidden?: boolean;
    editMode: boolean;
  }) => {
    const collectionTypeText = switchReturn(props.collectionSource, {
      system:
        props.collectionType === "filter"
          ? strings.collectionSourceContext.systemFilter
          : strings.collectionSourceContext.systemTag,
      household: strings.collectionSourceContext.household(props.createdByUsername),
      user: strings.collectionSourceContext.user,
    });

    return (
      <View style={[styles.primaryDetailsWrap, props.hidden ? { opacity: Opacity.medium } : {}]}>
        <View style={styles.primaryDetails}>
          {props.showCollectionType && !!collectionTypeText && (
            <>
              <CollectionType text={collectionTypeText} />
              <Spacer vertical={0.5} />
            </>
          )}
          <CollectionTitle title={props.title} editMode={props.editMode} hidden={props.hidden} />
          <RecipeCount count={props.recipeCount} />
        </View>
      </View>
    );
  }
);

const CollectionTitle = React.memo(
  (props: {
    title: string;
    titleStyle?: "regular" | "large" | "regularBold" | "largeBold";
    editMode: boolean;
    hidden?: boolean;
  }) => {
    const getFontProps = (): Pick<TextProps, "fontSize"> & Partial<TextProps> => {
      const fontSize: TextProps["fontSize"] = "body";
      let scale: TextProps["scale"] = undefined;
      let fontWeight: TextProps["fontWeight"] = undefined;

      if (!props.titleStyle) {
        return { fontSize, fontWeight };
      }

      const largeFontScale = 1.15;

      switch (props.titleStyle) {
        case "large": {
          scale = largeFontScale;
          break;
        }
        case "largeBold": {
          scale = largeFontScale;
          fontWeight = "medium";
          break;
        }
        case "regular":
          break;
        case "regularBold": {
          fontWeight = "medium";
          break;
        }
        default:
          bottomThrow(props.titleStyle);
      }

      return { fontSize, fontWeight, scale, enableFontScaling: scale ? "upOnly" : undefined };
    };

    return (
      <View style={{ flexDirection: "row", alignItems: "center" }}>
        {!!props.hidden && (
          <>
            <IconHide size={20} color={globalStyleColors.rgba("black", "light")} strokeWidth={2} />
            <Spacer horizontal={0.5} />
          </>
        )}
        <Text {...getFontProps()} numberOfLines={props.editMode ? 1 : 2}>
          {props.title}
        </Text>
      </View>
    );
  }
);

const RecipeCount = React.memo((props: { count: number }) => {
  return <TSecondary opacity="light">{strings.recipeCount(props.count)}</TSecondary>;
});

const CollectionType = React.memo((props: { text: string }) => {
  return (
    <TSecondary opacity="light" numberOfLines={1}>
      {props.text}
    </TSecondary>
  );
});

export const MoreMenu = React.memo((props: { onPress: () => void }) => {
  const onPress = useCallback(() => {
    props.onPress();
  }, [props.onPress]);

  return (
    <Pressable onPress={onPress}>
      <IconMoreVertical color={globalStyleColors.colorAccentCool} />
    </Pressable>
  );
});

function useRecipeCollectionOptionsSheet(args: RecipeCollectionOptionsProps) {
  const screen = useScreen();

  const openRecipeCollectionOptionsSheet = useCallback(() => {
    screen.nav.modal(navTree.get.screens.bottomSheet, {
      content: <RecipeCollectionOptions {...args} />,
      height: getRecipeCollectionOptionsHeight({
        canRename: args.canRename,
        canDelete: args.canDelete,
        canHide: args.canHide,
        hidden: args.hidden,
      }),
    });
  }, [screen.nav.modal, args]);

  return openRecipeCollectionOptionsSheet;
}

function getRecipeCollectionOptionsHeight(args: {
  canRename: boolean;
  canDelete: boolean;
  canHide: boolean;
  hidden: boolean;
}) {
  let itemCount = 1;

  if (args.canRename) {
    itemCount += 1;
  }
  if (args.canDelete) {
    itemCount += 1;
  }
  if (args.canHide) {
    // Whether the option is hide/unhide, it's always going to be just one of them
    itemCount += 1;
  }

  return getOptionsMenuHeight(itemCount, true);
}

interface RecipeCollectionOptionsProps {
  id: RecipeCollectionId;
  collectionName: string;
  canRename: boolean;
  canDelete: boolean;
  canHide: boolean;
  hidden: boolean;
  collectionType: Props["collectionType"];
  collectionSource: Props["collectionSource"];
  onPressChangeGroup: () => void;
  editMode?: Pick<
    RecipeCollectionsEditMode,
    "renameCollection" | "hideCollection" | "unhideCollection" | "deleteCollection"
  >;
}

const RecipeCollectionOptions = React.memo((props: RecipeCollectionOptionsProps) => {
  const bottomSheet = useBottomSheet();

  const [waitingHide, setWaitingHide] = useState(false);
  const [waitingUnhide, setWaitingUnhide] = useState(false);
  const [waitingDelete, setWaitingDelete] = useState(false);

  const waiting = waitingHide || waitingHide || waitingDelete;

  const onPressMoveSection = useCallback(() => {
    bottomSheet?.closeSheetAndGoBack();
    props.onPressChangeGroup();
  }, [bottomSheet, props.onPressChangeGroup]);

  // This is awaited by the recipeCollectionRename sheet on submit, which has its own waiting logic surrounding
  // the submit call. This sheet will already be closed by then.
  const onSubmitRename = useCallback(
    async (newName: string) => {
      await props.editMode?.renameCollection(props.id, props.collectionName, newName);
    },
    [props.editMode, props.id, props.collectionName]
  );

  const onPressRename = useRenameRecipeCollection({ collectionId: props.id, renameCollection: onSubmitRename });

  const onPressHide = useCallback(async () => {
    Haptics.feedback("itemStatusChanged");
    setWaitingHide(true);
    await props.editMode?.hideCollection(props.id);
    setWaitingUnhide(false);
    bottomSheet?.closeSheetAndGoBack();
  }, [bottomSheet, props.editMode, props.id, setWaitingHide]);

  const onPressUnhide = useCallback(async () => {
    Haptics.feedback("itemStatusChanged");
    setWaitingUnhide(true);
    await props.editMode?.unhideCollection(props.id);
    setWaitingHide(false);
    bottomSheet?.closeSheetAndGoBack();
  }, [bottomSheet, props.editMode, props.id, setWaitingUnhide]);

  const onPressDelete = useDeleteCollection({
    collectionId: props.id,
    deleteCollection: props.editMode?.deleteCollection,
    setWaitingDelete,
  });

  return (
    <OptionsMenu
      header={
        <RecipeCollectionContextMenuTitle
          collectionName={props.collectionName}
          collectionSource={props.collectionSource}
        />
      }
    >
      {props.canRename && (
        <OptionsMenuItem isFirst icon={"editPencil"} text={strings.collectionOptions.rename} onPress={onPressRename} />
      )}
      <OptionsMenuItem
        isFirst={!props.canRename}
        icon={"sort"}
        text={strings.collectionOptions.moveSection}
        onPress={onPressMoveSection}
      />
      {props.canHide && !props.hidden && (
        <OptionsMenuItem
          icon={"hide"}
          text={strings.collectionOptions.hide}
          onPress={onPressHide}
          waiting={waitingHide}
          disabled={waiting}
        />
      )}
      {props.hidden && (
        <OptionsMenuItem
          icon={"unhide"}
          text={strings.collectionOptions.unhide}
          onPress={onPressUnhide}
          waiting={waitingUnhide}
          disabled={waiting}
        />
      )}
      {props.canDelete && (
        <OptionsMenuItem
          icon={"delete"}
          text={strings.collectionOptions.delete}
          onPress={onPressDelete}
          waiting={waitingDelete}
          disabled={waiting}
        />
      )}
    </OptionsMenu>
  );
});

const styles = StyleSheet.create({
  card: {
    height: recipeCollectionCardHeight,
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "white",
    borderRadius: globalStyleConstants.defaultBorderRadius,
    padding: globalStyleConstants.minPadding,
    ...globalStyles.shadowItem,
  },
  photoGrid: {
    aspectRatio: 1,
    height: "100%",
    borderRadius: globalStyleConstants.unitSize,
    overflow: "hidden",
  },
  photoRow: {
    flex: 1,
    flexDirection: "row",
  },
  primaryDetailsWrap: {
    flexGrow: 1,
    flexShrink: 1,
    height: "100%",
    justifyContent: "center",
  },
  primaryDetails: {
    flexShrink: 1,
    paddingVertical: globalStyleConstants.minPadding / 2,
  },
});

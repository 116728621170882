import { isTagMatch } from "@eatbetter/recipes-shared/src/RecipeTagTypes";
import React, { useCallback } from "react";
import { StyleSheet, View } from "react-native";
import { globalStyleColors, globalStyleConstants } from "../GlobalStyles";
import { IconPlus } from "../Icons";
import { Pressable } from "../Pressable";
import { SectionHeading } from "../SectionHeading";
import { SelectableOval, SelectableOvalRenderBadge } from "../SelectableOval";
import { Spacer } from "../Spacer";
import { TSecondary } from "../Typography";
import {
  CollectionTagOrFilterGroup,
  getRecipeTagOrFilterKey,
  RecipeTagOrFilter,
  RecipeTagOrFilterKey,
} from "../../lib/composite/CollectionsSelectors.ts";
import { isFilterMatch } from "../../lib/recipes/AppFilterCollections.ts";
import { knownCollectionGroupIds } from "@eatbetter/recipes-shared";
import { bottomWithDefault } from "@eatbetter/common-shared";

const strings = {
  addNewTag: "Add New",
  emptySection: "No collections.",
};

interface TagSelectControlProps {
  groups: CollectionTagOrFilterGroup[];
  selected: RecipeTagOrFilter[];
  onPressTag: (tag: RecipeTagOrFilter) => void;
  waiting?: Record<RecipeTagOrFilterKey, boolean>;
  onPressAddTag?: () => void;
}

export const RecipeTagSelect = React.memo((props: TagSelectControlProps) => {
  const renderLastItem = useCallback(() => {
    return <>{!!props.onPressAddTag && <AddNewTagButton onPress={props.onPressAddTag} />}</>;
  }, [props.onPressAddTag]);

  return (
    <View>
      {props.groups.map((group, idx) => {
        const isPinned = group.groupId === knownCollectionGroupIds.pinned;
        return (
          <TagSection
            key={group.groupName}
            isFirst={idx === 0}
            sectionTitle={group.groupName}
            options={group.tagsOrFilters}
            selected={props.selected}
            onPressTag={props.onPressTag}
            renderLastItem={isPinned ? renderLastItem : undefined}
            waiting={props.waiting}
          />
        );
      })}
      <Spacer vertical={2} />
    </View>
  );
});

const AddNewTagButton = React.memo((props: { onPress: () => void }) => {
  return (
    <Pressable style={styles.addNewTagButton} onPress={props.onPress}>
      <IconPlus opacity="medium" strokeWidth={2.5} size={18} />
      <Spacer horizontal={0.25} />
      <TSecondary fontWeight="medium" opacity="medium">
        {strings.addNewTag}
      </TSecondary>
    </Pressable>
  );
});

const EmptyTagSection = React.memo(() => {
  return (
    <View style={styles.emptySection}>
      <TSecondary opacity="light">{strings.emptySection}</TSecondary>
    </View>
  );
});

const TagSection = React.memo(
  (props: {
    isFirst: boolean;
    sectionTitle: string;
    options: RecipeTagOrFilter[];
    selected: RecipeTagOrFilter[];
    onPressTag: (tag: RecipeTagOrFilter) => void;
    waiting?: Record<RecipeTagOrFilterKey, boolean>;
    renderLastItem?: () => React.ReactElement;
  }) => {
    return (
      <>
        {!props.isFirst && <Spacer vertical={2} />}
        <SectionHeading text={props.sectionTitle} noPadding />
        <Spacer vertical={1} />
        {props.options.length === 0 && <EmptyTagSection />}
        {props.options.length > 0 && (
          <SelectableTagGroup
            options={props.options}
            selected={props.selected}
            onPressTag={props.onPressTag}
            waiting={props.waiting}
            renderLastTime={props.renderLastItem}
          />
        )}
      </>
    );
  }
);

export const SelectableTagGroup = React.memo(
  (props: {
    options: RecipeTagOrFilter[];
    selected: RecipeTagOrFilter[];
    onPressTag: (tag: RecipeTagOrFilter) => void;
    waiting?: Record<RecipeTagOrFilterKey, boolean>;
    renderTagBadge?: SelectableOvalRenderBadge;
    renderLastTime?: () => React.ReactElement;
  }) => {
    return (
      <View style={styles.sectionWrap}>
        {props.options.map(i => {
          const key = getRecipeTagOrFilterKey(i);
          const selected = tagOrFilterIsSelected(i, props.selected);
          const isWaiting = props.waiting?.[key];

          return (
            <View key={key} style={styles.tagWrap}>
              <SelectableTag
                tag={i}
                onPress={props.onPressTag}
                waiting={isWaiting}
                isSelected={selected}
                renderBadge={props.renderTagBadge}
              />
            </View>
          );
        })}
        {props.renderLastTime?.()}
      </View>
    );
  }
);

export const SelectableTag = React.memo(
  (props: {
    /**
     * If the countRecipes property is not undefined, the tag will be disabled if countRecipes === 0;
     */
    tag: RecipeTagOrFilter;
    onPress: (tag: RecipeTagOrFilter) => void;
    isSelected: boolean;
    waiting?: boolean;
    disabled?: boolean;
    renderBadge?: SelectableOvalRenderBadge;
  }) => {
    const onPress = useCallback(() => {
      props.onPress(props.tag);
    }, [props.tag, props.onPress]);

    const text = getText(props.tag, !!props.renderBadge);
    // never disable because of zero count if it's selected. There are cases where a user can end up with zero results
    // with the selected filters and they need to be able to deselect.
    // For example, add some tags, then add a search query that results in zero results, and then try to remove a tag.
    const disableBecauseOfCount =
      !props.isSelected && props.tag.countRecipes !== undefined ? props.tag.countRecipes === 0 : false;

    return (
      <SelectableOval
        text={text}
        isSelected={props.isSelected}
        onPress={onPress}
        waiting={props.waiting}
        disabled={props.disabled || props.waiting || disableBecauseOfCount}
        renderBadge={props.renderBadge}
      />
    );
  }
);

function getText(t: RecipeTagOrFilter, useFilterAppliedName: boolean): string {
  if (t.type === "tag") {
    return t.tag.display;
  } else if (useFilterAppliedName) {
    return t.filter.appliedName ?? t.filter.filterName;
  } else {
    return t.filter.filterName;
  }
}

export function tagOrFilterIsSelected(t: RecipeTagOrFilter, selected: RecipeTagOrFilter[]): boolean {
  switch (t.type) {
    case "tag":
      return selected.some(s => s.type === "tag" && isTagMatch(s.tag.tag, t.tag.tag));
    case "filter":
      return selected.some(s => s.type === "filter" && isFilterMatch(s.filter, t.filter));
    default:
      return bottomWithDefault(t, false, "RecipeTagSelect.isSelected");
  }
}

const styles = StyleSheet.create({
  addNewTagButton: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: "auto",
    height: 32,
    borderRadius: 16,
    paddingLeft: 0.75 * globalStyleConstants.unitSize,
    paddingRight: 1.25 * globalStyleConstants.unitSize,
    borderColor: globalStyleColors.rgba("black", "medium"),
    borderWidth: 0.5,
  },
  emptySection: {
    paddingBottom: globalStyleConstants.unitSize,
    width: "auto",
  },
  sectionWrap: {
    flexDirection: "row",
    flexWrap: "wrap",
  },
  tagWrap: {
    marginBottom: 8,
    marginRight: 8,
  },
});

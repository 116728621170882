import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { ScreenView } from "../components/ScreenView";
import { useScreen, withScreenContainer } from "../navigation/ScreenContainer";
import { OtherUserProfileScreenProps, navTree } from "../navigation/NavTree";
import { UserId } from "@eatbetter/common-shared";
import { useProfileInfo } from "../lib/social/SocialSelectors";
import { otherUserProfileMounted, otherUserProfileUnmounted, SocialFeedType } from "../lib/social/SocialSlice";
import { useDispatch } from "../lib/redux/Redux";
import { loadNewProfilePosts, loadOlderProfilePosts, navToEntityScreen } from "../lib/social/SocialThunks";
import { displayUnexpectedErrorAndLog } from "../lib/Errors";
import { log } from "../Log";
import { SocialFeed, SocialFeedImperativeHandle } from "../components/social/SocialFeed";
import { FlexedSpinner } from "../components/Spinner";
import { HeaderProps } from "../components/ScreenHeaders";
import {
  reportOtherUserProfileViewed,
  reportProfileCookedCountPressed,
  reportProfileFollowingCountPressed,
  reportProfileRecipeCountPressed,
} from "../lib/analytics/AnalyticsEvents";
import { analyticsEvent } from "../lib/analytics/AnalyticsThunks";
import { ProfileCard } from "../components/social/ProfileCard";

const strings = {
  title: "Profile",
  emptyState: (name: string) => `${name} hasn't posted anything yet `,
};

export const OtherUserProfileScreen = withScreenContainer(
  "OtherUserProfileScreen",
  (props: OtherUserProfileScreenProps) => {
    const dispatch = useDispatch();
    const screen = useScreen();
    const feedType = useRef<SocialFeedType>({ type: "otherUserProfileFeed", userId: props.userId }).current;
    const feedRef = useRef<SocialFeedImperativeHandle>(null);

    const [navChecked, setNavChecked] = useState(false);
    const profileInfo = useProfileInfo(props.userId);

    // "redirect" to known screen if user is associated with a known entity
    useEffect(() => {
      if (profileInfo === undefined) {
        return;
      }

      // if there is no known ID, render
      if (!profileInfo.knownId) {
        setNavChecked(true);
      } else {
        // we were seeing a glitch during navigation animation if we fired this too fast. Add a short timeout.
        setTimeout(
          () => dispatch(navToEntityScreen(profileInfo.knownId!, screen.nav, "knownUserAuthorRedirect", "replace")),
          100
        );
      }
    }, [profileInfo, setNavChecked, screen.nav]);

    useEffect(() => {
      dispatch(otherUserProfileMounted(props.userId));
      dispatch(loadNewProfilePosts(props.userId)).catch(err => {
        displayUnexpectedErrorAndLog("Error calling loadNewProfilePosts", err);
      });

      return () => {
        dispatch(otherUserProfileUnmounted(props.userId));
      };
    }, [dispatch, props.userId]);

    const alreadyReportedProfileView = useRef(false);

    useEffect(() => {
      if (!alreadyReportedProfileView.current && profileInfo && screen.nav.focused) {
        const event = reportOtherUserProfileViewed({ user: profileInfo.user });
        dispatch(analyticsEvent(event));
        alreadyReportedProfileView.current = true;
      }
    }, [!!profileInfo, screen.nav.focused]);

    // NYI
    // const onPressShareProfile = useCallback(() => {
    //   // NYI: Share profile
    // }, []);

    const onPressRecipes = useCallback(() => {
      // Do nothing for now but report it
      dispatch(analyticsEvent(reportProfileRecipeCountPressed("otherUserProfile")));
    }, [dispatch]);

    const onPressCooks = useCallback(() => {
      dispatch(analyticsEvent(reportProfileCookedCountPressed("otherUserProfile")));
      feedRef.current?.scrollToFirstPost();
    }, [dispatch, feedRef.current]);

    const onPressFollowing = useCallback(() => {
      dispatch(analyticsEvent(reportProfileFollowingCountPressed("otherUserProfile")));
      screen.nav.goTo("push", navTree.get.screens.otherUserFollowing, { userId: props.userId });
    }, [dispatch, screen.nav.goTo, props.userId]);

    const onPullToRefresh = useCallback(() => {
      return dispatch(loadNewProfilePosts(props.userId)).catch(err => {
        displayUnexpectedErrorAndLog("Error calling loadNewProfilePosts pullToRefresh", err);
      });
    }, [dispatch, props.userId]);

    const onEndReached = useCallback(() => {
      dispatch(loadOlderProfilePosts(props.userId)).catch(err => {
        // don't display an error for this one.
        log.errorCaught(`Error calling loadOlderProfilePosts for ${feedType}`, err);
      });
    }, [dispatch, props.userId, feedType]);

    const emptyStateMessage = useMemo(() => {
      if (!profileInfo) {
        return "";
      }

      return strings.emptyState(profileInfo.user.name.trim().split(/\s+/)[0] ?? profileInfo.user.username);
    }, [profileInfo]);

    const headerComponent = useMemo(
      () => (
        <>
          {!!profileInfo && (
            <ProfileCard
              otherUserId={profileInfo.user.userId}
              photo={profileInfo.user.photo}
              name={profileInfo.user.name}
              username={profileInfo.user.username}
              profileBio={profileInfo.user.profileBio}
              profileLinks={{ links: profileInfo.user.profileLinks.map(i => i.url) }}
              primaryStats={[
                {
                  type: "recipes",
                  value: profileInfo.countRecipes,
                  valueIsMax: profileInfo.countRecipesIsMax,
                  onPress: onPressRecipes,
                },
                { type: "cooks", value: profileInfo.countCooks, onPress: onPressCooks },
                { type: "following", value: profileInfo.countFollowing, onPress: onPressFollowing },
              ]}
            />
          )}
        </>
      ),
      [profileInfo, onPressRecipes, onPressCooks, onPressFollowing]
    );

    const header = useMemo<HeaderProps>(() => {
      return {
        type: "custom",
        style: "default",
        title: strings.title,
        // NYI right: { type: "share", onPress: onPressShareProfile },
      };
    }, []);

    return (
      <ScreenView header={header} paddingHorizontal={false} paddingVertical={false} scrollView={false}>
        {(!profileInfo || !navChecked) && <FlexedSpinner />}
        {!!profileInfo && navChecked && (
          <SocialFeed
            ref={feedRef}
            feed={feedType}
            onEndReached={onEndReached}
            onPullToRefresh={onPullToRefresh}
            headerComponent={headerComponent}
            feedEmptyStateComponent={emptyStateMessage}
            paddingTop={"headerHeight"}
            paddingBottom={"bottomTabBarHeight"}
          />
        )}
      </ScreenView>
    );
  },
  {
    serializer: {
      userId: s => s,
    },
    parser: {
      userId: s => s as UserId,
    },
  }
);

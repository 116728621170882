import { PhotoRef } from "@eatbetter/photos-shared";
import {
  allRecipesCollectionId,
  AppCollectionManifest,
  AppRecipeCollection,
  AppUserRecipe,
  CollectionGroupDisplay,
  cookingAndShoppedCollectionId,
  getRecipeTagForCollection,
  RecipeCollectionGroupId,
  RecipeCollectionId,
  RecipeTag,
  RecipeTagId,
  SystemRecipeTag,
  UserRecipeId,
} from "@eatbetter/recipes-shared";
import { getCreateSelectorWithCacheSize } from "../redux/CreateSelector.ts";
import { useSelector } from "../redux/Redux.ts";
import { arrayMap, bottomThrow, TypedPrimitive } from "@eatbetter/common-shared";
import {
  AppFilterCollectionMeta,
  getAddedByFilters,
  getAppFilterCollectionMeta,
  getCookedFilters,
  getFilterPredicate,
  getFiltersPredicate,
  getRatingFilters,
  getTotalTimeFilters,
  RecipeFilter,
} from "../recipes/AppFilterCollections.ts";
import { RootState } from "../redux/RootReducer.ts";
import { RecipeFilters, selectLibraryFilterSession } from "../recipes/RecipesSlice.ts";
import { selectRecipe, selectRecipesById } from "../recipes/RecipesSelectors.ts";
import { selectCookingAndShoppedPredicate, selectRecipeFilterPredicate } from "./RecipeListSelectors.ts";
import {
  selectCollectionsById,
  selectFilters,
  selectGetCollectionByTag,
  selectKnownAndNonDeletedCollections,
  useDeferredFilters,
} from "./SharedRecipeAndCollectionSelectors.ts";
import {
  isGlobalSearchSessionId,
  LibraryFilterSessionId,
  LibraryOrSearchSessionId,
} from "./LibraryAndSearchSessionIds.ts";
import { useRef } from "react";
import { DeglazeUser } from "@eatbetter/users-shared";

/*************************************
 * EXPORTED HOOKS AND SELECTORS
 *************************************/

export const useCollections = () => useSelector(selectCollectionsData);

/**
 * Get tags and filters to display on the filter screen.
 */
export const useTagsAndFiltersForFiltering = (sessionId: LibraryOrSearchSessionId): CollectionTagOrFilterGroup[] => {
  const { filters } = useDeferredFilters(sessionId);

  return useSelector(s => {
    if (isGlobalSearchSessionId(sessionId)) {
      return selectGlobalTagsAndFiltersForFiltering(s);
    } else {
      return selectUserTagsAndFiltersForFiltering(s, filters);
    }
  });
};

/**
 * Get tags to display on the recipe tag edit screen
 */
export const useTagsForEditing = (): CollectionTagOrFilterGroup<RecipeTagAndType>[] =>
  useSelector(selectUserTagsForEditing);

/**
 * Get the list of active tags and filters being used to filter. If sessionId is undefined, an empty array is returned.
 */
export const useActiveTagsAndFilters = (sessionId: LibraryOrSearchSessionId | undefined): RecipeTagOrFilter[] => {
  const stableEmpty = useRef([]).current;
  return useSelector(s => {
    if (!sessionId) {
      return stableEmpty;
    }

    return selectActiveTagsAndFilters(s, sessionId);
  });
};

export const useLibraryFilterSessionCollectionToInclude = (sessionId: LibraryFilterSessionId) =>
  useSelector(s => {
    const c = selectLibraryFilterSession(s.recipes, sessionId)?.filters.collection;
    if (c?.action === "include") {
      return c.collectionId;
    } else {
      return undefined;
    }
  });

/**
 * Get the canonical tags for a given recipe.
 */
export const useRecipeTags = (id: UserRecipeId): RecipeTagAndType[] => useSelector(s => selectRecipeTags(s, id));

/**
 * Get suggestions to display on the filter bar
 */
export const useFilterSuggestions = (): RecipeTagOrFilter[] => useSelector(selectFilterSuggestions);

export const useCollectionExists = (collectionId: RecipeCollectionId): boolean => {
  return useSelector(s => {
    const collection = selectCollectionsById(s)[collectionId];
    return !!collection;
  });
};

/**
 * Get the name for a given collection
 */
export const useCollectionName = (collectionId: RecipeCollectionId) => {
  return useSelector(s => {
    const collection = selectCollectionsById(s)[collectionId];
    return collection?.name ?? "Recipe Collection";
  });
};

export const useCollectionSource = (collectionId: RecipeCollectionId) => {
  return useSelector(s => {
    const collection = selectCollectionsById(s)[collectionId];
    return collection?.source ?? "user";
  });
};

export const useCollectionType = (collectionId: RecipeCollectionId) => {
  return useSelector(s => {
    const collection = selectCollectionsById(s)[collectionId];
    return collection?.type ?? "filter";
  });
};

export const useCollectionHidden = (collectionId: RecipeCollectionId) => {
  return useSelector(s => {
    const collection = selectCollectionsById(s)[collectionId];
    return !!collection?.hidden;
  });
};

export const useCollectionCanRename = (collectionId: RecipeCollectionId) => {
  return useSelector(s => {
    const collection = selectCollectionsById(s)[collectionId];
    return !!collection?.canRename;
  });
};

export const useCollectionCanHide = (collectionId: RecipeCollectionId) => {
  return useSelector(s => {
    const collection = selectCollectionsById(s)[collectionId];
    return !!collection?.canHide;
  });
};

export const useCollectionCanDelete = (collectionId: RecipeCollectionId) => {
  return useSelector(s => {
    const collection = selectCollectionsById(s)[collectionId];
    return !!collection?.canDelete;
  });
};

// Returns the DeglazeUser for the collection creator if it was someone other than the authed user in the household
export const useCollectionCreatedByOtherUser = (
  collectionId: RecipeCollectionId,
  createdById?: string
): DeglazeUser | undefined => {
  return useSelector(s => {
    const creatorUserId = createdById ?? selectCollectionsById(s)[collectionId]?.creatorUserId;

    if (!creatorUserId) {
      return undefined;
    }

    const authedUserId = s.system.authedUser.data?.userId;
    const household = s.system.authedUser.data?.household;

    // Find the creator in the household, ensuring it's not the authed user
    return household?.find(i => i.userId !== authedUserId && i.userId === creatorUserId);
  });
};

export type RecipeTagOrFilterKey = TypedPrimitive<string, "tagOrFilterKey">;

/**
 * Given a RecipeTagOrFilter, produce a unique key
 * @param t
 */
export function getRecipeTagOrFilterKey(t: RecipeTagOrFilter): RecipeTagOrFilterKey {
  switch (t.type) {
    case "tag":
      return `${t.tag.tag.type}:${t.tag.tag.tag}` as RecipeTagOrFilterKey;
    case "filter":
      return `${t.filter.filterType}:${t.filter.filterName}` as RecipeTagOrFilterKey;
    default:
      bottomThrow(t);
  }
}

/*************************************
 * EXPORTED TYPES
 *************************************/

/**
 * Tags or filters using on the filter screen for the user to choose how to filter a list of recipes
 */
export interface CollectionTagOrFilterGroup<T extends RecipeTagOrFilter = RecipeTagOrFilter> {
  groupName: string;
  groupId?: RecipeCollectionGroupId;
  tagsOrFilters: T[];
}

/**
 * Type used in filter controls to specify tag or filter
 */
export type RecipeTagOrFilter = RecipeTagAndType | RecipeFilterAndType;
export interface RecipeTagAndType {
  type: "tag";
  tag: RecipeTagAndDisplay;
  /**
   * If defined, this drives if tags are enabled on the filter screen.
   * See RecipeTagSelect.ts
   */
  countRecipes?: number;
}

export interface RecipeFilterAndType {
  type: "filter";
  filter: RecipeFilter;

  /**
   * If defined, this drives if tags are enabled on the filter screen.
   * See RecipeTagSelect.ts
   */
  countRecipes?: number;
}

export interface RecipeTagAndDisplay {
  /**
   * Tag info for applying the tag to a recipe
   */
  tag: RecipeTag;

  /**
   * Display name for the tag/collection
   */
  display: string;

  collectionId: RecipeCollectionId;
}

export interface CollectionGroupData {
  group: CollectionGroupDisplay;
  /**
   * If false, this group has no collections that should rendered in the default view.
   * The data is still returned so it can be rendered in edit view.
   */
  render: boolean;
  collections: CollectionData[];
}

export interface CollectionData {
  collection: AppRecipeCollection;
  /**
   * If false, this collection has no recipes and displayIfEmpty is false, or the collection is hidden.
   * The collection is still returned so it can be rendered in edit view.
   */
  render: boolean;
  display: {
    recipeCount: number;
    images: PhotoRef[];
  };
}

/*************************************
 * INTERNAL SELECTORS
 *************************************/

const selectRecipeTags = getCreateSelectorWithCacheSize(10)(
  [
    (s, recipeId: UserRecipeId) => selectRecipe(s, recipeId)?.tags,
    s => selectGetCollectionByTag(s),
    s => selectGetCanonicalTagAndType(s),
  ],
  (recipeTags, getCollectionByTag, getCanonicalTagAndType) => {
    const sortedTags = (recipeTags ?? [])
      .filter(i => {
        // Filter our hidden and deleted collections
        const collection = getCollectionByTag(i);
        return !collection?.hidden && !collection?.deleted;
      })
      .sort((a, b) => {
        if (a.type === "user" && b.type === "system") {
          return -1;
        } else if (a.type === "system" && b.type === "user") {
          return 1;
        } else {
          return 0;
        }
      });

    return sortedTags.flatMap<RecipeTagAndType>(t => getCanonicalTagAndType(t) ?? []);
  }
);

const selectCollectionsData = getCreateSelectorWithCacheSize(1)(
  [
    s => selectCollectionDataByCollectionId(s),
    s => selectKnownAndNonDeletedCollections(s),
    s => selectCollectionsById(s),
  ],
  (dataById, collectionsAndLayout, collectionsById) => {
    if (!collectionsAndLayout) {
      return undefined;
    }

    const { layout } = collectionsAndLayout;

    return layout.groups.flatMap<CollectionGroupData>(g => {
      let renderGroup = false;
      const collections = g.collections.flatMap<CollectionData>(c => {
        const collection = collectionsById[c.id];
        if (!collection) {
          return [];
        }

        const collectionData = dataById[collection.id];
        if (!collectionData) {
          return [];
        }

        if (collectionData?.render) {
          renderGroup = true;
        }

        return collectionData;
      });

      return {
        group: g,
        collections,
        render: renderGroup,
      };
    });
  }
);

/**
 * Used for the collection home screen - filters don't affect this
 */
const selectCollectionDataByCollectionId = getCreateSelectorWithCacheSize(1)(
  [
    s => selectKnownAndNonDeletedCollections(s),
    s => selectGetCollectionByTag(s),
    s => selectRecipesById(s),
    s => selectCookingAndShoppedPredicate(s),
  ],
  (collectionsAndLayout, getCollectionByTag, recipesById, activePredicate) => {
    return calculateCollectionData(collectionsAndLayout, recipesById, getCollectionByTag, () => true, activePredicate);
  }
);

/**
 * Used to determine what filters to display
 */
const selectCollectionDataByCollectionIdWithLibraryFilters = getCreateSelectorWithCacheSize(1)(
  [
    s => selectKnownAndNonDeletedCollections(s),
    s => selectGetCollectionByTag(s),
    s => selectRecipesById(s),
    (s, filters: RecipeFilters) => selectRecipeFilterPredicate(s, filters),
  ],
  (collectionsAndLayout, getCollectionByTag, recipesById, recipePredicate) => {
    // The active recipe (cooking in progress/recently shopped) collection is irrelevant for filters, so we pass
    // undefined for the predicate
    return calculateCollectionData(collectionsAndLayout, recipesById, getCollectionByTag, recipePredicate, undefined);
  }
);

function calculateCollectionData(
  collectionsAndLayout: AppCollectionManifest | undefined,
  recipesById: Record<UserRecipeId, AppUserRecipe>,
  getCollectionByTag: (t: RecipeTag) => AppRecipeCollection | undefined,
  recipePredicate: (id: AppUserRecipe) => boolean,
  cookingAndShoppedPredicate: ((id: AppUserRecipe) => boolean) | undefined
): Record<RecipeCollectionId, CollectionData> {
  const data: Record<RecipeCollectionId, CollectionData> = {};

  if (collectionsAndLayout) {
    const filterIdsAndMeta: Array<{ collectionId: RecipeCollectionId; meta: AppFilterCollectionMeta }> = [];
    const [recipesByCollectionId, addRecipe] = arrayMap<RecipeCollectionId, AppUserRecipe>();
    const { collections } = collectionsAndLayout;
    collections.forEach(c => {
      if (c.type === "filter") {
        // special case active recipes
        if (c.id === cookingAndShoppedCollectionId && cookingAndShoppedPredicate) {
          const meta: AppFilterCollectionMeta = { predicate: cookingAndShoppedPredicate };
          filterIdsAndMeta.push({ collectionId: c.id, meta });
        } else {
          const meta = getAppFilterCollectionMeta(c.id);
          if (meta) {
            filterIdsAndMeta.push({ collectionId: c.id, meta });
          }
        }
      }
    });

    const recipes = Object.values(recipesById);

    // sort by created desc so we can just take the first N recipes to get the most recent photos
    Object.values(recipes)
      .sort((a, b) => b.created - a.created)
      .forEach(r => {
        if (r.archived || r.deleted || !recipePredicate(r)) {
          return;
        }

        r.tags.forEach(t => {
          const collection = getCollectionByTag(t);
          if (collection) {
            addRecipe(collection.id, r);
          }
        });

        filterIdsAndMeta.forEach(f => {
          if (f.meta.predicate(r)) {
            addRecipe(f.collectionId, r);
          }
        });
      });

    // build data for each
    collections.forEach(c => {
      const recipes = recipesByCollectionId[c.id] ?? [];
      const imageCount = 4;

      // If we're processing All Recipes and there are more than 4 recipes, reverse the array for low recipe counts, or shift it if we have
      // enough recipes so we end up with a different set.
      const useAltImages = c.id === allRecipesCollectionId || c.id === "sc:tag:side";

      // if there are fewer than image count recipes, just reverse the order we process
      // if there are more, shift the array imageCount positions, so [1,2,3,4,5] becomes [5,1,2,3,4].
      // in large libraries, this will effectively skip the first imageCount recipes.
      // in small libraries, we'll make sure we still use the best images, just in a different order
      const recipesForImages = useAltImages
        ? recipes.length <= imageCount
          ? [...recipes].reverse()
          : shiftArrayLeft(recipes, imageCount)
        : recipes;

      const recipeImages: PhotoRef[] = [];
      const otherImages: PhotoRef[] = [];

      // go until we have 4 primary recipe images, or 20 other images (this limit is simply to avoid iterating over a large number of photoless recipes)
      for (let i = 0; i < recipesForImages.length && recipeImages.length < imageCount && otherImages.length < 20; i++) {
        const recipe = recipesForImages[i]!;
        if (recipe.photo) {
          recipeImages.push(recipe.photo);
        } else if (recipe.book?.photo) {
          otherImages.push(recipe.book.photo);
        } else if (recipe.source.type === "userPhoto") {
          const firstPhoto = recipe.source.photos[0];
          if (firstPhoto) {
            otherImages.push(firstPhoto);
          }
        }
      }

      const images =
        recipeImages.length === imageCount ? recipeImages : [...recipeImages, ...otherImages].slice(0, imageCount);

      const render = !c.hidden && (recipes.length > 0 || c.displayIfEmpty);

      data[c.id] = {
        collection: c,
        render,
        display: {
          recipeCount: recipes.length,
          images,
        },
      };
    });
  }

  return data;
}

// reorder an array for the purposes of finding photos
function shiftArrayLeft<T>(arr: T[], i: number): T[] {
  if (arr.length === 0) return arr; // Handle empty array
  i = i % arr.length; // Normalize shift in case i > arr.length
  return [...arr.slice(i), ...arr.slice(0, i)]; // Slice and concatenate
}

const selectUserTagsForEditing: (s: RootState) => CollectionTagOrFilterGroup<RecipeTagAndType>[] =
  getCreateSelectorWithCacheSize(1)(
    [s => selectKnownAndNonDeletedCollections(s), s => selectCollectionsById(s)],
    (collectionsData, collectionById) => {
      if (!collectionsData) {
        return [];
      }

      const { collections, layout } = collectionsData;

      const collectionsById: Record<RecipeCollectionId, AppRecipeCollection> = {};
      collections.forEach(c => (collectionsById[c.id] = c));

      const groups = layout.groups.flatMap<CollectionTagOrFilterGroup<RecipeTagAndType>>(g => {
        const tagsOrFilters = g.collections.flatMap<RecipeTagAndType>(c => {
          const collection = collectionById[c.id];
          // we currently only support tag collections here. Filter collections (like All Recipes, Recently Added) don't
          // show up here and we append any filters we want to the end
          if (!collection || collection.type !== "tag" || collection.hidden || collection.deleted) {
            return [];
          }

          const tag = getRecipeTagForCollection(collection);
          return tag ? { type: "tag", tag: { display: collection.name, tag, collectionId: collection.id } } : [];
        });

        if (tagsOrFilters.length === 0) {
          return [];
        }

        return {
          groupName: g.name,
          groupId: g.id,
          tagsOrFilters,
        };
      });

      return groups;
    }
  );

const selectUserTagsAndFiltersForFiltering: (s: RootState, filters: RecipeFilters) => CollectionTagOrFilterGroup[] =
  getCreateSelectorWithCacheSize(3)(
    [
      s => selectUserTagsForEditing(s),
      s => selectGetCollectionByTag(s),
      (s, filters: RecipeFilters) => selectCollectionDataByCollectionIdWithLibraryFilters(s, filters),
      (s, filters: RecipeFilters) => selectFiltersForFiltering(s, filters),
      (_s, filters: RecipeFilters) => filters.collection?.collectionId,
    ],
    (groups, getCollectionByTag, dataByCollectionId, filterGroups, collectionId) => {
      const tagGroups = groups.flatMap(group => {
        const tagsOrFilters = group.tagsOrFilters.flatMap(tof => {
          const c = getCollectionByTag(tof.tag.tag);
          if (!c || c.id === collectionId) {
            return [];
          }

          const data = dataByCollectionId[c.id];

          // we return data for all filters and disable based on the count.
          // we stil want to omit hidden collections
          return data && !data.collection.hidden
            ? {
                ...tof,
                countRecipes: data.display.recipeCount,
              }
            : [];
        });

        if (tagsOrFilters.length > 0) {
          return {
            ...group,
            tagsOrFilters,
          };
        } else {
          return [];
        }
      });

      return [...tagGroups, ...filterGroups];
    }
  );

const selectFiltersForFiltering: (s: RootState, filters: RecipeFilters) => CollectionTagOrFilterGroup[] =
  getCreateSelectorWithCacheSize(1)(
    [
      (s, filters: RecipeFilters) => selectRecipeFilterPredicate(s, selectRecipesFiltersWithoutFilters(s, filters)),
      s => selectRecipesById(s),
      s => selectHouseholdFilterGroup(s),
      (_s, filters: RecipeFilters) => filters.filters,
    ],
    (tagPredicate, recipesById, householdGroup, activeFilters) => {
      const totalTimeFilterGroup = getTotalTimeGroup();

      const ratingFilterGroup = {
        groupName: "Recipe Rating",
        tagsOrFilters: getRatingFilters().map<RecipeFilterAndType>(filter => ({ type: "filter", filter })),
      };

      const cookedFilterGroup = {
        groupName: "Cooked",
        tagsOrFilters: getCookedFilters().map<RecipeFilterAndType>(filter => ({ type: "filter", filter })),
      };

      const groups = [totalTimeFilterGroup, ratingFilterGroup, cookedFilterGroup, ...householdGroup];

      const tagRecipes = Object.values(recipesById).filter(r => !r.deleted && !r.archived && tagPredicate(r));

      const withCounts = groups.map(group => {
        const first = group.tagsOrFilters[0];
        const filterTypeToExclude = first?.type === "filter" ? first.filter.filterType : undefined;
        const filtersForGroupPredicate = activeFilters?.filter(f => f.filterType !== filterTypeToExclude) ?? [];
        // this is a predicate representing all of the filters *except* those with the filterType represented in this group.
        // this is necessary to provide accurate counts/active state on the filters page.
        // this makes sense because filters in a specific group can all be selected as long as there is at least one recipe
        // that matches the relevant option. For example, if a user has a tag selected and 2 filters: "not cooked" and "under 60 minutes,
        // then we find all recipes that match the tag and "not cooked" filters. These are the tagPredicate, and filterPredicate, respectively.
        // Then we find the count of all recipes for each time filter, ignoring the active time filter, since time filters are exclusive and
        // tapping "30 minutes" toggles off "60 minutes"
        const filterPredicate = getFiltersPredicate(filtersForGroupPredicate);

        const predicatesAndFilters = group.tagsOrFilters.map(tof => {
          // these should all be filters, but make the compiler happy
          const predicate = tof.type === "filter" ? getFilterPredicate(tof.filter) : () => false;
          const filter: RecipeTagOrFilter = {
            ...tof,
            countRecipes: 0,
          };
          return { predicate, filter };
        });

        tagRecipes.forEach(r => {
          predicatesAndFilters.forEach(paf => {
            if (paf.predicate(r) && filterPredicate(r)) {
              paf.filter.countRecipes!++;
            }
          });
        });

        return {
          ...group,
          tagsOrFilters: predicatesAndFilters.map(paf => paf.filter),
        };
      });

      return withCounts;
    }
  );

// we're just using this for memoization if filters for selectFiltersForFiltering
const selectRecipesFiltersWithoutFilters = getCreateSelectorWithCacheSize(10)(
  [(_s, filters: RecipeFilters) => filters],
  filters => {
    return {
      ...filters,
      filters: undefined,
    };
  }
);

const selectHouseholdFilterGroup: (r: RootState) => CollectionTagOrFilterGroup[] = getCreateSelectorWithCacheSize(1)(
  [s => s.system.authedUser.data],
  user => {
    if (!user || !user.isRegistered || !user.householdId || user.household.length === 0) {
      return [];
    }

    const filters = getAddedByFilters(user.userId, [user, ...user.household]);
    const group: CollectionTagOrFilterGroup = {
      groupName: "Added by",
      tagsOrFilters: filters.map(filter => ({ type: "filter", filter })),
    };

    return [group];
  }
);

const selectGlobalTagsAndFiltersForFiltering: (s: RootState) => CollectionTagOrFilterGroup[] =
  getCreateSelectorWithCacheSize(5)(
    [s => s.recipes.tagManifest, s => selectGetCanonicalTagAndType(s)],
    (manifest, getCanonicalTagAndType) => {
      const tags = manifest.categoryList.map<CollectionTagOrFilterGroup>(c => {
        return {
          groupName: c.category,
          tagsOrFilters: c.tags.flatMap<RecipeTagOrFilter>(
            t => getCanonicalTagAndType({ type: "system", tag: t }) ?? []
          ),
        };
      });

      return [...tags, getTotalTimeGroup()];
    }
  );

const selectActiveTagsAndFilters: (s: RootState, sessionId: LibraryOrSearchSessionId) => RecipeTagOrFilter[] =
  getCreateSelectorWithCacheSize(5)(
    [
      // we select these separately so the selector doesn't rerun when the search query changes
      (s, sessionId: LibraryOrSearchSessionId) => selectFilters(s, sessionId)?.tags,
      (s, sessionId: LibraryOrSearchSessionId) => selectFilters(s, sessionId)?.filters,
      s => selectGetCanonicalTagAndType(s),
    ],
    (tagsOrUndefined, filtersOrUndefined, getCanonicalTagAndType) => {
      const uncheckedTags = tagsOrUndefined ?? [];
      const uncheckedFilters = filtersOrUndefined ?? [];

      // get the proper tag, which could have changed from a rename, or been deleted.
      const tags = uncheckedTags.flatMap<RecipeTagAndType>(t => getCanonicalTagAndType(t) ?? []);

      const filters = uncheckedFilters.map<RecipeFilterAndType>(filter => {
        return { type: "filter", filter };
      });

      return [...tags, ...filters];
    }
  );

const selectFilterSuggestions: (s: RootState) => RecipeTagOrFilter[] = getCreateSelectorWithCacheSize(1)(
  [s => selectGetCanonicalTagAndType(s)],
  getCanonicalTagAndType => {
    const systemTags: SystemRecipeTag[] = [
      {
        type: "system",
        tag: "main" as RecipeTagId,
      },
      {
        type: "system",
        tag: "dessert" as RecipeTagId,
      },
      {
        type: "system",
        tag: "breakfast" as RecipeTagId,
      },
      {
        type: "system",
        tag: "side" as RecipeTagId,
      },
      {
        type: "system",
        tag: "appetizer" as RecipeTagId,
      },
    ];

    // this logic verifies the tags are valid
    return systemTags.flatMap<RecipeTagAndType>(t => getCanonicalTagAndType(t) ?? []);
  }
);

export const selectGetCanonicalTagAndType: (s: RootState) => (t: RecipeTag) => RecipeTagAndType | undefined =
  getCreateSelectorWithCacheSize(1)([s => selectGetCollectionByTag(s)], getCollectionByTag => {
    return (t: RecipeTag) => {
      const collection = getCollectionByTag(t);
      if (!collection) {
        return undefined;
      }

      const tag = getRecipeTagForCollection(collection);
      return tag ? { type: "tag", tag: { display: collection.name, tag, collectionId: collection.id } } : undefined;
    };
  });

// This group is used for both the global tag/filter experience and the library experience
function getTotalTimeGroup(): CollectionTagOrFilterGroup {
  return {
    groupName: "Total Time",
    tagsOrFilters: getTotalTimeFilters().map<RecipeFilterAndType>(filter => ({ type: "filter", filter })),
  };
}

import { useCallback, useMemo, useState } from "react";
import { ScreenView } from "../components/ScreenView";
import { RecipeCollectionChangeGroupScreenProps } from "../navigation/NavTree";
import { useScreen, withNonNavigableScreenContainer } from "../navigation/ScreenContainer";
import { HeaderProps } from "../components/ScreenHeaders";
import { ScrollViewWithSubmit } from "./ScrollViewWithSubmit";
import {
  RecipeCollectionGroupSelect,
  RecipeCollectionSelectedGroup,
} from "../components/recipes/RecipeCollectionGroupSelect";
import { View } from "react-native";
import { globalStyleConstants } from "../components/GlobalStyles";
import { Spacer } from "../components/Spacer";
import { BottomNotch } from "../components/BottomNotch";
import { TBody } from "../components/Typography";
import { Haptics } from "../components/Haptics";
import { displayUnexpectedErrorAndLog } from "../lib/Errors";

const strings = {
  screenTitle: "Move Collection",
  submit: "Save",
  noGroup: "(No Group)",
  explainer: ["Move ", " to:"],
};

export const RecipeCollectionChangeGroupScreen =
  withNonNavigableScreenContainer<RecipeCollectionChangeGroupScreenProps>(
    "RecipeCollectionChangeGroupScreen",
    props => {
      const screen = useScreen();

      const [waitingSubmit, setWaitingSubmit] = useState(false);

      const initialGroup = props.groups.find(i => i.collections.some(i => i.id === props.collectionId));

      const [selectedGroup, setSelectedGroup] = useState<RecipeCollectionSelectedGroup | undefined>(() => {
        if (initialGroup) {
          return {
            type: "existing",
            id: initialGroup.id,
          };
        } else if (props.groups[0]) {
          return {
            type: "existing",
            id: props.groups[0].id,
          };
        }
        return undefined;
      });

      const canSubmit =
        (selectedGroup && selectedGroup.type === "existing" && selectedGroup.id !== initialGroup?.id) ||
        selectedGroup?.type === "new";

      const onSubmit = useCallback(async () => {
        Haptics.feedback("itemStatusChanged");

        if (!canSubmit) {
          displayUnexpectedErrorAndLog("Change collection group: onSubmit called but canSubmit is false", undefined, {
            initialGroup,
            selectedGroup,
            collectionId: props.collectionId,
          });
          return;
        }

        setWaitingSubmit(true);
        try {
          await props.moveCollectionToGroup(
            props.collectionId,
            selectedGroup.type === "existing" ? selectedGroup.id : { type: "new", name: selectedGroup.name }
          );
          screen.nav.goBack();
        } catch (err) {
          // Error handled/displayed in callback
        } finally {
          setWaitingSubmit(false);
        }
      }, [selectedGroup, props.collectionId, props.moveCollectionToGroup, screen.nav.goBack, setWaitingSubmit]);

      const screenHeader = useMemo<HeaderProps>(() => {
        return {
          type: "default",
          title: strings.screenTitle,
          right: {
            type: "cancel",
            onPress: () => screen.nav.goBack(),
          },
        };
      }, [screen.nav.goBack]);

      return (
        <ScreenView isModal header={screenHeader} scrollView={false} paddingVertical={false} paddingHorizontal={false}>
          <ScrollViewWithSubmit
            isModal
            submitText={strings.submit}
            onPressSubmit={onSubmit}
            submitDisabled={!canSubmit}
            waiting={waitingSubmit}
          >
            <Spacer vertical={0.5} />
            <View style={{ width: "100%", paddingHorizontal: globalStyleConstants.defaultPadding }}>
              <TBody>
                <TBody opacity="medium">{strings.explainer[0]}</TBody>
                <TBody fontWeight="medium" opacity="dark">
                  {props.collectionName}
                </TBody>
                <TBody opacity="medium">{strings.explainer[1]}</TBody>
              </TBody>
            </View>
            <Spacer vertical={1.5} />
            <View style={{ paddingHorizontal: globalStyleConstants.defaultPadding }}>
              <RecipeCollectionGroupSelect
                groups={props.groups}
                selected={selectedGroup ? selectedGroup : { type: "new", name: strings.noGroup }}
                setSelected={setSelectedGroup}
                startExpanded
                showCreateGroupOption
                disabled={waitingSubmit}
              />
            </View>
          </ScrollViewWithSubmit>
          <BottomNotch />
        </ScreenView>
      );
    }
  );

import { RecipeInfo } from "@eatbetter/recipes-shared";
import { CalendarDay, getCalendarDayFromDate, EpochMs } from "@eatbetter/common-shared";

export const strings = {
  daysOfWeek: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"] as const,
  months: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ] as const,
};

/**
 * Interface representing a day in the calendar with optional metadata
 */
export interface DayInfo {
  /** The date of the day */
  date: Date;
  /** Whether this day belongs to the current month being displayed */
  currentMonth?: boolean;
  /** Optional list of recipes associated with this day */
  recipes?: RecipeInfo[];
}

export function getCalendarDayToday(): CalendarDay {
  return getCalendarDayFromDate(new Date());
}

/**
 * Checks if two dates represent the same day
 * @param date1 - First date to compare
 * @param date2 - Second date to compare
 * @returns True if both dates are the same day
 */
export const isSameDay = (date1: Date, date2: Date) =>
  date1.getFullYear() === date2.getFullYear() &&
  date1.getMonth() === date2.getMonth() &&
  date1.getDate() === date2.getDate();

/**
 * Gets the number of days in a given month
 * @param year - The year to check
 * @param month - The month to check (0-11)
 * @returns The number of days in the specified month
 */
export const getDaysInMonth = (year: number, month: number) => new Date(year, month + 1, 0).getDate();

/**
 * Gets the day of the week (0-6) for the first day of a given month
 * @param year - The year to check
 * @param month - The month to check (0-11)
 * @returns The day of the week (0 = Sunday, 6 = Saturday)
 */
export const getFirstDayOfMonth = (year: number, month: number) => new Date(year, month, 1).getDay();

/**
 * Adds a specified number of months to a date
 * @param date - The starting date
 * @param count - Number of months to add (can be negative)
 * @returns A new date with the specified number of months added
 */
export const addMonths = (date: Date, count: number) => new Date(date.getFullYear(), date.getMonth() + count, 1);

/**
 * Adds a specified number of weeks to a date
 * @param date - The starting date
 * @param count - Number of weeks to add (can be negative)
 * @returns A new date with the specified number of weeks added
 */
export const addWeeks = (date: Date, count: number) => {
  const newDate = new Date(date);
  newDate.setDate(date.getDate() + count * 7);
  return newDate;
};

/**
 * Constants for calendar calculations
 */
export const calendarConstants = {
  daysInWeek: 7,
  gridRowCount: 6, // Always show 6 rows for consistent month view
  totalDaysInGrid: 6 * 7, // 42 days (6 rows * 7 days)
};

/**
 * Generates calendar data for a specific month
 * @param baseDate - The date to generate the month view for
 * @returns Array of DayInfo objects representing the month grid (42 days total)
 */
export const generateMonthCalendarData = (baseDate: Date): DayInfo[] => {
  const year = baseDate.getFullYear();
  const month = baseDate.getMonth();
  const daysInMonth = getDaysInMonth(year, month);
  const firstDayOfMonth = getFirstDayOfMonth(year, month);
  const days: DayInfo[] = [];

  // Get last days of previous month to fill first week
  const prevMonth = month === 0 ? 11 : month - 1;
  const prevYear = month === 0 ? year - 1 : year;
  const daysInPrevMonth = getDaysInMonth(prevYear, prevMonth);

  // Previous month days
  for (let i = 0; i < firstDayOfMonth; i++) {
    const day = daysInPrevMonth - firstDayOfMonth + i + 1;
    const date = new Date(prevYear, prevMonth, day);
    days.push({ date, currentMonth: false });
  }

  // Current month days
  for (let i = 1; i <= daysInMonth; i++) {
    const date = new Date(year, month, i);
    days.push({ date, currentMonth: true });
  }

  // Next month days
  const remainingDays = calendarConstants.totalDaysInGrid - days.length;
  const nextMonth = month === 11 ? 0 : month + 1;
  const nextYear = month === 11 ? year + 1 : year;

  for (let i = 1; i <= remainingDays; i++) {
    const date = new Date(nextYear, nextMonth, i);
    days.push({ date, currentMonth: false });
  }

  return days;
};

/**
 * Generates calendar data for a specific week
 * @param baseDate - Any date within the desired week
 * @returns Array of DayInfo objects representing the week (7 days)
 */
export const generateWeekCalendarData = (baseDate: Date): DayInfo[] => {
  const days: DayInfo[] = [];
  const weekStart = new Date(baseDate);
  weekStart.setDate(baseDate.getDate() - baseDate.getDay()); // Move to start of week (Sunday)

  for (let i = 0; i < calendarConstants.daysInWeek; i++) {
    const date = new Date(weekStart);
    date.setDate(weekStart.getDate() + i);
    days.push({
      date,
      currentMonth: date.getMonth() === baseDate.getMonth(),
    });
  }

  return days;
};

/**
 * Creates a clean Date object with time set to 00:00:00.000
 * @param date - The date to normalize
 * @returns A new Date object with time set to midnight
 */
export const normalizeDateToMidnight = (date: Date): Date => {
  const normalized = new Date(date);
  normalized.setHours(0, 0, 0, 0);
  return normalized;
};

/**
 * Normalizes an EpochMs timestamp to midnight, safe for worklets
 * @param epochMs - The timestamp to normalize
 * @returns A new EpochMs timestamp set to midnight
 */
export const normalizeDateToMidnightEpochMs = (epochMs: EpochMs): EpochMs => {
  "worklet";
  const date = new Date(epochMs);
  date.setHours(0, 0, 0, 0);
  return date.getTime() as EpochMs;
};

/**
 * Gets the start of the week (Sunday) for a given date
 * @param date - Any date within the week
 * @returns A new Date object set to the start of the week (Sunday)
 */
export const getWeekStart = (date: Date): Date => {
  const weekStart = new Date(date);
  weekStart.setDate(date.getDate() - date.getDay());
  weekStart.setHours(0, 0, 0, 0);
  return weekStart;
};

/**
 * Gets the end of the week (Saturday) for a given date
 * @param date - Any date within the week
 * @returns A new Date object set to the end of the week (Saturday)
 */
export const getWeekEnd = (date: Date): Date => {
  const weekEnd = getWeekStart(date);
  weekEnd.setDate(weekEnd.getDate() + 6);
  return weekEnd;
};

/**
 * Calculates the difference in weeks between two dates using UTC to avoid DST issues
 * @param date1 - First date to compare
 * @param date2 - Second date to compare
 * @returns Number of weeks between the two dates
 */
export const getWeekDifference = (date1: Date, date2: Date): number => {
  const d1 = new Date(date1);
  const d2 = new Date(date2);

  const weekStart1 = getWeekStart(d1);
  const weekStart2 = getWeekStart(d2);

  // Use UTC values to avoid DST issues
  const utcDate1 = Date.UTC(weekStart1.getFullYear(), weekStart1.getMonth(), weekStart1.getDate());
  const utcDate2 = Date.UTC(weekStart2.getFullYear(), weekStart2.getMonth(), weekStart2.getDate());

  // Calculate difference in weeks
  return Math.round((utcDate2 - utcDate1) / (7 * 24 * 60 * 60 * 1000));
};

/**
 * Calculates the difference in months between two dates
 * @param date1 - First date to compare
 * @param date2 - Second date to compare
 * @returns Number of months between the two dates
 */
export const getMonthDifference = (date1: Date, date2: Date): number => {
  const yearDiff = date2.getFullYear() - date1.getFullYear();
  const monthDiff = date2.getMonth() - date1.getMonth();
  return yearDiff * 12 + monthDiff;
};

/**
 * Creates a Date object from an EpochMs timestamp safely for use in worklets
 * Use this instead of new Date(epochMs) when working with shared values
 * @param epochMs - The timestamp in milliseconds
 * @returns A simple object with year, month, day properties
 */
export const epochMsToDateParts = (epochMs: EpochMs): { year: number; month: number; day: number } => {
  "worklet";
  const date = new Date(epochMs);
  return {
    year: date.getFullYear(),
    month: date.getMonth(),
    day: date.getDate(),
  };
};

/**
 * Compares if two epoch timestamps represent the same day, safe for worklets
 * @param epoch1 - First epoch timestamp to compare
 * @param epoch2 - Second epoch timestamp to compare
 * @returns True if both timestamps represent the same day
 */
export const isSameDayFromEpoch = (epoch1: EpochMs, epoch2: EpochMs): boolean => {
  "worklet";
  const date1 = epochMsToDateParts(epoch1);
  const date2 = epochMsToDateParts(epoch2);

  return date1.year === date2.year && date1.month === date2.month && date1.day === date2.day;
};

/**
 * Compares if two epoch timestamps represent the same month, safe for worklets
 * @param epoch1 - First epoch timestamp to compare
 * @param epoch2 - Second epoch timestamp to compare
 * @returns True if both timestamps represent the same month and year
 */
export const isSameMonthFromEpoch = (epoch1: EpochMs, epoch2: EpochMs): boolean => {
  "worklet";
  const date1 = epochMsToDateParts(epoch1);
  const date2 = epochMsToDateParts(epoch2);

  return date1.year === date2.year && date1.month === date2.month;
};

/**
 * Gets the start of the week (Sunday) for a date as epoch, safe for worklets
 * @param epochMs - The timestamp in milliseconds
 * @returns Epoch timestamp for start of the week
 */
export const getWeekStartFromEpoch = (epochMs: EpochMs): EpochMs => {
  "worklet";
  const date = new Date(epochMs);
  const day = date.getDay();
  const diff = date.getDate() - day;
  date.setDate(diff);
  date.setHours(0, 0, 0, 0);
  return date.getTime() as EpochMs;
};

/**
 * Compares if two epoch timestamps represent the same week, safe for worklets
 * @param epoch1 - First epoch timestamp to compare
 * @param epoch2 - Second epoch timestamp to compare
 * @returns True if both timestamps are in the same week
 */
export const isSameWeekFromEpoch = (epoch1: EpochMs, epoch2: EpochMs): boolean => {
  "worklet";
  const weekStart1 = getWeekStartFromEpoch(epoch1);
  const weekStart2 = getWeekStartFromEpoch(epoch2);

  return isSameDayFromEpoch(weekStart1, weekStart2);
};

/**
 * Formats a date for display in the calendar header
 * @param date - The date to format
 * @returns Formatted date string (e.g., "JANUARY 12")
 */
export const formatHeaderDate = (date: Date): string => {
  return `${strings.months[date.getMonth()]?.toUpperCase()} ${date.getDate()}`;
};

/**
 * Checks if a date is in the past
 * @param date - The date to check
 * @returns True if the date is in the past
 */
export const isPastDate = (date: Date): boolean => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  date = new Date(date);
  date.setHours(0, 0, 0, 0);
  return date < today;
};

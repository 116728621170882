import { EntityState, PayloadAction, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import {
  ServerData,
  serverDataRequested,
  serverDataReceived,
  serverDataErrored,
  ReceivedServerData,
} from "../redux/ServerData";
import { PlannedItem, PlannedItemId, MealPlanData } from "@eatbetter/planning-shared";
import { Draft } from "immer";
import { CalendarDay, addCalendarDays, EpochMs, compareCalendarDays } from "@eatbetter/common-shared";
import { getCalendarDayToday } from "../../components/CalendarUtils";

export interface PlanningState {
  // We always have the last week and all future plan data in state via a reactor.
  // This tracks the meta for that.
  futurePlanMeta: ServerData<{}>;

  plannedItems: EntityState<PlannedItem, PlannedItemId>;
}

const plannedItemsAdapter = createEntityAdapter<PlannedItem, PlannedItemId>({
  selectId: item => item.id,
});

const initialState: PlanningState = {
  futurePlanMeta: {},
  plannedItems: plannedItemsAdapter.getInitialState(),
};

export function rehydratePlanningState(persisted: Draft<PlanningState>): void {
  const entities = Object.values(persisted.plannedItems.entities);
  const start = getFutureMealPlanStartDate();
  const toDelete = entities.filter(item => compareCalendarDays(item.date, "<", start)).map(item => item.id);
  plannedItemsAdapter.removeMany(persisted.plannedItems, toDelete);
}

export function getFutureMealPlanStartDate(): CalendarDay {
  const today = getCalendarDayToday();
  return addCalendarDays(today, -30);
}

const planningSlice = createSlice({
  name: "planning",
  initialState,
  reducers: create => ({
    futurePlanRequested: create.reducer((state, action: PayloadAction<EpochMs>) => {
      serverDataRequested(state.futurePlanMeta, action.payload);
    }),

    futurePlanReceived: create.reducer((state, action: PayloadAction<ReceivedServerData<MealPlanData>>) => {
      serverDataReceived(state.futurePlanMeta, { data: {}, startTime: action.payload.startTime });
    }),

    futurePlanErrored: create.reducer(state => {
      serverDataErrored(state.futurePlanMeta);
    }),

    planDataReceived: create.reducer((state, action: PayloadAction<MealPlanData>) => {
      const toUpsert: PlannedItem[] = [];
      const toDelete: PlannedItemId[] = [];

      action.payload.items.forEach(item => {
        const existing = state.plannedItems.entities[item.id];
        if (existing && existing.version > item.version) {
          // ignore items that are older than the version in state
          return;
        }

        if (item.deleted) {
          toDelete.push(item.id);
        } else {
          toUpsert.push(item);
        }
      });

      if (toUpsert.length > 0) {
        plannedItemsAdapter.upsertMany(state.plannedItems, toUpsert);
      }

      if (toDelete.length > 0) {
        plannedItemsAdapter.removeMany(state.plannedItems, toDelete);
      }
    }),

    singlePlannedItemReceived: create.reducer((state, action: PayloadAction<PlannedItem>) => {
      const item = action.payload;
      const existing = state.plannedItems.entities[item.id];

      if (existing && existing.version > item.version) {
        // ignore items that are older than the version in state
        return;
      }

      if (item.deleted) {
        plannedItemsAdapter.removeOne(state.plannedItems, item.id);
      } else {
        plannedItemsAdapter.upsertOne(state.plannedItems, item);
      }
    }),
  }),
});

export const planningReducer = planningSlice.reducer;
export const {
  futurePlanRequested,
  futurePlanReceived,
  futurePlanErrored,
  planDataReceived,
  singlePlannedItemReceived,
} = planningSlice.actions;

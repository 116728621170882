import { useCallback, useEffect, useMemo } from "react";
import { globalStyleColors, globalStyleConstants } from "../components/GlobalStyles";
import { HeaderProps, useScreenHeaderDimensions } from "../components/ScreenHeaders";
import { RecipeFilterControl } from "../components/recipes/RecipeFilterControl";
import { ScreenView } from "../components/ScreenView";
import { Spacer } from "../components/Spacer";
import { useScreen, withNonNavigableScreenContainer } from "../navigation/ScreenContainer";
import { useBottomTabBarDimensions } from "../navigation/TabBar";
import { useDispatch } from "../lib/redux/Redux";
import { recipeLibraryFiltersChanged } from "../lib/recipes/RecipesThunks";
import { ScrollView, View } from "react-native";
import { RecipesFilterScreenProps } from "../navigation/NavTree";
import { isLibraryFilterSessionId } from "../lib/composite/LibraryAndSearchSessionIds.ts";
import {
  useFilteredRecipeListSectionsWithDeferredStatus,
  useRecipeCount,
} from "../lib/composite/RecipeListSelectors.ts";
import { Text } from "../components/Typography.tsx";

const strings = {
  screenTitle: "Filter",
  resultCount: (count: number) => `${count} recipe${count === 1 ? "" : "s"}`,
};

export const RecipesFilterScreen = withNonNavigableScreenContainer(
  "RecipesFilterScreen",
  (props: RecipesFilterScreenProps) => {
    const screen = useScreen();
    const dispatch = useDispatch();
    const { modalHeaderHeight } = useScreenHeaderDimensions();
    const { bottomNotchHeight } = useBottomTabBarDimensions();

    const isLibrarySearch = isLibraryFilterSessionId(props.sessionId);
    const { sections, deferred } = useFilteredRecipeListSectionsWithDeferredStatus(props.sessionId, "default");
    const resultCount = useRecipeCount(sections);

    const onPressDone = useCallback(() => {
      screen.nav.goBack();
    }, [screen.nav.goBack]);

    useEffect(() => {
      return () => {
        // this screen is used for both global search and library filtering
        // global search filter events are handled in thunks, so this should only fire for library searches
        // note that there was a bug up until v5.0.0 where this would fire for global searches IFF
        // there was a filter applied to the library as well because the event only fires if there is a filter
        // set.
        if (isLibraryFilterSessionId(props.sessionId)) {
          dispatch(recipeLibraryFiltersChanged(props.sessionId));
        }
      };
    }, [props.sessionId]);

    const screenHeader = useMemo<HeaderProps>(() => {
      return {
        type: "native",
        title: !isLibrarySearch
          ? strings.screenTitle
          : () => {
              return (
                <View style={{ alignItems: "center", paddingTop: globalStyleConstants.minPadding }}>
                  <Text fontSize="body">{strings.screenTitle}</Text>
                  <Text fontSize="secondary" color={globalStyleColors.colorAccentCool}>
                    {!deferred ? strings.resultCount(resultCount) : " "}
                  </Text>
                </View>
              );
            },
        right: { type: "done", onPress: onPressDone },
      };
    }, [onPressDone, resultCount, isLibrarySearch, deferred]);

    return (
      <ScreenView
        backgroundColor={globalStyleColors.colorGreyLight}
        paddingVertical={false}
        paddingHorizontal={false}
        scrollView={false}
        header={screenHeader}
        isModal
      >
        <ScrollView
          contentContainerStyle={{
            paddingTop: modalHeaderHeight,
            paddingHorizontal: 20,
          }}
          style={{ marginBottom: bottomNotchHeight }}
        >
          <Spacer vertical={1.5} />
          <RecipeFilterControl sessionId={props.sessionId} />
        </ScrollView>
      </ScreenView>
    );
  }
);

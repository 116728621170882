import { useCallback, useMemo, useState } from "react";
import { ScreenView, useScreenElementDimensions } from "../components/ScreenView";
import { useScreen, withScreenContainer } from "../navigation/ScreenContainer";
import { HeaderProps } from "../components/ScreenHeaders";
import { ScrollView } from "react-native";
import { InputAccessoryView } from "../components/InputAccessoryView";
import { BottomActionBar, bottomActionBarConstants } from "../components/BottomActionBar";
import { globalStyleConstants } from "../components/GlobalStyles";
import { SectionHeading } from "../components/SectionHeading";
import { Spacer } from "../components/Spacer";
import { RecipeCollectionTitleInput } from "../components/recipes/RecipeCollectionTitleInput";
import {
  RecipeCollectionGroupSelect,
  RecipeCollectionSelectedGroup,
} from "../components/recipes/RecipeCollectionGroupSelect";
import { useKeyboardLayoutAnimation } from "../components/Keyboard";
import { useDispatch } from "../lib/redux/Redux";
import { createCollectionAndAddRecipe } from "../lib/recipes/RecipesThunks";
import { useCollections } from "../lib/composite/CollectionsSelectors";
import {
  isStructuredRecipeError,
  RecipeCollectionGroupId,
  RecipeCollectionLayout,
  UpdateRecipeCollectionsArgs,
  UserRecipeId,
} from "@eatbetter/recipes-shared";
import { bottomThrow, newId } from "@eatbetter/common-shared";
import { getCollectionsLayout } from "../components/recipes/RecipeCollectionsEdit";
import { displayExpectedError, displayUnexpectedErrorAndLog } from "../lib/Errors";
import { Haptics } from "../components/Haptics";
import { Spinner } from "../components/Spinner";
import { analyticsEvent } from "../lib/analytics/AnalyticsThunks";
import { reportRecipeCollectionCreated } from "../lib/analytics/AnalyticsEvents";
import { CreateRecipeCollectionScreenProps } from "../navigation/NavTree.ts";

const strings = {
  screenTitle: "New Recipe Collection",
  submit: "Create Collection",
  collectionNameSectionHeader: "Collection Name",
  collectionNamePlaceholder: "Collection name",
  collectionSectionSectionHeader: "Group",
  noGroup: "(No Group)",
};

export const CreateRecipeCollectionScreen = withScreenContainer(
  "CreateRecipeCollectionScreen",
  (props: CreateRecipeCollectionScreenProps) => {
    const dispatch = useDispatch();
    const screen = useScreen();
    const { headerHeight, bottomTabBarHeight } = useScreenElementDimensions();
    const keyboardHeight = useKeyboardLayoutAnimation();

    const collections = useCollections();
    // IMPORTANT - THIS FILTER LOGIC IS DUPLICATED HERE AND RecipeLibraryCollectionsView.tsx
    // IF IT CHANGES HERE, IT SHOULD PROBABLY CHANGE THERE
    const groups = collections?.filter(i => !i.group.locked).map(i => i.group) ?? [];

    // Default to the first collection (should be "Pinned")
    const defaultGroupId = groups[0]?.id;

    const [collectionName, setCollectionName] = useState("");
    const [selectedGroup, setSelectedGroup] = useState<RecipeCollectionSelectedGroup | undefined>(
      defaultGroupId ? { type: "existing", id: defaultGroupId } : undefined
    );
    const [waitingSubmit, setWaitingSubmit] = useState(false);

    const canSubmit = !!collectionName.trim();

    const onSubmit = useCallback(async () => {
      let groupId: RecipeCollectionGroupId | undefined = undefined;
      let layout: RecipeCollectionLayout | undefined = undefined;

      if (selectedGroup && collections) {
        switch (selectedGroup.type) {
          case "existing": {
            groupId = selectedGroup.id;
            break;
          }
          case "new": {
            // For newly created groups, we generate a new ID and Layout update that includes the group. We pass this
            // along with the create collection call, specifying the new group ID. This all gets linked together
            // properly in the backend.
            groupId = `ug:${newId()}`;
            layout = getCollectionsLayout(collections);
            layout.groups.push({ id: groupId, name: selectedGroup.name, collections: [] });
            break;
          }
          default:
            bottomThrow(selectedGroup);
        }
      }

      const updateArgs: UpdateRecipeCollectionsArgs = {
        collection: { op: { type: "create", name: collectionName, group: groupId } },
        layout,
      };

      try {
        await dispatch(createCollectionAndAddRecipe(updateArgs, props.recipeId, setWaitingSubmit));
        Haptics.feedback("operationSucceeded");
        dispatch(
          analyticsEvent(
            reportRecipeCollectionCreated({
              collectionName: collectionName,
              groupId,
              groupName: selectedGroup && "name" in selectedGroup ? selectedGroup.name : undefined,
            })
          )
        );
        screen.nav.goBack();
      } catch (err) {
        // THIS ERROR HANDLER IS DUPLICATED IN 3 PLACES. ANY CHANGES HERE MIGHT NEED TO BE MADE IN THOSE PLACES AS WELL
        // SEE RecipeCollectionsEdit.tsx
        // SEE RecipeCollectionScreen.tsx
        if (isStructuredRecipeError(err) && err.data.code === "recipes/collectionNameInvalidError") {
          displayExpectedError(err.data.payload.userMessage);
        } else {
          displayUnexpectedErrorAndLog(
            "CreateRecipeCollectionScreen: error caught dispatching editCollections (onSubmit)",
            err,
            { selectedGroup, collections, collectionName, groupId, layout }
          );
        }
      }
    }, [selectedGroup, collections, dispatch, collectionName, setWaitingSubmit, screen.nav.goBack]);

    const screenHeader = useMemo<HeaderProps>(() => {
      return {
        type: "default",
        title: strings.screenTitle,
        right: {
          type: "cancel",
          onPress: screen.nav.goBack,
        },
        backgroundColor: "white",
      };
    }, [screen.nav.goBack]);

    return (
      <ScreenView header={screenHeader} scrollView={false} paddingVertical="none" paddingHorizontal="none">
        <ScrollView
          contentContainerStyle={{
            paddingHorizontal: globalStyleConstants.defaultPadding,
            paddingTop: headerHeight + globalStyleConstants.unitSize,
            paddingBottom:
              (keyboardHeight > 0
                ? keyboardHeight + bottomActionBarConstants.height
                : bottomTabBarHeight + bottomActionBarConstants.height) + globalStyleConstants.unitSize,
          }}
        >
          <SectionHeading text={strings.collectionNameSectionHeader} noPadding />
          <Spacer vertical={1} />
          <RecipeCollectionTitleInput
            value={collectionName}
            onChangeText={setCollectionName}
            placeholderText={strings.collectionNamePlaceholder}
            autofocus
            editable={!waitingSubmit}
          />
          <Spacer vertical={2} />
          <SectionHeading text={strings.collectionSectionSectionHeader} noPadding />
          <Spacer vertical={1} />
          {!!groups && (
            <RecipeCollectionGroupSelect
              groups={groups}
              selected={selectedGroup ? selectedGroup : { type: "new", name: strings.noGroup }}
              setSelected={setSelectedGroup}
              showCreateGroupOption
            />
          )}
          {!groups && <Spinner />}
        </ScrollView>
        <InputAccessoryView snapPoint={{ bottom: "tabBar" }}>
          <BottomActionBar
            primaryAction={{
              actionText: strings.submit,
              onPressAction: onSubmit,
              waiting: waitingSubmit,
              disabled: !canSubmit,
            }}
            disableSnapToBottom
          />
        </InputAccessoryView>
      </ScreenView>
    );
  },
  {
    serializer: {
      recipeId: { optional: true, fn: s => s },
    },
    parser: {
      recipeId: { optional: true, fn: s => s as UserRecipeId },
    },
  }
);

import { useCallback, useEffect, useMemo } from "react";
import { useCollectionsWithEditMode } from "../components/recipes/RecipeCollectionsEdit";
import { ScreenView } from "../components/ScreenView";
import { useScreen, withScreenContainer } from "../navigation/ScreenContainer";
import { RecipeLibraryCollectionsView } from "../components/recipes/RecipeLibraryCollectionsView";
import { BottomNotch } from "../components/BottomNotch";
import { HeaderProps, useHeaderScrollAnimation } from "../components/ScreenHeaders";
import { globalStyleConstants } from "../components/GlobalStyles";
import { useLibraryFilterSession } from "../lib/recipes/UseLibraryFilterSession.ts";

const strings = {
  screenTitle: "Reorder + Edit Collections",
};

export const RecipeCollectionsEditScreen = withScreenContainer("RecipeCollectionsEditScreen", () => {
  const screen = useScreen();

  const { collectionsData, editModeActions, startEditMode, endEditMode, waitingEndEdit } = useCollectionsWithEditMode();

  useEffect(() => {
    startEditMode();
  }, []);

  const onPressDone = useCallback(async () => {
    await endEditMode();
    screen.nav.goBack();
  }, [endEditMode]);

  const [headerTranslateY, onScroll] = useHeaderScrollAnimation();

  const screenHeader = useMemo<HeaderProps>(() => {
    return {
      type: "custom",
      title: strings.screenTitle,
      right: {
        type: "done",
        bold: true,
        onPress: onPressDone,
        waiting: waitingEndEdit,
      },
      disableBack: true,
      animationConfig: {
        animationProgress: headerTranslateY,
        blurBackgroundThreshold: globalStyleConstants.unitSize,
        disableRetract: true,
      },
    };
  }, [onPressDone, waitingEndEdit]);

  // this is not used in edit mode, but we need it for props
  const sessionId = useLibraryFilterSession();

  return (
    <ScreenView header={screenHeader} scrollView={false} paddingVertical={false} paddingHorizontal={false}>
      {!!collectionsData && (
        <RecipeLibraryCollectionsView
          sessionId={sessionId}
          collectionsData={collectionsData}
          editMode={editModeActions}
          onScroll={onScroll}
        />
      )}
      <BottomNotch />
    </ScreenView>
  );
});

import { switchReturn } from "@eatbetter/common-shared";
import { BottomTabNavigationOptions, useBottomTabBarHeight } from "@react-navigation/bottom-tabs";
import React, { useEffect } from "react";
import { Animated, StyleSheet, View } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { globalStyleColors, Opacity } from "../components/GlobalStyles";
import { IconBook, IconCalendar, IconCart, IconHome, IconProps, IconUserCircle } from "../components/Icons";
import { Photo } from "../components/Photo";
import { useNotificationUnreadCount } from "../lib/notifications/NotificationsSelectors";
import { useRegisteredUser } from "../lib/system/SystemSelectors";
import { useWobbleAnimation } from "../components/AttentionGrabbers";
import { useDispatch } from "../lib/redux/Redux";
import { reportOnboardingEvent } from "../lib/analytics/AnalyticsEvents";
import { analyticsEvent } from "../lib/analytics/AnalyticsThunks";
import { useSpotlightGroceryIcon } from "../lib/lists/ListsSelectors";
import { Badge } from "../components/Badges";

interface TabBarIconProps {
  focused: boolean;
  color: string;
  size: number;
}

export function useBottomTabBarDimensions() {
  const { bottom: bottomNotchHeight } = useSafeAreaInsets();

  let bottomTabBarHeight;
  try {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    bottomTabBarHeight = useBottomTabBarHeight();
  } catch {
    bottomTabBarHeight = bottomNotchHeight;
  }

  return {
    bottomNotchHeight,
    bottomTabBarHeight,
  };
}

export function getTabBarOptions(): BottomTabNavigationOptions {
  return {
    tabBarShowLabel: false,
    tabBarActiveTintColor: globalStyleColors.colorNavigationTint,
    tabBarInactiveTintColor: globalStyleColors.rgba("colorNavigationTint", "light"),
    tabBarStyle: styles.tabBarContainer,
  };
}

export function getTabBarIcon(
  iconType: "home" | "recipes" | "groceries" | "planning" | "profile",
  props: TabBarIconProps
) {
  const size = props.size;
  const color = props.color;
  // React navigation controls opacity through the color prop, which is set on
  // activeTintColor and inActiveTintColor in Tabs.Navigator.tabBarOptions
  const opacity = "opaque";

  return switchReturn(iconType, {
    home: <HomeIcon focused={props.focused} size={size} color={color} opacity={opacity} />,
    recipes: <RecipesIcon focused={props.focused} size={size} color={color} opacity={opacity} />,
    groceries: <GroceryIcon focused={props.focused} size={size} color={color} opacity={opacity} />,
    planning: <PlanningIcon focused={props.focused} size={size} color={color} opacity={opacity} />,
    profile: <ProfileIcon focused={props.focused} size={size} color={color} opacity={opacity} />,
  });
}

const HomeIcon = React.memo((props: TabBarIconProps & { opacity: IconProps["opacity"] }) => {
  const unreadNotifications = useNotificationUnreadCount();
  const badge = props.focused ? 0 : unreadNotifications;

  return (
    <View>
      <IconHome badgeCount={badge} size={props.size} color={props.color} opacity={props.opacity} />
    </View>
  );
});

const RecipesIcon = React.memo((props: TabBarIconProps & { opacity: IconProps["opacity"] }) => {
  return (
    <View style={{ marginTop: 2 }}>
      <IconBook size={props.size} color={props.color} opacity={props.opacity} />
    </View>
  );
});

const GroceryIcon = React.memo((props: TabBarIconProps & { opacity: IconProps["opacity"] }) => {
  const showHint = useSpotlightGroceryIcon();
  const dispatch = useDispatch();

  const wobbleAnimation = useWobbleAnimation({ loop: true, wobbleAmount: "large" });

  // Onboarding: draw attention to this tab button after user adds first recipe to grocery list
  useEffect(() => {
    if (showHint) {
      wobbleAnimation.startAnimation();
      dispatch(analyticsEvent(reportOnboardingEvent("spotlightGroceryTab")));
      return;
    }

    wobbleAnimation.stopAnimation();
  }, [showHint]);

  return (
    <Animated.View style={[{ marginTop: 2 }, wobbleAnimation.animatedStyle]}>
      <IconCart size={props.size} color={props.color} opacity={props.opacity} />
      {showHint && <Badge count={1} />}
    </Animated.View>
  );
});

const PlanningIcon = React.memo((props: TabBarIconProps & { opacity: IconProps["opacity"] }) => {
  return (
    <View>
      <IconCalendar size={props.size} color={props.color} opacity={props.opacity} />
    </View>
  );
});

const ProfileIcon = React.memo((props: TabBarIconProps & { opacity: IconProps["opacity"] }) => {
  const photo = useRegisteredUser()?.photo;
  const size = props.size + 2;
  const borderWidth = 2;

  const avatar = { width: size, height: size, borderRadius: size / 2 };
  const container = { width: size + borderWidth, height: size + borderWidth, borderRadius: (size + borderWidth) / 2 };
  const border = props.focused
    ? { borderWidth, borderColor: props.color }
    : { borderWidth, borderColor: "transparent" };
  const opacity = props.opacity ? { opacity: Opacity[props.opacity] } : {};

  return (
    <>
      {!!photo && (
        <View style={[container, border, opacity, { overflow: "hidden", marginBottom: 2 }]}>
          <View style={[avatar, { overflow: "hidden" }]}>
            <Photo style="flexed" source={photo} sourceSize="w288" />
          </View>
        </View>
      )}
      {!photo && <IconUserCircle size={size} color={props.color} opacity={props.opacity} />}
    </>
  );
});

const styles = StyleSheet.create({
  tabBarContainer: {
    position: "absolute",
    borderTopColor: globalStyleColors.colorGreyDark,
    backgroundColor: globalStyleColors.white,
  },
});

import { RecipeCollectionId } from "@eatbetter/recipes-shared";
import { useCallback } from "react";
import { Haptics } from "../Haptics";
import { useBottomSheet } from "../../screens/BottomSheetScreen";
import { Alert } from "../Alert/Alert";
import { useCollectionName } from "../../lib/composite/CollectionsSelectors";
import { displayUnexpectedErrorAndLog } from "../../lib/Errors";

const strings = {
  deleteConfirmationAlert: {
    title: (collectionName: string) => `Delete ${collectionName}?`,
    body: "This operation cannot be undone. This will delete the collection, but not the recipes in it.",
  },
};

export function useDeleteCollection(props: {
  collectionId: RecipeCollectionId;
  deleteCollection?: (collectionId: RecipeCollectionId) => Promise<void>;
  setWaitingDelete: (v: boolean) => void;
}) {
  const { collectionId, deleteCollection, setWaitingDelete } = props;
  const bottomSheet = useBottomSheet();
  const collectionName = useCollectionName(collectionId);

  const onConfirmDelete = useCallback(async () => {
    if (!deleteCollection) {
      displayUnexpectedErrorAndLog("onConfirmDelete called but deleteCollection is falsy", {
        deleteCollection,
        collectionId,
      });
      return;
    }

    try {
      setWaitingDelete(true);
      await deleteCollection(collectionId);
      Haptics.feedback("operationSucceeded");
      setWaitingDelete(false);
      bottomSheet?.closeSheetAndGoBack();
    } catch (err) {
      setWaitingDelete(false);
    }
  }, [setWaitingDelete, deleteCollection, bottomSheet]);

  const onPressDelete = useCallback(() => {
    Alert.alert(strings.deleteConfirmationAlert.title(collectionName), strings.deleteConfirmationAlert.body, [
      {
        type: "cancel",
        onPress: () => {},
      },
      {
        type: "delete",
        onPress: onConfirmDelete,
      },
    ]);
  }, [collectionName, onConfirmDelete]);

  return onPressDelete;
}

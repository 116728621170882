import { forwardRef, useCallback, useImperativeHandle, useMemo, useRef, useState } from "react";
import { FlatList, StyleSheet, View, Dimensions, ViewToken, LayoutChangeEvent } from "react-native";
import { globalStyleColors, globalStyleConstants, Opacity } from "./GlobalStyles";
import { FontSize, Text, useFontFamilyMap } from "./Typography";
import { switchReturn, range, bottomThrow, EpochMs, getCalendarDayFromDate } from "@eatbetter/common-shared";
import { Pressable } from "./Pressable";
import { Separator } from "./Separator";
import { Spacer } from "./Spacer";
import {
  DayInfo,
  isSameDay,
  addMonths,
  addWeeks,
  strings,
  generateMonthCalendarData,
  generateWeekCalendarData,
  getMonthDifference,
  getWeekDifference,
  getWeekStart,
  getWeekEnd,
  isPastDate,
  formatHeaderDate,
  isSameDayFromEpoch,
  epochMsToDateParts,
  isSameMonthFromEpoch,
  isSameWeekFromEpoch,
} from "./CalendarUtils";
import React from "react";
import Animated, {
  runOnJS,
  SharedValue,
  useAnimatedStyle,
  useDerivedValue,
  useAnimatedReaction,
} from "react-native-reanimated";
import { Haptics } from "./Haptics";

const constants = {
  infiniteScroll: {
    monthOffset: 12, // Start in the middle for infinite scroll (1 year back)
    weekOffset: 52, // Start in the middle for infinite scroll (1 year back)
    monthCount: 36, // 3 years worth of months
    weekCount: 104, // 2 years worth of weeks
  },
  layout: {
    monthRowHeight: 38,
    dayBorderRadius: globalStyleConstants.defaultBorderRadius,
    gridRowCount: 6,
    daysPerWeek: 7,
    headerBottomMargin: globalStyleConstants.unitSize,
    weekdayHeaderBottomMargin: 0.5 * globalStyleConstants.unitSize,
    verticalPadding: globalStyleConstants.unitSize,
    horizontalPadding: globalStyleConstants.defaultPadding,
    scrollThrottle: 16,
  },
  sizes: {
    small: {
      day: {
        width: 24,
        height: 24,
      },
      dot: {
        size: 5,
        marginHorizontal: 1.5,
        bottom: -8,
      },
      weekdayTextBottomMargin: 0,
    },
    large: {
      day: {
        width: 34,
        height: 34,
      },
      dot: {
        size: 9,
        marginHorizontal: 3,
        bottom: -9,
      },
      weekdayTextBottomMargin: globalStyleConstants.unitSize,
    },
  },
  heights: {
    month: {
      small: 312,
      large: 396,
    },
    week: {
      small: 116,
      large: 196,
    },
  },
};

interface CalendarGridProps {
  selectedDate: SharedValue<EpochMs>;
  onSelectDate: (date: Date) => void;
  mode: "month" | "week";
  onModeChange?: (mode: "month" | "week") => void;
  size?: "small" | "large";
  scrollDirection?: "vertical" | "horizontal";
  onScroll?: (visibleRange: { start: Date; end: Date }) => void;
  dotData?: Record<string, { breakfast?: boolean; lunch?: boolean; dinner?: boolean }>;
  height?: number; // Optional fixed height
}

export interface CalendarGridImperativeHandle {
  scrollToDate: (date: Date) => void;
}

export const CalendarGrid = forwardRef<CalendarGridImperativeHandle, CalendarGridProps>((props, ref) => {
  const {
    selectedDate,
    onSelectDate,
    mode = "month",
    size = "small",
    scrollDirection = "vertical",
    dotData = {},
    height,
  } = props;

  const today = new Date();

  const flatListRef = useRef<FlatList>(null);
  const initialScrollIndex =
    mode === "month" ? constants.infiniteScroll.monthOffset : constants.infiniteScroll.weekOffset;

  // Track container width to ensure proper sizing
  const [containerWidth, setContainerWidth] = useState(0);
  const onLayout = useCallback((e: LayoutChangeEvent) => {
    setContainerWidth(e.nativeEvent.layout.width);
  }, []);

  const calendarHeight = height || constants.heights[mode][size];

  useImperativeHandle(ref, () => ({
    scrollToDate: (date: Date) => {
      scrollToDate(date.getTime() as EpochMs);
    },
  }));

  const scrollToDate = useCallback(
    (dateEpochMs: EpochMs) => {
      const date = new Date(dateEpochMs);
      let diff = 0;

      if (mode === "month") {
        diff = getMonthDifference(today, date);
      } else {
        diff = getWeekDifference(today, date);
      }

      const index = initialScrollIndex + diff;
      if (flatListRef.current) {
        flatListRef.current.scrollToIndex({ animated: true, index });
      }
    },
    [initialScrollIndex, mode, today]
  );

  // Generate data for infinite scroll
  const data = useMemo(
    () => range(mode === "month" ? constants.infiniteScroll.monthCount : constants.infiniteScroll.weekCount),
    [mode]
  );

  // Calculate initial scroll index based on selected date
  const initialIndex = useMemo(() => {
    const selectedDateObj = new Date(selectedDate.value);
    let diff = 0;

    if (mode === "month") {
      diff = getMonthDifference(today, selectedDateObj);
    } else {
      diff = getWeekDifference(today, selectedDateObj);
    }

    return initialScrollIndex + diff;
  }, [selectedDate.value, mode, today, initialScrollIndex]);

  useAnimatedReaction(
    () => selectedDate.value,
    (current, previous) => {
      if (previous === null) return;

      if (mode === "month") {
        const isSame = isSameDayFromEpoch(current, previous) || isSameMonthFromEpoch(current, previous);

        if (!isSame) {
          runOnJS(scrollToDate)(current);
        }
      } else {
        const isSame = isSameDayFromEpoch(current, previous) || isSameWeekFromEpoch(current, previous);

        if (!isSame) {
          runOnJS(scrollToDate)(current);
        }
      }
    }
  );

  const renderDayItem = useCallback(
    (date: Date, currentMonth: boolean = true, uniqueKey: string) => {
      const dateKey = getCalendarDayFromDate(date);
      const isToday = isSameDay(date, today);
      const dots = dotData[dateKey];

      return (
        <DayItem
          key={uniqueKey}
          mode={mode}
          date={date}
          currentMonth={currentMonth}
          selectedDate={selectedDate}
          isToday={isToday}
          dots={dots}
          size={size}
          onPress={onSelectDate}
        />
      );
    },
    [size, dotData, today, selectedDate, onSelectDate, mode]
  );

  const renderMonthItem = useCallback(
    ({ item: monthOffset }: { item: number }) => {
      const date = addMonths(today, monthOffset - initialScrollIndex);
      const days = generateMonthCalendarData(date);

      const fontSize: keyof typeof FontSize = switchReturn(size, {
        small: "tertiary",
        large: "body",
      });

      // Ensure we have exactly 6 rows of 7 days
      const rows: DayInfo[][] = [];
      for (let i = 0; i < constants.layout.gridRowCount; i++) {
        rows.push(days.slice(i * constants.layout.daysPerWeek, (i + 1) * constants.layout.daysPerWeek));
      }

      return (
        <View
          style={[
            styles.monthContainer,
            {
              width: containerWidth || "100%",
              height: calendarHeight,
            },
          ]}
        >
          {/* Header with month and year */}
          <CalendarHeader mode="month" date={date} fontSize={fontSize} />

          <Separator orientation="row" />
          <Spacer vertical={0.5} />

          {/* Days of the week header */}
          <WeekdayHeader monthOffset={monthOffset} size={size} fontSize={fontSize} />

          <Separator orientation="row" />

          {/* Calendar grid */}
          <MonthGrid rows={rows} monthOffset={monthOffset} renderDayItem={renderDayItem} />
        </View>
      );
    },
    [size, initialScrollIndex, containerWidth, calendarHeight, renderDayItem, today]
  );

  const renderWeekItem = useCallback(
    ({ item: weekOffset }: { item: number }) => {
      // Find the start of the week (Sunday) for the current week
      const weekStart = new Date(today);
      weekStart.setDate(weekStart.getDate() - weekStart.getDay()); // Move to the start of current week (Sunday)

      // Add the offset weeks
      const date = addWeeks(weekStart, weekOffset - initialScrollIndex);
      const days = generateWeekCalendarData(date);

      const fontSize: keyof typeof FontSize = switchReturn(size, {
        small: "tertiary",
        large: "body",
      });

      return (
        <View
          style={[
            styles.weekContainer,
            {
              width: containerWidth || "100%",
              height: calendarHeight,
            },
          ]}
        >
          {/* Header with date range */}
          <CalendarHeader mode="week" date={date} fontSize={fontSize} />

          <Separator orientation="row" />
          <Spacer vertical={0.5} />

          {/* Days of the week header */}
          <WeekdayHeader monthOffset={weekOffset} size={size} fontSize={fontSize} />

          <Separator orientation="row" />
          <Spacer vertical={0.5} />

          {/* Week days */}
          <View style={styles.weekdayHeader}>
            {strings.daysOfWeek.map((day, index) => {
              const dayInfo = days[index];
              if (!dayInfo) return null;

              return (
                <View key={`week-header-${weekOffset}-${day}`} style={styles.weekDayCol}>
                  {renderDayItem(dayInfo.date, dayInfo.currentMonth, `week-day-${weekOffset}-${index}`)}
                </View>
              );
            })}
          </View>
        </View>
      );
    },
    [size, renderDayItem, initialScrollIndex, containerWidth, calendarHeight]
  );

  const renderItem = useCallback(
    ({ item }: { item: number }) => {
      switch (mode) {
        case "month":
          return renderMonthItem({ item });
        case "week":
          return renderWeekItem({ item });
        default:
          bottomThrow(mode);
      }
    },
    [mode, renderMonthItem, renderWeekItem]
  );

  const handleViewableItemsChanged = useCallback(
    ({ viewableItems }: { viewableItems: ViewToken[]; changed: ViewToken[] }) => {
      if (viewableItems.length > 0) {
        const viewableItem = viewableItems[0];
        if (viewableItem && typeof viewableItem.index === "number") {
          const itemOffset = viewableItem.index - initialScrollIndex;

          let newStartDate;
          if (mode === "month") {
            newStartDate = addMonths(today, itemOffset);
            newStartDate.setDate(1); // Start of month
            const firstDayOfWeek = newStartDate.getDay();
            newStartDate.setDate(newStartDate.getDate() - firstDayOfWeek); // Move back to start of week (Sunday)
          } else {
            const weekStart = new Date(today);
            weekStart.setDate(weekStart.getDate() - weekStart.getDay());
            newStartDate = addWeeks(weekStart, itemOffset);
          }

          const newEndDate = new Date(newStartDate);
          if (mode === "month") {
            // For month mode, we need to account for 6 full weeks
            newEndDate.setDate(newEndDate.getDate() + 6 * 7 - 1); // 6 weeks * 7 days - 1
          } else {
            newEndDate.setDate(newEndDate.getDate() + 6); // End of week (Saturday)
          }

          const newRange = { start: newStartDate, end: newEndDate };
          props.onScroll?.(newRange);
        }
      }
    },
    [initialScrollIndex, mode, props.onScroll]
  );

  const getItemLayout = useCallback(
    (_: unknown, index: number) => {
      const itemHeight = calendarHeight;
      const itemWidth = containerWidth || Dimensions.get("window").width;

      return {
        length: scrollDirection === "horizontal" ? itemWidth : itemHeight,
        offset: (scrollDirection === "horizontal" ? itemWidth : itemHeight) * index,
        index,
      };
    },
    [calendarHeight, containerWidth, scrollDirection]
  );

  const keyExtractor = useCallback((item: number) => `${mode}-${item.toString()}`, [mode]);

  return (
    <View style={[styles.container, { height: calendarHeight }]} onLayout={onLayout}>
      <FlatList
        key={`calendar-${mode}`}
        ref={flatListRef}
        data={data}
        renderItem={renderItem}
        keyExtractor={keyExtractor}
        // Pre-calculates and caches item dimensions, preventing measurement during scrolling
        // Significant scroll performance boost by avoiding layout calculations, but must manually keep dimensions accurate when items change
        getItemLayout={getItemLayout}
        initialScrollIndex={initialIndex}
        onViewableItemsChanged={handleViewableItemsChanged}
        viewabilityConfig={{
          viewAreaCoveragePercentThreshold: 50,
          minimumViewTime: 200,
        }}
        showsVerticalScrollIndicator={false}
        showsHorizontalScrollIndicator={false}
        horizontal={scrollDirection === "horizontal"}
        pagingEnabled
        scrollEventThrottle={constants.layout.scrollThrottle}
        decelerationRate="fast"
        snapToAlignment="start"
        snapToInterval={scrollDirection === "horizontal" ? containerWidth : calendarHeight}
        bounces={false}
        // Detaches offscreen views from the native view hierarchy
        // Reduces memory usage and improves scrolling performance, can cause blank areas if scrolling too fast
        removeClippedSubviews
        // Controls batch size during a render pass, smaller batches reduce frame drops during scrolling
        // May need more render passes to display all content
        maxToRenderPerBatch={3}
        // Controls render window beyond visible area (5 = visible + 2 screens on each side)
        // Reduces blank areas during scrolling by pre-rendering nearby items
        // Higher values consume more memory and processing power
        windowSize={5}
        // Limits items rendered on initial mount
        // Faster initial render time and reduced memory usage
        // Can cause blank spots if user scrolls quickly after mounting
        initialNumToRender={1}
        // Preserves visible content position during content updates
        maintainVisibleContentPosition={{
          minIndexForVisible: 0,
        }}
        // Controls time interval between batch renders
        updateCellsBatchingPeriod={50}
        // Prevents momentum from continuing across multiple pages
        disableIntervalMomentum
      />
    </View>
  );
});

const DayItem = React.memo(
  (props: {
    date: Date;
    mode: "month" | "week";
    currentMonth: boolean;
    selectedDate: SharedValue<EpochMs>;
    isToday: boolean;
    dots?: { breakfast?: boolean; lunch?: boolean; dinner?: boolean };
    size: "small" | "large";
    onPress: (date: Date) => void;
  }) => {
    // Store the date as an EpochMs for consistent handling
    const dateTimestamp = useRef(props.date.getTime() as EpochMs).current;
    const dateString = useRef(props.date.getDate().toString()).current;
    const isPastDateValue = useRef(isPastDate(props.date)).current;
    const isCurrentMonth = useRef(props.currentMonth).current;
    const mode = useRef(props.mode).current;

    const selectedTimestamp = props.selectedDate;

    // Use worklet-safe date parts comparison
    const isSelected = useDerivedValue(() => {
      const selectedDateParts = epochMsToDateParts(selectedTimestamp.value);
      const currentDateParts = epochMsToDateParts(dateTimestamp);

      return (
        selectedDateParts.year === currentDateParts.year &&
        selectedDateParts.month === currentDateParts.month &&
        selectedDateParts.day === currentDateParts.day
      );
    });

    const onPressInternal = useCallback(() => {
      if (!isSelected.value) {
        Haptics.feedback("itemStatusChanged");
      }
      props.onPress(props.date);
    }, [props.date, props.onPress, isSelected]);

    const selectedDayAnimation = useAnimatedStyle(() => {
      return {
        backgroundColor: isSelected.value ? globalStyleColors.colorAccentCool : "transparent",
      };
    });

    const selectedTextAnimation = useAnimatedStyle(() => {
      return {
        opacity: isSelected.value ? Opacity.opaque : Opacity.transparent,
      };
    });

    const unselectedTextAnimation = useAnimatedStyle(() => {
      return {
        opacity: isSelected.value
          ? Opacity.transparent
          : isPastDateValue || (mode === "month" && !isCurrentMonth)
          ? Opacity.light
          : Opacity.opaque,
      };
    });

    const fontFamily = useFontFamilyMap();

    const fontSize: FontSize = switchReturn(props.size, {
      large: FontSize.body,
      small: FontSize.tertiary,
    });

    const sizeConfig = constants.sizes[props.size];

    return (
      <View style={styles.dayItemContainer}>
        <Pressable onPress={onPressInternal} noFeedback hitSlop={globalStyleConstants.unitSize}>
          <Animated.View
            style={[
              styles.dayItem,
              {
                width: sizeConfig.day.width,
                height: sizeConfig.day.height,
                borderRadius: sizeConfig.day.width / 2,
              },
              props.isToday ? styles.todayDayItem : {},
              selectedDayAnimation,
            ]}
          >
            <View style={[StyleSheet.absoluteFill, { alignItems: "center", justifyContent: "center" }]}>
              <Animated.Text
                style={[
                  {
                    fontFamily: fontFamily.sansSerif,
                    fontSize: fontSize,
                    color: globalStyleColors.white,
                  },
                  selectedTextAnimation,
                ]}
              >
                {dateString}
              </Animated.Text>
            </View>
            <View style={[StyleSheet.absoluteFill, { alignItems: "center", justifyContent: "center" }]}>
              <Animated.Text
                style={[
                  {
                    fontFamily: fontFamily.sansSerif,
                    fontSize: fontSize,
                  },
                  unselectedTextAnimation,
                ]}
              >
                {dateString}
              </Animated.Text>
            </View>
          </Animated.View>
        </Pressable>
        {!!props.dots && (
          <DayDots
            dots={props.dots}
            size={sizeConfig.dot.size}
            marginHorizontal={sizeConfig.dot.marginHorizontal}
            bottom={sizeConfig.dot.bottom}
          />
        )}
      </View>
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.currentMonth === nextProps.currentMonth &&
      prevProps.isToday === nextProps.isToday &&
      prevProps.size === nextProps.size &&
      prevProps.selectedDate === nextProps.selectedDate &&
      JSON.stringify(prevProps.dots) === JSON.stringify(nextProps.dots)
    );
  }
);

const DayDots = React.memo(
  (props: {
    dots: { breakfast?: boolean; lunch?: boolean; dinner?: boolean };
    size: number;
    marginHorizontal: number;
    bottom: number;
  }) => (
    <View style={[styles.dotsContainer, { bottom: props.bottom }]}>
      {props.dots.breakfast && (
        <DayDot size={props.size} marginHorizontal={props.marginHorizontal} color={globalStyleColors.colorAccentWarm} />
      )}
      {props.dots.lunch && (
        <DayDot
          size={props.size}
          marginHorizontal={props.marginHorizontal}
          color={globalStyleColors.colorAccentMidDark}
        />
      )}
      {props.dots.dinner && (
        <DayDot size={props.size} marginHorizontal={props.marginHorizontal} color={globalStyleColors.colorAccentCool} />
      )}
    </View>
  )
);

const DayDot = React.memo((props: { size: number; marginHorizontal: number; color: string }) => (
  <View
    style={[
      styles.dot,
      {
        width: props.size,
        height: props.size,
        borderRadius: props.size / 2,
        marginHorizontal: props.marginHorizontal,
        backgroundColor: props.color,
      },
    ]}
  />
));

const WeekdayHeader = React.memo(
  (props: { monthOffset: number; size: "small" | "large"; fontSize: keyof typeof FontSize }) => (
    <View style={styles.weekdayHeader}>
      {strings.daysOfWeek.map(day => (
        <View
          key={`header-${day}-${props.monthOffset}`}
          style={[
            styles.weekdayText,
            {
              minWidth: constants.sizes[props.size].day.width,
              marginBottom: constants.sizes[props.size].weekdayTextBottomMargin,
            },
          ]}
        >
          <Text fontSize={props.fontSize} align="center" opacity={"dark"}>
            {day}
          </Text>
        </View>
      ))}
    </View>
  )
);

const MonthGrid = React.memo(
  (props: {
    rows: DayInfo[][];
    monthOffset: number;
    renderDayItem: (date: Date, currentMonth: boolean, uniqueKey: string) => JSX.Element;
  }) => (
    <View style={styles.monthGrid}>
      {props.rows.map((week, weekIndex) => (
        <View key={`week-${props.monthOffset}-${weekIndex}`} style={styles.weekRow}>
          {week.map((dayInfo, dayIndex) =>
            props.renderDayItem(
              dayInfo.date,
              dayInfo.currentMonth ?? true,
              `day-${props.monthOffset}-${weekIndex}-${dayIndex}`
            )
          )}
        </View>
      ))}
    </View>
  ),
  (prevProps, nextProps) => {
    return (
      prevProps.monthOffset === nextProps.monthOffset &&
      prevProps.renderDayItem === nextProps.renderDayItem &&
      JSON.stringify(prevProps.rows) === JSON.stringify(nextProps.rows)
    );
  }
);

const CalendarHeader = React.memo((props: { mode: "month" | "week"; date: Date; fontSize: keyof typeof FontSize }) => {
  const month = props.date.getMonth();
  const year = props.date.getFullYear();

  if (props.mode === "month") {
    return (
      <View style={styles.monthYearHeader}>
        <Text fontWeight="medium" fontSize={props.fontSize}>
          {strings.months[month]?.toUpperCase()} {year}
        </Text>
      </View>
    );
  }

  // For week mode, show the date range using utility functions
  const weekStart = getWeekStart(props.date);
  const weekEnd = getWeekEnd(props.date);

  return (
    <View style={styles.monthYearHeader}>
      <Text fontWeight="medium" fontSize={props.fontSize}>
        {formatHeaderDate(weekStart)} - {formatHeaderDate(weekEnd)}
      </Text>
    </View>
  );
});

const styles = StyleSheet.create({
  container: {
    width: "100%",
    overflow: "hidden",
  },
  monthContainer: {
    paddingVertical: constants.layout.verticalPadding,
    paddingHorizontal: constants.layout.horizontalPadding,
  },
  weekContainer: {
    paddingVertical: constants.layout.verticalPadding,
    paddingHorizontal: constants.layout.horizontalPadding,
    justifyContent: "center",
  },
  monthYearHeader: {
    marginBottom: constants.layout.headerBottomMargin,
  },
  weekdayHeader: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: constants.layout.weekdayHeaderBottomMargin,
  },
  weekdayText: {
    alignItems: "center",
  },
  monthGrid: {
    flexDirection: "column",
    flex: 1,
    justifyContent: "space-between",
  },
  weekRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    height: constants.layout.monthRowHeight,
    alignItems: "center",
    paddingVertical: 2,
  },
  weekDayCol: {
    alignItems: "center",
  },
  dayItemContainer: {
    alignItems: "center",
    position: "relative",
  },
  dayItem: {
    alignItems: "center",
    justifyContent: "center",
  },
  todayDayItem: {
    borderWidth: 1,
    borderColor: globalStyleColors.colorAccentCool,
  },
  dotsContainer: {
    flexDirection: "row",
    justifyContent: "center",
    position: "absolute",
    width: "100%",
  },
  dot: {
    backgroundColor: globalStyleColors.colorGreyDark,
  },
});

import { AppRecipeCollection, isStructuredRecipeError, RecipeCollectionId } from "@eatbetter/recipes-shared";
import { navTree, RecipeCollectionBulkActionScreenProps, RecipeCollectionScreenProps } from "../navigation/NavTree";
import { useScreen, withScreenContainer } from "../navigation/ScreenContainer";
import { ScreenView } from "../components/ScreenView";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  RecipeListSort,
  useCollectionInitialSort,
  useFilteredRecipeListSections,
  useRecipeCount,
  useRecipeSearchPhrase,
} from "../lib/composite/RecipeListSelectors.ts";
import {
  useActiveTagsAndFilters,
  useCollectionCanDelete,
  useCollectionCanHide,
  useCollectionCanRename,
  useCollectionExists,
  useCollectionHidden,
  useCollectionName,
  useCollectionSource,
  useCollectionType,
} from "../lib/composite/CollectionsSelectors.ts";
import { RecipeLibraryListView } from "../components/recipes/RecipeLibraryListView.tsx";
import { StyleSheet, View } from "react-native";
import { THeading1, TSecondary } from "../components/Typography.tsx";
import { Spacer } from "../components/Spacer.tsx";
import { globalStyleColors } from "../components/GlobalStyles.ts";
import { useRecipeLibraryScreenHeader } from "../components/recipes/RecipeLibraryScreenHeader.tsx";
import { TextInputHandle } from "../components/TextInput.tsx";
import { HeaderAnimationConfig, useHeaderScrollAnimation } from "../components/ScreenHeaders.tsx";
import { getOptionsMenuHeight, OptionsMenu, OptionsMenuItem } from "../components/OptionsMenu.tsx";
import { RecipeCollectionContextMenuTitle } from "../components/recipes/RecipeCollectionContextMenuTitle.tsx";
import { useInitialRecipeLoadComplete } from "../lib/recipes/RecipesSelectors.ts";
import { RecipeAddButton } from "../components/recipes/RecipeAddButton.tsx";
import { useCollectionBulkAction } from "./RecipeCollectionBulkActionScreen.tsx";
import { useDispatch } from "../lib/redux/Redux.ts";
import { bottomThrow, switchReturn } from "@eatbetter/common-shared";
import { editCollections } from "../lib/recipes/RecipesThunks.ts";
import { displayExpectedError, displayUnexpectedErrorAndLog } from "../lib/Errors.ts";
import { useRenameRecipeCollection } from "./RecipeCollectionRenameScreen.tsx";
import { useBottomSheet } from "./BottomSheetScreen.tsx";
import { useDeleteCollection } from "../components/recipes/RecipeCollectionDeleteConfirmation.tsx";
import { Haptics } from "../components/Haptics.ts";
import { useLibraryFilterSession } from "../lib/recipes/UseLibraryFilterSession.ts";
import { LibraryFilterSessionId } from "../lib/composite/LibraryAndSearchSessionIds.ts";
import { withNavDelay } from "../lib/util/WithNavDelay.ts";
import {
  reportCollectionContextMenuTapped,
  reportDeleteCollection,
  reportHideUnhideCollection,
  reportRecipeCollectionViewed,
  reportRenameCollection,
} from "../lib/analytics/AnalyticsEvents.ts";
import { analyticsEvent } from "../lib/analytics/AnalyticsThunks.ts";

const strings = {
  emptyState: {
    headline: "No Recipes",
    subheadlineUserOrHousehold: ["Tap + to ", "add recipes", " to this collection"],
    subheadlineSystem:
      "This is a smart collection. Recipes will automatically show up here when they match its smart filter.",
  },
  searchBoxPlaceholder: (collectionName: string) => `Search ${collectionName}`,
  optionsMenu: {
    addRecipes: "Select Recipes to Add",
    selectAndRemoveRecipes: "Select Recipes to Remove",
    selectAndDeleteRecipes: "Select Recipes to Delete",
    rename: "Rename Collection",
    delete: "Delete Collection",
    hideUnhide: (collectionSource: AppRecipeCollection["source"], action: "Hide" | "Unhide") =>
      switchReturn(collectionSource, {
        household: `${action} Household Collection`,
        system: `${action} Smart Collection`,
        user: "",
      }),
  },
  addRecipesActionSuffix: (collectionName: string) => `Add to ${collectionName}`,
};

export const RecipeCollectionScreen = withScreenContainer<RecipeCollectionScreenProps>(
  "RecipeCollectionScreen",
  props => {
    const dispatch = useDispatch();
    const screen = useScreen();
    const sessionId = useLibraryFilterSession({ collectionId: props.collectionId, action: "include" });
    const collectionExists = useCollectionExists(props.collectionId);
    const collectionName = useCollectionName(props.collectionId);
    const collectionType = useCollectionType(props.collectionId);
    const collectionSourceType = useCollectionSource(props.collectionId);
    const collectionHidden = useCollectionHidden(props.collectionId);
    const collectionCanRename = useCollectionCanRename(props.collectionId);
    const collectionCanDelete = useCollectionCanDelete(props.collectionId);
    const collectionCandHide = useCollectionCanHide(props.collectionId);
    const initialSort = useCollectionInitialSort(props.collectionId);
    const [sort, setSort] = useState<RecipeListSort>(initialSort);
    const sections = useFilteredRecipeListSections(sessionId, sort);
    const resultCount = useRecipeCount(sections);

    const searchPhrase = useRecipeSearchPhrase(sessionId) ?? "";
    const activeTags = useActiveTagsAndFilters(sessionId);
    const recipesLoading = !useInitialRecipeLoadComplete();

    const isSearchMode = activeTags.length > 0 || !!searchPhrase;
    const emptyState = !isSearchMode && resultCount === 0 && !recipesLoading;

    const searchBoxRef = useRef<TextInputHandle>(null);
    const [headerAnimationRef, onScroll] = useHeaderScrollAnimation();

    // If collection is deleted, nav back
    useEffect(() => {
      if (!collectionExists && screen.nav.focused) {
        withNavDelay(() => screen.nav.goBack());
      }
    }, [collectionExists, screen.nav.focused]);

    // Analytics
    useEffect(() => {
      dispatch(analyticsEvent(reportRecipeCollectionViewed({ collectionName, sort })));
    }, []);

    const headerAnimation: HeaderAnimationConfig = useMemo(() => {
      return {
        animationProgress: headerAnimationRef,
      };
    }, [headerAnimationRef]);

    const openContextMenu = useCollectionContextMenu({
      collectionId: props.collectionId,
      collectionType,
      sourceType: collectionSourceType,
      haveRecipes: !emptyState,
      canDelete: collectionCanDelete,
      canHide: collectionCandHide,
      canRename: collectionCanRename,
      sort,
      isHidden: collectionHidden,
      sessionId,
    });

    const screenHeader = useRecipeLibraryScreenHeader({
      sessionId,
      title: collectionName,
      searchBoxRef,
      searchBoxPlaceholder: strings.searchBoxPlaceholder(collectionName),
      animationConfig: headerAnimation,
      resultCount,
      openContextMenu,
      sort: sort,
      onChangeSort: setSort,
    });

    return (
      <ScreenView
        header={screenHeader}
        scrollView={false}
        paddingHorizontal={false}
        paddingVertical={false}
        loading={!collectionExists}
      >
        {!emptyState && (
          <RecipeLibraryListView
            sections={sections}
            resultCount={resultCount}
            onScroll={onScroll}
            sort={sort}
            sessionId={sessionId}
          />
        )}
        {emptyState && (
          <EmptyState
            collectionId={props.collectionId}
            collectionType={collectionType}
            sessionId={sessionId}
            sort={sort}
          />
        )}
        <RecipeAddButton
          context={collectionType === "filter" ? "filterCollection" : "nonFilterCollection"}
          collectionId={props.collectionId}
          sessionId={sessionId}
        />
      </ScreenView>
    );
  },
  {
    parser: {
      collectionId: s => s as RecipeCollectionId,
    },
    serializer: {
      collectionId: s => s,
    },
  }
);

const EmptyState = React.memo(
  (props: {
    collectionId: RecipeCollectionId;
    collectionType: AppRecipeCollection["type"];
    sessionId: LibraryFilterSessionId;
    sort: RecipeListSort;
  }) => {
    const screen = useScreen();

    const onPressAddRecipe = () => {
      screen.nav.modal(navTree.get.screens.recipeCollectionBulkAction, {
        action: "addToCollection",
        collectionId: props.collectionId,
        existingSessionId: props.sessionId,
        sort: props.sort,
      });
    };

    const renderSubheader = () => {
      switch (props.collectionType) {
        case "tag": {
          return (
            <TSecondary align="center">
              <TSecondary opacity="medium">{strings.emptyState.subheadlineUserOrHousehold[0]}</TSecondary>
              <TSecondary
                onPress={onPressAddRecipe}
                suppressHighlighting
                fontWeight="medium"
                color={globalStyleColors.colorTextLink}
              >
                {strings.emptyState.subheadlineUserOrHousehold[1]}
              </TSecondary>
              <TSecondary opacity="medium">{strings.emptyState.subheadlineUserOrHousehold[2]}</TSecondary>
            </TSecondary>
          );
        }
        case "filter": {
          return (
            <TSecondary align="center" opacity="medium">
              <TSecondary>{strings.emptyState.subheadlineSystem}</TSecondary>
            </TSecondary>
          );
        }
        default:
          bottomThrow(props.collectionType);
      }
    };

    return (
      <View style={[StyleSheet.absoluteFill, { justifyContent: "center", alignItems: "center" }]}>
        <THeading1 opacity="dark">{strings.emptyState.headline}</THeading1>
        <Spacer vertical={0.25} />
        <View style={{ maxWidth: "80%" }}>{renderSubheader()}</View>
      </View>
    );
  }
);

function useCollectionContextMenu(props: CollectionContextMenuProps) {
  const dispatch = useDispatch();
  const screen = useScreen();

  const openCollectionsContextMenu = useCallback(() => {
    dispatch(analyticsEvent(reportCollectionContextMenuTapped()));
    screen.nav.modal(navTree.get.screens.bottomSheet, {
      content: <CollectionContextMenu {...props} />,
      height: getCollectionContextMenuHeight({
        type: props.collectionType,
        haveRecipes: props.haveRecipes,
        canDelete: props.canDelete,
        canHide: props.canHide,
        canRename: props.canRename,
      }),
    });
  }, [props, screen.nav.modal, dispatch]);

  return openCollectionsContextMenu;
}

interface CollectionContextMenuProps {
  collectionId: RecipeCollectionId;
  haveRecipes: boolean;
  collectionType: AppRecipeCollection["type"];
  sourceType: AppRecipeCollection["source"];
  canRename: boolean;
  canDelete: boolean;
  canHide: boolean;
  sort?: RecipeListSort;
  isHidden: boolean;
  sessionId: LibraryFilterSessionId;
}

function getCollectionContextMenuHeight(args: {
  haveRecipes: boolean;
  type: AppRecipeCollection["type"];
  canHide: boolean;
  canDelete: boolean;
  canRename: boolean;
}) {
  let height = 0;

  if (args.type !== "filter") {
    // Add recipes
    height += 1;
  }

  if (args.haveRecipes) {
    if (args.type !== "filter") {
      // Remove recipes
      height += 1;
    }
    // Delete recipes
    height += 1;
  }

  if (args.canRename) {
    height += 1;
  }

  if (args.canDelete) {
    height += 1;
  }

  if (args.canHide) {
    // Hide or unhide
    height += 1;
  }

  return getOptionsMenuHeight(height, true);
}

const CollectionContextMenu = React.memo((props: CollectionContextMenuProps) => {
  const bottomSheet = useBottomSheet();
  const dispatch = useDispatch();
  const collectionName = useCollectionName(props.collectionId);

  const [action, setAction] = useState<RecipeCollectionBulkActionScreenProps["action"]>();
  const actionPerformed = useRef(false);

  const [waitingDelete, setWaitingDelete] = useState(false);
  const [waitingRename, setWaitingRename] = useState(false);
  const [waitingHide, setWaitingHide] = useState(false);

  const selectAndApplyBulkAction = useCollectionBulkAction({
    action: action ?? "addToCollection",
    collectionId: props.collectionId,
    sort: props.sort ?? "default",
    existingSessionId: props.sessionId,
  });

  useEffect(() => {
    if (selectAndApplyBulkAction && !actionPerformed.current && action !== undefined) {
      selectAndApplyBulkAction();
      actionPerformed.current = true;
    }
  }, [action, selectAndApplyBulkAction]);

  const onPressAddRecipes = useCallback(() => {
    setAction("addToCollection");
  }, [setAction]);

  const onPressRemoveRecipes = useCallback(() => {
    setAction("removeFromCollection");
  }, [setAction]);

  const onPressDeleteRecipes = useCallback(() => {
    setAction("deleteRecipes");
  }, [setAction]);

  const renameCollection = useCallback(
    async (to: string) => {
      try {
        await dispatch(
          editCollections(
            {
              collection: { op: { type: "rename", id: props.collectionId, from: collectionName, to } },
            },
            setWaitingRename
          )
        );
        dispatch(analyticsEvent(reportRenameCollection({ collectionId: props.collectionId, collectionName: to })));
      } catch (err) {
        // THIS ERROR HANDLER IS DUPLICATED IN 3 PLACES. ANY CHANGES HERE MIGHT NEED TO BE MADE IN THOSE PLACES AS WELL
        // SEE CreateRecipeCollectionsScreen.tsx
        // SEE RecipeCollectionsEdit.tsx
        if (isStructuredRecipeError(err) && err.data.code === "recipes/collectionNameInvalidError") {
          displayExpectedError(err.data.payload.userMessage);
        } else {
          displayUnexpectedErrorAndLog("renameCollection: error caught dispatching editCollections", err, {
            collectionId: props.collectionId,
            from: collectionName,
            to,
          });
        }
        // this is needed to prevent nav back after rename.
        throw err;
      }
    },
    [dispatch, props.collectionId, collectionName, setWaitingRename]
  );

  const deleteCollection = useCallback(
    async (collectionId: RecipeCollectionId) => {
      try {
        await dispatch(
          editCollections(
            {
              collection: { op: { type: "delete", id: collectionId } },
            },
            setWaitingDelete
          )
        );
        dispatch(analyticsEvent(reportDeleteCollection({ collectionId, collectionName })));
      } catch (err) {
        displayUnexpectedErrorAndLog("deleteCollection: error caught dispatching editCollections", err, {
          collectionId,
        });
        throw err;
      }
    },
    [dispatch, setWaitingDelete, collectionName]
  );

  const onPressRenameCollection = useRenameRecipeCollection({ collectionId: props.collectionId, renameCollection });

  const onPressDeleteCollection = useDeleteCollection({
    collectionId: props.collectionId,
    deleteCollection,
    setWaitingDelete,
  });

  const toggleHide = useCallback(async () => {
    const action = props.isHidden ? "unhide" : "hide";

    try {
      await dispatch(
        editCollections(
          {
            collection: { op: { type: action, id: props.collectionId } },
          },
          setWaitingHide
        )
      );
      Haptics.feedback("operationSucceeded");
      dispatch(
        analyticsEvent(
          reportHideUnhideCollection({
            action,
            collectionId: props.collectionId,
            collectionName,
            collectionSourceType: props.sourceType,
          })
        )
      );
      bottomSheet?.closeSheetAndGoBack();
    } catch (err) {
      displayUnexpectedErrorAndLog("hideCollection: error caught dispatching editCollections", err, {
        collectionId: props.collectionId,
        hidden: props.isHidden,
      });
    }
  }, [props.isHidden, props.collectionId, props.collectionType, collectionName, bottomSheet]);

  return (
    <OptionsMenu
      header={<RecipeCollectionContextMenuTitle collectionName={collectionName} collectionSource={props.sourceType} />}
    >
      {props.collectionType !== "filter" && (
        <OptionsMenuItem isFirst icon={"addFolder"} text={strings.optionsMenu.addRecipes} onPress={onPressAddRecipes} />
      )}
      {props.haveRecipes && (
        <>
          {props.collectionType !== "filter" && (
            <OptionsMenuItem
              icon={"removeFolder"}
              text={strings.optionsMenu.selectAndRemoveRecipes}
              onPress={onPressRemoveRecipes}
            />
          )}
          <OptionsMenuItem
            isFirst={props.collectionType === "filter"}
            icon={"delete"}
            destructive={false}
            text={strings.optionsMenu.selectAndDeleteRecipes}
            onPress={onPressDeleteRecipes}
          />
        </>
      )}
      {props.canRename && (
        <OptionsMenuItem
          icon={"editPencil"}
          text={strings.optionsMenu.rename}
          onPress={onPressRenameCollection}
          waiting={waitingRename}
        />
      )}
      {props.canDelete && (
        <OptionsMenuItem
          icon={"delete"}
          text={strings.optionsMenu.delete}
          onPress={onPressDeleteCollection}
          waiting={waitingDelete}
        />
      )}
      {props.canHide && (
        <>
          {!props.isHidden && (
            <OptionsMenuItem
              icon={"hide"}
              text={strings.optionsMenu.hideUnhide(props.sourceType, "Hide")}
              onPress={toggleHide}
              waiting={waitingHide}
            />
          )}
          {props.isHidden && (
            <OptionsMenuItem
              icon={"unhide"}
              text={strings.optionsMenu.hideUnhide(props.sourceType, "Unhide")}
              onPress={toggleHide}
              waiting={waitingHide}
            />
          )}
        </>
      )}
    </OptionsMenu>
  );
});

import {
  AppUserRecipe,
  SystemFilterRecipeCollectionId,
  isSystemRecipeCollectionId,
  RecipeCollectionId,
} from "@eatbetter/recipes-shared";
import {
  arrayMap,
  bottomWithDefault,
  daysBetween,
  defaultTimeProvider,
  DurationMs,
  getDurationInMilliseconds,
  UserId,
} from "@eatbetter/common-shared";
import { DeglazeUser } from "@eatbetter/users-shared";

/************************************
 * OVERVIEW
 * There are 2 types of filters that we deal with
 * A filter recipe collection appears on the recipe collection page and *not* on the filter page.
 * A filter collection is something like "All Recipes" or "Recently Added"
 * A RecipeFilter appears only on the filter page and not on the collection page
 * A RecipeFilter is something like "30 minutes or less" or "Never cooked"
 * Both have associated predicates
 ************************************/

/*************************************
 * EXPORTED FUNCTIONS
 ************************************/

export function isFilterMatch(f1: RecipeFilter, f2: RecipeFilter) {
  return f1.filterType === f2.filterType && f1.filterName === f2.filterName;
}

export function getTotalTimeFilters(): TotalTimeFilter[] {
  const times = [
    { name: "30 min", totalTime: getDurationInMilliseconds({ minutes: 30 }) },
    { name: "45 min", totalTime: getDurationInMilliseconds({ minutes: 45 }) },
    { name: "1 hour", totalTime: getDurationInMilliseconds({ hours: 1 }) },
    { name: "2 hours", totalTime: getDurationInMilliseconds({ hours: 2 }) },
    { name: "4 hours", totalTime: getDurationInMilliseconds({ hours: 4 }) },
    { name: "1 day", totalTime: getDurationInMilliseconds({ days: 1 }) },
  ];

  return times.map<TotalTimeFilter>(t => {
    return {
      filterType: "totalTime",
      filterName: t.name,
      appliedName: `Under ${t.name}`,
      exclusive: true,
      maxTotalTime: t.totalTime,
    };
  });
}

export function getCookedFilters(): CookedFilter[] {
  return [
    {
      filterType: "cooked",
      filterName: "Never cooked",
      minCookCount: 0,
      maxCookCount: 0,
      exclusive: true,
    },
    {
      filterType: "cooked",
      filterName: "Cooked",
      minCookCount: 1,
      maxCookCount: Number.MAX_SAFE_INTEGER,
      exclusive: true,
    },
  ];
}

export function getRatingFilters(): RecipeRatingFilter[] {
  const ratings = [
    { name: "5-star", rating: 5 },
    { name: "4-star", rating: 4 },
    { name: "3-star", rating: 3 },
    { name: "2-star", rating: 2 },
    { name: "1-star", rating: 1 },
    { name: "Not rated", rating: null },
  ] as const;

  return ratings.map<RecipeRatingFilter>(r => {
    return {
      filterType: "rating",
      filterName: r.name,
      rating: r.rating,
      exclusive: false,
    };
  });
}

export function getAddedByFilters(userId: UserId, users: DeglazeUser[]): AddedByFilter[] {
  return users
    .filter(u => !u.isAnonymous)
    .map<AddedByFilter>(u => {
      const name = u.userId === userId ? "Me" : `@${u.username}`;
      const appliedName = u.userId === userId ? "me" : `@${u.username}`;
      return {
        filterType: "addedBy",
        filterName: name,
        appliedName: `Added by ${appliedName}`,
        addedBy: u.userId,
        exclusive: true,
      };
    });
}

export function getAppFilterCollectionMeta(cid: RecipeCollectionId): AppFilterCollectionMeta | undefined {
  if (!isSystemRecipeCollectionId(cid)) {
    return undefined;
  }

  return appFilterCollections[cid as keyof typeof appFilterCollections];
}

/**
 * Get a composite predicate for all filters. This currently applies AND logic for everything, but if we wish to support
 * non-exclusive filters, we can OR filters that have the same filterType and exclusive: false
 * @param f
 */
export function getFiltersPredicate(f: RecipeFilter[]): (r: AppUserRecipe) => boolean {
  const [orMap, addToOrMap] = arrayMap<RecipeFilter["filterType"], RecipeFilter>();
  const andFilters: RecipeFilter[] = [];

  f.forEach(filter => {
    if (filter.exclusive) {
      andFilters.push(filter);
    } else {
      addToOrMap(filter.filterType, filter);
    }
  });

  const orPredicates = Object.values(orMap).map(list => {
    const predicates = list.map(getFilterPredicate);
    return (r: AppUserRecipe) => predicates.some(p => p(r));
  });

  const fns = [...andFilters.map(getFilterPredicate), ...orPredicates];
  return (r: AppUserRecipe) => fns.every(fn => fn(r));
}

export function getFilterPredicate(f: RecipeFilter): (r: AppUserRecipe) => boolean {
  return (r: AppUserRecipe) => {
    switch (f.filterType) {
      case "totalTime": {
        return !!r.time?.total && r.time?.total[0] <= f.maxTotalTime;
      }
      case "cooked": {
        const cookedCount = r.stats.cooked ?? 0;
        return cookedCount >= f.minCookCount && cookedCount <= f.maxCookCount;
      }
      case "addedBy": {
        return r.userId === f.addedBy;
      }
      case "rating": {
        const rating = r.rating?.type === "1to5Star" ? r.rating.rating : null;
        return rating === f.rating;
      }
      default:
        return bottomWithDefault(f, false, "getFilterPredicate");
    }
  };
}

/*************************************
 * EXPORTED TYPES
 ************************************/

export interface AppFilterCollectionMeta {
  predicate: (r: AppUserRecipe) => boolean;
}

export type RecipeFilter = RecipeRatingFilter | CookedFilter | TotalTimeFilter | AddedByFilter;

export interface RecipeRatingFilter extends RecipeFilterBase<"rating", false> {
  rating: number | null;
}

export interface CookedFilter extends RecipeFilterBase<"cooked", true> {
  minCookCount: number;
  maxCookCount: number;
}

export interface TotalTimeFilter extends RecipeFilterBase<"totalTime", true> {
  maxTotalTime: DurationMs;
}

export interface AddedByFilter extends RecipeFilterBase<"addedBy", true> {
  addedBy: UserId;
}

/*************************************
 * PRIVATE TYPES AND FUNCTIONS
 ************************************/

interface RecipeFilterBase<TType extends string, TExclusive extends boolean> {
  filterType: TType;
  /**
   * The display name for this filter. Must be unique within filterType: TType.
   * For example, for time, a filterName might be "30 minutes"
   */
  filterName: string;

  /**
   * In some cases, we want the name that displays in the seledcted filter pill to be different.
   * If set, this name will be used.
   * For example, "30 minutes" on the filter screen might be displayed as "Under 30 min"
   */
  appliedName?: string;

  /**
   * If true, only one filter from the filterType can be applied at any given time.
   */
  exclusive: TExclusive;
}

const appFilterCollections: Record<SystemFilterRecipeCollectionId, AppFilterCollectionMeta> = {
  // "sc:filter:active_recipes" AKA activeRecipesCollectionId is special cased since it acts on more
  // than just the recipe itself (it needs external info - specifically the grocery list recipes
  // and cooking session recipes. See selectActiveRecipeCollectionPredicate in RecipeListSelectors.
  "sc:filter:all_recipes": {
    predicate: () => true,
  },
  "sc:filter:recently_added": {
    predicate: r => daysBetween(r.created, defaultTimeProvider()) <= 7,
  },
};

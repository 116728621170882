import React, { useCallback, useLayoutEffect, useMemo, useRef } from "react";
import { useInitialRecipeLoadComplete } from "../../lib/recipes/RecipesSelectors";
import { RecipeListSort, useRecipeSearchPhrase } from "../../lib/composite/RecipeListSelectors";
import { SearchAndFilterBar, useSearchAndFilterBarHeight } from "../SearchBox";
import { CustomHeaderProps, HeaderAnimationConfig, HeaderProps } from "../ScreenHeaders";
import { TextInputHandle } from "../TextInput";
import { useDispatch } from "../../lib/redux/Redux";
import { recipeLibraryFiltersChanged, searchRecipeLibrary } from "../../lib/recipes/RecipesThunks";
import {
  removeAllLibraryFiltersExceptCollection,
  removeTagOrFilterFromLibraryFilter,
} from "../../lib/recipes/RecipesSlice";
import { navTree } from "../../navigation/NavTree";
import { useScreen } from "../../navigation/ScreenContainer";
import { analyticsEvent } from "../../lib/analytics/AnalyticsThunks";
import { reportLibraryAddFilterButtonPressed } from "../../lib/analytics/AnalyticsEvents";
import { Haptics } from "../Haptics";
import { HeaderRightProps } from "../ScreenHeaderRightButtons";
import { LayoutAnimation } from "react-native";
import {
  RecipeTagOrFilter,
  useActiveTagsAndFilters,
  useLibraryFilterSessionCollectionToInclude,
} from "../../lib/composite/CollectionsSelectors.ts";
import { useRecipeListSortMenu } from "../../screens/RecipeListSortMenu.tsx";
import { globalStyleColors } from "../GlobalStyles.ts";
import { HeaderLeftProps } from "../ScreenHeaderLeftButtons.tsx";
import { LibraryFilterSessionId } from "../../lib/composite/LibraryAndSearchSessionIds.ts";
import { withNavDelay } from "../../lib/util/WithNavDelay.ts";
import { TBody, TSecondary } from "../Typography.tsx";
import { Spacer } from "../Spacer.tsx";
import { FeatureDemoSnippet } from "../FeatureDemoSnippet.tsx";
import { cookingAndShoppedCollectionId } from "@eatbetter/recipes-shared";

const strings = {
  searchPlaceholder: "Search your library",
  customizeCollectionsWalkthrough: {
    headline: "Customize Your Way",
    subheadline: "Tap here to reorder, hide, or delete your collections.",
  },
};

export function useRecipeLibraryScreenHeader(
  props: Pick<CustomHeaderProps, "title" | "style"> & {
    sessionId: LibraryFilterSessionId;
    searchBoxRef: React.RefObject<TextInputHandle>;
    searchBoxPlaceholder?: string;
    animationConfig?: HeaderAnimationConfig;
    openContextMenu?: () => void;
    resultCount: number;
    sort?: RecipeListSort;
    onChangeSort?: (v: RecipeListSort) => void;
    onCancel?: () => void;
    showWalkthrough?: "customizeCollections" | "filters";
    onWalkthroughNext?: () => void;
  }
): HeaderProps {
  const {
    sessionId,
    title,
    style,
    searchBoxRef,
    searchBoxPlaceholder,
    openContextMenu,
    animationConfig,
    resultCount,
    sort,
    onChangeSort,
    onCancel,
    showWalkthrough,
    onWalkthroughNext,
  } = props;

  const dispatch = useDispatch();
  const screen = useScreen();
  const searchPhrase = useRecipeSearchPhrase(sessionId) ?? "";
  const collectionId = useLibraryFilterSessionCollectionToInclude(sessionId);
  const activeTags = useActiveTagsAndFilters(sessionId);
  const recipesLoading = !useInitialRecipeLoadComplete();

  const isSearchMode = activeTags.length > 0 || !!searchPhrase;
  const emptyState = !isSearchMode && resultCount === 0 && !recipesLoading;

  const searchAndFilterBarHeight = useSearchAndFilterBarHeight(activeTags);

  const openSortMenu = useRecipeListSortMenu({ activeSort: sort, onChangeSort: onChangeSort });

  const isInitialRender = useRef(true);

  // Animate layout changes when results change or a tag filter is added/removed. Without layout effect, we don't
  // get the animation here.
  useLayoutEffect(() => {
    if (screen.nav.focused && !isInitialRender.current) {
      LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
    } else {
      // Don't start animating layout changes until the screen has finished mounting to avoid animation during nav
      withNavDelay(() => {
        isInitialRender.current = false;
      });
    }
  }, [activeTags]);

  const onChangeSearchPhrase = useCallback(
    (value: string) => {
      // I tried debouncing by using a state variable to set the display text
      // and then use a debounced function called from the change handler to update
      // redux. Performance was better without the debounce. The lagginess in the sim
      // is much less of an issue on device, even with 1000 recipes.
      dispatch(searchRecipeLibrary({ query: value, sessionId }));
    },
    [searchPhrase, sessionId]
  );

  const onPressFilterButton = useCallback(() => {
    dispatch(analyticsEvent(reportLibraryAddFilterButtonPressed()));
    screen.nav.modal(navTree.get.screens.recipesFilter, { sessionId });
  }, [dispatch, screen.nav.modal, sessionId]);

  const onRemoveFilter = useCallback(
    (tagOrFilter: RecipeTagOrFilter) => {
      Haptics.feedback("itemDeleted");
      dispatch(removeTagOrFilterFromLibraryFilter({ tagOrFilter, sessionId }));
      dispatch(recipeLibraryFiltersChanged(sessionId));
    },
    [dispatch, sessionId]
  );

  const onPressClearSearchAndFilters = useCallback(() => {
    Haptics.feedback("itemDeleted");
    dispatch(removeAllLibraryFiltersExceptCollection(sessionId));
  }, [dispatch, sessionId]);

  const renderSearchAndFilterBar = useCallback(() => {
    if (emptyState || recipesLoading) {
      return null;
    }

    return (
      <SearchAndFilterBar
        ref={searchBoxRef}
        placeholderText={searchBoxPlaceholder ?? strings.searchPlaceholder}
        searchPhrase={searchPhrase}
        onChangeSearchPhrase={onChangeSearchPhrase}
        onPressFilterButton={onPressFilterButton}
        activeTagFilters={activeTags}
        onRemoveFilter={onRemoveFilter}
        cancelAction={isSearchMode ? "clear" : undefined}
        onPressCancel={onPressClearSearchAndFilters}
        walkthrough={props.showWalkthrough === "filters" ? "filters" : undefined}
        onWalkthroughNext={props.onWalkthroughNext}
      />
    );
  }, [
    emptyState,
    recipesLoading,
    searchPhrase,
    onChangeSearchPhrase,
    onPressFilterButton,
    isSearchMode,
    activeTags,
    onRemoveFilter,
    onPressClearSearchAndFilters,
    searchBoxRef,
    props.showWalkthrough,
    props.onWalkthroughNext,
  ]);

  return useMemo<HeaderProps>(() => {
    const contextMenuButton: HeaderRightProps | undefined =
      openContextMenu && collectionId !== cookingAndShoppedCollectionId
        ? {
            type: "menu",
            onPress: openContextMenu,
            walkthrough:
              props.showWalkthrough === "customizeCollections"
                ? {
                    show: true,
                    buttonText: "next",
                    message: (
                      <>
                        <TBody fontWeight="medium" align="center">
                          {strings.customizeCollectionsWalkthrough.headline}
                        </TBody>
                        <Spacer vertical={0.25} />
                        <TSecondary align="center">{strings.customizeCollectionsWalkthrough.subheadline}</TSecondary>
                        <Spacer vertical={1} />
                        <FeatureDemoSnippet type="reorderCollections" />
                      </>
                    ),
                    wobble: false,
                    onPressButton: props.onWalkthroughNext,
                    onPressChildComponent: () => {},
                  }
                : undefined,
          }
        : undefined;

    const sortMenuButton: HeaderRightProps | undefined = openSortMenu
      ? {
          type: "sort",
          onPress: openSortMenu,
          activeSort: sort ?? "default",
          disabled: resultCount < 2,
        }
      : undefined;

    const right: HeaderRightProps | undefined =
      contextMenuButton && sortMenuButton
        ? {
            type: "twoButtons",
            left: sortMenuButton,
            right: contextMenuButton,
          }
        : contextMenuButton
        ? contextMenuButton
        : sortMenuButton
        ? sortMenuButton
        : undefined;

    const left: HeaderLeftProps | undefined = onCancel
      ? {
          type: "cancel",
          onPress: onCancel,
          bold: true,
          color: globalStyleColors.colorAccentCool,
        }
      : undefined;

    return {
      type: "custom",
      style,
      title,
      right,
      left,
      animationConfig,
      subHeaderComponent: {
        render: renderSearchAndFilterBar,
        getHeight: () => searchAndFilterBarHeight,
      },
    };
  }, [
    style,
    title,
    animationConfig,
    renderSearchAndFilterBar,
    searchAndFilterBarHeight,
    openContextMenu,
    openSortMenu,
    sort,
    onCancel,
    resultCount,
    showWalkthrough,
    onWalkthroughNext,
    collectionId,
  ]);
}

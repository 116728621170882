import { useCallback, useMemo, useState } from "react";
import { ScreenView } from "../components/ScreenView";
import { useScreen, withScreenContainer } from "../navigation/ScreenContainer";
import { HeaderProps, useScreenHeaderDimensions } from "../components/ScreenHeaders";
import { TBody } from "../components/Typography";
import { InputAccessoryView } from "../components/InputAccessoryView";
import { BottomActionBar, bottomActionBarConstants } from "../components/BottomActionBar";
import { Spacer } from "../components/Spacer";
import { globalStyleConstants } from "../components/GlobalStyles";
import { ScrollView } from "react-native-gesture-handler";
import { utf8ByteSize } from "@eatbetter/common-shared";
import { useProfileEditGoBackHandler } from "../components/social/ProfileEditFieldScreenComponent";
import { Haptics } from "../components/Haptics";
import { useToast } from "../components/Toast";
import { reportIssue } from "../lib/system/SystemThunks";
import { useDispatch } from "../lib/redux/Redux";
import { displayUnexpectedErrorAndLog } from "../lib/Errors";
import { SupportLink } from "../components/SupportComponents";
import { useKeyboardLayoutAnimation } from "../components/Keyboard";
import { ParagraphTextInput } from "../components/ParagraphTextInput";

const strings = {
  screenTitle: "Send us your feedback",
  prompt: "Your feedback means the world to us.\nHow can we make Deglaze better for you?",
  placeholder: "Tell us what you think...",
  submit: "Submit",
  errorSubmitting: "We hit a snag submitting your feedback. Please try again.",
  toastConfirmation: "Your feedback was submitted, thanks!",
  needResponse: "Need a response? Email us instead:\n",
};

export const FeedbackScreen = withScreenContainer("FeedbackScreen", () => {
  const dispatch = useDispatch();
  const screen = useScreen();
  const { headerHeight } = useScreenHeaderDimensions();
  const keyboardHeight = useKeyboardLayoutAnimation();
  const [text, setText] = useState("");
  const [waiting, setWaiting] = useState(false);

  const onPressBack = useProfileEditGoBackHandler(!!text);

  const toast = useToast();

  const onSubmit = useCallback(async () => {
    try {
      await dispatch(reportIssue({ type: "userFeedbackSubmitted", userFeedbackText: text }, setWaiting));
      Haptics.feedback("operationSucceeded");
      screen.nav.goBack();
      toast.show({ type: "infoToast", message: strings.toastConfirmation });
    } catch (err) {
      displayUnexpectedErrorAndLog("Error dispatching reportIssue from FeedbackScreen", err, { feedbackText: text });
    }
  }, [setWaiting, text, dispatch, screen.nav.goBack, toast]);

  const header = useMemo<HeaderProps>(() => {
    return {
      type: "custom",
      title: strings.screenTitle,
      backgroundColor: "white",
      onPressBack,
    };
  }, [onPressBack]);

  return (
    <ScreenView header={header} scrollView={false} paddingVertical="none">
      <ScrollView
        keyboardShouldPersistTaps={"always"}
        contentContainerStyle={{
          paddingTop: headerHeight + globalStyleConstants.unitSize,
          paddingBottom: keyboardHeight + bottomActionBarConstants.height + globalStyleConstants.unitSize,
        }}
        showsVerticalScrollIndicator={false}
      >
        <Spacer vertical={1} />
        <TBody align="center">{strings.prompt}</TBody>
        <Spacer vertical={1.5} />
        <ParagraphTextInput text={text} onChangeText={setText} placeholderText={strings.placeholder} autofocus />
        <Spacer vertical={1.5} />
        <TBody align="center" opacity="dark">
          <TBody>{strings.needResponse}</TBody>
          <SupportLink />
        </TBody>
      </ScrollView>
      <InputAccessoryView snapPoint={{ bottom: "tabBar" }}>
        <BottomActionBar
          primaryAction={{
            onPressAction: onSubmit,
            actionText: strings.submit,
            waiting,
            disabled: !text || utf8ByteSize(text) > 8192,
            singlePress: true,
          }}
          disableSnapToBottom
        />
      </InputAccessoryView>
    </ScreenView>
  );
});

import { switchReturn } from "@eatbetter/common-shared";
import React, { PropsWithChildren } from "react";
import { StyleSheet, View } from "react-native";
import { globalStyleColors, globalStyleConstants } from "./GlobalStyles";
import {
  IconOpenLink,
  IconCart,
  IconCookPotOpen,
  IconEditSquare,
  IconLink,
  IconShare,
  IconArchive,
  IconDelete,
  IconText,
  IconClock,
  IconNote,
  IconTag,
  IconFlag,
  IconSpeakerMuted,
  IconSpeakerOn,
  IconOpenExternal,
  IconStar,
  IconCamera,
  IconImage,
  IconBook,
  IconEdit,
  IconSwitch,
  IconSort,
  IconSettings,
  IconPlus,
  IconHide,
  IconUnhide,
  IconFolderPlus,
  IconFolderMinus,
} from "./Icons";
import { Pressable } from "./Pressable";
import { Spacer } from "./Spacer";
import { Spinner } from "./Spinner";
import { TBody, TSecondary } from "./Typography";
import { Separator } from "./Separator";
import { smallScreenBreakpoint } from "./Responsive";
import { NewBadge } from "./Badges";
import { RadioButton } from "./RadioButton";

const constants = {
  itemMarginTop: 2 * globalStyleConstants.unitSize,
  itemHeight: 28,
  menuPaddingBottom: 3.5 * globalStyleConstants.unitSize,
};

export function getOptionsMenuHeight(itemCount: number, header?: boolean): number {
  const unitSize = globalStyleConstants.unitSize;
  const itemHeight = constants.itemHeight;
  const spacerHeight = 1.3 * unitSize;
  const separatorHeight = 1;
  const menuPaddingBottom = constants.menuPaddingBottom;

  const headerHeight = header ? unitSize + itemHeight + unitSize + separatorHeight : 0;

  const firstItemHeight = spacerHeight + itemHeight;
  const subsequentItemHeight = spacerHeight + separatorHeight + spacerHeight + itemHeight;
  const totalHeight = headerHeight + firstItemHeight + (itemCount - 1) * subsequentItemHeight + menuPaddingBottom;

  return totalHeight;
}

export const OptionsMenu = React.memo((props: PropsWithChildren<{ header?: string | React.ReactElement }>) => {
  return (
    <View style={styles.menu}>
      {!!props.header && (
        <View style={{ paddingRight: 2 * globalStyleConstants.unitSize }}>
          <Spacer vertical={1} />
          <View style={styles.menuHeader}>
            {!!props.header && (
              <>
                {typeof props.header === "string" && (
                  <TBody fontWeight="medium" opacity="dark" numberOfLines={1}>
                    {props.header}
                  </TBody>
                )}
                {typeof props.header !== "string" && props.header}
              </>
            )}
          </View>
          <Spacer vertical={1} />
          <Separator orientation="row" />
        </View>
      )}
      <View style={styles.menuOptions}>{props.children}</View>
    </View>
  );
});

type OptionIcon =
  | "archive"
  | "add"
  | "addFolder"
  | "audioOn"
  | "audioOff"
  | "camera"
  | "cart"
  | "cook"
  | "delete"
  | "editSquare"
  | "editPencil"
  | "flag"
  | "goTo"
  | "hide"
  | "image"
  | "library"
  | "link"
  | "note"
  | "openExternal"
  | "removeFolder"
  | "selected"
  | "settings"
  | "share"
  | "switch"
  | "rating"
  | "sort"
  | "tag"
  | "text"
  | "time"
  | "unhide"
  | "unselected"
  | (() => React.ReactElement);

interface OptionsMenuItemProps {
  icon: OptionIcon;
  text: string;
  preview?: string | React.ReactElement;
  onPress: () => void;
  waiting?: boolean;
  disabled?: boolean;
  isFirst?: boolean;
  badge?: "new";
  destructive?: boolean;
}

export const OptionsMenuItem = React.memo((props: OptionsMenuItemProps) => (
  <>
    {!props.isFirst && (
      <>
        <Spacer vertical={1.3} />
        <Separator orientation="row" />
      </>
    )}
    <Spacer vertical={1.3} />
    <Pressable
      style={styles.itemContainer}
      disabled={props.disabled || props.waiting}
      singlePress
      onPress={props.onPress}
    >
      <View style={styles.iconContainer}>
        {renderOptionIcon({ type: props.icon, waiting: props.waiting, destructive: props.destructive })}
      </View>
      <Spacer horizontal={1} />
      <View style={styles.textContainer}>
        <View>
          <TBody
            errorText={props.destructive !== false && (props.icon === "archive" || props.icon === "delete")}
            numberOfLines={1}
            adjustsFontSizeToFit
          >
            {props.text}
          </TBody>
        </View>
        {!!props.preview && (
          <>
            <Spacer horizontal={1} />
            {typeof props.preview === "string" && (
              <View
                style={{
                  flexShrink: 1,
                  maxWidth: smallScreenBreakpoint,
                  alignSelf: "flex-end",
                }}
              >
                <TSecondary opacity="light" numberOfLines={1}>
                  {props.preview}
                </TSecondary>
              </View>
            )}
            {typeof props.preview !== "string" && props.preview}
          </>
        )}
      </View>
      {props.badge === "new" && (
        <View style={{ paddingLeft: globalStyleConstants.unitSize }}>
          <NewBadge />
        </View>
      )}
    </Pressable>
  </>
));

const renderOptionIcon = (props: { type: OptionIcon; waiting?: boolean; destructive?: boolean }) => {
  if (props.waiting) {
    return <Spinner />;
  }

  if (typeof props.type !== "string") {
    return props.type();
  }

  const destructiveColor = props.destructive !== false ? globalStyleColors.colorAccentWarm : undefined;

  const optionIcon = switchReturn(props.type, {
    add: <IconPlus opacity="opaque" />,
    addFolder: <IconFolderPlus opacity="opaque" />,
    archive: <IconArchive opacity="opaque" color={destructiveColor} />,
    audioOff: <IconSpeakerMuted opacity="opaque" />,
    audioOn: <IconSpeakerOn opacity="opaque" color={globalStyleColors.colorTimerAction} />,
    camera: <IconCamera opacity="opaque" />,
    cart: <IconCart opacity={"opaque"} />,
    cook: <IconCookPotOpen opacity={"opaque"} />,
    delete: <IconDelete opacity="opaque" color={destructiveColor} />,
    editPencil: <IconEdit opacity={"opaque"} />,
    editSquare: <IconEditSquare opacity={"opaque"} />,
    flag: <IconFlag opacity={"opaque"} />,
    goTo: <IconOpenLink opacity="opaque" />,
    hide: <IconHide opacity="opaque" />,
    image: <IconImage opacity="opaque" />,
    library: <IconBook opacity="opaque" />,
    link: <IconLink opacity="opaque" />,
    note: <IconNote opacity="opaque" />,
    openExternal: <IconOpenExternal opacity="opaque" />,
    removeFolder: <IconFolderMinus opacity="opaque" />,
    selected: <RadioButton isSelected />,
    settings: <IconSettings opacity="opaque" />,
    share: <IconShare opacity="opaque" />,
    switch: <IconSwitch opacity="opaque" />,
    sort: <IconSort opacity="opaque" />,
    rating: <IconStar opacity="opaque" />,
    tag: <IconTag opacity="opaque" />,
    text: <IconText opacity="opaque" />,
    time: <IconClock opacity="opaque" />,
    unhide: <IconUnhide opacity="opaque" />,
    unselected: <RadioButton isSelected={false} />,
  });

  return optionIcon;
};

const styles = StyleSheet.create({
  menu: {
    height: "100%",
    paddingBottom: constants.menuPaddingBottom,
  },
  menuHeader: {
    height: constants.itemHeight,
    paddingLeft: 2 * globalStyleConstants.unitSize,
    width: "100%",
  },
  menuOptions: {
    paddingHorizontal: 2 * globalStyleConstants.unitSize,
  },
  itemContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  iconContainer: {
    width: constants.itemHeight,
    height: constants.itemHeight,
  },
  textContainer: {
    flex: 1,
    flexDirection: "row",
  },
});

import { LibraryFilterSessionId, newLibraryFilterSessionId } from "../composite/LibraryAndSearchSessionIds.ts";
import { useEffect, useRef } from "react";
import {
  addLibraryFilterSession,
  addLibraryFilterSessionFromExistingFilters,
  CollectionIdAndAction,
  removeLibraryFilterSession,
} from "./RecipesSlice.ts";
import { useDispatch } from "../redux/Redux.ts";

export const useLibraryFilterSession = (collection?: CollectionIdAndAction): LibraryFilterSessionId => {
  const sessionId = useRef(newLibraryFilterSessionId()).current;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(addLibraryFilterSession({ sessionId, collection }));

    return () => {
      dispatch(removeLibraryFilterSession(sessionId));
    };
  }, [sessionId]);

  return sessionId;
};

export const useClonedLibraryFilterSession = (existingSessionId: LibraryFilterSessionId): LibraryFilterSessionId => {
  const sessionId = useRef(newLibraryFilterSessionId()).current;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(addLibraryFilterSessionFromExistingFilters({ sessionId, existingSessionId }));

    return () => {
      dispatch(removeLibraryFilterSession(sessionId));
    };
  }, [sessionId]);

  return sessionId;
};

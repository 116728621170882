import React, { useCallback, useEffect, useState } from "react";
import { View } from "react-native";
import { SectionHeading } from "../SectionHeading";
import { RecipeCookCount } from "./RecipeCookCount";
import { Spacer } from "../Spacer";
import { globalStyleColors, globalStyleConstants } from "../GlobalStyles";
import { RecipeEvents } from "@eatbetter/composite-shared";
import { Spinner } from "../Spinner";
import { useScreen } from "../../navigation/ScreenContainer";
import { SocialPostId } from "@eatbetter/posts-shared";
import { navTree } from "../../navigation/NavTree";
import { TBody, Text, TextProps, TSecondary } from "../Typography";
import { getDateDisplayString } from "../../lib/util/DateUtilities";
import { UserId } from "@eatbetter/common-shared";
import { useAuthedUser } from "../../lib/system/SystemSelectors";
import { DeglazeUser } from "@eatbetter/users-shared";
import { useRecipeStats } from "../../lib/recipes/RecipesSelectors";
import { useDispatch } from "../../lib/redux/Redux";
import { getRecipeEvents } from "../../lib/recipes/RecipesThunks";
import { log } from "../../Log";
import { UserRecipeId } from "@eatbetter/recipes-shared";

const strings = {
  cookingHistory: "Cooking History",
  with: " with ",
  notCooked: "You haven't cooked this yet.",
};

interface Props {
  recipeId: UserRecipeId;
}

export const RecipeCookingHistory = React.memo((props: Props) => {
  const dispatch = useDispatch();
  const cookCount = useRecipeStats(props.recipeId)?.cooked ?? 0;

  const [recipeEvents, setRecipeEvents] = useState<RecipeEvents>();
  const [waitingRecipeEvents, setWaitingRecipeEvents] = useState(false);

  useEffect(() => {
    dispatch(getRecipeEvents(props.recipeId, setWaitingRecipeEvents))
      .then(events => setRecipeEvents(events))
      .catch(err =>
        log.errorCaught("RecipeNotes: error dispatching getRecipeEvents", err, { recipeId: props.recipeId })
      );
  }, [props.recipeId]);

  return (
    <View style={{ paddingHorizontal: globalStyleConstants.defaultPadding }}>
      <View style={{ flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
        <SectionHeading text={strings.cookingHistory} noPadding />
        <RecipeCookCount type="expanded" cookCount={cookCount} />
      </View>
      <Spacer vertical={1} />
      {!waitingRecipeEvents && <RecipeHistoryList recipeEvents={recipeEvents} />}
      {!!waitingRecipeEvents && <Spinner />}
    </View>
  );
});

const RecipeHistoryList = React.memo((props: { recipeEvents: RecipeEvents | undefined }) => {
  const screen = useScreen();

  const navToPost = useCallback(
    (postId: SocialPostId) => {
      screen.nav.goTo("push", navTree.get.screens.postDetail, { postId });
    },
    [screen.nav.goTo]
  );

  const getTextLinkProps = (postId: SocialPostId): Partial<TextProps> => {
    return {
      underline: true,
      fontWeight: "medium",
      color: globalStyleColors.colorAccentCool,
      onPress: () => navToPost(postId),
      suppressHighlighting: true,
    };
  };

  if (!props.recipeEvents) {
    return null;
  }

  return (
    <View>
      {props.recipeEvents.events.length === 0 && <TBody opacity="light">{strings.notCooked}</TBody>}
      {props.recipeEvents.events.map((i, idx) => {
        const linkProps = i.postInfo ? getTextLinkProps(i.postInfo.id) : {};

        return (
          <View key={i.started}>
            {idx !== 0 && <Spacer vertical={1.5} />}
            <Text fontSize="secondary">
              <Text {...linkProps} fontSize="secondary">
                {getDateDisplayString(i.started, true, true)}
              </Text>
              <CookedWithUsers userIds={i.by} />
            </Text>
            {!!i.postInfo?.cookComment && (
              <Text fontSize="secondary" opacity="dark">
                {i.postInfo.cookComment.text}
              </Text>
            )}
          </View>
        );
      })}
    </View>
  );
});

const CookedWithUsers = React.memo((props: { userIds: UserId[] }) => {
  const screen = useScreen();
  const authedUser = useAuthedUser();

  const householdById: Record<UserId, DeglazeUser> = {};
  authedUser?.household.forEach(i => (householdById[i.userId] = i));

  const onPressUser = useCallback(
    (userId: UserId) => {
      screen.nav.goTo("push", navTree.get.screens.otherUserProfile, { userId });
    },
    [screen.nav.goTo]
  );

  if (!props.userIds.some(i => !!householdById[i])) {
    return null;
  }

  return (
    <TSecondary>
      <TSecondary opacity="dark">{strings.with}</TSecondary>
      {props.userIds.map((i, idx) => {
        const currentUser = householdById[i];

        return (
          <React.Fragment key={i}>
            {!!currentUser && (
              <>
                {idx > 0 && <TSecondary opacity="dark">{", "}</TSecondary>}
                <TSecondary
                  color={globalStyleColors.colorAccentCool}
                  fontWeight="medium"
                  underline
                  onPress={() => onPressUser(i)}
                  suppressHighlighting
                >
                  {"@" + currentUser.username}
                </TSecondary>
              </>
            )}
          </React.Fragment>
        );
      })}
    </TSecondary>
  );
});

import { EpochMs, SocialMediaDomainKey, TypedPrimitive, UrlString, UserId, Username } from "@eatbetter/common-shared";
import { HouseholdId, NotificationId, SignInMethod, UserAccessLevel } from "@eatbetter/users-shared";
import {
  KnownAuthor,
  KnownAuthorId,
  KnownPublisher,
  KnownPublisherId,
  RecipeCollectionGroupId,
  RecipeCollectionId,
  RecipeId,
  RecipeSource,
  UserRecipeStatus,
} from "@eatbetter/recipes-shared";
import { StandardPrimaryCategory, UnitConversion } from "@eatbetter/items-shared";
import { GroceryListId, GroceryListItemId, GroceryListSort } from "@eatbetter/lists-shared";
import { SocialEntityId, SocialPost, SocialPostId } from "@eatbetter/posts-shared";

export const analyticsUserIdKey = "Deglaze User ID";
const housholdMembersKey = "Household Members";
const usernameKey = "Username";
const userAccessKey = "User Access";

export type MixpanelTimestamp = TypedPrimitive<string, "mixpanelTimestamp">;

export function getMixpanelTimestamp(ts: EpochMs): MixpanelTimestamp {
  // this gives us something like 2023-09-22T17:42:12.679Z
  const withMs = new Date(ts).toISOString();

  // mixpanel wants YYYY-MM-DDTHH:MM:SS
  // https://docs.mixpanel.com/docs/other-bits/tutorials/developers/mixpanel-for-developers-fundamentals
  const i = withMs.indexOf(".");
  return withMs.substring(0, i) as MixpanelTimestamp;
}

export type AnalyticsValueType = string | boolean | number | string[] | number[];

export interface AnalyticsSuperPropertyMap {
  [analyticsUserIdKey]: UserId;
  [usernameKey]: Username;
  [housholdMembersKey]: number;
  [userAccessKey]: UserAccessLevel;
  "User Auth": "Signed out" | "Signed in" | "Signed in (no account)";
  "User Registered": boolean;
  // added this because mixpanel treats undefined as false, so
  // isRegistered = false doesn't get you anonymous users since it also gets users
  // that haven't upgraded
  "User is Anonymous": boolean;
}

export interface AttributionProperties {
  "Attribution Type": string;
  "Attribution Media Source": string;
  "Attribution Campaign": string;
  "Attribution Http Referrer": string;
  "Attribution Short Link": string;
}

export interface AnalyticsUserPropertyMap extends AttributionProperties {
  // user properties
  $name: string;
  [analyticsUserIdKey]: UserId;
  "Household ID": HouseholdId;
  [housholdMembersKey]: number;
  [usernameKey]: Username;
  [userAccessKey]: UserAccessLevel;
  "Font Scale": number;
  "Name from Auth Provider": string;
  "Push Notifications Enabled": boolean;
  "User Created": MixpanelTimestamp;
  "User Deleted": boolean;
  "User Deleted Reason": "Deleted by User";
  "User Referred By": UserId;
  "User Registered": boolean;
  "User is Anonymous": boolean;
  "User was Anonymous": boolean;
  "Attribution Type": string;
  "Attribution Media Source": string;
  "Attribution Campaign": string;
  "Attribution Http Referrer": string;
  "Attribution Link": string;
  "App Discovery Source": string;
  "App Discovery Source Other": string;

  // activity counts and social stats
  "Cooking Sessions Started": number;
  "Cooking Timers Created": number;
  "Follow Recommendations": number;
  Followers: number;
  // Renamed to Following in Mixpanel UI
  "Followed Users": number;
  "Grocery List Items Created": number;
  "Grocery List Items Edited": number;
  "Recipe Count": number;
  "Recipe Collections Created": number;
  "Recipes Added to Collections": number;
  "Recipes Added to Grocery List": number;
  "Recipes Edited": number;
  "Recipes Shared": number;
  "Social Post Comments": number;
  "Social Posts Created": number;
  "Social Posts Shared": number;
  "Social Post Likes": number;
  "Searches Performed": number;
}

export type NumericAnalyticsUserPropertyMap = {
  // key remapping to filter to only numeric properties
  // https://ghaiklor.github.io/type-challenges-solutions/en/medium-pickbytype.html
  [key in keyof AnalyticsUserPropertyMap as AnalyticsUserPropertyMap[key] extends number
    ? key
    : never]: AnalyticsUserPropertyMap[key];
};

/**
 * The goal of this is to try to standardize property names so they are consistent
 * across events that use the same (or similarly themed) properties.
 */
export interface AnalyticsEventPropertyMap extends AttributionProperties {
  // App Share
  "App Share Link Created From":
    | "Search Users Screen"
    | "Profile Screen Share Icon"
    | "Profile Screen Invite Friends Button";

  // Cooking timer properties
  "Timer Min Duration": number;
  "Timer Max Duration": number;
  // the number of concurrent timers, including the new one
  "Timer Count": number;

  // CTA Properties
  "CTA Location": CtaLocation;

  // Error properties
  "Error Display Message": string;
  "Error Message": string;
  "Error Stack": string[];
  "Error ID": string;
  "Error Display Context": "errorBoundary" | "alert";
  "Error Description": string;

  // Explainer sheet properties
  "Explainer Sheet Name": string;

  // External Link properties
  "External Link URL": string;
  "External Link Location": "Social Post Comment" | "Social Post Text";

  // <N> is replaced with the 1-based index of each slide
  "Launch Carousel Slide <N> Duration Seconds": number;
  "Launch Carousel Sum Duration Seconds": number;
  "Launch Carousel Back Actions": number;
  "Photo Ingestion Tips Carousel Slide <N> Duration Seconds": number;
  "Photo Ingestion Tips Carousel Sum Duration Seconds": number;
  "Photo Ingestion Tips Carousel Back Actions": number;
  "In Kitchen First Run Carousel Slide <N> Duration Seconds": number;
  "In Kitchen First Run Carousel Sum Duration Seconds": number;
  "In Kitchen First Run Carousel Back Actions": number;

  // Grocery List Properties
  "List ID": GroceryListId;
  "List Item ID": GroceryListItemId;
  "List Item Category": StandardPrimaryCategory | "uncategorized";
  "List Item From Suggestion": boolean;
  "List Item Group Count": number;
  "List Item Group Swipe": boolean;
  "List Item Manual Category": StandardPrimaryCategory;
  "List Item Suggestion ID": string;
  "List Item Suggestion Index": number;
  "List Item Suggestion Text": string;
  "List Item Suggestion Type": "Pill" | "Typeahead";
  "List Item Text Previous": string;
  "List Item Text": string;
  "List View": GroceryListSort;

  // Notification properties
  "Notification External URL": UrlString;
  "Notification External URL Key": string;
  "Notification ID": NotificationId;
  "Notification Tapped From": "OS" | "App Notification Center";
  "Notification Type": string;
  "Notification Onboarding Prompt": string;
  "Notification Unread Count": number;
  "Notification Title": string;
  "Notification Body": string;

  // Onboarding questionnaire
  "Onboarding Overall Duration Seconds": number;
  "Onboarding Welcome Screen Duration Seconds": number;
  "Onboarding Ingestion Sources": string[];
  "Onboarding Want Organization": boolean;
  "Onboarding Try Groceries": boolean;
  "Onboarding Have Household": boolean;
  "Onboarding Want Notifications": boolean;
  "Onboarding Have Notification Permission": boolean;
  "Onboarding Notification Permissions Granted": boolean;
  "Onboarding Entities Followed": number;
  "Onboarding Initial Following Post Count": number;
  "Onboarding Discovery Source": string;
  "Onboarding Discovery Source Other": string;
  "Onboarding Discovery Source Index": number;

  // User profile updated properties
  "Profile Name Original": string;
  "Profile Name New": string;
  "Profile Username Original": string;
  "Profile Username New": string;
  "Profile Photo Original": string;
  "Profile Photo New": string;
  "Profile Bio Original": string;
  "Profile Bio New": string;
  "Profile Link Original": string;
  "Profile Link New": string;

  // User profile viewed properties
  "Profile Viewed Context":
    | "Own Profile"
    | "Other User Profile"
    | "Known User Author Profile"
    | "Known Author Page"
    | "Known Publisher Page";
  "Profile ID": UserId | KnownAuthorId | KnownPublisherId;
  "Profile Name": string;
  "Profile Username": Username;
  "Profile Link": string;
  "Profile Link Index": number;

  // Push permission properties
  "Notification Permission Changed Via": "Settings" | "Prompt";
  "Notification Permission Screen Context": "Setup" | "Timers";

  // Recipe Library properties
  // See related Search properties below
  "Recipe Library Search Query": string;
  "Recipe Library Filter Tags": string[];

  // Recipe properties
  "Recipe Added Via":
    | "App (Manual Recipe)"
    | "App (Photos)"
    | "App (URL)"
    | "Search Results"
    | "Share Extension (URL)"
    | "Social Post"
    | "Social Post Comment"
    | "User Shared Recipe"
    | "Web View Browse";
  // whether the html source for a web recipe is available. Currently only true if the recipoe is added via share extension + safari.
  "Recipe HTML Source Available": boolean;
  "Recipe Age in Days": number;
  "Recipe Author Name": string;
  "Recipe Book Name": string;
  "Recipe Book Purchase Link": string;
  "Recipe Count Cooked": number;
  "Recipe Count Shopped": number;
  "Recipe ID": RecipeId;
  "Recipe Publisher Name": string;
  "Recipe Source Type": RecipeSource["type"];
  "Recipe Processing Status": UserRecipeStatus;
  /**
   * True if the user toggles the text view of a URL recipe and is viewing the text version
   */
  "Recipe Text View": boolean;
  "Recipe Title": string;
  "Recipe URL": UrlString;
  "Recipe Viewed From": "Library" | "Social Post" | "User Shared Recipe" | "Social Post Comment" | "Search Results";
  "Recipe in Library": boolean;
  "Recipe Paywall Status": PaywallDetectionOutcome;
  "Recipe is Quality": boolean;
  "Recipe Social Media Source": SocialMediaDomainKey;
  "Recipe Scale": number;
  "Recipe Scale Context": "Grocery Item Edit Screen" | "Grocery Recipe Sort View" | "Cooking Session";
  "Recipe Unit Conversion": UnitConversion;
  "Recipe Ommitted Grocery Item Count": number;
  "Recipe Quote Exceeded Context": "Share Extension" | "App Add From URL" | "App Add From Photos";
  // describes the edit
  "Recipe Edit Source Op": string;
  "Recipe Edit Source From":
    | "Select Book Screen"
    | "Book Cover Photo Screen"
    | "Edit Book Screen"
    | "Edit Simple Source Screen";
  "Recipe List Count": number;
  "Recipe List Sorted By":
    | "Default"
    | "Alphabetical Ascending"
    | "Alphabetical Descending"
    | "Added Date Ascending"
    | "Added Date Descending";
  "Recipe List Selected Count": number;

  // Recipe Collection properties
  "Collection Name": string;
  "Collection ID": RecipeCollectionId;
  "Collection Group ID": RecipeCollectionGroupId;
  "Collection Group Name": string;
  "Collection Move Up Count": number;
  "Collection Move Down Count": number;
  "Collection Group Move Up Count": number;
  "Collection Group Move Down Count": number;

  // for tracking what context a paywall was encountered in
  "Paywall Location": PaywallLocation;

  // for tracking where notes, tags, time, etc. are updated from
  "Recipe Update Location": "Recipe Detail" | "Cooking Session" | "End Cooking Session";

  // Recipe Note Properties
  "Recipe Note": string;

  // Recipe Tag Properties
  "Recipe Tag Type": string;
  "Recipe Tag Name": string;

  // Recipe time properties
  "Recipe Time Original": string;
  "Recipe Time New": string;

  // Recipe rating properties
  "Recipe Rating Original": string;
  "Recipe Rating New": string;

  // Reported Issue properties
  "Reported Issue Type": string;
  "Reported Issue ID": string;
  "Reported Issue Description": string;
  // if the requested entity was manually entered
  "Requested Entity Manually Entered": boolean;
  "User Requested Feature": string;

  "Screen Props": string;
  /**
   * Screen duration instance is the number of seconds the screen was active before the screen is unfocused
   * or the app is backgrounded. Total seconds is the total active time for the screen before it is unmounted.
   */
  "Screen Duration Instance Active Seconds": number;
  "Screen Duration Total Active Seconds": number;

  // Search
  // See related library search properties above
  "Search Session ID": string;
  "Search Query": string;
  "Search Query Started From": "Home" | "Known Author Profile" | "Known Publisher Profile";
  "Search Filter Tags": string[];
  "Search Query Suggestion Value": string;
  "Search Query Suggestion Index": number;
  "Search Query Suggestion Type": string;
  "Search Results Scrolled Event": boolean;
  "Search Results Scrolled Index": number;
  "Search Entity Results Count": number;
  "Search Library Results Count": number;
  "Search Server Recipe Results Count": number;
  "Search Result Index": number;

  // Entity Search
  "User Search Context": string;
  "User Search Query": string;
  "Browse Known Entities Viewed From": string;
  "Browse Known Entities Initial Search Query": string;
  "Known Entities Search Query": string;

  // Cooking Session properties
  "Session Duration Seconds": number;
  "Session End Type": "Save" | "Discard";
  "Session End Create Post": boolean;
  "Session End Post Comment": string;
  "Session End Rating": string;
  "Session Participants": number;
  // the number of active cooking sessions, including the newly created one
  "Sessions Active": number;
  "Cooking Session Font Scale": number;

  // number of users that a user sends a recipe to
  "Share Recipients": number;
  "Share Recipient Usernames": string[];
  "Shared by User ID": UserId;
  "Shared by Username": Username;
  "Shared Recipe in Library": boolean;
  "Shared Recipe Web View URL": string;
  "Shared Recipe ID": string;
  "Shared Source Recipe ID": string;

  // Sign-in, Anonymous registration screen, Create Account
  "Anonymous Account Linked": boolean;
  "Anonymous Prompt Count": number;
  "Anonymous Prompt Context": "required" | "suggested" | "action";
  "Anonymous Prompt Action": string;
  "Sign-in Method": SignInMethod;
  "Sign-in Email": string;
  "Sign-in Link Protocol": string;
  "User was Anonymous": boolean;

  // Get app
  "Get App Platform": "ios" | "android";

  // Social Feed properties
  // we use different event names for social feed scrolls - "Social feed scrolled to index 5", Social feed scrolled to index 10", etc.
  // this property should be set on all events for easy filtering
  "Social Feed Scrolled Event": boolean;
  // this should be the value from the event name: 5 and 10 in the example above
  "Social Feed Scrolled Index": number;

  // Social Post properties
  "Social Post Body": string;
  "Social Post Comment": string;
  "Social Post ID": SocialPostId;
  "Social Post Type": SocialPost["type"];

  // Social follow properties
  // sticking with the legacy names here - we can update in the mixpanel dashboard if we want to
  "Followed User ID": SocialEntityId;
  "Followed User Context":
    | "Profile (Other User)"
    | "Following (Own)"
    | "Followers (Own)"
    | "Following (Other User)"
    | "Followers (Other User)"
    | "Social Post Likes"
    | "Notification (New Follower)"
    | "Recommended Follows (Feed Top Slot)"
    | "Recommended Follows (Feed Inline Slot)"
    | "Search Results"
    | "Entity Search";

  // Recommended follows properties
  "Recommended Follows Count": number;
  "Recommended Follow Entity ID": string;
  "Recommended Follow Username": string;
  "Recommended Follow Entity Name": string;

  // Known entity properties
  "Known Entity ID": KnownAuthorId | KnownPublisherId;
  "Known Entity Name": string;
  "Known Entity Type": (KnownAuthor | KnownPublisher)["type"];
  "Known Entity User ID": UserId;
  "Known Entity Page Viewed From": string;

  // Review Properties
  "Review Prompt Action":
    | "Cooking Session Saved"
    | "Social Entity Followed"
    | "Recipe Added to Grocery List"
    | "Recipe Detail Viewed"
    | "Recipe Saved from Social Post"
    | "Social Post Liked";

  "Review Prompt Thresholds": string;

  // Survey properties
  "Survey Name": string;
  "Survey Options": string[];
  "Survey Selection": string;
  "Survey Selection Index": number;
  "Survey Other Text": string;

  // User feedback
  "User Feedback Content": string[];

  // Video properties
  "Video Key": string;
  "Video URL": UrlString;
  "Video Length": number;
  "Video Loaded": boolean;
  "Video Percentage Watched": number;
  "Video Max Second Reached": number;

  // WebView sign-in
  "WebView Sign-In Domain": string;
  "WebView Sign-In Paste Location": "WebView" | "HelpScreen";
}

export interface AnalyticsEventAndProperties {
  events?: Array<{ name: string; properties?: Partial<AnalyticsEventPropertyMap> }>;
  setProperties?: Partial<AnalyticsUserPropertyMap>;
  incrementProperties?: Partial<NumericAnalyticsUserPropertyMap>;
  superProperties?: Partial<AnalyticsSuperPropertyMap>;
  attributionEvents?: Array<{ name: string; properties?: Partial<AnalyticsEventPropertyMap> }>;
}

export type CtaLocation = "Empty Feed" | "Top of Feed" | "Inline Feed";
export type PaywallDetectionOutcome = "paywall" | "noPaywall" | "needsReview" | "unknown" | "noCheckerForDomain";
export type PaywallLocation =
  | "Library Recipe Detail"
  | "Reader Mode"
  | "Cooking Session"
  | "Edit Recipe"
  | "Post View Recipe"
  | "Share View Recipe"
  | "Search View Recipe";
export type CookingSessionOpenedFrom =
  | "Recipe Library"
  | "Switch Recipe Button"
  | "Focus Instruction Event"
  | "Cooking Session Ended"
  | "View in Library Button";

import {
  addTagOrFilterToRecipeFilters,
  RecipeFilters,
  removeTagOrFilterFromRecipeFilters,
} from "../recipes/RecipesSlice";
import { SearchArgs, SearchRecipeResult, SearchResults, SearchSuggestions } from "@eatbetter/search-shared";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { log } from "../../Log";
import { FilterKeys } from "@eatbetter/common-shared";
import { AppUserRecipe, KnownAuthorId, KnownPublisherId, RecipeInfo } from "@eatbetter/recipes-shared";
import { Draft } from "immer";
import { RecipeTagOrFilter } from "../composite/CollectionsSelectors.ts";
import { GlobalSearchSessionId } from "../composite/LibraryAndSearchSessionIds.ts";

interface RequestAndResults {
  args: SearchArgs;
  error?: boolean;
  results?: SearchResults;
}

// When the server result recipe is already in the user's library, we use the library recipe instead of the server RecipeInfo
// so that we can display metadata (e.g. cook count)
export type AppSearchRecipeResult = Omit<SearchRecipeResult, "recipe"> & { recipe: AppUserRecipe | RecipeInfo };

export interface SearchSessionState {
  filters: RecipeFilters;
  authorId?: KnownAuthorId;
  publisherId?: KnownPublisherId;
  requests: RequestAndResults[];
  suggestions: Record<string, string[]>;
  querySubmitted: boolean;
  isScrolled: boolean;
}

export interface SearchState {
  sessions: Record<GlobalSearchSessionId, SearchSessionState>;
  recentQueries: string[];
}

const initialState: SearchState = {
  sessions: {},
  recentQueries: [],
};

export function rehydrateSearchState(persisted: Draft<SearchState>) {
  persisted.sessions = {};
  // Most recent 50 queries is plenty for now. Keeping them all is probably not an issue, but this keeps things predictable + explicit.
  persisted.recentQueries.splice(50);
}

export function migrateSearchState(existingState: unknown, newState: Draft<SearchState>): void {
  log.info("Migrating search state");

  // use filter keys here so that we'll get a compilation error if the type of recentQueries changes
  const key: FilterKeys<SearchState, string[]> = "recentQueries";
  if (existingState && typeof existingState === "object" && key in existingState && Array.isArray(existingState[key])) {
    log.info("Migrating recentQueries");
    existingState[key].forEach(v => {
      if (typeof v === "string" && v.trim() !== "") {
        newState.recentQueries.push(v);
      }
    });
  }
}

export const selectSearchSession = (
  state: SearchState,
  sessionId: GlobalSearchSessionId
): SearchSessionState | undefined => {
  return state.sessions[sessionId];
};

const searchSlice = createSlice({
  name: "search",
  initialState,

  reducers: create => ({
    addSearchSession: create.reducer(
      (
        state,
        action: PayloadAction<{
          sessionId: GlobalSearchSessionId;
          authorId?: KnownAuthorId;
          publisherId?: KnownPublisherId;
        }>
      ) => {
        const { sessionId } = action.payload;
        if (state.sessions.hasOwnProperty(sessionId)) {
          log.error(`Not adding search session with id ${sessionId} because session already exists`);
          return;
        }

        state.sessions[sessionId] = {
          authorId: action.payload.authorId,
          publisherId: action.payload.publisherId,
          filters: {},
          requests: [],
          suggestions: {},
          querySubmitted: false,
          isScrolled: false,
        };
      }
    ),

    removeSearchSession: create.reducer((state, action: PayloadAction<{ sessionId: GlobalSearchSessionId }>) => {
      const { sessionId } = action.payload;
      if (!state.sessions.hasOwnProperty(sessionId)) {
        log.error(`Can't remove session with id ${sessionId}`);
      }
      delete state.sessions[sessionId];
    }),

    clearSearchSession: create.reducer((state, action: PayloadAction<GlobalSearchSessionId>) => {
      const search = selectSearchSession(state, action.payload);
      if (!search) {
        log.error(`No search session with id ${action.payload} in clearSearchSession`);
        return;
      }
      search.requests = [];
    }),

    searchResultsScrolledToTop: create.reducer((state, action: PayloadAction<GlobalSearchSessionId>) => {
      const search = selectSearchSession(state, action.payload);
      if (!search) {
        log.error(`No search session with id ${action.payload} in scrolledToTop`);
        return;
      }
      search.isScrolled = false;
    }),

    searchResultsScrolledFromTop: create.reducer((state, action: PayloadAction<GlobalSearchSessionId>) => {
      const search = selectSearchSession(state, action.payload);
      if (!search) {
        log.error(`No search session with id ${action.payload} in scrolledFromTop`);
        return;
      }
      search.isScrolled = true;
    }),

    clearQuerySubmitted: create.reducer((state, action: PayloadAction<GlobalSearchSessionId>) => {
      const search = selectSearchSession(state, action.payload);
      if (!search) {
        log.error(`No search session with id ${action.payload} in clearQuerySubmitted`);
        return;
      }
      search.querySubmitted = false;
    }),

    newSearchStarted: create.reducer(
      (state, action: PayloadAction<{ args: SearchArgs; sessionId: GlobalSearchSessionId }>) => {
        const search = selectSearchSession(state, action.payload.sessionId);
        if (!search) {
          log.error(`No search session with id ${action.payload.sessionId} in newSearchStarted`);
          return;
        }

        search.requests = [{ args: action.payload.args }];
        search.querySubmitted = true;

        // Update query history state
        const queryText = action.payload.args.query.query?.trim();
        if (!queryText) {
          return;
        }
        const existingIdx = state.recentQueries.indexOf(queryText);
        if (existingIdx >= 0) {
          // If it's already in the list, remove it from its current position
          state.recentQueries.splice(existingIdx, 1);
        }
        // Add to the beginning of the list
        state.recentQueries.unshift(queryText);
      }
    ),

    nextResultsRequested: create.reducer(
      (state, action: PayloadAction<{ args: SearchArgs; sessionId: GlobalSearchSessionId }>) => {
        const search = selectSearchSession(state, action.payload.sessionId);
        if (!search) {
          log.error(`No search session with id ${action.payload.sessionId} in newResultsRequested`);
          return;
        }
        search.requests.push({ args: action.payload.args });
      }
    ),

    resultsReceived: create.reducer(
      (state, action: PayloadAction<{ results: SearchResults; sessionId: GlobalSearchSessionId }>) => {
        const session = selectSearchSession(state, action.payload.sessionId);
        if (!session) {
          log.error(`No search session with id ${action.payload.sessionId} in resultsReceived`);
          return;
        }

        // it's possible we've started a new search since the request was started.
        // see if we have a matching ID and set the results if so
        const reqRes = session.requests.find(
          r => r.args.requestId && r.args.requestId === action.payload.results.args.requestId
        );
        if (reqRes) {
          // there are scenarios where opensearch paging will return dup results. This should only occur if a document is
          // added/updated and it affects the ordering of the results. While it should be rare, filter out dups here.
          const set = new Set<string>();
          session.requests.forEach(r => {
            if (r.results) {
              r.results.recipes.forEach(r => set.add(r.recipe.id));
            }
          });

          const filteredRecipes = action.payload.results.recipes.filter(r => !set.has(r.recipe.id));

          const s: SearchResults = {
            ...action.payload.results,
            recipes: filteredRecipes,
          };

          reqRes.results = s;
        } else {
          log.info("No matching request found for search results. Ignoring.");
        }
      }
    ),

    requestErrored: create.reducer(
      (state, action: PayloadAction<{ requestId?: string; sessionId: GlobalSearchSessionId }>) => {
        const search = selectSearchSession(state, action.payload.sessionId);

        if (!search) {
          log.error(`No search session with id ${action.payload.sessionId} in requestErrored`);
          return;
        }

        if (!action.payload.requestId) {
          log.warn("Received call to SearchSlice requestErrored without requestId. This should not happen.");
          return;
        }

        const reqRes = search.requests.find(r => r.args.requestId && r.args.requestId === action.payload.requestId);
        if (reqRes) {
          reqRes.error = true;
        } else {
          log.info("No matching request found for search error. Ignoring.", { err: action.payload });
        }
      }
    ),

    searchTextChanged: create.reducer(
      (state, action: PayloadAction<{ sessionId: GlobalSearchSessionId; text: string }>) => {
        const searchSession = selectSearchSession(state, action.payload.sessionId);

        if (!searchSession) {
          log.error(`No search session with id ${action.payload.sessionId} in searchTextSubmitted`);
          return;
        }

        searchSession.filters.search = action.payload.text;

        if (!action.payload.text) {
          // If the text input is cleared with the clear button, there is a slight delay until the focus event kicks in and you
          // can see a glitch before the query suggestions come back up. This makes it immediate and smooth.
          searchSession.querySubmitted = false;
        }
      }
    ),

    suggestionsReceived: create.reducer(
      (state, action: PayloadAction<{ sessionId: GlobalSearchSessionId; suggestions: SearchSuggestions }>) => {
        const session = selectSearchSession(state, action.payload.sessionId);
        if (!session) {
          log.error(`No search session with id ${action.payload.sessionId} in suggestionsReceived`);
          return;
        }

        const suggestions = action.payload.suggestions.suggestions.map(s => s.suggestion);
        const query = action.payload.suggestions.args.query.query?.toLowerCase().trim();

        if (query) {
          session.suggestions[query] = suggestions;
        }

        // cleanup old suggestions if we have more than 10 in the session.
        // keep everythign that doens't start with the first character of the current search term
        const keys = Object.keys(session.suggestions);
        if (keys.length > 10) {
          const firstChar = session.filters.search?.charAt(0).toLowerCase();
          keys.forEach(k => {
            if (k.charAt(0).toLowerCase() !== firstChar) {
              delete session.suggestions[k];
            }
          });
        }
      }
    ),

    tagAddedToFilter: create.reducer(
      (state, action: PayloadAction<{ tagOrFilter: RecipeTagOrFilter; sessionId: GlobalSearchSessionId }>) => {
        const search = selectSearchSession(state, action.payload.sessionId);

        if (!search) {
          log.error(`No search session with id ${action.payload.sessionId} in tagAddedToFilter`);
          return;
        }

        addTagOrFilterToRecipeFilters(action.payload.tagOrFilter, search.filters);
      }
    ),

    tagRemovedFromFilter: create.reducer(
      (state, action: PayloadAction<{ tagOrFilter: RecipeTagOrFilter; sessionId: GlobalSearchSessionId }>) => {
        const search = selectSearchSession(state, action.payload.sessionId);

        if (!search) {
          log.error(`No search session with id ${action.payload.sessionId} in tagRemovedFromFilter`);
          return;
        }

        removeTagOrFilterFromRecipeFilters(action.payload.tagOrFilter, search.filters);

        // Go back to query state if there are no active tag filters remaining
        if ((search.filters.tags?.length ?? 0) === 0 && (search.filters.filters?.length ?? 0) === 0) {
          search.querySubmitted = false;
        }
      }
    ),

    searchCleared: create.reducer((state, action: PayloadAction<{ sessionId: GlobalSearchSessionId }>) => {
      const search = selectSearchSession(state, action.payload.sessionId);

      if (!search) {
        log.error(`No search session with id ${action.payload.sessionId} in searchCleared`);
        return;
      }

      search.filters.search = "";
      search.filters.filters = [];
      search.filters.tags = [];
      search.requests = [];
      search.querySubmitted = false;
    }),

    removeRecentQuery: create.reducer((state, action: PayloadAction<string>) => {
      const existingIdx = state.recentQueries.map(i => i.toLowerCase()).indexOf(action.payload.toLowerCase());
      if (existingIdx >= 0) {
        state.recentQueries.splice(existingIdx, 1);
      }
    }),

    removeAllRecentQueries: create.reducer(state => {
      state.recentQueries = [];
    }),
  }),
});

export const {
  addSearchSession,
  clearSearchSession,
  removeSearchSession,
  searchResultsScrolledFromTop,
  searchResultsScrolledToTop,
  clearQuerySubmitted,
  newSearchStarted,
  nextResultsRequested,
  resultsReceived,
  requestErrored,
  searchTextChanged,
  suggestionsReceived,
  tagAddedToFilter,
  tagRemovedFromFilter,
  searchCleared,
  removeRecentQuery,
  removeAllRecentQueries,
} = searchSlice.actions;

export const searchReducer = searchSlice.reducer;

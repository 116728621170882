import { UserRecipeId } from "@eatbetter/recipes-shared";
import { navTree, ReportSocialMediaCaptionIssueScreenProps } from "../navigation/NavTree";
import { useScreen, withScreenContainer } from "../navigation/ScreenContainer";
import { ScreenView } from "../components/ScreenView";
import { useCallback, useMemo, useState } from "react";
import { useDispatch } from "../lib/redux/Redux";
import { HeaderProps } from "../components/ScreenHeaders";
import { View } from "react-native";
import { Haptics } from "../components/Haptics";
import { TBody, Text, TextProps } from "../components/Typography";
import { Spacer } from "../components/Spacer";
import { emptyToUndefined, parseInteger, switchReturn } from "@eatbetter/common-shared";
import { useRecipeDescription, useSourceRecipeId } from "../lib/recipes/RecipesSelectors";
import { TextInput, TextInputProps } from "../components/TextInput";
import React from "react";
import { globalStyleColors, globalStyleConstants } from "../components/GlobalStyles";
import { reportIssue } from "../lib/system/SystemThunks";
import { displayUnexpectedErrorAndLog } from "../lib/Errors";
import { useResponsiveDimensions } from "../components/Responsive";
import { ScrollViewWithSubmit } from "./ScrollViewWithSubmit";
import { BottomSheetContent, useBottomSheet } from "./BottomSheetScreen";
import { useToast } from "../components/Toast";
import { useFetchRecipe } from "../lib/recipes/UseFetchRecipe";

const strings = {
  screenTitleWrittenRecipe: "Report Issue: Recipe Text in Caption",
  screenTitleRecipeLink: "Report Issue: Recipe Link in Caption",
  writtenRecipeHeadline: "Please select the recipe from\nthe caption text below",
  linkHeadline: "Please select the recipe link from\nthe caption text below",
  noCaptionHeadline: "We don't see a caption for this\npost or video",
  submit: "Submit",
  wrongCaptionLink: "I see a different caption",
  wrongCaptionBottomSheet: {
    title: "Report Incorrect Caption?",
    body: "If you see a different post caption/description than what we see, tap below to report it and we'll look into it.",
    cta: "Report Incorrect Caption",
    toast: "Your issue was reported successfully",
  },
};

export const ReportSocialMediaCaptionIssueScreen = withScreenContainer<ReportSocialMediaCaptionIssueScreenProps>(
  "ReportSocialMediaCaptionIssueScreen",
  props => {
    const screen = useScreen();
    const dispatch = useDispatch();

    // We fetch the external recipe because we want the caption we see, not the user's copy, which they could have modified
    // (we saw this several times with reported tickets)
    const sourceRecipeId = useSourceRecipeId(props.recipeId);
    const sourceRecipeOrUserRecipeId = useFetchRecipe(sourceRecipeId).fetchedRecipe ?? props.recipeId;
    const caption = useRecipeDescription(sourceRecipeOrUserRecipeId) ?? "";

    const [captionSelection, setCaptionSelection] = useState<NonNullable<TextInputProps["selection"]>>({
      start: 0,
      end: Math.min(caption.length, 30),
    });

    // Tracks if the user has interacted with the text selection interface. We want to make sure they touch it at least
    // once before enabling the submit button.
    const [userChangedSelection, setUserChangedSelection] = useState(false);

    const [waiting, setWaiting] = useState(false);

    const onChangeCaptionSelection = useCallback<NonNullable<TextInputProps["onSelectionChange"]>>(
      ({ nativeEvent: { selection } }) => {
        // This accomplishes two things: 1) Addresses a weird bug where after initial render, the selection goes away if
        // onSelectionChange is set on TextInput - it overrides the initial value and sets selection start/end to the
        // end of the string. 2) Prevents the text selection UI from going away if you single tap elsewhere on the
        // text - i.e. makes you select something.
        if (selection.start === selection.end) {
          return;
        }

        setCaptionSelection(selection);
        setUserChangedSelection(true);
      },
      [setCaptionSelection, setUserChangedSelection]
    );

    const submit = useCallback(
      async (userReportedWrongCaption?: boolean) => {
        try {
          const issueType = switchReturn(props.issueType, {
            linkInCaption: "recipeLinkInCaption" as const,
            recipeInCaption: "writtenRecipeInCaption" as const,
          });

          await dispatch(
            reportIssue(
              {
                type: "socialMediaRecipeMissingIssue",
                issue: issueType,
                recipeId: props.recipeId,
                caption,
                userSelectedCaptionText: userReportedWrongCaption
                  ? undefined
                  : caption.substring(captionSelection.start, captionSelection.end),
                userReportedWrongCaption,
              },
              setWaiting
            )
          );
        } catch (err) {
          displayUnexpectedErrorAndLog("ReportSocialMediaCaptionIssueScreen: error caught in submit()", err, {
            caption,
            captionSelection,
            recipeId: props.recipeId,
            issueType: props.issueType,
            userReportedWrongCaption,
          });
          // Don't nav back on error
          return;
        }
        Haptics.feedback("operationSucceeded");
        screen.nav.pop(props.popCount);
      },
      [dispatch, caption, props.recipeId, props.issueType, setWaiting, props.popCount, screen.nav.pop]
    );

    const toast = useToast();

    const reportWrongCaption = useCallback(async () => {
      await submit(true);
      toast.show({ type: "infoToast", message: strings.wrongCaptionBottomSheet.toast });
    }, [submit, toast]);

    const onPressWrongCaption = useCallback(() => {
      Haptics.feedback("itemStatusChanged");
      screen.nav.modal(navTree.get.screens.bottomSheet, {
        content: <ReportWrongCaptionSheet onPressReportIssue={reportWrongCaption} />,
      });
    }, [screen.nav.modal, reportWrongCaption]);

    const header = useMemo<HeaderProps>(() => {
      return {
        type: "default",
        title: props.issueType === "linkInCaption" ? strings.screenTitleRecipeLink : strings.screenTitleWrittenRecipe,
      };
    }, [props.issueType]);

    const headline =
      emptyToUndefined(caption) === undefined
        ? strings.noCaptionHeadline
        : switchReturn(props.issueType, {
            linkInCaption: strings.linkHeadline,
            recipeInCaption: strings.writtenRecipeHeadline,
          });

    const { defaultFontScale } = useResponsiveDimensions();

    const commonTextProps: Partial<TextProps> = {
      align: "center",
      enableFontScaling: "upOnly",
      scale: defaultFontScale,
    };

    return (
      <ScreenView header={header} scrollView={false} paddingVertical={false}>
        <ScrollViewWithSubmit
          onPressSubmit={submit}
          submitText={strings.submit}
          waiting={waiting}
          submitDisabled={!caption || !userChangedSelection}
        >
          <Spacer vertical={1} />
          <TBody {...commonTextProps} fontWeight="medium">
            {headline}
          </TBody>
          <Spacer vertical={2} />
          <View style={{ maxHeight: "50%" }}>
            <TextSelect value={caption} selection={captionSelection} onSelectionChange={onChangeCaptionSelection} />
          </View>
          <Spacer vertical={2} />
          <TBody {...commonTextProps} onPress={onPressWrongCaption} color={globalStyleColors.colorAccentCool} underline>
            {strings.wrongCaptionLink}
          </TBody>
        </ScrollViewWithSubmit>
      </ScreenView>
    );
  },
  {
    serializer: {
      recipeId: s => s,
      issueType: s => s,
      popCount: s => s.toString(),
    },
    parser: {
      recipeId: s => s as UserRecipeId,
      issueType: s => s as ReportSocialMediaCaptionIssueScreenProps["issueType"],
      popCount: s => parseInteger(s),
    },
  }
);

const TextSelect = React.memo(
  (props: {
    value: string;
    selection: TextInputProps["selection"];
    onSelectionChange: TextInputProps["onSelectionChange"];
  }) => {
    const { defaultFontScale } = useResponsiveDimensions();

    return (
      <View
        style={{
          padding: globalStyleConstants.unitSize,
          borderRadius: globalStyleConstants.defaultBorderRadius,
          backgroundColor: globalStyleColors.colorGreyLight,
          borderWidth: 1,
          borderColor: globalStyleColors.colorGreyDark,
        }}
      >
        <TextInput
          value={props.value}
          editable={false}
          selection={props.selection}
          onSelectionChange={props.onSelectionChange}
          contextMenuHidden
          textColor={globalStyleColors.black}
          multiline
          autoFocus
          backgroundColor="transparent"
          noBorder
          fontScale={defaultFontScale}
        />
      </View>
    );
  }
);

const ReportWrongCaptionSheet = React.memo((props: { onPressReportIssue: () => void }) => {
  const bottomSheet = useBottomSheet();

  const onPressReportIssue = useCallback(() => {
    bottomSheet?.closeSheetAndGoBack();
    props.onPressReportIssue();
  }, [bottomSheet, props.onPressReportIssue]);

  return (
    <BottomSheetContent
      title={strings.wrongCaptionBottomSheet.title}
      actionButtonText={strings.wrongCaptionBottomSheet.cta}
      onPressActionButton={onPressReportIssue}
    >
      <Text fontSize="body" align="center" responsive>
        {strings.wrongCaptionBottomSheet.body}
      </Text>
    </BottomSheetContent>
  );
});

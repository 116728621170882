import { bottomThrow } from "./Util";

export type CalendarDay = `${number}-${number}-${number}`;

export function getCalendarDayFromDate(date: Date): CalendarDay {
  return date.toISOString().split("T")[0] as CalendarDay;
}

export function addCalendarDays(calendarDay: CalendarDay, days: number): CalendarDay {
  const date = new Date(calendarDay);
  date.setDate(date.getDate() + days);
  return date.toISOString().split("T")[0] as CalendarDay;
}

export function compareCalendarDays(a: CalendarDay, op: ">" | ">=" | "==" | "<=" | "<", b: CalendarDay): boolean {
  const dateA = new Date(a);
  const dateB = new Date(b);
  switch (op) {
    case ">":
      return dateA > dateB;
    case ">=":
      return dateA >= dateB;
    case "==":
      return dateA.getTime() === dateB.getTime();
    case "<=":
      return dateA <= dateB;
    case "<":
      return dateA < dateB;
    default:
      bottomThrow(op);
  }
}
export function calendarDaySortCompare(a: CalendarDay, b: CalendarDay): number {
  const dateA = new Date(a);
  const dateB = new Date(b);
  if (dateA < dateB) return -1;
  if (dateA > dateB) return 1;
  return 0;
}

import React, { PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useScreen, withScreenContainer } from "../navigation/ScreenContainer";
import { ScreenView } from "../components/ScreenView";
import { StyleSheet, View } from "react-native";
import { navTree, RecipesHomeScreenProps } from "../navigation/NavTree";
import { Spacer } from "../components/Spacer";
import { useTabRenderDelay } from "../lib/util/UseTabRenderDelay";
import {
  RecipeListSort,
  useFilteredRecipeListSections,
  useRecipeCount,
  useRecipeSearchPhrase,
} from "../lib/composite/RecipeListSelectors";
import { FontSize, TBody, Text, THeading2 } from "../components/Typography";
import {
  useInitialRecipeLoadComplete,
  useCollectionsActivationThresholdMet,
  useLibraryViewMode,
  useCollectionsWalkthroughActivationThresholdMet,
  useLibraryRecipeCount,
} from "../lib/recipes/RecipesSelectors";
import {
  libraryViewModeChanged,
  RecipeLibraryViewMode,
  removeAllLibraryFiltersExceptCollection,
} from "../lib/recipes/RecipesSlice";
import { useCheckpointCompleted, useIsAnonymousUser, useSystemSetting } from "../lib/system/SystemSelectors";
import { useBottomSheet } from "./BottomSheetScreen";
import { getOptionsMenuHeight, OptionsMenu, OptionsMenuItem } from "../components/OptionsMenu";
import {
  reportCollectionsEditModeTapped,
  reportCreateCollectionTapped,
  reportPhotoIngestionWalkthroughCompleted,
  reportPhotoIngestionWalkthroughStarted,
  reportRecipeCollectionsnWalkthroughCompleted,
  reportRecipeLibraryHeaderTapped,
  reportRecipeLibraryViewSwitch,
} from "../lib/analytics/AnalyticsEvents";
import { analyticsEvent } from "../lib/analytics/AnalyticsThunks";
import { switchReturn, tryParseBool } from "@eatbetter/common-shared";
import { maybePromptForReview } from "../lib/system/SystemThunks";
import { navToAnonymousSignin } from "../lib/util/AnonymousSignIn";
import { globalStyleColors } from "../components/GlobalStyles";
import { ShareExtensionDemoVideo } from "./OnboardShareExtensionScreen";
import { checkpointsCompleted } from "../lib/system/SystemSlice";
import { Pressable } from "../components/Pressable";
import { IconChevronDown } from "../components/Icons";
import { useDispatch } from "../lib/redux/Redux";
import { RecipeLibraryListView } from "../components/recipes/RecipeLibraryListView";
import { log } from "../Log";
import { useRecipeLibraryViews } from "../components/recipes/RecipeLibraryInterfaces";
import { RecipeLibraryCollectionsView } from "../components/recipes/RecipeLibraryCollectionsView";
import { TextInputHandle } from "../components/TextInput";
import { useActiveTagsAndFilters, useCollections } from "../lib/composite/CollectionsSelectors";
import { withNavDelay } from "../lib/util/WithNavDelay";
import { useRecipeLibraryScreenHeader } from "../components/recipes/RecipeLibraryScreenHeader";
import { HeaderAnimationConfig } from "../components/ScreenHeaders";
import { RecipeAddButton, RecipeAddButtonProps } from "../components/recipes/RecipeAddButton";
import { Haptics } from "../components/Haptics";
import { useLibraryFilterSession } from "../lib/recipes/UseLibraryFilterSession.ts";
import { useWalkthrough, WalkthroughStep } from "../components/Walkthrough.tsx";
import { allRecipesCollectionId } from "@eatbetter/recipes-shared";
import { LibraryFilterSessionId } from "../lib/composite/LibraryAndSearchSessionIds.ts";

const strings = {
  headerMyRecipes: "My Recipes",
  headerMyCollections: "My Collections",
  emptyState: {
    headline: "Save any recipe right\nfrom your browser",
    subhead: "",
    dontSeeDeglaze: "Don't see Deglaze?",
  },
  searchPlaceholder: "Search your library",
  editCollectionsAction: "customize your library",
  collectionsMenu: {
    newCollection: "New Recipe Collection",
    reorderAndEdit: "Reorder + Edit Collections",
  },
  collectionsToggleWalkthrough: {
    message: ["Tap here to switch to your\n", "My Collections", " view"],
  },
  selectAndDeleteRecipes: "Select Recipes to Delete",
};

const collectionsViewWalkthroughSteps = ["create", "customize", "filter"] as const;

export const RecipesHomeScreen = withScreenContainer(
  "RecipesHomeScreen",
  (props: RecipesHomeScreenProps) => {
    const dispatch = useDispatch();
    const screen = useScreen();
    const shouldRender = useTabRenderDelay(500, screen.nav.focused);

    const alreadyPromptedForReview = useRef(false);

    const [recipeSort, setRecipeSort] = useState<RecipeListSort>("default");

    useEffect(() => {
      if (props.cookingSessionJustSaved && screen.nav.focused && !alreadyPromptedForReview.current) {
        alreadyPromptedForReview.current = true;
        // wait for any navigation animation to end - tested on a device and this timing seems to work well
        setTimeout(() => {
          dispatch(maybePromptForReview("Cooking Session Saved"));
        }, 500);
      }
    }, [props.cookingSessionJustSaved, dispatch, screen.nav.focused, alreadyPromptedForReview]);

    return React.createElement(RecipesHomeScreenComponent, {
      shouldRender,
      recipeSort,
      setRecipeSort,
    });
  },
  {
    serializer: {
      cookingSessionJustSaved: { optional: true, fn: b => (b ? "1" : "0") },
    },
    parser: {
      cookingSessionJustSaved: { optional: true, fn: b => tryParseBool(b) ?? false },
    },
  }
);

interface Props {
  shouldRender: boolean;
  recipeSort: RecipeListSort;
  setRecipeSort: (v: RecipeListSort) => void;
}

export const RecipesHomeScreenComponent = (props: Props) => {
  const dispatch = useDispatch();
  const screen = useScreen();
  const photosEnabled = !!useSystemSetting("recipePhotoIngestion");
  const photosWalkthroughCompleted = useCheckpointCompleted("photoRecipeWalkthroughCompleted");

  const sessionId = useLibraryFilterSession();
  const sections = useFilteredRecipeListSections(sessionId, props.recipeSort);
  const resultCount = useRecipeCount(sections);

  // Photo ingestion walkthrough for existing users to discover it
  const photoIngestionWalkthrough = screen.nav.focused && photosEnabled && !photosWalkthroughCompleted;
  const badgePhotoIngestionMenuItem = useRef(false);

  const searchBoxRef = useRef<TextInputHandle>(null);
  const searchPhrase = useRecipeSearchPhrase(sessionId);

  // Filters
  const activeTagFilters = useActiveTagsAndFilters(sessionId);
  const hasActiveSearchFilter = !!searchPhrase;
  const hasActiveTagFilters = activeTagFilters.length > 0;
  const isSearchMode = hasActiveSearchFilter || hasActiveTagFilters;

  // Determine what view mode we're in (allRecipes | collections)
  const persistedLibraryViewMode = useLibraryViewMode();

  // Use list view for search results
  const libraryViewMode: RecipeLibraryViewMode = isSearchMode ? "allRecipes" : persistedLibraryViewMode;

  const libraryViews = useRecipeLibraryViews();
  const currentLibraryView = libraryViews[libraryViewMode];

  const collectionsData = useCollections();

  // Recipe collections walkthrough
  const collectionsWalkthroughCompleted = useCheckpointCompleted("recipeCollectionsWalkthroughCompleted");
  const userHasToggledCollections = useCheckpointCompleted("recipeCollectionsToggled");
  const showCollectionsWalkthrough =
    userHasToggledCollections &&
    !collectionsWalkthroughCompleted &&
    screen.nav.focused &&
    libraryViewMode === "collections";

  const endCollectionsWalkthrough = useCallback(() => {
    dispatch(analyticsEvent(reportRecipeCollectionsnWalkthroughCompleted()));
    dispatch(checkpointsCompleted(["recipeCollectionsWalkthroughCompleted"]));
  }, [dispatch]);

  const collectionsWalkthrough = useWalkthrough(
    "Recipe Collections",
    collectionsViewWalkthroughSteps,
    showCollectionsWalkthrough,
    endCollectionsWalkthrough
  );

  useEffect(() => {
    if (photoIngestionWalkthrough) {
      dispatch(analyticsEvent(reportPhotoIngestionWalkthroughStarted()));
      badgePhotoIngestionMenuItem.current = true;
    }
  }, [photoIngestionWalkthrough]);

  const endPhotoIngestionWalkthrough = useCallback(() => {
    dispatch(analyticsEvent(reportPhotoIngestionWalkthroughCompleted()));
    dispatch(checkpointsCompleted(["photoRecipeWalkthroughCompleted"]));
  }, [dispatch]);

  // Add convenience mechanism to get to original tab state. When the tab is focused, it you press once you get the
  // standard scroll to top behavior. Once scrolled to top, if you tap again it clears the search filters for you.
  const onTabPress = useCallback(() => {
    if (currentLibraryView.headerPosition.value === 0) {
      dispatch(removeAllLibraryFiltersExceptCollection(sessionId));
    }
  }, [dispatch, currentLibraryView.headerPosition, sessionId]);

  const recipesLoading = !useInitialRecipeLoadComplete();
  const recipeCount = useLibraryRecipeCount();
  const emptyState = recipeCount === 0 && !recipesLoading;

  const showShareExtensionSetup = !useCheckpointCompleted("shareExtensionUsed") && !emptyState;

  const addItemMenuContext: RecipeAddButtonProps["context"] = switchReturn(persistedLibraryViewMode, {
    allRecipes: "libraryAllRecipes",
    collections: "libraryCollections",
  });

  // If the user navs away, blur the search box because it's weird to come back and have the keyboard pop back up
  useEffect(() => {
    if (!screen.nav.focused) {
      searchBoxRef.current?.blur();
    }
  }, [screen.nav.focused]);

  // Reset header positions to top on view switch. This is instantaneous as it happens on the UI thread and avoids
  // glitch or weird transitions between views.
  useEffect(() => {
    Object.values(libraryViews)?.forEach(i => (i.headerPosition.value = 0));
  }, [libraryViewMode]);

  const openCollectionsMenu = useCollectionsMenu();
  const openListViewMenu = useListViewMenu({ searchSessionId: sessionId, sort: props.recipeSort });
  const renderScreenTitle = useCallback(() => <RecipeLibraryScreenTitle />, []);
  const headerAnimationConfig = useMemo<HeaderAnimationConfig>(
    () => ({ animationProgress: currentLibraryView.headerPosition }),
    [currentLibraryView.headerPosition]
  );

  const screenHeader = useRecipeLibraryScreenHeader({
    sessionId,
    title: renderScreenTitle,
    style: "tabRoot",
    searchBoxRef,
    openContextMenu:
      libraryViewMode === "collections"
        ? openCollectionsMenu
        : libraryViewMode === "allRecipes"
        ? openListViewMenu
        : undefined,
    sort: props.recipeSort,
    onChangeSort: libraryViewMode === "allRecipes" ? props.setRecipeSort : undefined,
    animationConfig: headerAnimationConfig,
    resultCount,
    showWalkthrough:
      collectionsWalkthrough.currentStep === "customize"
        ? "customizeCollections"
        : collectionsWalkthrough.currentStep === "filter"
        ? "filters"
        : undefined,
    onWalkthroughNext: collectionsWalkthrough.goToNextStep,
  });

  // -------- RENDER STARTS HERE --------

  return (
    <ScreenView
      scrollView={false}
      paddingVertical={false}
      paddingHorizontal={false}
      header={screenHeader}
      loading={!props.shouldRender || recipesLoading}
      onTabPress={onTabPress}
    >
      {emptyState && <EmptyState />}
      {!emptyState && (
        <>
          <LibraryViewContainer isVisible={libraryViewMode === "allRecipes"}>
            <RecipeLibraryListView
              ref={libraryViews.allRecipes.ref}
              sections={sections}
              resultCount={resultCount}
              sort={props.recipeSort}
              onScroll={libraryViews.allRecipes.onScroll}
              showShareExtensionTip={showShareExtensionSetup}
              sessionId={sessionId}
            />
          </LibraryViewContainer>
          <LibraryViewContainer isVisible={libraryViewMode === "collections"}>
            {!!collectionsData && (
              <RecipeLibraryCollectionsView
                ref={libraryViews.collections.ref}
                collectionsData={collectionsData}
                onScroll={libraryViews.collections.onScroll}
                sessionId={sessionId}
              />
            )}
          </LibraryViewContainer>
        </>
      )}
      <RecipeAddButton
        context={addItemMenuContext}
        photoIngestionWalkthroughEnabled={photoIngestionWalkthrough}
        endPhotoIngestionWalkthrough={endPhotoIngestionWalkthrough}
        badgePhotoIngestionMenuItem={badgePhotoIngestionMenuItem.current}
        sessionId={sessionId}
        collectionsWalkthroughEnabled={collectionsWalkthrough.currentStep === "create"}
        onCollectionsWalkthroughNext={collectionsWalkthrough.goToNextStep}
      />
    </ScreenView>
  );
};

const LibraryViewContainer = React.memo((props: PropsWithChildren<{ isVisible: boolean }>) => {
  if (!props.isVisible) {
    return null;
  }
  return props.children;
});

const EmptyState = React.memo(() => {
  const dispatch = useDispatch();
  const { nav } = useScreen();

  const onPressAddFirstRecipe = useCallback(() => {
    nav.modal(navTree.get.screens.onboardShareExtensionFirstTime);
  }, [dispatch]);

  return (
    <View style={styles.emptyStateContainer}>
      <THeading2 numberOfLines={2} adjustsFontSizeToFit align="center">
        {strings.emptyState.headline}
      </THeading2>
      <Spacer vertical={0.5} />
      <View style={{ height: "55%" }}>
        {/* Don't report analytics since the user isn't actively tapping to watch the video */}
        <ShareExtensionDemoVideo />
      </View>
      <Spacer vertical={1} />
      <View style={{ flexDirection: "row", alignItems: "center" }}>
        <TBody
          onPress={onPressAddFirstRecipe}
          suppressHighlighting
          color={globalStyleColors.colorTextLink}
          fontWeight="medium"
        >
          {strings.emptyState.dontSeeDeglaze}
        </TBody>
      </View>
    </View>
  );
});

/**
 * Pressable screen title that controls view switching between classic list view and collections.
 */
const RecipeLibraryScreenTitle = React.memo(() => {
  const dispatch = useDispatch();
  const screen = useScreen();
  const libraryViewMode = useLibraryViewMode();
  // whether or not the user sees the collection toggle
  // as soon as they toggle for the first time, the "recipeCollectionsActivated" checkpoint is set
  // current threshold is a single recipe
  const collectionsActivatedThresholdMet = useCollectionsActivationThresholdMet();

  // once the user hits this threshold (currently 5 recipes), we point them to the collections toggle if the
  // recipeCollectionsActiviated checkpoint isn't already set
  const collectionWalkthroughThresholdMet = useCollectionsWalkthroughActivationThresholdMet();

  const [showCollectionsToggleWalkthrough, setShowCollectionsToggleWalkthrough] = useState(false);

  const collectionsEnabled = useSystemSetting("recipeCollections");
  const userHasToggledCollections = useCheckpointCompleted("recipeCollectionsToggled");
  // show the toggle if the user has ever seen collections already, or they've met the threshold to see thte toggle, or if we are showing the walkthrough
  // collectionsActivatedThresholdMet *should* always be true if showWalkthrough is true
  const showCollectionsToggle =
    collectionsEnabled &&
    (libraryViewMode === "collections" ||
      userHasToggledCollections ||
      collectionsActivatedThresholdMet ||
      showCollectionsToggleWalkthrough);

  // Trigger walkthrough when collections activation threshold is met
  useEffect(() => {
    if (
      !userHasToggledCollections &&
      collectionsActivatedThresholdMet &&
      collectionWalkthroughThresholdMet &&
      screen.nav.focused &&
      libraryViewMode === "allRecipes"
    ) {
      setShowCollectionsToggleWalkthrough(true);
    }
  }, [
    userHasToggledCollections,
    collectionsActivatedThresholdMet,
    collectionWalkthroughThresholdMet,
    screen.nav.focused,
    libraryViewMode,
  ]);

  const text = switchReturn(libraryViewMode, {
    allRecipes: strings.headerMyRecipes,
    collections: strings.headerMyCollections,
  });

  const fontSize = "h1";
  const fontScale = 28 / FontSize[fontSize];

  const onPressSwitchView = useCallback(
    (newMode: RecipeLibraryViewMode) => {
      if (newMode === "collections" && !userHasToggledCollections) {
        dispatch(checkpointsCompleted(["recipeCollectionsToggled"]));
      }
      dispatch(libraryViewModeChanged(newMode));
    },
    [dispatch, userHasToggledCollections]
  );

  const onPressScreenHeader = useCallback(() => {
    dispatch(analyticsEvent(reportRecipeLibraryHeaderTapped()));

    if (!showCollectionsToggle) {
      log.error("Recipe library screen header onPress callback called but collections feature is disabled. Returning");
      return;
    }

    screen.nav.modal(navTree.get.screens.bottomSheet, {
      content: <SwitchViewSheet currentView={libraryViewMode} onPressSwitchView={onPressSwitchView} />,
      height: getOptionsMenuHeight(2),
    });
  }, [libraryViewMode, onPressSwitchView, showCollectionsToggle, dispatch]);

  const completeWalkthrough = useCallback(() => {
    Haptics.feedback("itemStatusChanged");
    setShowCollectionsToggleWalkthrough(false);
    onPressScreenHeader();
  }, [dispatch, setShowCollectionsToggleWalkthrough, onPressScreenHeader]);

  const headerColor = showCollectionsToggleWalkthrough ? "white" : undefined;

  const screenTitle = (
    <Pressable
      style={{ flexDirection: "row", alignItems: "center" }}
      onPress={onPressScreenHeader}
      noFeedback
      disabled={!showCollectionsToggle}
    >
      <Text fontSize={fontSize} font="serif" enableFontScaling="upAndDown" scale={fontScale} color={headerColor}>
        {text}
      </Text>
      {!!showCollectionsToggle && (
        <>
          <Spacer horizontal={0.5} />
          <IconChevronDown opacity="opaque" size={20} strokeWidth={2} color={headerColor} />
        </>
      )}
    </Pressable>
  );

  const walkthroughMessage = (
    <TBody align="center">
      <TBody>{strings.collectionsToggleWalkthrough.message[0]}</TBody>
      <TBody fontWeight="medium">{strings.collectionsToggleWalkthrough.message[1]}</TBody>
      <TBody>{strings.collectionsToggleWalkthrough.message[2]}</TBody>
    </TBody>
  );

  return showCollectionsToggleWalkthrough ? (
    <WalkthroughStep
      show={showCollectionsToggleWalkthrough}
      message={walkthroughMessage}
      buttonText={"gotIt"}
      onPressChildComponent={completeWalkthrough}
    >
      {screenTitle}
    </WalkthroughStep>
  ) : (
    screenTitle
  );
});

const SwitchViewSheet = React.memo(
  (props: { currentView: RecipeLibraryViewMode; onPressSwitchView: (newMode: RecipeLibraryViewMode) => void }) => {
    const dispatch = useDispatch();
    const bottomSheet = useBottomSheet();

    const onPressSwitchView = (newView: RecipeLibraryViewMode) => {
      Haptics.feedback("itemStatusChanged");
      dispatch(analyticsEvent(reportRecipeLibraryViewSwitch(newView)));
      bottomSheet?.closeSheetAndGoBack();
      props.onPressSwitchView(newView);
    };

    const getIcon = (option: RecipeLibraryViewMode) => {
      return props.currentView === option ? "selected" : "unselected";
    };

    return (
      <OptionsMenu>
        <OptionsMenuItem
          icon={getIcon("allRecipes")}
          text={strings.headerMyRecipes}
          onPress={() => onPressSwitchView("allRecipes")}
          isFirst
        />
        <OptionsMenuItem
          icon={getIcon("collections")}
          text={strings.headerMyCollections}
          onPress={() => onPressSwitchView("collections")}
        />
      </OptionsMenu>
    );
  }
);

function useListViewMenu(props: ListViewMenuProps) {
  const screen = useScreen();

  const openListViewMenu = useCallback(() => {
    screen.nav.modal(navTree.get.screens.bottomSheet, {
      content: <ListViewMenu {...props} />,
      height: getOptionsMenuHeight(1),
    });
  }, [screen.nav.modal, props]);

  return openListViewMenu;
}

function useCollectionsMenu() {
  const screen = useScreen();

  const openCollectionsMenu = useCallback(() => {
    screen.nav.modal(navTree.get.screens.bottomSheet, {
      content: <CollectionsMenu />,
      height: getOptionsMenuHeight(2),
    });
  }, [screen.nav.modal]);

  return openCollectionsMenu;
}

interface ListViewMenuProps {
  searchSessionId: LibraryFilterSessionId;
  sort: RecipeListSort;
}

const ListViewMenu = React.memo((props: ListViewMenuProps) => {
  const screen = useScreen();
  const bottomSheet = useBottomSheet();

  const onPressSelectAndDelete = useCallback(() => {
    bottomSheet?.closeSheetAndGoBack();
    withNavDelay(() =>
      screen.nav.modal(navTree.get.screens.recipeCollectionBulkAction, {
        action: "deleteRecipes",
        collectionId: allRecipesCollectionId,
        existingSessionId: props.searchSessionId,
        sort: props.sort,
      })
    );
  }, [bottomSheet, screen.nav.modal, props.searchSessionId, props.sort]);

  return (
    <OptionsMenu>
      <OptionsMenuItem
        isFirst
        icon="delete"
        destructive={false}
        text={strings.selectAndDeleteRecipes}
        onPress={onPressSelectAndDelete}
      />
    </OptionsMenu>
  );
});

const CollectionsMenu = React.memo(() => {
  const dispatch = useDispatch();
  const screen = useScreen();
  const bottomSheet = useBottomSheet();
  const isAnon = useIsAnonymousUser();

  const onPressCreateCollection = useCallback(() => {
    dispatch(analyticsEvent(reportCreateCollectionTapped("contextMenu")));
    bottomSheet?.closeSheetAndGoBack();

    if (isAnon) {
      withNavDelay(() =>
        navToAnonymousSignin(screen.nav, {
          mode: "action",
          userVisibleActionDescription: strings.editCollectionsAction,
        })
      );
    } else {
      withNavDelay(() => screen.nav.goTo("push", navTree.get.screens.createRecipeCollection));
    }
  }, [screen.nav.goTo, bottomSheet, isAnon, dispatch]);

  const onPressReorderAndEdit = useCallback(() => {
    dispatch(analyticsEvent(reportCollectionsEditModeTapped()));
    bottomSheet?.closeSheetAndGoBack();

    withNavDelay(() => screen.nav.modal(navTree.get.screens.recipeCollectionsEdit));
  }, [bottomSheet, isAnon]);

  return (
    <OptionsMenu>
      <OptionsMenuItem
        isFirst
        icon="add"
        text={strings.collectionsMenu.newCollection}
        onPress={onPressCreateCollection}
      />
      <OptionsMenuItem icon="settings" text={strings.collectionsMenu.reorderAndEdit} onPress={onPressReorderAndEdit} />
    </OptionsMenu>
  );
});

const styles = StyleSheet.create({
  emptyStateContainer: {
    flex: 1,
    paddingHorizontal: 22,
    justifyContent: "center",
    alignItems: "center",
  },
});

import { Platform, StyleSheet } from "react-native";
import { useScreen, withScreenContainer } from "../navigation/ScreenContainer";
import { globalStyleColors, globalStyleConstants } from "../components/GlobalStyles";
import { TextInputHandle } from "../components/TextInput";
import { useCallback, useEffect, useRef, useState } from "react";
import { Spacer } from "../components/Spacer";
import { ScreenView } from "../components/ScreenView";
import { TSecondary } from "../components/Typography";
import { InputAccessoryView } from "../components/InputAccessoryView";
import { BottomActionBar, bottomActionBarConstants } from "../components/BottomActionBar";
import { useScreenHeaderDimensions } from "../components/ScreenHeaders";
import { ScrollView } from "react-native-gesture-handler";
import { EnterEmailScreenProps, navTree } from "../navigation/NavTree";
import { sendEmailSignInLink } from "../lib/system/SystemThunks";
import { useDispatch } from "../lib/redux/Redux";
import { bottomThrow } from "@eatbetter/common-shared";
import { displayUnexpectedErrorAndLog } from "../lib/Errors";
import { useKeyboardLayoutAnimation } from "../components/Keyboard";
import { FormValidationError } from "../components/FormValidationError";
import { FormInput } from "../components/FormInput";
import { navToAnonymousSignin } from "../lib/util/AnonymousSignIn";
import { useIsAnonymousRegistrationRequired } from "../lib/util/UseAnonymousRegistrationPrompt";
import { withNavDelay } from "../lib/util/WithNavDelay";

const strings = {
  screenHeader: "Continue with email",
  instructions: "Let's see if you have an account. If not, it will take just a few seconds to create one.",
  submit: "Continue",
  emailLabel: "Email",
  invalidEmail: "Please enter a valid email address.",
};

const emailRegex = /^\S+@\S+\.\S+$/;

export const EnterEmailScreen = withScreenContainer(
  "EnterEmailScreen",
  (props: EnterEmailScreenProps) => {
    const screen = useScreen();
    const dispatch = useDispatch();
    const { headerHeight } = useScreenHeaderDimensions();
    const keyboardHeight = useKeyboardLayoutAnimation();
    const textInputRef = useRef<TextInputHandle>(null);

    const [waiting, setWaiting] = useState(false);

    const [email, setEmail] = useState("");
    const [emailIsInvalid, setEmailIsInvalid] = useState(false);

    const anonRegRequired = useIsAnonymousRegistrationRequired();

    // This prevents the bypassing of mandatory anon registration by just tapping the back button. Since this is a
    // push action, the modal closes before navigating here. But with this, we pop it back up if the user goes back.
    const onPressBack = useCallback(() => {
      screen.nav.goBack();

      if (anonRegRequired) {
        withNavDelay(() => navToAnonymousSignin(screen.nav, { mode: "required" }));
      }
    }, [anonRegRequired, screen.nav.goBack]);

    const onSubmit = useCallback(() => {
      if (!emailRegex.test(email)) {
        setEmailIsInvalid(true);
        return;
      }

      textInputRef.current?.blur();

      // this is for the apple test review account so they can sign in for review
      if (email.toLowerCase().trim() === "test-user@deglaze.app") {
        screen.nav.goTo("push", navTree.get.screens.enterPassword, { email });
        return;
      }

      setWaiting(true);
      dispatch(sendEmailSignInLink(email, props.redirectPath))
        .then(res => {
          if (res.success) {
            screen.nav.goTo("push", navTree.get.screens.checkYourEmail, { email, redirectPath: props.redirectPath });
            return;
          }

          switch (res.result) {
            case "invalidEmail":
              setEmailIsInvalid(true);
              break;
            default:
              // this will hit the catch below, but that's fine.
              bottomThrow(res.result);
          }
        })
        .catch(err => {
          displayUnexpectedErrorAndLog("Unexpected error in EnterEmailScreen.onSubmit", err);
        })
        .finally(() => setWaiting(false));
    }, [email, setEmailIsInvalid, props.redirectPath, screen.nav.goTo, dispatch]);

    const onChangeEmail = useCallback(
      (value: string) => {
        setEmailIsInvalid(false);
        setEmail(value);
      },
      [setEmailIsInvalid, setEmail]
    );

    useEffect(() => {
      if (Platform.OS === "web") {
        // Safari + Chrome disable focus requests without user interaction, so it ends up in a
        // 'half' state where some animations trigger but there's no keyboard.
        return;
      }

      if (screen.nav.focused) {
        setTimeout(() => textInputRef.current?.focus(), 650);
        return;
      }

      textInputRef.current?.blur();
    }, [screen.nav.focused]);

    return (
      <ScreenView
        scrollView={false}
        paddingVertical={false}
        paddingHorizontal={false}
        header={{ type: "custom", title: strings.screenHeader, backgroundColor: "transparent", onPressBack }}
      >
        <ScrollView
          keyboardShouldPersistTaps="always"
          contentContainerStyle={[
            styles.contentWrap,
            {
              paddingTop: headerHeight + globalStyleConstants.unitSize,
              paddingBottom: keyboardHeight + bottomActionBarConstants.height + 2 * globalStyleConstants.unitSize,
            },
          ]}
        >
          <TSecondary opacity="medium">{strings.instructions}</TSecondary>
          <Spacer vertical={2} />
          <FormInput
            ref={textInputRef}
            label={strings.emailLabel}
            value={email}
            onChangeText={onChangeEmail}
            textContentType="emailAddress"
            keyboardType="email-address"
            autoCapitalize="none"
            isReadOnly={waiting}
            showClearButton={!waiting}
            onSubmitEditing={onSubmit}
            validationState={emailIsInvalid ? "invalid" : undefined}
          />
          <Spacer vertical={1.5} />
          {emailIsInvalid && <FormValidationError message={strings.invalidEmail} />}
        </ScrollView>
        <InputAccessoryView backgroundColor="transparent">
          <BottomActionBar
            primaryAction={{
              actionText: strings.submit,
              onPressAction: onSubmit,
              disabled: !email,
              waiting,
              singlePress: true,
            }}
            containerBackgroundColor="transparent"
            disableSnapToBottom
          />
        </InputAccessoryView>
      </ScreenView>
    );
  },
  {
    serializer: {
      redirectPath: { optional: true, fn: s => s },
    },
    parser: {
      redirectPath: { optional: true, fn: s => s },
    },
  }
);

const styles = StyleSheet.create({
  contentWrap: {
    paddingHorizontal: 20,
  },
  emailInput: {
    paddingTop: 8,
    paddingBottom: 8,
    borderBottomColor: globalStyleColors.rgba("black", "xlight"),
    borderBottomWidth: 1,
  },
  emailInvalid: {
    borderBottomColor: globalStyleColors.colorAccentWarm,
  },
});

import React, { useCallback } from "react";
import { UserRecipeId } from "@eatbetter/recipes-shared";
import { StyleSheet, View, ViewProps } from "react-native";
import { globalStyleConstants, globalStyles } from "../GlobalStyles";
import { IconChevronRight } from "../Icons";
import { Spacer } from "../Spacer";
import { useScreen } from "../../navigation/ScreenContainer";
import { navTree, RecipeEditFieldLocation } from "../../navigation/NavTree";
import { Pressable } from "../Pressable";
import { SelectableOval } from "../SelectableOval";
import { TSecondary } from "../Typography";
import { getRecipeTagOrFilterKey, RecipeTagOrFilter, useRecipeTags } from "../../lib/composite/CollectionsSelectors.ts";
import { bottomThrow, discriminate } from "@eatbetter/common-shared";
import { ScrollView } from "react-native-gesture-handler";

const strings = {
  tags: "Collections",
  noTags: "Add to collections",
};

interface Props {
  recipeId: UserRecipeId;
  style?: "default" | "compact";
  disabled?: boolean;
  location: RecipeEditFieldLocation;
  noShadow?: boolean;
}

export const RecipeTags = (props: Props) => {
  const screen = useScreen();
  const tags = useRecipeTags(props.recipeId);

  const onPress = useCallback(() => {
    screen.nav.goTo("push", navTree.get.screens.recipeTagsEdit, {
      recipeId: props.recipeId,
      recipeEditFieldLocation: props.location,
    });
  }, [screen.nav.goTo, props.location, props.recipeId]);

  return (
    <Pressable
      style={[styles.container, props.noShadow ? {} : globalStyles.shadowItem]}
      onPress={onPress}
      noFeedback
      disabled={props.disabled}
    >
      <View style={styles.heading}>
        <View>
          <TSecondary>{strings.tags}</TSecondary>
        </View>
        <View style={styles.headingContent}>
          {props.style === "compact" && (
            <>
              {tags.length === 0 && (
                <>
                  <TSecondary opacity="light">{strings.noTags}</TSecondary>
                  <Spacer horizontal={1} />
                </>
              )}
              {tags.length > 0 && (
                <>
                  <Spacer horizontal={2} />
                  <View style={{ flexShrink: 1 }}>
                    <TagsCompactDisplay tags={tags} />
                  </View>
                  <Spacer horizontal={1} />
                </>
              )}
            </>
          )}
          <IconChevronRight />
        </View>
      </View>
      {props.style !== "compact" && (
        <>
          <Spacer vertical={1} />
          <View style={{ marginRight: 1.5 * globalStyleConstants.unitSize }}>
            {tags.length === 0 && <TSecondary opacity="medium">{strings.noTags}</TSecondary>}
            {tags.length > 0 && <TagsPreview style="pill" tags={tags} onPressTag={onPress} />}
          </View>
        </>
      )}
    </Pressable>
  );
};

const TagsCompactDisplay = React.memo((props: { tags: RecipeTagOrFilter[] }) => {
  return <TagsDisplayText tags={props.tags} numberOfLines={1} />;
});

const TagsDisplayText = React.memo((props: { tags: RecipeTagOrFilter[]; numberOfLines?: number }) => {
  const previewString = useRecipeTagsPreview(props.tags);

  return (
    <TSecondary numberOfLines={props.numberOfLines} opacity="medium">
      {previewString}
    </TSecondary>
  );
});

export const TagsPreview = React.memo(
  (props: { style: "pill" | "text"; tags: RecipeTagOrFilter[]; onPressTag: () => void }) => {
    const tags = props.tags.filter(discriminate("type", "tag"));

    if (tags.length === 0) {
      return (
        <TSecondary numberOfLines={1} opacity="light">
          {strings.noTags}
        </TSecondary>
      );
    }

    switch (props.style) {
      case "pill": {
        return (
          <ScrollView horizontal showsHorizontalScrollIndicator={false}>
            <View style={{ flexDirection: "row" }}>
              {tags.map(i => {
                return (
                  <TagPreview
                    key={getRecipeTagOrFilterKey(i)}
                    style={props.style}
                    tagOrFilter={i}
                    contentContainerStyle={{
                      marginRight: 0.5 * globalStyleConstants.unitSize,
                      marginBottom: 0.5 * globalStyleConstants.unitSize,
                    }}
                    onPressTag={props.onPressTag}
                  />
                );
              })}
            </View>
          </ScrollView>
        );
      }
      case "text": {
        return (
          <TSecondary numberOfLines={1} opacity="medium">
            {tags.map((i, idx) => {
              return (
                <TSecondary key={i.tag.collectionId}>
                  {idx !== 0 && <TSecondary>{", "}</TSecondary>}
                  <TagPreview
                    key={getRecipeTagOrFilterKey(i)}
                    style={props.style}
                    tagOrFilter={i}
                    contentContainerStyle={{}}
                    onPressTag={props.onPressTag}
                  />
                </TSecondary>
              );
            })}
          </TSecondary>
        );
      }
      default:
        bottomThrow(props.style);
    }
  }
);

const TagPreview = React.memo(
  (props: {
    style: "pill" | "text";
    tagOrFilter: RecipeTagOrFilter;
    contentContainerStyle: ViewProps["style"];
    onPressTag: () => void;
  }) => {
    if (props.tagOrFilter.type !== "tag") {
      return null;
    }

    switch (props.style) {
      case "pill": {
        return (
          <View
            style={{
              marginRight: 0.5 * globalStyleConstants.unitSize,
              marginBottom: 0.5 * globalStyleConstants.unitSize,
            }}
          >
            <SelectableOval text={props.tagOrFilter.tag.display} isSelected onPress={props.onPressTag} />
          </View>
        );
      }
      case "text": {
        return <TSecondary>{props.tagOrFilter.tag.display}</TSecondary>;
      }
      default:
        bottomThrow(props.style);
    }
  }
);

export function useRecipeTagsPreview(tags: RecipeTagOrFilter[]): string {
  return tags
    .filter(discriminate("type", "tag"))
    .map(t => t.tag.display)
    .join(", ");
}

const styles = StyleSheet.create({
  container: {
    padding: globalStyleConstants.unitSize,
    backgroundColor: "white",
    borderRadius: 20,
  },
  heading: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    height: 32,
  },
  headingContent: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
  },
});

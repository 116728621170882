import {
  Comment,
  getSocialEntityId,
  isEntitySocialPost,
  isUserSocialPost,
  Like,
  LikeUnlikePostArgs,
  NewRecipePost,
  SocialEntityId,
  SocialPost,
  SocialPostId,
  TextPost,
  UserRecipeActionPost,
} from "@eatbetter/posts-shared";
import { useSelector } from "../redux/Redux";
import { RootState } from "../redux/RootReducer";
import { DeglazeUser } from "@eatbetter/users-shared";
import { followingFeed, selectSocialFeed, SocialFeedType, SocialState } from "./SocialSlice";
import { UserId, bottomThrow, bottomNop } from "@eatbetter/common-shared";
import { createSelector3, getCreateSelectorWithCacheSize } from "../redux/CreateSelector";
import { selectIsAnonymousUser } from "../system/SystemSelectors";
import { RecipeId } from "@eatbetter/recipes-shared";

export const useIsFollowingEntity = (entityId: SocialEntityId): boolean =>
  useSelector(s => selectIsFollowingEntity(s.social, entityId));

export const selectIsFollowingEntity = (s: SocialState, entityId: SocialEntityId): boolean => {
  const update = s.followingUpdates[entityId];
  if (update === "follow") {
    return true;
  } else if (update === "unfollow") {
    return false;
  }

  return !!s.followingInfo.data?.idsFollowed.includes(entityId);
};

export const selectRecommendedFollows = createSelector3(
  s => s.social.followingInfo.data,
  s => s.social.dismissedFollowRecommendations,
  s => s.social.followingUpdates,
  (followingData, dismissedEntities, updates) => {
    if (!followingData) {
      return [];
    }

    const recommendedUsers = followingData.recommended.entities;
    const alreadyFollowing = followingData.idsFollowed;

    return recommendedUsers.filter(u => {
      const id = getSocialEntityId(u.entity);
      return (
        // we don't want to show a recommendation for somebody the user is already following
        !alreadyFollowing.includes(id) &&
        // we don't want to show a recommendation for a user they've dismissed from the recommendation module
        !dismissedEntities.includes(id) &&
        // if a user is present in this object, it means they were recently followed or unfollowed
        // in either case, we don't want to show a recommendation for them
        !updates.hasOwnProperty(id)
      );
    });
  }
);

export const useRecommendedFollows = () => useSelector(selectRecommendedFollows);

export const useRecommendedFollowsDismissedThisSession = () =>
  useSelector(s => {
    return s.social.dismissedFollowRecommendationsModule;
  });

const selectPostIds = getCreateSelectorWithCacheSize(10)(
  [
    (_s, feedType: SocialFeedType) => feedType,
    (s, feedType: SocialFeedType) => selectSocialFeed(s.social, feedType),
    s => selectIsAnonymousUser(s),
  ],
  (feed, f, isAnon) => {
    if (feed?.type === "profileFeed" && isAnon) {
      return { postIds: [], loading: false };
    }

    return { postIds: f?.postIds ?? [], loading: !f?.newPostsMeta.data };
  }
);

export const usePostIds = (feed: SocialFeedType): { postIds: SocialPostId[]; loading: boolean } =>
  useSelector(s => selectPostIds(s, feed));

/**
 * Returns true if authed user has one ore more posts in their following feed
 */
export const useHaveFollowingFeedPosts = () => {
  return usePostIds(followingFeed).postIds.length > 0;
};

export const useFeedIsScrolled = (feed: SocialFeedType) =>
  useSelector(s => {
    const f = selectSocialFeed(s.social, feed);
    return f?.isListScrolled ?? false;
  });

export const useFeedHasNewerPosts = (feed: SocialFeedType) =>
  useSelector(s => {
    const f = selectSocialFeed(s.social, feed);
    return !!(f?.newerPostsNotRendered ?? false);
  });

export const useProfileInfo = (userId?: UserId) =>
  useSelector(s => {
    return userId ? s.social.otherUserFeeds[userId]?.profile : s.social.profileInfo;
  });

export const usePost = (
  postOrId: SocialPostId | SocialPost
): Omit<UserRecipeActionPost, "likes"> | Omit<TextPost, "likes"> | Omit<NewRecipePost, "likes"> | undefined =>
  useSelector(s => {
    if (typeof postOrId === "string") {
      return selectPost(s, postOrId);
    }
    return postOrId;
  });

const selectPostComments = (s: RootState, postId: SocialPostId): Comment[] => {
  const postComments = selectPost(s, postId)?.comments.items ?? [];
  return postComments;
};

export const usePostComments = (postId: SocialPostId) => useSelector(s => selectPostComments(s, postId));

const selectPostLikes = (s: RootState, postId: SocialPostId): Like[] => {
  const user: DeglazeUser | undefined = s.system.authedUser.data?.isRegistered ? s.system.authedUser.data : undefined;
  const postLikes = selectPost(s, postId)?.likes.items ?? [];

  if (!user) {
    return postLikes;
  }

  const pending = selectPendingLikeArgs(s, postId);
  if (pending?.action === "like") {
    return [...postLikes, { user }];
  }

  return postLikes;
};

export const usePostLikes = (postId: SocialPostId, sort?: "time" | "followed", excludeAuthedUser?: boolean) =>
  useSelector(s => {
    const userId = s.system.authedUser.data?.userId;
    const postLikes = selectPostLikes(s, postId);
    const filteredPostLikes = userId && excludeAuthedUser ? postLikes.filter(i => i.user.userId !== userId) : postLikes;

    const sortType = sort ?? "time";
    switch (sortType) {
      case "time": {
        return filteredPostLikes;
      }
      case "followed": {
        const followedList = filteredPostLikes.filter(i => selectIsFollowingEntity(s.social, i.user.userId));
        const notFollowedList = filteredPostLikes.filter(i => !selectIsFollowingEntity(s.social, i.user.userId));
        return [...followedList, ...notFollowedList];
      }
      default: {
        bottomThrow(sortType);
      }
    }
  });

export const usePostSaveCount = (postId: SocialPostId) =>
  useSelector(s => {
    const post = selectPost(s, postId);
    if (!post || post.type === "textPost") {
      return 0;
    }

    return post.saves.count;
  });

export const usePostExists = (id: SocialPostId) => {
  return useSelector(s => {
    return !!selectPost(s, id);
  });
};

export const usePostOwnerId = (id: SocialPostId | undefined): SocialEntityId | undefined => {
  return useSelector(s => {
    if (!id) {
      return undefined;
    }

    const post = selectPost(s, id);

    if (!post) {
      return undefined;
    }

    if (isUserSocialPost(post)) {
      return getSocialEntityId(post.user);
    } else if (isEntitySocialPost(post)) {
      return getSocialEntityId(post.entity);
    } else {
      bottomNop(post);
      return undefined;
    }
  });
};

const selectPost = (s: RootState, postId: SocialPostId) => s.social.posts[postId];

/**
 * Figure out if there is a pending action to like or unlike a post. This takes precedence
 * over the likes saved in the post state
 */
const selectPendingLikeArgs = (s: RootState, postId: SocialPostId): LikeUnlikePostArgs | undefined => {
  // order of precedence:
  // 1. queued - this is the most recently added
  // 2. pending - this is in flight
  const pendingAndQueued = s.social.pendingLikes[postId];
  return pendingAndQueued?.queued?.like ?? pendingAndQueued?.pending?.like;
};

const selectPostLiked = (s: RootState, postId: SocialPostId): boolean => {
  // 1. pending action
  // 2. stored like
  const pending = selectPendingLikeArgs(s, postId);

  if (pending) {
    return pending.action === "like";
  }

  const userId = s.system.authedUser.data?.userId;
  const post = s.social.posts[postId];

  if (!post || !userId) {
    return false;
  }

  return post.likes.items.some(l => l.user.userId === userId);
};

export const usePostLiked = (postId: SocialPostId | undefined) =>
  useSelector(s => (postId ? selectPostLiked(s, postId) : false));

export const useDebugFeedScrollText = () =>
  useSelector(s => {
    if (s.social.debugFeedScroll) {
      return `fo: ${s.social.followingFeed.isListScrolled ? "1" : "0"} ex: ${
        s.social.exploreFeed.isListScrolled ? "1" : "0"
      } p: ${s.social.profileFeed.isListScrolled ? "1" : "0"}`;
    }

    return undefined;
  });

/**
 * true if the user is following at least 1 user
 */
export const useHasFollowings = () =>
  useSelector(s => {
    const idLength = s.social.followingInfo.data?.idsFollowed.length ?? 0;
    const newFollow = Object.values(s.social.followingUpdates).some(u => u === "follow");
    return idLength > 0 || newFollow;
  });

export const selectFollowingCountForOnboarding = (s: RootState) => {
  // we could use the profile following count, but we load it async when following, so it could fail
  // this should be correct for the purposes of onboarding - it *should* be correct in all cases
  // as long as followingIds is actually loaded
  const ids = new Set(s.social.followingInfo.data?.idsFollowed ?? []);
  Object.entries(s.social.followingUpdates).forEach(e => {
    switch (e[1]) {
      case "follow":
        ids.add(e[0] as SocialEntityId);
        break;
      case "unfollow":
        ids.delete(e[0] as SocialEntityId);
        break;
      default:
        bottomNop(e[1]);
    }
  });

  return ids.size;
};

export const selectPostCountForOnboarding = (s: RootState) => {
  return s.social.followingFeed.postIds.length;
};

export const usePostSourceRecipeId = (postId?: SocialPostId): RecipeId | undefined =>
  useSelector(s => selectPostSourceRecipeId(s, postId));

export const selectPostSourceRecipeId = (state: RootState, postId: SocialPostId | undefined) => {
  if (!postId) {
    return undefined;
  }

  const post = selectPost(state, postId);
  if (!post) {
    return undefined;
  }

  switch (post.type) {
    case "userRecipeActionPost":
      return post.sourceRecipeId;
    case "newRecipePost":
      return post.recipeInfo.id;
    case "textPost":
      return undefined;
    default:
      bottomNop(post);
      return undefined;
  }
};

import React, { useCallback, useMemo, useState } from "react";
import { NavApi } from "../../navigation/ScreenContainer";
import { haveRecipeIngredients, RecipeId, RecipeSource, UserRecipeId } from "@eatbetter/recipes-shared";
import { Haptics } from "../Haptics";
import { navTree } from "../../navigation/NavTree";
import { archiveRecipe } from "../../lib/recipes/RecipesThunks";
import { getOptionsMenuHeight, OptionsMenu, OptionsMenuItem } from "../OptionsMenu";
import { Alert } from "../Alert/Alert";
import { useDispatch } from "../../lib/redux/Redux";
import { useBottomSheet } from "../../screens/BottomSheetScreen";
import { analyticsEvent } from "../../lib/analytics/AnalyticsThunks";
import {
  reportLibraryRecipeContextMenuItemTapped,
  reportUrlRecipeOpenedExternally,
} from "../../lib/analytics/AnalyticsEvents";
import {
  useRecipeIngredients,
  useRecipeNotes,
  useRecipeRating,
  useRecipeSourceType,
  useRecipeStatus,
  useRecipeUserEnteredAttribution,
} from "../../lib/recipes/RecipesSelectors";
import { UrlString, bottomThrow } from "@eatbetter/common-shared";
import { openWebpage } from "../../lib/util/WebUtil";
import { useRecipeTagsPreview } from "./RecipeTags";
import { RecipeRatingEdit, RecipeRatingEditSheet, recipeRatingEditSheetHeight } from "./RecipeRating";
import { usePaywallStatus } from "../PaywallDetector";
import { displayUnexpectedErrorAndLog } from "../../lib/Errors";
import { useNavToReportRecipeIssue } from "../../lib/util/UseNavToReportRecipeIssue";
import { useRecipeTags } from "../../lib/composite/CollectionsSelectors.ts";

const strings = {
  notes: "Notes",
  tags: "Collections",
  rating: "Rating",
  share: "Share",
  editRecipe: "Edit Recipe",
  editAttribution: "Edit Recipe Source",
  openExternal: "Open in External Browser",
  reportIssue: "Report a Recipe Issue",
  archiveRecipe: "Delete Recipe",
  archiveAlert: {
    title: "Delete Recipe",
    body: "Are you sure you want to delete this recipe?",
  },
};

export function getRecipeDetailOptionsHeight(args: { recipeSourceType: RecipeSource["type"] | undefined }) {
  const recipeSourceType = args.recipeSourceType ?? "user";

  switch (recipeSourceType) {
    case "url":
      return getOptionsMenuHeight(7);
    case "book":
      return getOptionsMenuHeight(6);
    case "userPhoto":
      return getOptionsMenuHeight(7);
    case "user":
      return getOptionsMenuHeight(6);
    default:
      bottomThrow(recipeSourceType);
  }
}

interface Props {
  recipeId: UserRecipeId;
  sourceRecipeId?: RecipeId;
  openExternalUrl?: UrlString;
  nav: NavApi;
}

export const RecipeDetailOptions = React.memo((props: Props) => {
  const dispatch = useDispatch();
  const bottomSheet = useBottomSheet();
  const recipeSourceType = useRecipeSourceType(props.recipeId);
  const recipeNotes = useRecipeNotes(props.recipeId);
  const userEnteredAttribution = useRecipeUserEnteredAttribution(props.recipeId);
  const recipeTags = useRecipeTags(props.recipeId);
  const recipeTagsPreview = useRecipeTagsPreview(recipeTags);
  const recipeRating = useRecipeRating(props.recipeId);
  const recipeStatus = useRecipeStatus(props.recipeId);
  const canEditAttribution = recipeSourceType === "user" || recipeSourceType === "userPhoto";
  const recipeIngredients = useRecipeIngredients(props.recipeId);
  const haveRecipe = recipeIngredients && haveRecipeIngredients(recipeIngredients) && recipeStatus !== "processing";
  const navToReportRecipeIssue = useNavToReportRecipeIssue({
    recipeId: props.recipeId,
    issueType: haveRecipe ? "incorrect" : "missing",
    nav: props.nav,
  });

  const [waitingArchiveRecipe, setWaitingArchiveRecipe] = useState(false);

  const { paywallStatus, paywallIsUp } = usePaywallStatus(props.recipeId);

  const onPressNotes = useCallback(() => {
    bottomSheet?.closeSheetAndGoBack();
    dispatch(analyticsEvent(reportLibraryRecipeContextMenuItemTapped({ action: "notes" })));
    setTimeout(
      () =>
        props.nav.goTo("push", navTree.get.screens.recipeNotesEdit, {
          recipeEditFieldLocation: "recipeDetail",
          recipeId: props.recipeId,
        }),
      300
    );
  }, [bottomSheet, props.nav.goTo, props.recipeId]);

  const onPressTags = useCallback(() => {
    bottomSheet?.closeSheetAndGoBack();
    dispatch(analyticsEvent(reportLibraryRecipeContextMenuItemTapped({ action: "tags" })));
    setTimeout(
      () =>
        props.nav.goTo("push", navTree.get.screens.recipeTagsEdit, {
          recipeEditFieldLocation: "recipeDetail",
          recipeId: props.recipeId,
        }),
      300
    );
  }, [bottomSheet, props.nav.goTo, props.recipeId]);

  const onPressRating = useCallback(() => {
    bottomSheet?.closeSheetAndGoBack();
    dispatch(analyticsEvent(reportLibraryRecipeContextMenuItemTapped({ action: "rating" })));
    setTimeout(
      () =>
        props.nav.modal(navTree.get.screens.bottomSheet, {
          height: recipeRatingEditSheetHeight,
          content: <RecipeRatingEditSheet recipeId={props.recipeId} />,
        }),
      300
    );
  }, [bottomSheet, props.nav.modal, props.recipeId]);

  const onEdit = useCallback(() => {
    bottomSheet?.closeSheetAndGoBack();
    dispatch(analyticsEvent(reportLibraryRecipeContextMenuItemTapped({ action: "editRecipe" })));
    setTimeout(() => props.nav.goTo("push", navTree.get.screens.recipeEdit, { recipeId: props.recipeId }), 300);
  }, [bottomSheet, props.nav.goTo, props.recipeId]);

  const onEditAttribution = useCallback(() => {
    bottomSheet?.closeSheetAndGoBack();
    dispatch(analyticsEvent(reportLibraryRecipeContextMenuItemTapped({ action: "editSource" })));
    setTimeout(() => {
      if (recipeSourceType === "user" || userEnteredAttribution?.type === "simple") {
        props.nav.goTo("push", navTree.get.screens.editSimpleAttribution, { recipeId: props.recipeId, popCount: 1 });
      } else if (recipeSourceType === "userPhoto") {
        props.nav.goTo("push", navTree.get.screens.attributionQuestions, { recipeId: props.recipeId, popCount: 1 });
      }
    }, 300);
  }, [bottomSheet, props.nav.modal, props.recipeId, recipeSourceType, userEnteredAttribution]);

  const onOpenExternal = useCallback(() => {
    if (!props.openExternalUrl) {
      displayUnexpectedErrorAndLog("Open Externally called but openExternalUrl is falsy", {}, { props });
      return;
    }
    bottomSheet?.closeSheetAndGoBack();
    dispatch(analyticsEvent(reportUrlRecipeOpenedExternally({ url: props.openExternalUrl, paywallStatus })));
    openWebpage(props.openExternalUrl);
  }, [bottomSheet, dispatch, props.openExternalUrl, paywallStatus]);

  const onPressReportIssue = useCallback(() => {
    bottomSheet?.closeSheetAndGoBack();
    dispatch(analyticsEvent(reportLibraryRecipeContextMenuItemTapped({ action: "reportIssue" })));
    setTimeout(() => navToReportRecipeIssue(), 300);
  }, [bottomSheet, props.nav.goTo, props.recipeId, navToReportRecipeIssue]);

  const promptDelete = useCallback(() => {
    Alert.alert(strings.archiveAlert.title, strings.archiveAlert.body, [
      {
        type: "cancel",
        onPress: () => {},
      },
      {
        type: "delete",
        onPress: async () => {
          await dispatch(archiveRecipe({ recipeId: props.recipeId }, setWaitingArchiveRecipe));
          Haptics.feedback("operationSucceeded");
          bottomSheet?.closeSheetAndGoBack();
        },
      },
    ]);
  }, [props.recipeId, setWaitingArchiveRecipe, dispatch, bottomSheet]);

  const onArchive = useCallback(() => {
    promptDelete();
  }, [bottomSheet, promptDelete]);

  const ratingPreview = useMemo(() => {
    return <RecipeRatingEdit rating={recipeRating} />;
  }, [recipeRating]);

  const disableEditRecipe = paywallIsUp || recipeSourceType === "book" || recipeStatus === "processing";

  return (
    <OptionsMenu>
      <OptionsMenuItem icon="note" isFirst text={strings.notes} preview={recipeNotes} onPress={onPressNotes} />
      <OptionsMenuItem icon="tag" text={strings.tags} preview={recipeTagsPreview} onPress={onPressTags} />
      <OptionsMenuItem icon="rating" text={strings.rating} onPress={onPressRating} preview={ratingPreview} />
      <OptionsMenuItem icon="editSquare" text={strings.editRecipe} onPress={onEdit} disabled={disableEditRecipe} />
      {canEditAttribution && (
        <OptionsMenuItem
          icon="editPencil"
          text={strings.editAttribution}
          onPress={onEditAttribution}
          disabled={disableEditRecipe}
        />
      )}
      {recipeSourceType === "url" && (
        <OptionsMenuItem
          icon="openExternal"
          text={strings.openExternal}
          onPress={onOpenExternal}
          disabled={!props.openExternalUrl}
        />
      )}
      {recipeSourceType !== "user" && (
        <OptionsMenuItem icon="flag" text={strings.reportIssue} onPress={onPressReportIssue} />
      )}
      <OptionsMenuItem waiting={waitingArchiveRecipe} icon="delete" text={strings.archiveRecipe} onPress={onArchive} />
    </OptionsMenu>
  );
});

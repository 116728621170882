import { ImageStyle, StyleProp, StyleSheet, View, ViewStyle } from "react-native";
import { Gradient } from "./Gradient/Gradient";
import { ShimmerEffect } from "./AttentionGrabbers";
import { GradientProps } from "./Gradient/GradientInterfaces";

export type PhotoPlaceholderType = "default" | "topLeftToBottomRight" | "topRightToBottomLeft";

export const PhotoPlaceholder = (props: {
  type: PhotoPlaceholderType;
  style: StyleProp<ViewStyle | ImageStyle>;
  color?: string;
  loading?: boolean;
}) => {
  const gradientProps: GradientProps = { type: props.type === "default" ? "topRightToBottomLeft" : props.type };

  return (
    <>
      <View style={[props.style, { overflow: "hidden" }, props.loading ? StyleSheet.absoluteFill : {}]}>
        {!props.loading && <Gradient {...gradientProps} />}
        {!!props.loading && <ShimmerEffect backgroundColor="colorGreyDark" />}
      </View>
    </>
  );
};

import React, { useCallback, useImperativeHandle, useMemo, useRef } from "react";
import { ScrollView, StyleSheet, View } from "react-native";
import { globalStyleColors, globalStyleConstants } from "./GlobalStyles";
import { IconEx, IconFilter, IconPlus, IconSearch } from "./Icons";
import { Pressable } from "./Pressable";
import { SelectableTagGroup, tagOrFilterIsSelected } from "./recipes/RecipeTagSelect";
import { Separator } from "./Separator";
import { Spacer } from "./Spacer";
import { TextInput, TextInputHandle, TextInputProps } from "./TextInput";
import { TBody, TSecondary } from "./Typography";
import { SelectableOvalRenderBadge } from "./SelectableOval";
import { switchReturn } from "@eatbetter/common-shared";
import { RecipeTagOrFilter, useFilterSuggestions } from "../lib/composite/CollectionsSelectors.ts";
import { WalkthroughStep } from "./Walkthrough.tsx";
import { FeatureDemoSnippet } from "./FeatureDemoSnippet.tsx";

const strings = {
  cancel: "Cancel",
  clear: "Clear",
  totalPrefix: "Under ",
  activePrefix: "Active: ",
  collectionsWalkthrough: {
    headline: "Powerful Filters",
    subheadline: "Tap here to filter your recipes by collection, rating, cooked, and more.",
  },
};

const constants = {
  searchBarHeight: 72,
  filterBarHeight: 44,
};

export function useSearchAndFilterBarHeight(activeTagFilters?: RecipeTagOrFilter[], showSuggestions?: boolean) {
  if ((activeTagFilters && activeTagFilters.length > 0) || !!showSuggestions) {
    return constants.searchBarHeight + constants.filterBarHeight;
  }

  return constants.searchBarHeight;
}

export type SearchAndFilterBarProps = Pick<TextInputProps, "placeholderText" | "autoFocus" | "autoCorrect"> & {
  searchPhrase?: string;
  onChangeSearchPhrase?: (v: string) => void;
  onPressFilterButton?: () => void;
  onPressAddFilterButton?: () => void;
  activeTagFilters?: RecipeTagOrFilter[];
  onRemoveFilter?: (tagOrFilter: RecipeTagOrFilter) => void;
  onPressFilterSuggestion?: (tagOrFilter: RecipeTagOrFilter) => void;
  cancelAction?: "cancel" | "clear";
  onPressCancel?: () => void;
  onPressSubmit?: () => void;
  editable?: boolean;
  onPressSearchBox?: () => void;
  onFocus?: () => void;
  keyboardReturnKeyType?: "done" | "search";
  paddingHorizontal?: "default" | "none";
  walkthrough?: "filters";
  onWalkthroughNext?: () => void;
};

export const SearchAndFilterBar = React.memo(
  React.forwardRef<TextInputHandle, SearchAndFilterBarProps>((props, ref) => {
    const hasActiveFilters = !!props.activeTagFilters && props.activeTagFilters.length > 0;
    const height = useSearchAndFilterBarHeight(props.activeTagFilters, !!props.onPressFilterSuggestion);

    return (
      <View style={{ height }}>
        <SearchBar
          ref={ref}
          searchPhrase={props.searchPhrase}
          onChangeSearchPhrase={props.onChangeSearchPhrase}
          onPressFilterButton={props.onPressFilterButton}
          placeholderText={props.placeholderText}
          autoFocus={props.autoFocus}
          onFocus={props.onFocus}
          hasActiveFilters={hasActiveFilters}
          onPressSubmit={props.onPressSubmit}
          cancelAction={props.cancelAction}
          onPressCancel={props.onPressCancel}
          editable={props.editable}
          onPressSearchBox={props.onPressSearchBox}
          keyboardReturnKeyType={props.keyboardReturnKeyType}
          paddingHorizontal={props.paddingHorizontal}
          walkthrough={props.walkthrough}
          onWalkthroughNext={props.onWalkthroughNext}
        />
        {hasActiveFilters && (
          <FiltersBar
            onPressFilterButton={props.onPressAddFilterButton ?? props.onPressFilterButton}
            activeTagFilters={props.activeTagFilters}
            onRemoveFilter={props.onRemoveFilter}
          />
        )}
        {!hasActiveFilters && !!props.onPressFilterSuggestion && (
          <FiltersBar
            onPressFilterButton={props.onPressAddFilterButton ?? props.onPressFilterButton}
            onPressFilterSuggestion={props.onPressFilterSuggestion}
          />
        )}
      </View>
    );
  })
);

type SearchProps = Pick<
  SearchAndFilterBarProps,
  | "searchPhrase"
  | "onChangeSearchPhrase"
  | "placeholderText"
  | "autoFocus"
  | "onPressFilterButton"
  | "cancelAction"
  | "onPressCancel"
  | "onPressSubmit"
  | "onPressSearchBox"
  | "onFocus"
  | "editable"
  | "keyboardReturnKeyType"
  | "paddingHorizontal"
  | "autoCorrect"
  | "walkthrough"
  | "onWalkthroughNext"
> & { hasActiveFilters: boolean };

const SearchBar = React.memo(
  React.forwardRef<TextInputHandle, SearchProps>((props, ref) => {
    const inputRef = useRef<TextInputHandle>(null);

    const editable = props.editable !== false;
    const showFilterButton = !props.hasActiveFilters && !!props.onPressFilterButton;
    const keyboardReturnKey = props.keyboardReturnKeyType ?? "done";

    const onPressCancel = useCallback(() => {
      if (props.onPressCancel) {
        props.onPressCancel();
        inputRef.current?.blur();
      }
    }, [props.onPressCancel, inputRef]);

    const onPressSearchBox = useCallback(() => {
      props.onPressSearchBox?.();
    }, [props.onPressSearchBox]);

    useImperativeHandle(ref, () => inputRef.current!);

    const paddingHorizontal = switchReturn(props.paddingHorizontal ?? "default", {
      default: {},
      none: { paddingHorizontal: 0 },
    });

    return (
      <View style={[styles.searchBar, paddingHorizontal]}>
        <Pressable style={styles.searchBox} onPress={onPressSearchBox} noFeedback disabled={editable}>
          <IconSearch opacity="opaque" size={22} />
          <Spacer horizontal={0.25} />
          <View
            style={{
              flex: 1,
              paddingLeft: globalStyleConstants.unitSize / 2,
              pointerEvents: editable ? undefined : "none",
            }}
          >
            <TextInput
              ref={inputRef}
              value={props.searchPhrase}
              placeholderText={props.placeholderText}
              onChangeText={props.onChangeSearchPhrase}
              onSubmit={props.onPressSubmit}
              onFocus={props.onFocus}
              autoFocus={props.autoFocus}
              autoCorrect={props.autoCorrect}
              returnKeyType={keyboardReturnKey}
              backgroundColor={"transparent"}
              editable={editable}
              noBorder
              noPadding
            />
          </View>
          {showFilterButton && !!props.onPressFilterButton && (
            <FilterButton
              onPress={props.onPressFilterButton}
              walkthrough={props.walkthrough}
              onWalkthroughNext={props.onWalkthroughNext}
            />
          )}
        </Pressable>
        {!!props.cancelAction && <CancelButton type={props.cancelAction} onPress={onPressCancel} marginLeftUnits={1} />}
      </View>
    );
  })
);

type FilterProps = Pick<
  SearchAndFilterBarProps,
  "activeTagFilters" | "onRemoveFilter" | "onPressFilterButton" | "onPressFilterSuggestion"
>;

const FiltersBar = React.memo((props: FilterProps) => {
  const onPressTag = useCallback(
    (tag: RecipeTagOrFilter) => {
      if (tagOrFilterIsSelected(tag, props.activeTagFilters ?? [])) {
        props.onRemoveFilter?.(tag);
      }

      // If it's not an active tag, it must be a suggestion
      props.onPressFilterSuggestion?.(tag);
    },
    [props.activeTagFilters, props.onRemoveFilter, props.onPressFilterSuggestion]
  );

  const onPressAddFilter = useCallback(() => {
    props.onPressFilterButton?.();
  }, [props.onPressFilterButton]);

  const renderDeleteBadge = useCallback<SelectableOvalRenderBadge>(({ isSelected }) => {
    return <>{isSelected && <DeleteBadge />}</>;
  }, []);

  const filterSuggestions = useFilterSuggestions();
  const filterTagSuggestions = useMemo<RecipeTagOrFilter[]>(() => {
    if (!props.onPressFilterSuggestion || (!!props.activeTagFilters && props.activeTagFilters.length > 0)) {
      return [];
    }
    return filterSuggestions;
  }, [props.onPressFilterSuggestion, props.activeTagFilters?.length, filterSuggestions]);

  return (
    <View style={styles.filterBar}>
      <AddFilterButton onPress={onPressAddFilter} marginBottomUnits={0.5} />
      <ScrollView
        horizontal
        bounces={false}
        showsHorizontalScrollIndicator={false}
        style={{ paddingHorizontal: globalStyleConstants.unitSize }}
        contentContainerStyle={{ paddingRight: 0.5 * globalStyleConstants.unitSize }}
        keyboardShouldPersistTaps="always"
      >
        <SelectableTagGroup
          options={props.activeTagFilters ?? filterTagSuggestions ?? []}
          selected={props.activeTagFilters ?? []}
          onPressTag={onPressTag}
          renderTagBadge={renderDeleteBadge}
        />
      </ScrollView>
      <View style={styles.filterRightBoundary} />
    </View>
  );
});

const FilterButton = React.memo(
  (props: { onPress: () => void; walkthrough?: "filters"; onWalkthroughNext?: () => void }) => {
    const filterButton = (
      <Pressable style={styles.filterButton} onPress={props.onPress}>
        <IconFilter
          opacity="opaque"
          color={props.walkthrough === "filters" ? "white" : globalStyleColors.colorAccentCool}
          size={22}
        />
      </Pressable>
    );

    return (
      <View style={{ marginLeft: globalStyleConstants.minPadding, flexDirection: "row", alignItems: "center" }}>
        <View style={{ paddingVertical: globalStyleConstants.minPadding }}>
          <Separator orientation="column" />
        </View>
        {props.walkthrough === "filters" ? (
          <WalkthroughStep
            show
            buttonText={"gotIt"}
            message={
              <>
                <TBody fontWeight="medium" align="center">
                  {strings.collectionsWalkthrough.headline}
                </TBody>
                <Spacer vertical={0.25} />
                <TSecondary align="center">{strings.collectionsWalkthrough.subheadline}</TSecondary>
                <Spacer vertical={1.5} />
                <FeatureDemoSnippet type="filters" borderRadius />
              </>
            }
            onPressButton={props.onWalkthroughNext}
            onPressChildComponent={() => {}}
            wobble={false}
          >
            {filterButton}
          </WalkthroughStep>
        ) : (
          filterButton
        )}
      </View>
    );
  }
);

const AddFilterButton = React.memo(
  (props: { onPress: () => void; marginBottomUnits?: number; marginRightUnits?: number }) => {
    return (
      <View
        style={{
          alignItems: "center",
          flexDirection: "row",
          marginBottom: props.marginBottomUnits ? props.marginBottomUnits * globalStyleConstants.unitSize : undefined,
          marginRight: props.marginRightUnits ? props.marginRightUnits * globalStyleConstants.unitSize : undefined,
        }}
      >
        <Pressable style={{ flexDirection: "row", alignItems: "center" }} onPress={props.onPress}>
          <IconPlus opacity="opaque" size={28} color={globalStyleColors.colorAccentCool} />
          <Spacer horizontal={0.5} />
          <View>
            <TSecondary color={globalStyleColors.colorAccentCool}>{"Filter"}</TSecondary>
          </View>
        </Pressable>
        <Spacer horizontal={1} />
        <Separator orientation="column" color={globalStyleColors.rgba("black", "light")} lineWidth={0.5} />
      </View>
    );
  }
);

const CancelButton = React.memo(
  (props: {
    type: NonNullable<SearchAndFilterBarProps["cancelAction"]>;
    onPress: () => void;
    marginLeftUnits?: number;
  }) => {
    const text = switchReturn(props.type, {
      cancel: strings.cancel,
      clear: strings.clear,
    });

    return (
      <Pressable
        style={props.marginLeftUnits ? { marginLeft: props.marginLeftUnits * globalStyleConstants.unitSize } : {}}
        onPress={props.onPress}
      >
        <TSecondary fontWeight="medium" color={globalStyleColors.colorAccentCool}>
          {text}
        </TSecondary>
      </Pressable>
    );
  }
);

const DeleteBadge = React.memo(() => {
  return (
    <View
      style={{ marginLeft: 0.5 * globalStyleConstants.unitSize, marginRight: -0.5 * globalStyleConstants.unitSize }}
    >
      <IconEx color={"white"} opacity="opaque" size={14} />
    </View>
  );
});

const styles = StyleSheet.create({
  searchBar: {
    flexDirection: "row",
    alignItems: "center",
    height: constants.searchBarHeight,
    paddingHorizontal: globalStyleConstants.defaultPadding,
    paddingVertical: globalStyleConstants.unitSize,
  },
  searchBox: {
    flex: 1,
    flexShrink: 1,
    flexDirection: "row",
    alignItems: "center",
    height: constants.searchBarHeight - 2 * globalStyleConstants.unitSize,
    backgroundColor: globalStyleColors.rgba("colorGreyDark", "light"),
    borderRadius: constants.searchBarHeight / 1.2,
    paddingHorizontal: 1.3 * globalStyleConstants.unitSize,
  },
  filterBar: {
    height: constants.filterBarHeight,
    flexDirection: "row",
    marginLeft: globalStyleConstants.unitSize + 8,
    paddingBottom: 0.5 * globalStyleConstants.unitSize,
  },
  filterButton: {
    paddingLeft: globalStyleConstants.unitSize,
  },
  filterRightBoundary: {
    width: globalStyleConstants.unitSize,
    backgroundColor: "white",
    zIndex: 1,
    shadowColor: "white",
    shadowRadius: 6,
    shadowOffset: {
      height: 0,
      width: -1,
    },
    shadowOpacity: 0.6,
  },
});

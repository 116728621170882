import { useScreen, withScreenContainer } from "../navigation/ScreenContainer";
import { ScreenView } from "../components/ScreenView";
import { SettingsItem } from "../components/SettingsItem";
import { useCallback } from "react";
import { navTree } from "../navigation/NavTree";
import { useDispatch } from "../lib/redux/Redux";
import { analyticsEvent } from "../lib/analytics/AnalyticsThunks";
import {
  reportHowToAddRecipesButtonTapped,
  reportHowToGrocerylistListButtonTapped,
} from "../lib/analytics/AnalyticsEvents";

const strings = {
  title: "How To",
  recipes: "Adding Recipes",
  organize: "Organizing Recipes",
  groceries: "Grocery List",
};

export const HowToScreen = withScreenContainer("HowToScreen", () => {
  const dispatch = useDispatch();
  const screen = useScreen();

  const onPressRecipes = useCallback(() => {
    dispatch(analyticsEvent(reportHowToAddRecipesButtonTapped()));
    screen.nav.goTo("push", navTree.get.screens.howToAddRecipes);
  }, [screen.nav.goTo]);

  const onPressGroceries = useCallback(() => {
    dispatch(analyticsEvent(reportHowToGrocerylistListButtonTapped()));
    screen.nav.goTo("push", navTree.get.screens.howToGroceryOverview);
  }, [screen.nav.goTo]);

  /** Commenting out for now until we update the content */
  // const onPressOrganizeRecipes = useCallback(() => {
  //   dispatch(analyticsEvent(reportHowToOrganizeRecipesButtonTapped()));
  //   screen.nav.goTo("push", navTree.get.screens.howToOrganize);
  // }, [screen.nav.goTo]);

  return (
    <ScreenView header={{ type: "default", style: "default", title: strings.title }}>
      <SettingsItem title={strings.recipes} onPress={onPressRecipes} navAction />
      {/* <SettingsItem title={strings.organize} onPress={onPressOrganizeRecipes} navAction /> */}
      <SettingsItem title={strings.groceries} onPress={onPressGroceries} navAction />
    </ScreenView>
  );
});

import { useCallback, useEffect, useMemo, useState } from "react";
import { RecipeCollectionTitleInput } from "../components/recipes/RecipeCollectionTitleInput";
import { ScreenView, useScreenElementDimensions } from "../components/ScreenView";
import { navTree, RecipeCollectionRenameProps } from "../navigation/NavTree";
import { useScreen, withNonNavigableScreenContainer } from "../navigation/ScreenContainer";
import { InputAccessoryView } from "../components/InputAccessoryView";
import { BottomActionBar, bottomActionBarConstants } from "../components/BottomActionBar";
import { HeaderProps } from "../components/ScreenHeaders";
import { LayoutAnimation, ScrollView } from "react-native";
import { globalStyleColors, globalStyleConstants } from "../components/GlobalStyles";
import { useKeyboardLayoutAnimation } from "../components/Keyboard";
import { displayUnexpectedErrorAndLog } from "../lib/Errors";
import { Haptics } from "../components/Haptics";
import { RecipeCollectionId } from "@eatbetter/recipes-shared";
import {
  useCollectionCreatedByOtherUser,
  useCollectionName,
  useCollectionSource,
} from "../lib/composite/CollectionsSelectors";
import { withNavDelay } from "../lib/util/WithNavDelay";
import { useBottomSheet } from "./BottomSheetScreen";
import { Spacer } from "../components/Spacer";
import { TSecondary } from "../components/Typography";
import { Alert } from "../components/Alert/Alert";

const strings = {
  submit: "Save",
  collectionRenameScreen: {
    title: "Rename Collection",
    placeholder: "Collection name",
  },
  dupeNameError: (entityType: string) => `A ${entityType} with that name already exists`,
  householdCollectionAlert: {
    title: "Rename Household Collection?",
    body: (createdByUsername: string) =>
      `This collection was created by ${createdByUsername}. If you rename it, it will rename it for everyone in the household.`,
    rename: "Rename",
  },
};

export const RecipeCollectionRenameScreen = withNonNavigableScreenContainer<RecipeCollectionRenameProps>(
  "RecipeCollectionRenameScreen",
  props => {
    const screen = useScreen();
    const { modalHeaderHeight, bottomNotchHeight } = useScreenElementDimensions();
    const keyboardHeight = useKeyboardLayoutAnimation();

    const [value, setValue] = useState(props.initialValue);
    const [waitingSubmit, setWaitingSubmit] = useState(false);

    const isDupeName = !!props.existingNames?.some(i => i.toLowerCase() === value.trim().toLowerCase());
    const canSubmit = !!value.trim() && props.initialValue !== value.trim() && !isDupeName;

    // Lazy way to get the entity type since we're using this screen for collections + groups
    const entityType = props.screenTitle.slice(props.screenTitle.lastIndexOf(" ") + 1).toLowerCase();

    useEffect(() => {
      LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
    }, [isDupeName]);

    const onSubmit = useCallback(async () => {
      Haptics.feedback("itemStatusChanged");

      if (!canSubmit) {
        displayUnexpectedErrorAndLog(
          "RecipeCollectionRenameScreen: onSubmit called but canSubmit is false",
          undefined,
          {
            canSubmit,
            value,
            props,
          }
        );
        return;
      }

      try {
        setWaitingSubmit(true);
        await props.onSubmit(value.trim());
        setWaitingSubmit(false);
        screen.nav.goBack();
      } catch {
        // Error handling/display happens in callback, but it can rethrow to make sure we don't nav back in that case.
        setWaitingSubmit(false);
      }
    }, [value, canSubmit, props.onSubmit, screen.nav.goBack, setWaitingSubmit]);

    const onCancel = useCallback(() => {
      screen.nav.goBack();
    }, [screen.nav.goBack]);

    const screenHeader = useMemo<HeaderProps>(() => {
      return {
        type: "default",
        title: props.screenTitle,
        right: {
          type: "cancel",
          onPress: onCancel,
        },
        backgroundColor: "white",
      };
    }, [props.screenTitle, onCancel]);

    return (
      <ScreenView isModal header={screenHeader} scrollView={false} paddingHorizontal={false} paddingVertical={false}>
        <ScrollView
          keyboardShouldPersistTaps="always"
          contentContainerStyle={{
            paddingHorizontal: globalStyleConstants.defaultPadding,
            paddingTop: modalHeaderHeight + globalStyleConstants.unitSize,
            paddingBottom:
              (keyboardHeight > 0 ? keyboardHeight + bottomActionBarConstants.height : bottomNotchHeight) +
              globalStyleConstants.unitSize,
          }}
        >
          <RecipeCollectionTitleInput
            autofocus
            value={value}
            onChangeText={setValue}
            placeholderText={props.placeholderText}
            onSubmit={onSubmit}
          />
          {isDupeName && (
            <>
              <Spacer vertical={1.5} />
              <TSecondary color={globalStyleColors.colorAccentCool}>{strings.dupeNameError(entityType)}</TSecondary>
            </>
          )}
        </ScrollView>
        <InputAccessoryView snapPoint={{ bottom: "screenBottom" }} backgroundColor="transparent">
          <BottomActionBar
            primaryAction={{
              type: "submit",
              actionText: strings.submit,
              onPressAction: onSubmit,
              disabled: !canSubmit,
              waiting: waitingSubmit,
            }}
            disableSnapToBottom
            containerBackgroundColor="transparent"
          />
        </InputAccessoryView>
      </ScreenView>
    );
  }
);

export function useRenameRecipeCollection(props: {
  collectionId: RecipeCollectionId;
  renameCollection: (newName: string) => void;
  existingNames?: string[];
}) {
  const { collectionId, renameCollection } = props;
  const screen = useScreen();
  const collectionName = useCollectionName(collectionId);
  const collectionSource = useCollectionSource(collectionId);
  const collectionCreatedByUser = useCollectionCreatedByOtherUser(props.collectionId);
  const bottomSheet = useBottomSheet();

  const navToRename = useCallback(() => {
    bottomSheet?.closeSheetAndGoBack();
    withNavDelay(() => {
      screen.nav.modal(navTree.get.screens.recipeCollectionRename, {
        initialValue: collectionName,
        placeholderText: strings.collectionRenameScreen.placeholder,
        screenTitle: strings.collectionRenameScreen.title,
        onSubmit: renameCollection,
        existingNames: props.existingNames,
      });
    });
  }, [bottomSheet, collectionName, screen.nav.modal, renameCollection, props.existingNames]);

  const onPressRename = useCallback(() => {
    if (collectionSource === "household" && collectionCreatedByUser) {
      Alert.alert(
        strings.householdCollectionAlert.title,
        strings.householdCollectionAlert.body(collectionCreatedByUser.username),
        [
          {
            type: "save",
            onPress: navToRename,
            text: strings.householdCollectionAlert.rename,
          },
          {
            type: "cancel",
            onPress: () => {},
          },
        ]
      );
    } else {
      navToRename();
    }
  }, [navToRename, collectionSource, collectionCreatedByUser]);

  return onPressRename;
}

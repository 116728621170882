import {
  AppUserRecipe,
  UserRecipeId,
  RecipeCollectionId,
  SystemFilterRecipeCollectionId,
  cookingAndShoppedCollectionId,
} from "@eatbetter/recipes-shared";
import { CookingSessionId } from "@eatbetter/cooking-shared";
import { createSelector5, getCreateSelectorWithCacheSize } from "../redux/CreateSelector";
import { useSelector } from "../redux/Redux";
import { RootState } from "../redux/RootReducer";
import { selectCookingSessionAndRecipeIds } from "../cooking/CookingSessionsSelectors";
import { RecipeInstanceAndIds, selectRecipeInstances } from "../lists/ListsSelectors";
import { selectRecipesById, selectRecipeViewTimeOverrides } from "../recipes/RecipesSelectors";
import {
  bottomWithDefault,
  daysBetween,
  defaultTimeProvider,
  emptyToUndefined,
  EpochMs,
  filterOutFalsy,
  switchReturn,
  UserId,
} from "@eatbetter/common-shared";
import { groupBy } from "lodash";
import { selectUserId } from "../system/SystemSelectors";
import { searchRecipes } from "../recipes/RecipeSearch";
import { RecipeTag } from "@eatbetter/recipes-shared/dist/RecipeTagTypes";
import { getAppFilterCollectionMeta, getFiltersPredicate, RecipeFilter } from "../recipes/AppFilterCollections.ts";
import { CollectionIdAndAction, RecipeFilters, selectLibraryFilterSession } from "../recipes/RecipesSlice.ts";
import {
  selectCollectionsById,
  selectGetCollectionByTag,
  useDeferredFilters,
} from "./SharedRecipeAndCollectionSelectors.ts";
import { useMemo } from "react";
import {
  isLibraryFilterSessionId,
  LibraryFilterSessionId,
  LibraryOrSearchSessionId,
} from "./LibraryAndSearchSessionIds.ts";

/**************************************
 * EXPORTED TYPES
 **************************************/
export interface ActiveRecipeListItem {
  type: "active";
  recipeId: UserRecipeId;
  cookingSessionId: CookingSessionId;
  // we need the list key to change when the recipe section changes, or when the sort of the section changes
  // this is because we use the maintainVisibleContentPosition property and the docs state reordering can cause strange behavior
  listKey: string;
  sort: number;
}

export interface DefaultRecipeListItem {
  type: "default";
  recipeId: UserRecipeId;
  // we need the list key to change when the recipe section changes, or when the sort of the section changes
  // this is because we use the maintainVisibleContentPosition property and the docs state reordering can cause strange behavior
  listKey: string;
  sort: number;
}

export type RecipeListItem = ActiveRecipeListItem | DefaultRecipeListItem;

export interface RecipeListSections {
  cookingSessionRecipes: ActiveRecipeListItem[];
  groceryListRecipes: DefaultRecipeListItem[];
  otherRecipes: DefaultRecipeListItem[];
}

export type RecipeListSort = "default" | "dateCreated_desc" | "dateCreated_asc" | "alpha_asc" | "alpha_desc";

const librarySearchCacheSize = 5;

/**************************************
 * EXPORTED FUNCTIONS
 **************************************/

export const useLastScrollListToTopAction = () => useSelector(s => s.recipes.lastScrollListToTopAction);

export const useRecipeSearchPhrase = (sessionId: LibraryFilterSessionId) =>
  useSelector(s => selectLibraryFilterSession(s.recipes, sessionId)?.filters.search);

export const useFilteredRecipeListSections = (
  sessionId: LibraryOrSearchSessionId,
  sort: RecipeListSort
): RecipeListSections => {
  const { sections } = useFilteredRecipeListSectionsWithDeferredStatus(sessionId, sort);
  return sections;
};

export const useFilteredRecipeListSectionsWithDeferredStatus = (
  sessionId: LibraryOrSearchSessionId,
  sort: RecipeListSort
): { sections: RecipeListSections; deferred: boolean } => {
  const { deferred, filters } = useDeferredFilters(sessionId);
  const sections = useSelector(s => {
    // This is a library-only call, but we allow for global search IDs here so that we can call the hook
    // from the library filter screen (adhering to the rules of hooks). If it's not a library session ID,
    // we simply return empty results (though this should not be called with a global search session ID).
    if (!isLibraryFilterSessionId(sessionId)) {
      return emptySections;
    }

    return selectFilteredRecipeListSections(s, filters, sort);
  });

  return useMemo(() => {
    return { sections, deferred };
  }, [deferred, sections]);
};

// static ref for empty sections
const emptySections = {
  cookingSessionRecipes: [],
  groceryListRecipes: [],
  otherRecipes: [],
};

export const useRecipeCount = (sections: RecipeListSections): number => {
  return useMemo(() => {
    return Object.values(sections).reduce<number>((count, curr) => (count += curr.length), 0);
  }, [sections]);
};

export const useCollectionInitialSort = (collectionId: RecipeCollectionId): RecipeListSort => {
  const recentlyAddedId: SystemFilterRecipeCollectionId = "sc:filter:recently_added";
  if (collectionId === recentlyAddedId) {
    return "dateCreated_desc";
  }

  return "default";
};

export const selectRecipeFilterPredicate: (s: RootState, filters: RecipeFilters) => (r: AppUserRecipe) => boolean =
  getCreateSelectorWithCacheSize(1)(
    [
      (s, filters: RecipeFilters) =>
        selectTagAndCollectionPredicate(s, filters.tags, filters.filters, filters.collection),
      (s, filters: RecipeFilters) => selectRecipesMatchingSearchFilter(s, filters.search),
    ],
    (tagPredicate, searchScores) => {
      return (r: AppUserRecipe) => {
        const tagHit = !tagPredicate || tagPredicate(r);
        const searchHit = !searchScores || !!searchScores[r.id];
        return tagHit && searchHit;
      };
    }
  );

/**
 * Used in search results page
 */
export const useFilteredLibraryRecipesForGlobalSearchResults = (sessionId: LibraryOrSearchSessionId) => {
  const { filters } = useDeferredFilters(sessionId);
  return useSelector(s => selectFilteredRecipesForGlobalSearchResults(s, filters));
};

export const useFilteredCookingSessionAndGroceryRecipes = (sessionId: LibraryFilterSessionId) => {
  const { filters } = useDeferredFilters(sessionId);
  return useSelector(s => selectFilteredCookingSessionAndGroceryRecipes(s, filters));
};

/**
 * Get recipe list sections with default sort
 */
export const selectRecipeListSections: (s: RootState) => RecipeListSections = createSelector5(
  s => selectUserId(s),
  s => selectCookingSessionAndRecipeIds(s),
  s => selectRecipeInstances(s),
  s => selectRecipesById(s),
  s => selectRecipeViewTimeOverrides(s),
  (userId, cookingSessions, listRecipeInstances, recipesById, viewTimeOverrides) => {
    const seen: Set<UserRecipeId> = new Set();

    const showArchived = false;

    const cookingSessionRecipes = [...cookingSessions]
      .map<ActiveRecipeListItem>(s => {
        seen.add(s.recipeId);
        const sort = s.timeStarted;
        return {
          type: "active",
          recipeId: s.recipeId,
          cookingSessionId: s.cookingSessionId,
          listKey: `inProgress-${s.recipeId}-${sort}`,
          sort,
        };
      })
      .sort((a, b) => b.sort - a.sort);

    // a recipe can technically be added to the list multiple times, so make sure there aren't dups
    const groceryListRecipes = mergeRecipeInstances(listRecipeInstances)
      .filter(
        r =>
          !seen.has(r.recipeId) &&
          recipesById[r.recipeId] &&
          !recipesById[r.recipeId]?.archived &&
          notCookedSinceAddedToList(r.timeAdded, recipesById[r.recipeId]) &&
          notStale(r.timeCompleted)
      )
      .map<DefaultRecipeListItem>(r => {
        seen.add(r.recipeId);
        const sort = r.timeAdded;
        return { type: "default", recipeId: r.recipeId, listKey: `recentlyShopped-${r.recipeId}-${sort}`, sort };
      })
      .sort((a, b) => b.sort - a.sort);

    const otherRecipes = Object.entries(recipesById)
      .filter(e => {
        return !seen.has(e[0] as UserRecipeId) && (showArchived || !e[1].archived) && !e[1].deleted;
      })
      .map<DefaultRecipeListItem>(e => {
        const sort = getRecipeSortTime(e[1], userId, viewTimeOverrides);
        return { type: "default", recipeId: e[0] as UserRecipeId, sort, listKey: `other-${e[0]}-${sort}` };
      })
      .sort((a, b) => {
        return b.sort - a.sort;
      });
    return {
      cookingSessionRecipes,
      groceryListRecipes,
      otherRecipes,
    };
  }
);

/**
 * Predicate for the active recipes (cooking in progress/recently shopped) collection
 * this is special cased since it acts on more than the recipe itself
 */
export const selectCookingAndShoppedPredicate: (s: RootState) => (r: AppUserRecipe) => boolean =
  getCreateSelectorWithCacheSize(1)([s => selectRecipeListSections(s)], sections => {
    const set = new Set<UserRecipeId>();
    sections.cookingSessionRecipes.forEach(r => set.add(r.recipeId));
    sections.groceryListRecipes.forEach(r => set.add(r.recipeId));
    return (r: AppUserRecipe) => set.has(r.id);
  });

const selectFilteredRecipesForGlobalSearchResults: (s: RootState, filters: RecipeFilters) => AppUserRecipe[] =
  getCreateSelectorWithCacheSize(librarySearchCacheSize)(
    [(s, filters: RecipeFilters) => selectFilteredRecipeListSections(s, filters, "default"), s => s.recipes.entities],
    (sections, recipes) => {
      return filterOutFalsy([
        ...sections.cookingSessionRecipes.map(i => recipes[i.recipeId]),
        ...sections.groceryListRecipes.map(i => recipes[i.recipeId]),
        ...sections.otherRecipes.map(i => recipes[i.recipeId]),
      ]);
    }
  );

const selectFilteredRecipeListSections: (
  s: RootState,
  filters: RecipeFilters,
  sort: RecipeListSort
) => RecipeListSections = getCreateSelectorWithCacheSize(librarySearchCacheSize)(
  [
    s => selectRecipeListSections(s),
    (s, filters: RecipeFilters) => selectRecipesMatchingSearchFilter(s, filters.search),
    (s, filters: RecipeFilters) =>
      selectTagAndCollectionPredicate(s, filters.tags, filters.filters, filters.collection),
    s => selectRecipesById(s),
    (_a1, _a2, sort: RecipeListSort) => sort,
  ],
  (list, searchScores, tagAndCollectionPredicate, recipes, sort) => {
    if (!searchScores && !tagAndCollectionPredicate && sort === "default") {
      return list;
    }

    const predicate = (r: AppUserRecipe) => {
      const searchHit = !searchScores || searchScores.hasOwnProperty(r.id);
      const tagHit = tagAndCollectionPredicate?.(r) ?? true;
      return searchHit && tagHit;
    };

    const entries = Object.entries(list).map(entry => {
      const [key, list] = entry;
      const filtered = (list as RecipeListItem[]).filter(i => {
        const recipe = recipes[i.recipeId];
        return !!recipe && predicate(recipe);
      });

      // if we have search scores, we should re-order so that title matches
      // are above other matches.
      // if we have tag scores, we should re-order so that more tag matches get sorted first
      // Hermes sort is supposedly now stable, so the results should end up sorted
      // by relevance, recency.
      if (searchScores && sort === "default") {
        filtered.sort((a, b) => {
          const aSearchScore = searchScores?.[a.recipeId] ?? 0;
          const bSearchScore = searchScores?.[b.recipeId] ?? 0;
          return bSearchScore - aSearchScore;
        });
      } else if (sort !== "default") {
        filtered.sort((a, b) => {
          const aRecipe = recipes[a.recipeId];
          const bRecipe = recipes[b.recipeId];

          if (!aRecipe || !bRecipe) {
            return 0;
          }

          switch (sort) {
            case "alpha_asc":
              return aRecipe.title.localeCompare(bRecipe.title, "en", { sensitivity: "base" });
            case "alpha_desc":
              return bRecipe.title.localeCompare(aRecipe.title, "en", { sensitivity: "base" });
            case "dateCreated_asc":
              return aRecipe.created - bRecipe.created;
            case "dateCreated_desc":
              return bRecipe.created - aRecipe.created;
            default:
              return bottomWithDefault(sort, 0, "selectFilteredRecipeListSections sort");
          }
        });
      }
      return [key, filtered];
    });

    return Object.fromEntries(entries);
  }
);

const selectFilteredCookingSessionAndGroceryRecipes: (
  s: RootState,
  filters: RecipeFilters
) => { cookingSessionRecipes: AppUserRecipe[]; groceryRecipes: AppUserRecipe[] } = getCreateSelectorWithCacheSize(
  librarySearchCacheSize
)(
  [(s, filters: RecipeFilters) => selectFilteredRecipeListSections(s, filters, "default"), s => s.recipes.entities],
  (sections, recipes) => {
    return {
      cookingSessionRecipes: filterOutFalsy(sections.cookingSessionRecipes.map(i => recipes[i.recipeId])),
      groceryRecipes: filterOutFalsy(sections.groceryListRecipes.map(i => recipes[i.recipeId])),
    };
  }
);

const selectRecipesMatchingSearchFilter: (
  s: RootState,
  query: string | undefined
) => Record<UserRecipeId, number> | undefined = getCreateSelectorWithCacheSize(librarySearchCacheSize)(
  [s => selectRecipesById(s), (_, query: string | undefined) => query],
  (recipeMap, query) => {
    if (!query || emptyToUndefined(query) === undefined || query.length < 2) {
      return undefined;
    }

    const recipes = Object.values(recipeMap);
    return searchRecipes(recipes, query);
  }
);

const truePredicate = () => true;
const falsePredicate = () => false;

const selectCollectionPredicate: (
  s: RootState,
  collection: CollectionIdAndAction | undefined
) => (r: AppUserRecipe) => boolean = getCreateSelectorWithCacheSize(librarySearchCacheSize)(
  [
    s => selectCollectionsById(s),
    (_s, collection: CollectionIdAndAction | undefined) => collection,
    (s, collection: CollectionIdAndAction | undefined) =>
      collection?.collectionId === cookingAndShoppedCollectionId ? selectCookingAndShoppedPredicate(s) : undefined,
  ],
  (collectionsById, collectionIdAndAction, activePredicate) => {
    if (!collectionIdAndAction) {
      return truePredicate;
    }

    if (collectionIdAndAction?.collectionId === cookingAndShoppedCollectionId) {
      return activePredicate ?? (() => false);
    }

    const { collectionId, action: collectionAction } = collectionIdAndAction;
    const collection = collectionsById[collectionId];

    if (!collection) {
      return falsePredicate;
    }

    if (collection.type === "filter") {
      const meta = getAppFilterCollectionMeta(collection.id);
      if (!meta) {
        return falsePredicate;
      } else if (collectionAction === "exclude") {
        return (r: AppUserRecipe) => !meta.predicate(r);
      } else {
        return meta.predicate;
      }
    }

    if (collection.type === "tag") {
      const collectionTags = collection.tagType === "system" ? collection.systemTags : collection.userTags;
      const predicate = getIsTagMatch(collection.tagType, collectionTags);
      if (collectionAction === "exclude") {
        return (r: AppUserRecipe) => !predicate(r);
      } else {
        return predicate;
      }
    }

    return falsePredicate;
  }
);

// we don't select the entire filters object because it will change when the search query is updated
const selectTagAndCollectionPredicate: (
  s: RootState,
  tags: RecipeTag[] | undefined,
  filters: RecipeFilter[] | undefined,
  collection: CollectionIdAndAction | undefined
) => ((r: AppUserRecipe) => boolean) | undefined = getCreateSelectorWithCacheSize(librarySearchCacheSize)(
  [
    (_s, tags: RecipeTag[] | undefined) => tags,
    (_s, _, filters: RecipeFilter[] | undefined) => filters,
    (_a1, _a2, _a3, collection: CollectionIdAndAction | undefined) => collection,
    (s, _a2, _a3, collection: CollectionIdAndAction | undefined) => selectCollectionPredicate(s, collection),
    s => selectGetCollectionByTag(s),
  ],
  (tags, filters, collectionIdAndAction, collectionPredicate, getCollectionByTag) => {
    if ((!tags || tags.length === 0) && (!filters || filters.length === 0) && !collectionIdAndAction) {
      return undefined;
    }

    const predicates: Array<(a: AppUserRecipe) => boolean> = [collectionPredicate];

    // find the collection for a tag. We do this to get the full list of matching tag values, which can be > 1
    // during a rename operation
    tags?.forEach(t => {
      const c = getCollectionByTag(t);
      if (c) {
        const tags = switchReturn(c.tagType, {
          user: c.userTags,
          system: c.systemTags,
        });
        predicates.push(getIsTagMatch(c.tagType, tags));
      } else {
        predicates.push(getIsTagMatch(t.type, [t.tag]));
      }
    });

    if (filters && filters.length > 0) {
      predicates.push(getFiltersPredicate(filters));
    }

    return (a: AppUserRecipe) => predicates.every(p => p(a));
  }
);

function getIsTagMatch(type: RecipeTag["type"], tags: string[]): (r: AppUserRecipe) => boolean {
  const set = new Set(tags.map(t => t.toLowerCase()));
  return (r: AppUserRecipe) => {
    return r.tags.some(t => t.type === type && set.has(t.tag.toLowerCase()));
  };
}

function getRecipeSortTime(
  recipe: AppUserRecipe | undefined,
  userId: UserId | undefined,
  overrides: Record<UserRecipeId, EpochMs>
): number {
  // this shouldn't happen, but it simplifies logic for callers to support it
  if (!recipe) {
    return 0;
  }

  const override = overrides[recipe.id];
  const lastView = override ?? (userId ? recipe.stats.lastViewed[userId] : 0) ?? 0;

  // in the case we have an override, it means the user recently viewed the recipe, and we don't want it to change
  // position, specifically in the case they edited it. Other actions that change lastAction are adding to the grocery list
  // and cooking, and if either of those change, the section it's displayed in changes and this value isn't even used.
  // Basically, this means that if a user edits the recipe and then hits back, the recipe will be in the same spot in the list that
  // it was before the edit.
  const lastAction = override ? 0 : recipe.stats.lastAction;
  return Math.max(lastView, lastAction);
}

function mergeRecipeInstances(recipeInstances: RecipeInstanceAndIds[]) {
  const instancesByRecipeId = groupBy(recipeInstances, i => i.recipeId);
  const recipes = Object.fromEntries<{
    recipeId: UserRecipeId;
    timeAdded: EpochMs;
    timeCompleted?: EpochMs;
  }>(
    Object.entries(instancesByRecipeId).map(([recipeId, instances]) => {
      const merged = instances.reduce((prev, curr) => {
        // Return the most recently added recipe
        if (prev.timeAdded > curr.timeAdded) {
          return prev;
        }
        return curr;
      });

      return [
        recipeId,
        { recipeId: merged.recipeId, timeAdded: merged.timeAdded, timeCompleted: merged.timeCompleted },
      ];
    })
  );

  return Object.values(recipes);
}

// figure out if the recipe has been cooked since it was added to the list.
function notCookedSinceAddedToList(listTime: EpochMs, recipe: AppUserRecipe | undefined): boolean {
  // this might be possible if the recipe has been deleted after being added to the list
  // not sure if this is the correct behavior, but it should be very rare
  if (!recipe) {
    return true;
  }

  return (recipe.stats.lastCooked ?? 0) < listTime;
}

function notStale(completedTime: EpochMs | undefined): boolean {
  if (!completedTime) {
    return true;
  }

  return daysBetween(completedTime, defaultTimeProvider()) < 7;
}

import { SearchRecipeResult } from "@eatbetter/search-shared";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  Animated,
  LayoutAnimation,
  LayoutChangeEvent,
  ListRenderItem,
  NativeScrollEvent,
  NativeSyntheticEvent,
  StyleSheet,
  View,
} from "react-native";
import { RecipeCard, RecipePressedHandler } from "../recipes/RecipeCards";
import { Spacer } from "../Spacer";
import { TSecondary } from "../Typography";
import { SearchAndFilterBar } from "../SearchBox";
import { Opacity, globalStyleConstants } from "../GlobalStyles";
import { Spinner } from "../Spinner";
import { navToCookingSessionIfExists } from "../../navigation/NavThunks";
import { useScreen } from "../../navigation/ScreenContainer";
import { useDispatch } from "../../lib/redux/Redux";
import { navTree } from "../../navigation/NavTree";
import { searchResultsScrolledFromTop, searchResultsScrolledToTop } from "../../lib/search/SearchSlice";
import { formatNumberWithCommas } from "@eatbetter/common-shared";
import { useTrackScrollSession } from "../../lib/util/UseTrackScrollSession";
import { useSearchResultsScrolled } from "../../lib/search/SearchSelectors";
import { searchResultsScrolled } from "../../lib/search/SearchThunks";
import { GlobalSearchSessionId } from "../../lib/composite/LibraryAndSearchSessionIds.ts";

const strings = {
  recipes: " recipes",
};

type InternalItem = "search" | "resultCount";
type Item = SearchRecipeResult | InternalItem;
type RenderListPart = (props: { highlighted?: boolean; leadingItem?: Item }) => React.ReactElement;
type RenderItem = ListRenderItem<Item>;
type KeyExtractor = (item: Item, index: number) => string;

interface KnownEntityRecipeCollectionProps {
  recipes: SearchRecipeResult[];
  recipeCount?: number;
  maxRecipeCountExceeded?: boolean;
  recipesLoading?: boolean;
  onEndReached: () => void;
  searchPlaceholderText: string;
  onPressSearchBox: () => void;
  renderListHeader: () => React.ReactNode;
  searchSessionId: GlobalSearchSessionId;
}

export const KnownEntityRecipeCollection = React.memo((props: KnownEntityRecipeCollectionProps) => {
  const screen = useScreen();
  const dispatch = useDispatch();

  const data: Item[] = useMemo(() => ["search", "resultCount", ...props.recipes], [props.recipes]);

  const [listHeaderHeight, setListHeaderHeight] = useState(0);

  const onListHeaderLayout = useCallback(
    (e: LayoutChangeEvent) => {
      setListHeaderHeight(e.nativeEvent.layout.height);
    },
    [setListHeaderHeight]
  );

  const renderListHeaderComponent: RenderListPart = useCallback(() => {
    return <View onLayout={onListHeaderLayout}>{props.renderListHeader()}</View>;
  }, [props.renderListHeader]);

  const stickyHeaderIndices: number[] = useMemo(
    () => [data.indexOf("search") + 1], // list header is position 0
    [data, props.renderListHeader]
  );

  const scrollY = useRef(new Animated.Value(0)).current;
  const stateIsScrolled = useSearchResultsScrolled(props.searchSessionId);

  const onScroll = Animated.event<NativeSyntheticEvent<NativeScrollEvent>>(
    [{ nativeEvent: { contentOffset: { y: scrollY } } }],
    {
      useNativeDriver: true,
      listener: ({ nativeEvent: { contentOffset } }: any) => {
        const isScrolled = contentOffset.y > 0;
        if (isScrolled !== stateIsScrolled) {
          dispatch(
            isScrolled
              ? searchResultsScrolledFromTop(props.searchSessionId)
              : searchResultsScrolledToTop(props.searchSessionId)
          );
        }
      },
    }
  );

  const reportScrolledEvent = useCallback(
    (indexReached: number) => {
      dispatch(searchResultsScrolled({ sessionId: props.searchSessionId, indexReached }));
    },
    [props.searchSessionId]
  );

  const { onViewableItemsChanged } = useTrackScrollSession({ isScrolled: stateIsScrolled, reportScrolledEvent });

  const searchBoxBackgroundOpacity = scrollY.interpolate({
    inputRange: [0, listHeaderHeight, listHeaderHeight + 1],
    outputRange: [Opacity.transparent, Opacity.transparent, Opacity.opaque],
    extrapolate: "clamp",
  });

  const onPressRecipe: RecipePressedHandler = useCallback(
    (recipeId, index) => {
      if (!dispatch(navToCookingSessionIfExists({ type: "search", nav: screen.nav.switchTab, recipeId }))) {
        screen.nav.goTo("push", navTree.get.screens.searchViewRecipe, {
          recipeId,
          searchSessionId: props.searchSessionId,
          searchResultIndex: index,
        });
      }
    },
    [screen.nav.goBack, screen.nav.switchTab]
  );

  const renderItem: RenderItem = useCallback<RenderItem>(
    ({ item, index }) => {
      switch (item) {
        case "search": {
          return (
            <View style={{ opacity: props.recipesLoading ? 0 : 1 }}>
              <Animated.View
                style={[StyleSheet.absoluteFill, { backgroundColor: "white", opacity: searchBoxBackgroundOpacity }]}
              />
              <SearchAndFilterBar
                placeholderText={props.searchPlaceholderText}
                editable={false}
                onPressSearchBox={props.onPressSearchBox}
              />
            </View>
          );
        }
        case "resultCount": {
          return (
            <>
              {!props.recipesLoading && (
                <ResultCountHeader
                  resultCount={props.recipeCount ?? 0}
                  maxRecipeCountExceeded={!!props.maxRecipeCountExceeded}
                />
              )}
            </>
          );
        }
        default: {
          return (
            <View style={{ paddingHorizontal: globalStyleConstants.minPadding }}>
              <RecipeCard {...item.recipe} index={index} onPress={onPressRecipe} sessionId={props.searchSessionId} />
            </View>
          );
        }
      }
    },
    [
      props.searchPlaceholderText,
      props.recipeCount,
      props.onPressSearchBox,
      props.recipesLoading,
      searchBoxBackgroundOpacity,
    ]
  );

  const keyExtractor: KeyExtractor = useCallback(item => {
    switch (item) {
      case "search": {
        return item;
      }
      case "resultCount": {
        return item;
      }
      default: {
        return item.recipe.id;
      }
    }
  }, []);

  const renderItemSeparator: RenderListPart = useCallback(() => {
    return <Spacer vertical={globalStyleConstants.minPadding} unit="pixels" />;
  }, []);

  const renderListFooter: RenderListPart = useCallback(() => {
    return (
      <>
        {!!props.recipesLoading && <LoadingFooter />}
        <Spacer vertical={2} />
      </>
    );
  }, [props.recipesLoading]);

  useEffect(() => {
    if (screen.nav.focused) {
      LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
    }
  }, [data]);

  return (
    <Animated.FlatList
      data={data}
      renderItem={renderItem}
      keyExtractor={keyExtractor}
      ListHeaderComponent={renderListHeaderComponent}
      ItemSeparatorComponent={renderItemSeparator}
      ListFooterComponent={renderListFooter}
      onEndReached={props.onEndReached}
      onEndReachedThreshold={1}
      stickyHeaderIndices={stickyHeaderIndices}
      stickyHeaderHiddenOnScroll
      onScroll={onScroll}
      scrollEventThrottle={16}
      showsVerticalScrollIndicator={false}
      onViewableItemsChanged={onViewableItemsChanged}
    />
  );
});

const ResultCountHeader = React.memo((props: { resultCount: number; maxRecipeCountExceeded: boolean }) => {
  return (
    <View style={{ paddingHorizontal: globalStyleConstants.defaultPadding }}>
      <TSecondary>
        <TSecondary>{`${formatNumberWithCommas(props.resultCount)}${
          props.maxRecipeCountExceeded ? "+" : ""
        }`}</TSecondary>
        <TSecondary>{strings.recipes}</TSecondary>
      </TSecondary>
    </View>
  );
});

const LoadingFooter = React.memo(() => {
  return (
    <View style={{ justifyContent: "center", alignItems: "center", paddingVertical: globalStyleConstants.unitSize }}>
      <Spinner />
    </View>
  );
});

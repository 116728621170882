import React, { useCallback } from "react";
import { AddItemButton } from "../Buttons";
import { useDispatch } from "../../lib/redux/Redux";
import { useScreen } from "../../navigation/ScreenContainer";
import { navTree } from "../../navigation/NavTree";
import { analyticsEvent } from "../../lib/analytics/AnalyticsThunks";
import { reportAddRecipeButtonTapped, reportCreateCollectionTapped } from "../../lib/analytics/AnalyticsEvents";
import { useBottomSheet } from "../../screens/BottomSheetScreen";
import { getOptionsMenuHeight, OptionsMenu, OptionsMenuItem } from "../OptionsMenu";
import { useIsAnonymousUser, useSystemSetting } from "../../lib/system/SystemSelectors";
import { withNavDelay } from "../../lib/util/WithNavDelay";
import { navToAnonymousSignin } from "../../lib/util/AnonymousSignIn";
import { useCollectionBulkAction } from "../../screens/RecipeCollectionBulkActionScreen";
import { RecipeCollectionId } from "@eatbetter/recipes-shared";
import { LibraryFilterSessionId } from "../../lib/composite/LibraryAndSearchSessionIds.ts";
import { bottomThrow } from "@eatbetter/common-shared";

const strings = {
  addMenu: {
    createCollection: "New Recipe Collection",
    addRecipesToCollection: "Select + Add Recipes",
    addFromUrl: "Import Recipe from URL",
    addFromPhotos: "Import Recipe from Photos",
    addManual: "Enter Your Own Recipe",
  },
  addManualAction: "add your own recipe",
  editCollectionsAction: "customize your library",
};

export interface RecipeAddButtonProps extends RecipeAddMenuProps {
  photoIngestionWalkthroughEnabled?: boolean;
  endPhotoIngestionWalkthrough?: () => void;
  collectionsWalkthroughEnabled?: boolean;
  onCollectionsWalkthroughNext?: () => void;
  sessionId: LibraryFilterSessionId;
}

export const RecipeAddButton = React.memo((props: RecipeAddButtonProps) => {
  const dispatch = useDispatch();
  const screen = useScreen();
  const photosEnabled = !!useSystemSetting("recipePhotoIngestion");

  const onPress = useCallback(() => {
    dispatch(analyticsEvent(reportAddRecipeButtonTapped()));
    screen.nav.modal(navTree.get.screens.bottomSheet, {
      content: (
        <RecipeAddMenu
          context={props.context}
          collectionId={props.collectionId}
          badgePhotoIngestionMenuItem={props.badgePhotoIngestionMenuItem}
          sessionId={props.sessionId}
        />
      ),
      height: getAddRecipeSheetHeight({ context: props.context, photosEnabled }),
    });
  }, [dispatch, screen.nav.modal, photosEnabled, props.context, props.collectionId]);

  const walkthrough = props.photoIngestionWalkthroughEnabled
    ? "photoIngestion"
    : props.collectionsWalkthroughEnabled
    ? "createCollection"
    : undefined;

  const onWalkthroughNext = useCallback(() => {
    if (!walkthrough) {
      return;
    }

    switch (walkthrough) {
      case "photoIngestion": {
        props.endPhotoIngestionWalkthrough?.();
        break;
      }
      case "createCollection": {
        props.onCollectionsWalkthroughNext?.();
        break;
      }
      default:
        bottomThrow(walkthrough);
    }
  }, [walkthrough, props.onCollectionsWalkthroughNext, props.onCollectionsWalkthroughNext]);

  return <AddItemButton onPress={onPress} walkthroughEnabled={walkthrough} onWalkthroughNext={onWalkthroughNext} />;
});

function getAddRecipeSheetHeight(args: { photosEnabled: boolean; context: RecipeAddButtonProps["context"] }) {
  let menuHeight = 2;

  if (args.photosEnabled) {
    menuHeight += 1;
  }
  if (args.context !== "libraryAllRecipes" && args.context !== "filterCollection") {
    menuHeight += 1;
  }

  return getOptionsMenuHeight(menuHeight);
}

interface RecipeAddMenuProps {
  context: "libraryAllRecipes" | "libraryCollections" | "nonFilterCollection" | "filterCollection";
  collectionId?: RecipeCollectionId;
  badgePhotoIngestionMenuItem?: boolean;
  sessionId: LibraryFilterSessionId;
}

const RecipeAddMenu = React.memo((props: RecipeAddMenuProps) => {
  const dispatch = useDispatch();
  const screen = useScreen();
  const bottomSheet = useBottomSheet();
  const photosEnabled = !!useSystemSetting("recipePhotoIngestion");

  const addFromUrl = useCallback(() => {
    bottomSheet?.closeSheetAndGoBack();
    withNavDelay(() =>
      screen.nav.goTo("push", navTree.get.screens.recipeAddFromUrl, { collectionId: props.collectionId })
    );
  }, [bottomSheet, screen.nav.goTo, props.collectionId]);

  const isAnon = useIsAnonymousUser();
  const addFromPhotos = useCallback(() => {
    bottomSheet?.closeSheetAndGoBack();

    if (isAnon) {
      withNavDelay(() =>
        navToAnonymousSignin(screen.nav, { mode: "action", userVisibleActionDescription: strings.addManualAction })
      );
    } else {
      withNavDelay(() =>
        screen.nav.goTo("push", navTree.get.screens.recipeAddFromPhotos, { collectionId: props.collectionId })
      );
    }
  }, [bottomSheet, isAnon, screen.nav.goTo, props.collectionId]);

  const addManual = useCallback(() => {
    bottomSheet?.closeSheetAndGoBack();

    if (isAnon) {
      withNavDelay(() =>
        navToAnonymousSignin(screen.nav, { mode: "action", userVisibleActionDescription: strings.addManualAction })
      );
    } else {
      withNavDelay(() =>
        screen.nav.goTo("push", navTree.get.screens.recipeAddManual, { collectionId: props.collectionId })
      );
    }
  }, [bottomSheet, isAnon, screen.nav.goTo, props.collectionId]);

  const createCollection = useCallback(() => {
    dispatch(analyticsEvent(reportCreateCollectionTapped("addButton")));
    bottomSheet?.closeSheetAndGoBack();

    if (isAnon) {
      withNavDelay(() =>
        navToAnonymousSignin(screen.nav, { mode: "action", userVisibleActionDescription: strings.addManualAction })
      );
    } else {
      withNavDelay(() => screen.nav.goTo("push", navTree.get.screens.createRecipeCollection));
    }
  }, [bottomSheet, isAnon, screen.nav.goTo, dispatch]);

  const selectAndAddRecipes = useCollectionBulkAction({
    action: "addToCollection",
    collectionId: props.collectionId,
    existingSessionId: props.sessionId,
  });

  return (
    <OptionsMenu>
      {props.context === "libraryCollections" && (
        <OptionsMenuItem icon="add" text={strings.addMenu.createCollection} isFirst onPress={createCollection} />
      )}
      {props.context === "nonFilterCollection" && !!selectAndAddRecipes && (
        <OptionsMenuItem
          icon="addFolder"
          text={strings.addMenu.addRecipesToCollection}
          isFirst
          onPress={selectAndAddRecipes}
        />
      )}
      <OptionsMenuItem
        isFirst={
          props.context === "libraryAllRecipes" ||
          props.context === "filterCollection" ||
          (props.context === "nonFilterCollection" && !selectAndAddRecipes)
        }
        icon="link"
        text={strings.addMenu.addFromUrl}
        onPress={addFromUrl}
      />
      {photosEnabled && (
        <OptionsMenuItem
          icon="camera"
          text={strings.addMenu.addFromPhotos}
          onPress={addFromPhotos}
          badge={props.badgePhotoIngestionMenuItem ? "new" : undefined}
        />
      )}
      <OptionsMenuItem icon="editPencil" text={strings.addMenu.addManual} onPress={addManual} />
    </OptionsMenu>
  );
});

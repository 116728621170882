import {
  RecipeTagCategoryList,
  RecipeTagManifest,
  SystemRecipeTag,
  systemTagCollectionIdPrefix,
  UserRecipeId,
} from "@eatbetter/recipes-shared";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { LayoutAnimation, View } from "react-native";
import { displayUnexpectedErrorAndLog } from "../../lib/Errors";
import { editRecipeTag } from "../../lib/recipes/RecipesThunks";
import { useDispatch } from "../../lib/redux/Redux";
import { Haptics } from "../Haptics";
import { RecipeTagSelect, tagOrFilterIsSelected } from "./RecipeTagSelect";
import { useScreen } from "../../navigation/ScreenContainer";
import { RecipeEditFieldLocation } from "../../navigation/NavTree";
import {
  CollectionTagOrFilterGroup,
  getRecipeTagOrFilterKey,
  RecipeTagOrFilter,
  RecipeTagOrFilterKey,
  useRecipeTags,
  useTagsForEditing,
} from "../../lib/composite/CollectionsSelectors.ts";

const strings = {
  tagSelectError: "Sorry, we're having trouble connecting right now and couldn't save your tag update",
};

interface Props {
  recipeId: UserRecipeId;
  onPressAddUserTag: () => void;
  location: RecipeEditFieldLocation;
}

export const RecipeTagsEdit = React.memo((props: Props) => {
  const dispatch = useDispatch();
  const screen = useScreen();
  const selected = useRecipeTags(props.recipeId);
  const groups = useTagsForEditing();

  const [waiting, setWaiting] = useState<Record<RecipeTagOrFilterKey, boolean>>({});

  useEffect(() => {
    if (!screen.nav.focused) {
      return;
    }
    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
  }, [groups, selected, groups, screen.nav.focused]);

  const toggleTag = useCallback(
    (tagOrFilter: RecipeTagOrFilter) => {
      Haptics.feedback("itemStatusChanged");

      if (tagOrFilter.type !== "tag") {
        return;
      }

      const isSelected = tagOrFilterIsSelected(tagOrFilter, selected);

      dispatch(
        editRecipeTag(
          {
            tag: tagOrFilter.tag.tag,
            action: isSelected ? "remove" : "add",
            recipeId: props.recipeId,
            collectionId: tagOrFilter.tag.collectionId,
          },
          props.location,
          value => setWaiting(prev => ({ ...prev, [getRecipeTagOrFilterKey(tagOrFilter)]: value }))
        )
      ).catch(err => {
        displayUnexpectedErrorAndLog(strings.tagSelectError, err, {
          recipeId: props.recipeId,
          tagOrFilter,
        });
      });
    },
    [selected, dispatch, setWaiting, props.location, props.recipeId]
  );

  return (
    <RecipeTagSelect
      groups={groups}
      selected={selected}
      onPressTag={toggleTag}
      waiting={waiting}
      onPressAddTag={props.onPressAddUserTag}
    />
  );
});

// ADMIN-ONLY

interface AdminRecipeTagsEditProps {
  systemTags: RecipeTagCategoryList;
  selectedTags: SystemRecipeTag[];
  tagManifest?: RecipeTagManifest;
  onPressTag: (tag: SystemRecipeTag) => void;
}

export const AdminRecipeTagsEdit = (props: AdminRecipeTagsEditProps) => {
  const onPressTag = useCallback(
    (tagOrFilter: RecipeTagOrFilter) => {
      if (tagOrFilter.type !== "tag" || tagOrFilter.tag.tag.type !== "system") {
        throw new Error(`Unexpected tag type in AdminRecipeTagsEdit: ${JSON.stringify(tagOrFilter)}`);
      }

      props.onPressTag(tagOrFilter.tag.tag);
    },
    [props.onPressTag]
  );

  const getTagName = useCallback(
    (t: SystemRecipeTag) => {
      return props.tagManifest?.tagDisplay[t.tag] ?? t.tag;
    },
    [props.tagManifest]
  );

  const groups = useMemo(() => {
    return props.systemTags.map<CollectionTagOrFilterGroup>(g => {
      return {
        groupName: g.category,
        tagsOrFilters: g.tags.map(tagId => {
          const tag: SystemRecipeTag = { type: "system", tag: tagId };
          return {
            type: "tag",
            tag: {
              collectionId: `${systemTagCollectionIdPrefix}${tagId}`,
              display: getTagName(tag),
              tag,
            },
          };
        }),
      };
    });
  }, [getTagName, props.systemTags]);

  const selected: RecipeTagOrFilter[] = useMemo(() => {
    return props.selectedTags.map<RecipeTagOrFilter>(t => {
      return {
        type: "tag",
        tag: {
          collectionId: `${systemTagCollectionIdPrefix}${t.tag}`,
          display: getTagName(t),
          tag: t,
        },
      };
    });
  }, [getTagName, props.selectedTags]);

  return (
    <View style={{ padding: 24, paddingTop: 0, borderWidth: 1, borderRadius: 12, borderColor: "black" }}>
      <RecipeTagSelect groups={groups} selected={selected} onPressTag={onPressTag} />
    </View>
  );
};

import {
  defaultTimeProvider,
  EpochMs,
  parseBool,
  secondsBetween,
  switchReturn,
  UserId,
  Username,
} from "@eatbetter/common-shared";
import {
  Comment,
  CommentId,
  CommentRecipeInfo,
  PartialCommentId,
  SocialEntityId,
  SocialPostId,
} from "@eatbetter/posts-shared";
import { RecipeId, UserRecipeId } from "@eatbetter/recipes-shared";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { StyleSheet, View, ListRenderItem, FlatList, FlatListProps, Keyboard } from "react-native";
import { globalStyleColors, globalStyleConstants, globalStyles } from "../components/GlobalStyles";
import { IconLike, IconPlus } from "../components/Icons";
import { Photo } from "../components/Photo";
import { Pressable } from "../components/Pressable";
import { ScreenView, useScreenElementDimensions } from "../components/ScreenView";
import { SocialPostHeading } from "../components/social/SocialPostHeading";
import { ContainerBordered } from "../components/Containers";
import { Spacer } from "../components/Spacer";
import { Spinner } from "../components/Spinner";
import { TextInputHandle } from "../components/TextInput";
import { TBody, TSecondary, TTertiary } from "../components/Typography";
import { useDispatch } from "../lib/redux/Redux";
import { usePostComments, usePostOwnerId } from "../lib/social/SocialSelectors";
import { addCommentToPost, deleteCommentFromPost, loadPost } from "../lib/social/SocialThunks";
import { getTimeSinceDisplayString } from "../lib/util/DateUtilities";
import { useIdempotentId } from "../lib/util/UseIdempotentId";
import { navTree, PostCommmentsScreenProps, ShareViewRecipeScreenProps } from "../navigation/NavTree";
import { useScreen, withScreenContainer } from "../navigation/ScreenContainer";
import { DeglazeUser } from "@eatbetter/users-shared";
import { Haptics } from "../components/Haptics";
import { useKeyboardLayoutAnimation } from "../components/Keyboard";
import { getPullToRefresh } from "../components/PullToRefresh";
import { useRegisteredUser, useSystemSetting } from "../lib/system/SystemSelectors";
import { CommentRecipeCard, RecipeCard, RecipePressedHandler } from "../components/recipes/RecipeCards";
import { TextPostBody } from "../components/social/SocialPost";
import { displayUnexpectedErrorAndLog } from "../lib/Errors";
import { AutoLink } from "../components/AutoLink";
import {
  AtMentionQuery,
  AtMentionSelectUser,
  AtMentionSelectUserProps,
  TextInputWithAtMentions,
  TextWithAtMentions,
} from "../components/AtMentions";
import { SearchUsersContext } from "../lib/users/UsersThunks";
import { usePostForDetailScreen } from "../lib/social/SocialHooks";
import { useNavToProfileScreen } from "../lib/util/UseNavToProfileScreen";
import { InputAccessoryView } from "../components/InputAccessoryView";
import { HeaderProps } from "../components/ScreenHeaders";
import { bottomActionBarConstants } from "../components/BottomActionBar";
import { useAnonymousRedirectCallback } from "../lib/util/AnonymousSignIn";
import { navToCookingSessionIfExists } from "../navigation/NavThunks";
import { Alert } from "../components/Alert/Alert";
import { getOptionsMenuHeight, OptionsMenu, OptionsMenuItem } from "../components/OptionsMenu";
import { useBottomSheet } from "./BottomSheetScreen";
import { reportIssue } from "../lib/system/SystemThunks.ts";
import { useToast } from "../components/Toast.tsx";

const constants = {
  commentInputMinHeight: 48,
  commentInputMaxHeight: 128,
};

const strings = {
  screenHeader: "Comments",
  post: "Post",
  reply: "Reply",
  delete: "Delete",
  options: "Options",
  placeholderText: "Add a comment...",
  commentAction: "comment",
  deleteCommentAlert: {
    title: "Delete Comment?",
    body: "",
  },
  deleteAndReportCommentAlert: {
    title: "Delete and Report Comment?",
    body: "",
  },
  commentOptionsMenu: {
    deleteAndReport: "Delete & Report Comment",
    report: "Report Inappropriate Content",
  },
  reportConfirmation: "Thank you for reporting! We'll look into it.",
  deleteAndReportConfirmation: "The comment has been deleted and reported. Thank you!",
  deleteConfirmation: "Your comment has been deleted.",
};

export const PostCommentsScreen = withScreenContainer(
  "PostCommentsScreen",
  (props: PostCommmentsScreenProps) => {
    const [commentText, setCommentText] = useState("");
    const [cursorPosition, setCursorPosition] = useState(commentText.length);
    const [atMentionQuery, setAtMentionQuery] = useState<AtMentionQuery>();
    const [waiting, setWaiting] = useState(false);
    const [submitDisabled, setSubmitDisabled] = useState(true);

    // the post is loaded by the usePostForDetailScreen hook in the post summary that is rendered in the list

    return React.createElement<Props>(PostCommentScreenComponent, {
      postId: props.postId,
      commentText,
      setCommentText,
      cursorPosition,
      setCursorPosition,
      atMentionQuery,
      setAtMentionQuery,
      waiting,
      setWaiting,
      submitDisabled,
      setSubmitDisabled,
      inputFocused: props.focusInput,
    });
  },
  {
    serializer: {
      postId: s => s,
      focusInput: {
        optional: true,
        fn: s => String(s),
      },
    },
    parser: {
      postId: s => s as SocialPostId,
      focusInput: {
        optional: true,
        fn: s => parseBool(s),
      },
    },
  }
);

interface Props {
  postId: SocialPostId;
  commentText: string;
  setCommentText: (value: ((prev: string) => string) | string) => void;
  cursorPosition: number;
  setCursorPosition: (v: number) => void;
  atMentionQuery: AtMentionQuery | undefined;
  setAtMentionQuery: (v?: AtMentionQuery) => void;
  waiting: boolean;
  setWaiting: (v: boolean) => void;
  submitDisabled: boolean;
  setSubmitDisabled: (v: boolean) => void;
  inputFocused?: boolean;
}

const PostCommentScreenComponent = (props: Props) => {
  const dispatch = useDispatch();
  const [id, refreshId] = useIdempotentId<PartialCommentId>();
  const commentsListRef = useRef<FlatList>(null);
  const textInputRef = useRef<TextInputHandle>(null);
  const screen = useScreen();
  const { headerHeight, bottomTabBarHeight } = useScreenElementDimensions();
  const keyboardHeight = useKeyboardLayoutAnimation();
  const { nav } = useScreen();

  useEffect(() => {
    if (props.inputFocused) {
      setTimeout(() => {
        textInputRef.current?.focus();
      }, 650);
    }
  }, []);

  const onSubmit = useAnonymousRedirectCallback(
    strings.commentAction,
    async () => {
      props.setAtMentionQuery(undefined);
      props.setSubmitDisabled(true);

      try {
        await dispatch(
          addCommentToPost({ postId: props.postId, text: props.commentText.trim(), commentId: id }, props.setWaiting)
        );

        Haptics.feedback("itemAdded");
        refreshId();

        // We batch this all together to avoid rendering/layout race conditions we were seeing. Without the batching
        // and setTimeout() on the Keyboard.dismiss() call, the TextInput would persist the text despite the state update.
        // This happened despite trying useEffect and other workarounds. The below is the cleanest / simplest solution as
        // of 8/16/2023.
        props.setCommentText(() => {
          commentsListRef.current?.scrollToOffset({ animated: true, offset: Number.MAX_SAFE_INTEGER });
          setTimeout(() => Keyboard.dismiss());
          return "";
        });
      } catch (err) {
        displayUnexpectedErrorAndLog("Error adding comment to post", err);
        props.setSubmitDisabled(false);
      }
    },
    [
      dispatch,
      commentsListRef,
      props.postId,
      props.commentText,
      props.setCommentText,
      props.setWaiting,
      props.setAtMentionQuery,
      props.setSubmitDisabled,
      id,
      refreshId,
      nav,
    ]
  );

  const onChangeCommentText = useAnonymousRedirectCallback(
    strings.commentAction,
    async (text: string) => {
      props.setCommentText(text);
      props.setSubmitDisabled(!text);
    },
    [props.setCommentText, props.setSubmitDisabled]
  );

  const onPressReply = useAnonymousRedirectCallback(
    strings.commentAction,
    (username: Username) => {
      if (!textInputRef.current) {
        return;
      }

      const atMentionPrefix: AtMentionQuery["prefix"] = "@";

      textInputRef.current.focus();
      props.setCommentText(`${atMentionPrefix}${username} `);
    },
    [textInputRef.current, props.setCommentText]
  );

  const onPressAddRecipe = useAnonymousRedirectCallback(
    strings.commentAction,
    () => {
      screen.nav.modal(navTree.get.screens.shareCommentRecipes, { postId: props.postId });
    },
    [screen.nav.modal, props.postId]
  );

  const onPullToRefresh = useCallback(async () => {
    await dispatch(loadPost(props.postId));
  }, [dispatch, props.postId]);

  const onPressPostSummary = useCallback(() => {
    if (!dispatch(navToCookingSessionIfExists({ type: "post", nav: screen.nav.switchTab, postId: props.postId }))) {
      screen.nav.goTo("push", navTree.get.screens.postViewRecipe, { postId: props.postId });
    }
  }, [screen.nav.goTo, screen.nav.switchTab, props.postId]);

  const refreshControl = useMemo(
    () => getPullToRefresh(onPullToRefresh, headerHeight),
    [onPullToRefresh, headerHeight]
  );

  const atMentionPresentation = useMemo<AtMentionSelectUserProps["presentation"]>(() => {
    return {
      type: "fullScreenOverlay",
      paddingTop: headerHeight + globalStyleConstants.unitSize,
      paddingBottom: keyboardHeight + constants.commentInputMaxHeight + 2 * globalStyleConstants.unitSize,
    };
  }, [headerHeight, keyboardHeight]);

  const searchUsersContext = useMemo<SearchUsersContext>(
    () => ({ type: "postComment", postId: props.postId }),
    [props.postId]
  );

  const header = useMemo<HeaderProps>(() => ({ type: "default", title: strings.screenHeader }), []);

  return (
    <ScreenView
      header={header}
      paddingHorizontal={false}
      paddingVertical={false}
      scrollView={false}
      backgroundColor="white"
    >
      <AtMentionSelectUser
        presentation={atMentionPresentation}
        text={props.commentText}
        setText={props.setCommentText}
        atMentionQuery={props.atMentionQuery}
        setAtMentionQuery={props.setAtMentionQuery}
        cursorPosition={props.cursorPosition}
      />
      <CommentsList
        ref={commentsListRef}
        postId={props.postId}
        refreshControl={refreshControl}
        onPressReply={onPressReply}
        onPressPostSummary={onPressPostSummary}
        paddingTop={headerHeight}
        paddingBottom={
          (keyboardHeight > 0 ? keyboardHeight : bottomTabBarHeight) +
          constants.commentInputMaxHeight +
          4 * globalStyleConstants.unitSize
        }
      />
      <CommentInput
        ref={textInputRef}
        text={props.commentText}
        onChangeText={onChangeCommentText}
        cursorPosition={props.cursorPosition}
        setCursorPosition={props.setCursorPosition}
        setAtMentionQuery={props.setAtMentionQuery}
        searchUsersContext={searchUsersContext}
        onPressAddRecipe={onPressAddRecipe}
        onSubmit={onSubmit}
        waiting={props.waiting}
        submitDisabled={props.submitDisabled}
        focused={props.inputFocused}
      />
    </ScreenView>
  );
};

interface CommentsListProps {
  postId: SocialPostId;
  onPressReply: (username: Username) => void;
  onPressPostSummary: () => void;
  refreshControl?: React.ReactElement;
  paddingBottom?: number;
  paddingTop?: number;
}

const CommentsList = React.memo(
  React.forwardRef<FlatList, CommentsListProps>((props, ref) => {
    const comments = usePostComments(props.postId);

    const renderItem: ListRenderItem<Comment> = useCallback(
      ({ item: comment }) => {
        return <CommentListItem postId={props.postId} comment={comment} onPressReply={props.onPressReply} />;
      },
      [props.onPressReply, props.postId]
    );

    const keyExtractor = useCallback<NonNullable<FlatListProps<Comment>["keyExtractor"]>>(item => item.id, []);

    const extraData = useMemo(() => [props.onPressReply, props.postId], [props.onPressReply, props.postId]);

    const listHeader = useMemo(
      () => <PostSummary postId={props.postId} onPress={props.onPressPostSummary} />,
      [props.postId, props.onPressPostSummary]
    );

    const contentContainerStyle = useMemo(() => {
      return { paddingTop: props.paddingTop, paddingBottom: props.paddingBottom };
    }, [props.paddingTop, props.paddingBottom]);

    return (
      <FlatList
        ref={ref}
        data={comments}
        keyExtractor={keyExtractor}
        renderItem={renderItem}
        extraData={extraData}
        ListHeaderComponent={listHeader}
        refreshControl={props.refreshControl}
        contentContainerStyle={contentContainerStyle}
        keyboardShouldPersistTaps="handled"
      />
    );
  })
);

const CommentListItem = React.memo(
  (props: {
    comment: Comment;
    postId: SocialPostId;
    isFirstItem?: boolean;
    onPressReply: (username: Username) => void;
  }) => {
    const navToProfile = useNavToProfileScreen(props.comment.user.userId, "postComments");
    const postOwner = usePostOwnerId(props.postId);

    return (
      <View style={styles.listItem}>
        <View style={styles.comment}>
          <Pressable style={{ maxHeight: 3 * globalStyleConstants.unitSize }} noFeedback onPress={navToProfile}>
            <Photo source={props.comment.user.photo} style="avatarSmall" sourceSize="w288" />
          </Pressable>
          <Spacer horizontal={1} />
          <CommentContent
            postId={props.postId}
            postOwnerId={postOwner}
            commentOwner={props.comment.user}
            commentId={props.comment.id}
            commentText={props.comment.text}
            commentMentions={props.comment.mentionsAndIds}
            commentRecipes={props.comment.recipes ?? []}
            timestamp={props.comment.ts}
            onPressReply={props.onPressReply}
          />
        </View>
        {/* Uncomment to expose comment liking if we ever get there */}
        <LikeButton hide />
      </View>
    );
  }
);

const CommentContent = React.memo(
  (props: {
    postId: SocialPostId;
    postOwnerId: SocialEntityId | undefined;
    commentOwner: DeglazeUser;
    commentId: CommentId;
    commentText: string;
    commentMentions: Record<Username, UserId>;
    commentRecipes: CommentRecipeInfo[];
    timestamp: EpochMs;
    onPressReply: (username: Username) => void;
  }) => {
    const screen = useScreen();
    const authedUser = useRegisteredUser();
    const dispatch = useDispatch();
    const isSuperAdmin = !!useSystemSetting("superAdmin");
    const toast = useToast();

    const isPostOwner = !!authedUser && authedUser.userId === props.postOwnerId;
    const isCommentOwner = !!authedUser && authedUser.userId === props.commentOwner.userId;

    const recipes = props.commentRecipes;

    const onPressReply = useCallback(() => {
      props.onPressReply(props.commentOwner.username);
    }, [props.onPressReply, props.commentOwner.username]);

    const deleteComment = useCallback(async () => {
      try {
        await dispatch(deleteCommentFromPost({ postId: props.postId, commentId: props.commentId }));
        toast.show({ type: "infoToast", message: strings.deleteConfirmation });
      } catch (err) {
        displayUnexpectedErrorAndLog("Error calling deleteCommentFromPost in PostCommentsScreen", err, { props });
      }
    }, [props.commentId, props.postId]);

    const onPressDelete = useCallback(() => {
      Alert.alert(strings.deleteCommentAlert.title, strings.deleteCommentAlert.body, [
        {
          type: "cancel",
          onPress: () => {},
        },
        {
          type: "delete",
          onPress: deleteComment,
        },
      ]);
    }, [deleteComment]);

    const onPressOptions = useCallback(() => {
      screen.nav.modal(navTree.get.screens.bottomSheet, {
        content: (
          <CommentOptionsMenu
            commentId={props.commentId}
            postId={props.postId}
            commentOwner={props.commentOwner.userId}
            commentText={props.commentText}
            isPostOwner={isPostOwner}
            isCommentOwner={isCommentOwner}
            isSuperAdmin={isSuperAdmin}
          />
        ),
        height: getOptionsMenuHeight(1),
      });
    }, [props.commentId, props.postId, props.commentOwner.userId, isPostOwner, isCommentOwner]);

    return (
      <View style={{ flex: 1 }}>
        <CommentText
          commentId={props.commentId}
          user={props.commentOwner}
          commentText={props.commentText}
          commentMentions={props.commentMentions}
        />
        {recipes.length > 0 && (
          <>
            <Spacer vertical={1} />
            <CommentRecipes commentId={props.commentId} sharedBy={props.commentOwner.userId} commentRecipes={recipes} />
            <Spacer vertical={0.5} />
          </>
        )}
        <Spacer vertical={0.5} />
        <CommentActionsBar
          isPostOwner={isPostOwner}
          isCommentOwner={isCommentOwner}
          timestamp={props.timestamp}
          onPressReply={onPressReply}
          onPressDelete={onPressDelete}
          onPressOptions={onPressOptions}
        />
      </View>
    );
  }
);

const CommentOptionsMenu = React.memo(
  (props: {
    postId: SocialPostId;
    commentId: CommentId;
    commentOwner: UserId;
    commentText: string;
    isCommentOwner: boolean;
    isPostOwner: boolean;
    isSuperAdmin: boolean;
  }) => {
    const dispatch = useDispatch();
    const bottomSheet = useBottomSheet();
    const toast = useToast();

    const [waiting, setWaiting] = useState(false);

    const showDeleteAndReport = (props.isPostOwner || props.isSuperAdmin) && !props.isCommentOwner;
    const showReport = !showDeleteAndReport && !props.isCommentOwner;

    const onPressReport = useCallback(async () => {
      try {
        await dispatch(
          reportIssue({
            type: "contentIssue",
            contentOwnerId: props.commentOwner,
            postId: props.postId,
            issue: "other",
            otherText: `User reported comment ${props.commentId} by user ${props.commentOwner} from post ${props.postId}. Comment text: ${props.commentText}`,
          })
        );
        Haptics.feedback("operationSucceeded");
        bottomSheet?.closeSheetAndGoBack();
        toast.show({ type: "infoToast", message: strings.reportConfirmation });
      } catch (err) {
        displayUnexpectedErrorAndLog("Error calling reportIssue in PostCommentsScreen", err, { props });
      }
    }, [props.commentOwner, props.postId, props.commentId, props.commentText, bottomSheet]);

    const deleteAndReport = useCallback(async () => {
      try {
        setWaiting(true);
        await Promise.all([
          dispatch(
            reportIssue({
              type: "contentIssue",
              contentOwnerId: props.commentOwner,
              postId: props.postId,
              issue: "other",
              otherText: `User deleted and reported comment ${props.commentId} by user ${props.commentOwner} from post ${props.postId} on behalf of another user. Comment text: ${props.commentText}`,
            })
          ),
          dispatch(deleteCommentFromPost({ postId: props.postId, commentId: props.commentId })),
        ]);
        Haptics.feedback("operationSucceeded");
        bottomSheet?.closeSheetAndGoBack();
        toast.show({ type: "infoToast", message: strings.deleteAndReportConfirmation });
      } catch (err) {
        displayUnexpectedErrorAndLog("Error calling deleteCommentFromPost in PostCommentsScreen", err, { props });
      } finally {
        setWaiting(false);
      }
    }, [props.postId, props.commentId, props.commentOwner, props.commentText, bottomSheet, setWaiting]);

    const onPressDeleteAndReport = useCallback(() => {
      Alert.alert(strings.deleteAndReportCommentAlert.title, strings.deleteAndReportCommentAlert.body, [
        {
          type: "cancel",
          onPress: () => {},
        },
        {
          type: "delete",
          onPress: deleteAndReport,
        },
      ]);
    }, [deleteAndReport]);

    return (
      <OptionsMenu>
        {showDeleteAndReport && (
          <OptionsMenuItem
            isFirst
            icon={"delete"}
            text={strings.commentOptionsMenu.deleteAndReport}
            onPress={onPressDeleteAndReport}
            waiting={waiting}
          />
        )}
        {showReport && (
          <OptionsMenuItem
            isFirst={!showDeleteAndReport}
            icon={"flag"}
            text={strings.commentOptionsMenu.report}
            onPress={onPressReport}
            waiting={waiting}
          />
        )}
      </OptionsMenu>
    );
  }
);

const CommentRecipes = React.memo(
  (props: { commentId: CommentId; sharedBy: UserId; commentRecipes: CommentRecipeInfo[] }) => {
    const dispatch = useDispatch();
    const screen = useScreen();

    const onPressRecipe = useCallback(
      (sourceRecipeId: RecipeId, userRecipeId: UserRecipeId) => {
        if (
          !dispatch(navToCookingSessionIfExists({ type: "share", recipeId: sourceRecipeId, nav: screen.nav.switchTab }))
        ) {
          screen.nav.goTo("push", navTree.get.screens.shareViewRecipe, {
            sharedByUserId: props.sharedBy,
            sharedByRecipeId: userRecipeId,
            sourceRecipeId,
            context: "socialPostComment" as const,
          });
        }
      },
      [screen.nav.goTo, screen.nav.switchTab, props.sharedBy]
    );

    return (
      <>
        {props.commentRecipes.map((i, idx) => {
          return (
            <View key={props.commentId + props.sharedBy + i.userRecipeId + idx}>
              {idx !== 0 && <Spacer vertical={1} />}
              <CommentRecipe index={idx} sharedByUserId={props.sharedBy} onPress={onPressRecipe} recipeInfo={i} />
            </View>
          );
        })}
      </>
    );
  }
);

const CommentActionsBar = React.memo(
  (props: {
    isPostOwner: boolean;
    isCommentOwner: boolean;
    timestamp: EpochMs;
    onPressReply?: () => void;
    onPressDelete?: () => void;
    onPressOptions?: () => void;
  }) => {
    return (
      <View style={{ flexDirection: "row" }}>
        <TimestampText timeStamp={props.timestamp} />
        {!props.isCommentOwner && !!props.onPressReply && (
          <>
            <CommentActionsSeparator />
            <ReplyButton onPress={props.onPressReply} />
          </>
        )}
        {props.isCommentOwner && !!props.onPressDelete && (
          <>
            <CommentActionsSeparator />
            <DeleteButton onPress={props.onPressDelete} />
          </>
        )}
        {!props.isCommentOwner && !!props.onPressOptions && (
          <>
            <CommentActionsSeparator />
            <OptionsButton onPress={props.onPressOptions} />
          </>
        )}
      </View>
    );
  }
);

export const InlineComment = React.memo(
  (props: { comment: Comment; onPressRecipe: (args: ShareViewRecipeScreenProps) => void }) => {
    const comment = props.comment;

    return (
      <>
        <CommentText
          user={props.comment.user}
          commentId={comment.id}
          commentText={comment.text}
          commentMentions={comment.mentionsAndIds}
          style="light"
        />
        {comment.recipes?.map((i, idx) => (
          <View key={comment.id + i.userRecipeId + idx}>
            <Spacer vertical={0.5} />
            <CommentRecipeCard
              {...i.recipeInfo}
              index={idx}
              sharedByUserId={comment.user.userId}
              sourceRecipeId={i.sourceRecipeId ?? i.userRecipeId}
              userRecipeId={i.userRecipeId}
              onPressRecipe={props.onPressRecipe}
              sessionId={undefined}
              showTimeFilter={false}
            />
          </View>
        ))}
      </>
    );
  }
);

const CommentText = React.memo(
  (props: {
    commentId: CommentId;
    user: DeglazeUser;
    commentText: string;
    commentMentions: Record<Username, UserId>;
    style?: "normal" | "light";
  }) => {
    const style = props.style ?? "normal";

    const commentBodyOpacity = switchReturn(style, {
      normal: "opaque" as const,
      light: "dark" as const,
    });

    return (
      <TSecondary>
        <TUsername user={props.user} style={style} />
        <TSpace />
        <TextWithAtMentions
          text={props.commentText}
          mentions={props.commentMentions}
          renderText={(text, idx) => (
            <AutoLink location="postComment" key={text + idx}>
              <TSecondary opacity={commentBodyOpacity} fixEmojiLineHeight>
                {text}
              </TSecondary>
            </AutoLink>
          )}
          renderAtMention={(text, userId, idx) => <TMention key={text + idx} displayText={text} userId={userId} />}
        />
      </TSecondary>
    );
  }
);

const TUsername = React.memo((props: { user: DeglazeUser; style: "normal" | "light" }) => {
  const navToProfile = useNavToProfileScreen(props.user.userId, "postComments");

  const opacity = switchReturn(props.style, {
    normal: "opaque" as const,
    light: "dark" as const,
  });

  return (
    <TSecondary fontWeight="medium" opacity={opacity} onPress={navToProfile} suppressHighlighting>
      {props.user.username}
    </TSecondary>
  );
});

const TMention = React.memo((props: { displayText: string; userId: UserId }) => {
  const navToProfile = useNavToProfileScreen(props.userId, "postComments");

  return (
    <TSecondary color={globalStyleColors.colorTextLink} onPress={navToProfile} suppressHighlighting>
      {props.displayText}
    </TSecondary>
  );
});

const TSpace = React.memo(() => {
  const space = " ";
  return <TSecondary>{space}</TSecondary>;
});

const LikeButton = React.memo((props: { hide?: boolean }) => {
  // Purely for layout preservation until we implement likes
  const button = !props.hide && <IconLike size={16} />;
  const padding = !props.hide ? globalStyleConstants.unitSize : globalStyleConstants.unitSize / 2;

  return <View style={{ padding }}>{button}</View>;
});

const TimestampText = React.memo((props: { timeStamp: EpochMs }) => {
  const [seconds, setSeconds] = useState(secondsBetween(defaultTimeProvider(), props.timeStamp));

  useEffect(() => {
    let handle: NodeJS.Timeout;
    if (seconds < 60) {
      handle = setTimeout(() => setSeconds(secondsBetween(defaultTimeProvider(), props.timeStamp)), 1000);
    } else {
      handle = setTimeout(() => setSeconds(secondsBetween(defaultTimeProvider(), props.timeStamp)), 60000);
    }

    return () => {
      if (handle) {
        clearTimeout(handle);
      }
    };
  }, [seconds, props.timeStamp]);

  const timestamp = getTimeSinceDisplayString(props.timeStamp, false);

  return <TTertiary opacity="medium">{timestamp}</TTertiary>;
});

const ReplyButton = React.memo((props: { onPress: () => void }) => {
  return (
    <Pressable onPress={props.onPress} hitSlop={globalStyleConstants.unitSize}>
      <TTertiary opacity="medium">{strings.reply}</TTertiary>
    </Pressable>
  );
});

const DeleteButton = React.memo((props: { onPress: () => void }) => {
  return (
    <Pressable onPress={props.onPress} hitSlop={globalStyleConstants.unitSize}>
      <TTertiary opacity="medium">{strings.delete}</TTertiary>
    </Pressable>
  );
});

const OptionsButton = React.memo((props: { onPress: () => void }) => {
  return (
    <Pressable onPress={props.onPress} hitSlop={globalStyleConstants.unitSize}>
      <TTertiary opacity="medium">{strings.options}</TTertiary>
    </Pressable>
  );
});

const CommentActionsSeparator = React.memo(() => {
  return (
    <>
      <Spacer horizontal={1} />
      <View
        style={{
          width: 3,
          height: 3,
          borderRadius: 3,
          backgroundColor: globalStyleColors.rgba("black", "medium"),
          alignSelf: "center",
        }}
      />
      <Spacer horizontal={1} />
    </>
  );
});

const CommentInput = React.forwardRef<
  TextInputHandle,
  {
    text: string;
    onChangeText: (text: string) => void;
    cursorPosition: number;
    setCursorPosition: (position: number) => void;
    setAtMentionQuery: (query: AtMentionQuery | undefined) => void;
    searchUsersContext: SearchUsersContext;
    onSubmit: () => void;
    onPressAddRecipe: () => void;
    waiting: boolean;
    submitDisabled: boolean;
    focused?: boolean;
  }
>((props, ref) => {
  return (
    <InputAccessoryView snapPoint={{ bottom: "tabBar" }}>
      <View style={[styles.inputAccessoryBar, globalStyles.borderBottomBarThin]}>
        <ContainerBordered paddingHorizontal={0} paddingVertical={0} borderRadius={constants.commentInputMinHeight / 2}>
          <View style={styles.inputControl}>
            <View style={{ height: constants.commentInputMinHeight }}>
              <ShareRecipeButton onPress={props.onPressAddRecipe} disabled={false} />
            </View>
            <View style={styles.textInput}>
              <TextInputWithAtMentions
                ref={ref}
                value={props.text}
                onChangeText={props.onChangeText}
                cursorPosition={props.cursorPosition}
                setCursorPosition={props.setCursorPosition}
                setAtMentionQuery={props.setAtMentionQuery}
                searchUsersContext={props.searchUsersContext}
                placeholderText={strings.placeholderText}
                fontSize="secondary"
              />
            </View>
            <View style={{ height: constants.commentInputMinHeight }}>
              <PostButton disabled={props.submitDisabled} onPress={props.onSubmit} waiting={props.waiting} />
            </View>
          </View>
        </ContainerBordered>
      </View>
    </InputAccessoryView>
  );
});

const ShareRecipeButton = React.memo((props: { onPress: () => void; disabled: boolean }) => {
  return (
    <Pressable disabled={props.disabled} onPress={props.onPress} style={styles.shareRecipeButton}>
      <IconPlus color="white" opacity="opaque" />
    </Pressable>
  );
});

const PostButton = React.memo((props: { onPress: () => void; disabled: boolean; waiting: boolean }) => {
  return (
    <Pressable disabled={props.disabled || props.waiting} onPress={props.onPress} style={styles.postButton}>
      {props.waiting ? (
        <Spinner />
      ) : (
        <TBody actionText fontWeight="medium">
          {strings.post}
        </TBody>
      )}
    </Pressable>
  );
});

const PostSummary = React.memo((props: { postId: SocialPostId; onPress: RecipePressedHandler }) => {
  const post = usePostForDetailScreen(props.postId);

  if (!post) {
    return <Spinner />;
  }

  const entity = post.type === "newRecipePost" ? post.entity : post.user;

  return (
    <View style={styles.summary}>
      <View style={{ paddingHorizontal: globalStyleConstants.unitSize }}>
        <SocialPostHeading
          type={post.type}
          postId={post.id}
          entity={entity}
          cookCount={post.type === "userRecipeActionPost" ? post.userRecipeStats.cooked : undefined}
        />
      </View>
      <Spacer vertical={1} />
      {post.type === "userRecipeActionPost" && (
        <RecipeCard
          {...post.recipeInfo}
          index={0}
          onPress={props.onPress}
          sessionId={undefined}
          showTimeFilter={false}
        />
      )}
      {post.type === "textPost" && <TextPostBody body={post.body} />}
    </View>
  );
});

const CommentRecipe = React.memo(
  (props: {
    index: number;
    recipeInfo: CommentRecipeInfo;
    sharedByUserId: UserId;
    onPress: (sourceRecipeId: RecipeId, sharedByRecipeId: UserRecipeId) => void;
    isFirst?: boolean;
  }) => {
    const onPress = useCallback(
      (args: ShareViewRecipeScreenProps) => {
        props.onPress(args.sourceRecipeId ?? props.recipeInfo.userRecipeId, props.recipeInfo.userRecipeId);
      },
      [props.onPress, props.recipeInfo.userRecipeId]
    );

    return (
      <CommentRecipeCard
        {...props.recipeInfo.recipeInfo}
        index={props.index}
        userRecipeId={props.recipeInfo.userRecipeId}
        sharedByUserId={props.sharedByUserId}
        sourceRecipeId={props.recipeInfo.sourceRecipeId}
        onPressRecipe={onPress}
        sessionId={undefined}
        showTimeFilter={false}
      />
    );
  }
);

const styles = StyleSheet.create({
  summary: {
    backgroundColor: "white",
    padding: 8,
  },
  listItem: {
    flexDirection: "row",
    alignItems: "flex-start",
    paddingHorizontal: 20,
    paddingVertical: globalStyleConstants.unitSize,
  },
  comment: {
    flexShrink: 1,
    flexDirection: "row",
    justifyContent: "space-between",
  },
  inputAccessoryBar: {
    padding: globalStyleConstants.unitSize,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    backgroundColor: bottomActionBarConstants.defaultBackgroundColor,
  },
  inputControl: {
    flex: 1,
    flexDirection: "row",
    alignItems: "flex-end",
    minHeight: constants.commentInputMinHeight,
    maxHeight: constants.commentInputMaxHeight,
    backgroundColor: globalStyleColors.white,
    borderRadius: constants.commentInputMinHeight / 2,
  },
  textInput: {
    flex: 1,
    minHeight: constants.commentInputMinHeight,
    justifyContent: "center",
    paddingTop: 4,
    paddingBottom: 6,
    paddingHorizontal: 0.5 * globalStyleConstants.unitSize,
  },
  postButton: {
    flex: 1,
    paddingHorizontal: globalStyleConstants.unitSize,
    justifyContent: "center",
  },
  shareRecipeButton: {
    alignItems: "center",
    justifyContent: "center",
    marginLeft: 4,
    marginRight: 6,
    marginVertical: 4,
    width: 40,
    height: 40,
    borderRadius: 20,
    backgroundColor: globalStyleColors.colorAccentCool,
    shadowColor: "black",
    shadowOpacity: 0.25,
    shadowRadius: 2,
    shadowOffset: { height: 2, width: 0 },
  },
});

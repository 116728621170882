export function arrayMap<TKey extends string, TValue>(): [Record<TKey, TValue[]>, (key: TKey, value: TValue) => void] {
  const map = {} as Record<TKey, TValue[]>;

  const add = (key: TKey, value: TValue) => {
    if (!map[key]) {
      map[key] = [];
    }

    map[key]!.push(value);
  };

  return [map, add];
}

import { EpochMs, TypedPrimitive, UserId } from "@eatbetter/common-shared";
import { UserRecipeId } from "./RecipeTypes";
import { RecipeCollectionId } from "./CollectionTypes";

export type RecipeTagId = TypedPrimitive<string, "recipeTagId">;

export type RecipeTag = UserRecipeTag | SystemRecipeTag;

export interface UserRecipeTag {
  type: "user";
  tag: string;
}

export interface SystemRecipeTag {
  type: "system";
  tag: RecipeTagId;
}

export function isTagMatch(t1: RecipeTag, t2: RecipeTag): boolean {
  return t1.type === t2.type && t1.tag === t2.tag;
}

export type RecipeTagSource = "admin" | "user" | "tagger";

export type RecipeTagAndMetadata = RecipeTag & RecipeTagMetadata;
export type SystemRecipeTagAndMetadata = SystemRecipeTag & RecipeTagMetadata;

export interface RecipeTagMetadata {
  // hash = git hash of the code that added it
  // qid = query id of the query from which it was derived if from ai
  added: RecipeTagAddedMetadata;
  // if a tag is replaced by an admin, but it was there originally, we record the original source.
  addedOriginal?: RecipeTagAddedMetadata;
  deleted?: { by: RecipeTagSource; ts: EpochMs };
  renamedFrom?: Array<{ from: string; ts: EpochMs }>;
}

export type RecipeTagAddedMetadata = AdminAddedMetadata | UserAddedMetadata | TaggerAddedMetadata;

export interface AdminAddedMetadata {
  by: "admin";
  ts: EpochMs;
}

export interface UserAddedMetadata {
  by: "user";
  userId?: UserId;
  ts: EpochMs;
}

export interface TaggerAddedMetadata {
  by: "tagger";
  via: "parser" | "ai";
  ts: EpochMs;
  hash?: string;
  qid?: string;
}

export interface EditUserRecipeTagArgs {
  recipeId: UserRecipeId;
  tag: RecipeTag;
  action: "add" | "remove";
  // Added for v5.0. Optional only for back compat reasons
  collectionId?: RecipeCollectionId;
}

export type RecipeTagUpdate = AdminTagUpdate | TaggerTagUpdate;

export interface AdminTagUpdate {
  by: "admin";
  op: "replace";
  // currently, full replace is only supported for admins
  // ai/tags always merge because we don't want to blow admin tags away
  tags: SystemRecipeTag[];
}

export interface TaggerTagUpdate {
  /**
   * Additive only means that no tags can be deleted in the operation.
   * mergeReplace means that tags will be deleted if they are absent from the tags
   * and the precedence allows deletion (i.e. tagger update can't overrule admin update)
   */
  by: "tagger";
  op: "additiveOnly" | "mergeReplace";
  tags: SystemRecipeTagAndTaggerAddedMetadata[];
}

export interface SystemRecipeTagAndAddedMetadata<T extends RecipeTagAddedMetadata> extends SystemRecipeTag {
  added: T;
  deleted?: undefined;
}

export type SystemRecipeTagAndTaggerAddedMetadata = SystemRecipeTagAndAddedMetadata<TaggerAddedMetadata>;

// MANIFEST TYPES

export type RecipeTagCategoryList = Array<{ category: string; tags: RecipeTagId[] }>;

export interface RecipeTagManifest {
  tagDisplay: Record<RecipeTagId, string>;
  categoryList: RecipeTagCategoryList;
}

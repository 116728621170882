import React from "react";
import { TBody } from "../Typography";
import { AppRecipeCollection } from "@eatbetter/recipes-shared";

const strings = {
  collectionType: {
    system: "Deglaze Collection",
    household: "Household Collection",
    user: "",
  } satisfies Record<AppRecipeCollection["source"], string>,
};

export const RecipeCollectionContextMenuTitle = React.memo(
  (props: { collectionName: string; collectionSource: AppRecipeCollection["source"] }) => {
    const collectionTypeDisplayText = ` (${strings.collectionType[props.collectionSource]})`;

    return (
      <TBody numberOfLines={1}>
        <TBody fontWeight="medium">{props.collectionName}</TBody>
        {(props.collectionSource === "system" || props.collectionSource === "household") && (
          <TBody opacity="dark">{collectionTypeDisplayText}</TBody>
        )}
      </TBody>
    );
  }
);

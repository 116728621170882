import { switchReturn } from "@eatbetter/common-shared";
import React from "react";
import { globalStyleColors } from "./GlobalStyles";
import { StyleSheet, View } from "react-native";
import { MealType } from "../screens/PlanningHomeScreen";
import { TTertiary } from "./Typography";

export const MealTypeLabel = React.memo((props: { mealType: MealType }) => {
  const displayText = switchReturn(props.mealType, {
    breakfast: "Breakfast",
    lunch: "Lunch",
    dinner: "Dinner",
    snack: "Snack",
  });

  const pillColor = switchReturn(props.mealType, {
    dinner: globalStyleColors.colorAccentCool,
    lunch: globalStyleColors.colorAccentMidDark,
    breakfast: globalStyleColors.colorAccentWarm,
    snack: globalStyleColors.white,
  });

  const textColor = switchReturn(props.mealType, {
    breakfast: globalStyleColors.white,
    lunch: globalStyleColors.black,
    dinner: globalStyleColors.white,
    snack: globalStyleColors.black,
  });

  return (
    <View style={styles.mealTypeOverlay}>
      <View style={[styles.mealTypeOverlayPill, { backgroundColor: pillColor }]}>
        <TTertiary color={textColor}>{displayText}</TTertiary>
      </View>
    </View>
  );
});

const styles = StyleSheet.create({
  mealTypeOverlay: {
    position: "absolute",
    justifyContent: "center",
    alignItems: "center",
    top: 0,
    left: 0,
    padding: 8,
    width: "auto",
    zIndex: 1,
  },
  mealTypeOverlayPill: {
    justifyContent: "center",
    alignItems: "center",
    height: 20,
    opacity: 0.8,
    backgroundColor: "white",
    paddingHorizontal: 10,
    borderRadius: 12,
    borderWidth: 0.5,
    borderColor: globalStyleColors.rgba("black", "xlight"),
  },
});

import React from "react";
import { StyleSheet, View } from "react-native";
import { TSecondary, TTertiary } from "../Typography";
import { Spacer } from "../Spacer";
import { IconCookPotOpen } from "../Icons";
import { globalStyleColors } from "../GlobalStyles";
import { bottomThrow } from "@eatbetter/common-shared";

const strings = {
  cooks: " cooks",
};

interface Props {
  type: "compact" | "expanded";
  cookCount: number;
}

export const RecipeCookCount = React.memo((props: Props) => {
  switch (props.type) {
    case "compact": {
      return (
        <View style={styles.cookCount}>
          <IconCookPotOpen opacity="light" size={16} color={globalStyleColors.blackSoft} />
          <Spacer horizontal={0.4} />
          <TTertiary opacity={"dark"} color={globalStyleColors.blackSoft}>
            {props.cookCount}
          </TTertiary>
        </View>
      );
    }
    case "expanded": {
      return (
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <IconCookPotOpen size={22} color={globalStyleColors.colorAccentCool} opacity="opaque" />
          <Spacer horizontal={0.5} />
          <View style={{ alignSelf: "flex-end" }}>
            <TSecondary color={globalStyleColors.colorAccentCool}>
              <TSecondary>{props.cookCount}</TSecondary>
              <TSecondary>{strings.cooks}</TSecondary>
            </TSecondary>
          </View>
        </View>
      );
    }
    default:
      bottomThrow(props.type);
  }
});

const styles = StyleSheet.create({
  cookCount: {
    flexDirection: "row",
    alignItems: "flex-start",
  },
});

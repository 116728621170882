import { newId } from "@eatbetter/common-shared";

const libraryPrefix = "library:";
const globalPrefix = "globalSearch:";

export type LibraryOrSearchSessionId = LibraryFilterSessionId | GlobalSearchSessionId;
export type LibraryFilterSessionId = `${typeof libraryPrefix}${string}`;
export type GlobalSearchSessionId = `${typeof globalPrefix}${string}`;

export function isLibraryFilterSessionId(id: LibraryOrSearchSessionId): id is LibraryFilterSessionId {
  return id.startsWith(libraryPrefix);
}

export function isGlobalSearchSessionId(id: LibraryOrSearchSessionId): id is GlobalSearchSessionId {
  return id.startsWith(globalPrefix);
}

export function newLibraryFilterSessionId(): LibraryFilterSessionId {
  return `${libraryPrefix}${newId()}`;
}

export function newGlobalSearchSessionId(): GlobalSearchSessionId {
  return `${globalPrefix}:${newId()}`;
}

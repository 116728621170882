import {
  defaultTimeProvider,
  isAxiosNetworkError,
  isAxiosTimeout,
  isStructuredError,
  LogContext,
  newId,
} from "@eatbetter/common-shared";
import { Alert, Platform } from "react-native";
import { log } from "../Log";
import { CurrentEnvironment } from "../CurrentEnvironment";
import { getGlobalDispatch } from "./redux/Redux";
import { analyticsEvent } from "./analytics/AnalyticsThunks";
import { reportUnexpectedErrorDisplayed } from "./analytics/AnalyticsEvents";
import { numericCheckpointsUpdated } from "./system/SystemSlice.ts";

/**
 * catch handler that calls displayUnexpectedErrorAndLog
 */
export function displayUnexpectedErrorAndLogHandler(logMsg: string, ctx?: LogContext) {
  return (err: any) => {
    displayUnexpectedErrorAndLog(logMsg, err, ctx);
  };
}

/**
 * Error function for *unexpected* errors. It logs an error and displays an error to the user.
 * *Note* logMsg is the message that is logged, *not*
 * the message that is displayed to the user.
 * The displayed message will be determined from the error - If it's a StructuredError
 * with a friendly message, that will be used. Otherwise, a generic message will be displayed.
 * @param logMsg The message that will be
 * @param err
 * @param ctx
 */
export function displayUnexpectedErrorAndLog(logMsg: string, err: any, ctx?: LogContext) {
  const isNetworkError = isAxiosNetworkError(err);
  const isTimeoutError = isAxiosTimeout(err);
  const isConnectionError = isNetworkError || isTimeoutError;
  let displayedMessage = "Well this is embarassing: something went wrong.";
  if (isStructuredError(err)) {
    if (err.data.userMessage) {
      displayedMessage = err.data.userMessage;
    }

    if (CurrentEnvironment.configEnvironment() !== "prod") {
      displayedMessage += `\nDEV ONLY\ncode: ${err.data.code}\nunfriendly:${err.data.unfriendlyMessage}`;
    }
  } else {
    if (isNetworkError) {
      displayedMessage =
        "It seems there might be a problem with your internet connection. Please verify your connection and try again.";
    } else if (isTimeoutError) {
      displayedMessage =
        "It seems your internet connection might be slow at the moment. Please check it and try again.";
    }
    if (CurrentEnvironment.configEnvironment() !== "prod") {
      displayedMessage += `\nDEV ONLY\n${JSON.stringify(err)}`;
    }
  }

  const logId = newId();
  log.errorCaught(`Displaying Error with ID ${logId}. isConnectionError: ${isConnectionError}. ${logMsg}`, err, {
    logId,
    displayedMessage,
    isConnectionError,
    ...ctx,
  });

  try {
    const dispatch = getGlobalDispatch();
    if (dispatch) {
      dispatch(numericCheckpointsUpdated({ lastNegativeInteraction: defaultTimeProvider() }));
      dispatch(
        analyticsEvent(
          reportUnexpectedErrorDisplayed({
            err,
            errorId: logId,
            description: logMsg,
            context: "alert",
            displayMessage: displayedMessage,
          })
        )
      );
    }
  } catch (caughtErr) {
    log.errorCaught("Error dispatching reportUnexpectedErrorDisplayed in displayUnexpectedErrorAndLog", caughtErr);
  }

  displayError(displayedMessage, logId);
}

/**
 * Used to display an *expected* error to a user. An expected error is one where the
 * system is functioning normally, but you wish to provide the user with feedback.
 * This should be used sparingly - there are typically better UI patterns for this.
 * @param errorMsg
 */
export function displayExpectedError(errorMsg: string) {
  displayError(errorMsg);
}

function displayError(msg: string, logId?: string) {
  const reference = logId ? ` Reference: ${logId}` : "";
  const message = `${msg}${reference}`;

  if (Platform.OS === "web") {
    //@ts-ignore This is temporary
    alert(message);
  } else {
    Alert.alert(message);
  }
}

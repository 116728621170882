import { AppDispatch, SyncThunkAction, ThunkAction } from "../redux/Redux";
import {
  AddRecipeArgs,
  AddRecipeFromUrlArgs,
  ArchiveRecipeArgs,
  EditRecipeArgs,
  GetUserRecipeArgs,
  UserRecipeId,
  GetRecipeArgs,
  ShareRecipeArgs,
  SaveSharedRecipeArgs,
  AppRecipeBase,
  RecipeTime,
  AppUserRecipe,
  isStructuredRecipeError,
  RecipeRating,
  AppRecipe,
  NextRecipesStart,
  UserRecipes,
  PartialRecipeId,
  AddRecipeFromPhotosArgs,
  EditUserRecipeAttributionArgs,
  BookCoverInfo,
  UpdateRecipeCollectionsArgs,
  AppCollectionManifest,
  BulkEditRecipesArgs,
  RecipeCollectionId,
  getRecipeTagForCollection,
} from "@eatbetter/recipes-shared";
import {
  recipeAdded,
  recipesRequested,
  recipesPartialReceived,
  recipesFullReceived,
  recipesErrored,
  recipeEdited,
  singleRecipeReceived,
  saveNewRecipeDraft,
  setSearchFilter,
  RecipeFilters,
  recipesScriptErrored,
  recipesScriptRequested,
  recipesScriptReceived,
  libraryRecipeFetchStarted,
  recipesNoLongerProcessing,
  attributionSet,
  collectionManifestRecieved,
  selectLibraryFilterSession,
} from "./RecipesSlice";
import { isLoading } from "../redux/ServerData";
import { log } from "../../Log";
import { AppAddPhotoArgs, SetWaitingHandler } from "../Types";
import {
  defaultTimeProvider,
  discriminate,
  EpochMs,
  filterOutFalsy,
  newId,
  secondsBetween,
  StructuredError,
  switchReturn,
} from "@eatbetter/common-shared";
import {
  reportPaywallHit,
  reportRecipeAdded,
  reportRecipeDeleted,
  reportRecipeDetailViewed,
  reportRecipeEdited,
  reportRecipeLibraryFiltered,
  reportRecipeLibrarySearched,
  reportRecipeNoteEdited,
  reportRecipeQuotaExceeded,
  reportRecipeRatingEdited,
  reportRecipeShared,
  reportRecipeSourceEdited,
  reportRecipesReceived,
  reportRecipeTagAddedRemoved,
  reportRecipeTimeEdited,
  reportUnsupportedUrlRecipeAdded,
} from "../analytics/AnalyticsEvents";
import { analyticsEvent } from "../analytics/AnalyticsThunks";
import { EditUserRecipeOnSaveArgs } from "../../components/recipes/RecipeEditControl";
import { DeglazeUser } from "@eatbetter/users-shared";
import { EditUserRecipeTagArgs, RecipeTagManifest } from "@eatbetter/recipes-shared/dist/RecipeTagTypes";
import { selectRecipeListSections } from "../composite/RecipeListSelectors";
import { searchRecipes } from "./RecipeSearch";
import { debounce } from "lodash";
import { RecipeEditFieldLocation } from "../../navigation/NavTree";
import { Alert } from "../../components/Alert/Alert";
import { AnalyticsEventPropertyMap, PaywallLocation, RecipeEvents } from "@eatbetter/composite-shared";
import { ViewRecipeScreenComponentProps } from "../../components/ViewRecipeScreenComponent";
import { selectLibraryRecipe } from "./RecipesSelectors";
import { selectSearchSession } from "../search/SearchSlice";
import { addPhotoInBackground } from "../photos/PhotoThunks";
import { GlobalSearchSessionId, LibraryFilterSessionId } from "../composite/LibraryAndSearchSessionIds.ts";
import { selectCollectionsById } from "../composite/SharedRecipeAndCollectionSelectors.ts";
import { loadGroceryLists } from "../lists/ListsThunks.ts";
import { loadCookingSessions } from "../cooking/CookingSessionsThunks.ts";

const strings = {
  paywallAlert: {
    title: (publisherName?: string) => `Sign in to ${publisherName ?? "website"}`,
    body: (publisherName?: string) => `Please sign in to ${publisherName ?? "websiste"} to see the full recipe.`,
    confirmButton: "Go back",
  },
};

export const addRecipeFromWebView = (
  args: AddRecipeFromUrlArgs,
  setWaiting?: SetWaitingHandler
): ThunkAction<UserRecipeId> => {
  return async (dispatch, _, deps) => {
    log.info("Thunk: addRecipeFromWebView", { args });
    const resp = await deps.api.withReturn(setWaiting).addRecipeFromUrl(args);
    if (resp.data) {
      dispatch(recipeAdded(resp.data));
      const event = reportRecipeAdded({ addedVia: "webViewBrowse", recipe: resp.data });
      dispatch(analyticsEvent(event));
      return resp.data.id;
    } else {
      if (resp.error.code === "recipes/unsupportedUrlError") {
        const event = reportUnsupportedUrlRecipeAdded(args.url);
        dispatch(analyticsEvent(event));
      }

      throw new StructuredError(resp.error);
    }
  };
};

export const addRecipeFromUrl = (
  args: AddRecipeFromUrlArgs,
  collectionId?: RecipeCollectionId,
  setWaiting?: SetWaitingHandler
): ThunkAction<UserRecipeId> => {
  return async (dispatch, _getState, deps) => {
    try {
      log.info("Thunk: addRecipeFromUrl", { args });
      setWaiting?.(true);
      const resp = await deps.api.withReturn().addRecipeFromUrl(args);

      if (resp.data) {
        const event = reportRecipeAdded({ addedVia: "appUrl", recipe: resp.data, collectionId });
        dispatch(analyticsEvent(event));

        await dispatch(addNewRecipeToCollectionAndRedux(resp.data, collectionId));

        return resp.data.id;
      } else {
        if (resp.error.code === "recipes/unsupportedUrlError") {
          const event = reportUnsupportedUrlRecipeAdded(args.url);
          dispatch(analyticsEvent(event));
        }

        if (resp.error.code === "recipes/importQuotaExceeded") {
          dispatch(analyticsEvent(reportRecipeQuotaExceeded("appAddFromUrl")));
        }

        throw new StructuredError(resp.error);
      }
    } finally {
      setWaiting?.(false);
    }
  };
};

export const getBookCoverInfo = (
  recipeId: UserRecipeId,
  photoArgs: AppAddPhotoArgs,
  onProgress?: (n: number) => void,
  setWaiting?: SetWaitingHandler
): ThunkAction<BookCoverInfo> => {
  return async (dispatch, _getState, deps) => {
    log.info("Thunk: getBookCoverPhotoInfo");

    try {
      setWaiting?.(true);
      const photo = await dispatch(addPhotoInBackground(photoArgs, onProgress));
      const resp = await deps.api.withThrow().getBookCoverInfo({ recipeId, photo });
      return resp.data;
    } finally {
      setWaiting?.(false);
    }
  };
};

export const addRecipeFromPhotos = (
  photosToUpload: AppAddPhotoArgs[],
  collectionId?: RecipeCollectionId,
  onProgress?: (uri: string, percentageComplete: number) => void,
  setWaiting?: SetWaitingHandler
): ThunkAction<void> => {
  return async (dispatch, _getState, deps) => {
    log.info("Thunk: addRecipeFromPhotos");

    try {
      setWaiting?.(true);
      const photos = await Promise.all(
        photosToUpload.map(p => {
          return dispatch(
            addPhotoInBackground(p, progress => {
              onProgress?.(p.uri, progress);
            })
          );
        })
      );

      const partial = newId<PartialRecipeId>();
      const args: AddRecipeFromPhotosArgs = {
        id: partial,
        photos,
      };

      const resp = await deps.api.withThrow().addRecipeFromPhotos(args);
      const event = reportRecipeAdded({ addedVia: "appPhotos", recipe: resp.data });
      dispatch(analyticsEvent(event));
      await dispatch(addNewRecipeToCollectionAndRedux(resp.data, collectionId));
    } finally {
      setWaiting?.(false);
    }
  };
};

export const addManualRecipe = (
  args: AddRecipeArgs,
  collectionId?: RecipeCollectionId,
  setWaiting?: SetWaitingHandler
): ThunkAction<void> => {
  return async (dispatch, _getState, deps) => {
    try {
      log.info("Thunk: addManualRecipe", { id: args.id });
      setWaiting?.(true);
      // create the recipe
      const resp = await deps.api.withThrow().addManualRecipe(args);
      // clear the draft if one was saved.
      dispatch(saveNewRecipeDraft(undefined));

      const event = reportRecipeAdded({ addedVia: "appManual", recipe: resp.data });
      dispatch(analyticsEvent(event));

      await dispatch(addNewRecipeToCollectionAndRedux(resp.data, collectionId));
    } finally {
      setWaiting?.(false);
    }
  };
};

export const getSavedRecipeDraft = (): SyncThunkAction<EditUserRecipeOnSaveArgs | undefined> => {
  return (_dispatch, getState, _deps) => {
    return getState().recipes.newRecipeDraft;
  };
};

export const getRecipe = (args: GetRecipeArgs, setWaiting?: SetWaitingHandler): ThunkAction<AppRecipeBase> => {
  return async (_dispatch, _getState, deps) => {
    log.info("Thunk: getRecipe", { args });
    const resp = await deps.api.withThrow(setWaiting).getRecipe(args);
    return resp.data;
  };
};

export const getRecipeEvents = (recipeId: UserRecipeId, setWaiting?: SetWaitingHandler): ThunkAction<RecipeEvents> => {
  return async (_dispatch, _getState, deps) => {
    log.info("Thunk: getRecipeEvents");
    const resp = await deps.api.withThrow(setWaiting).getUserRecipeEvents({ recipeId });
    return resp.data;
  };
};

/**
 * Exclusively for the use of the updateProcessingRecipesReactor
 */
export const updateProcessingLibraryRecipes = (
  toUpdate: UserRecipeId | undefined,
  toRemove: UserRecipeId[]
): ThunkAction<void> => {
  return async (dispatch, _gs, _deps) => {
    log.info("Thunk: updateProcessingLibraryRecipes");
    if (toUpdate) {
      dispatch(libraryRecipeFetchStarted(toUpdate));
      dispatch(getAndStoreUserRecipe({ recipeId: toUpdate })).catch(err => {
        log.errorCaught("Error dispatching getAndStoreUserRecipe from updateProcessingLibraryRecipes", err);
      });
    }

    if (toRemove.length > 0) {
      dispatch(recipesNoLongerProcessing(toRemove));
    }
  };
};

export const getAndStoreUserRecipe = (args: GetUserRecipeArgs, setWaiting?: SetWaitingHandler): ThunkAction<void> => {
  return async (dispatch, _gs, deps) => {
    log.info("Thunk: getAndStoreUserRecipe", { args });
    const resp = await deps.api.withThrow(setWaiting).getUserRecipe(args);
    dispatch(singleRecipeReceived(resp.data));
  };
};

export const editRecipe = (args: EditRecipeArgs, setWaiting?: SetWaitingHandler): ThunkAction<void> => {
  return async (dispatch, _getState, deps) => {
    log.info("Thunk: editRecipe", { id: args.id });
    const resp = await deps.api.withThrow(setWaiting).editRecipe(args);
    dispatch(recipeEdited(resp.data));
    const event = reportRecipeEdited({ recipe: resp.data });
    dispatch(analyticsEvent(event));
  };
};

export const editRecipeNote = (
  recipeId: UserRecipeId,
  text: string,
  location: RecipeEditFieldLocation,
  setWaiting?: SetWaitingHandler
): ThunkAction<void> => {
  return async (dispatch, _getState, deps) => {
    log.info("Thunk: editRecipeNote", { id: recipeId });
    const tsClient = deps.time.epochMs();
    const resp = await deps.api.withThrow(setWaiting).editRecipeNote({ recipeId, text, tsClient });
    dispatch(recipeEdited(resp.data));
    debouncedReportRecipeNoteEdited(dispatch, resp.data, location);
  };
};

// use a slightly longer time here - unlike search, we don't care about the intermediate states
// initially thought we could do this on unmount, but that doesn't really work for edits in a cooking session
const debouncedReportRecipeNoteEdited = debounce(
  (dispatch: AppDispatch, recipe: AppUserRecipe, location: RecipeEditFieldLocation) => {
    const event = reportRecipeNoteEdited({ recipe, newNote: recipe.notes?.text ?? "", location });
    dispatch(analyticsEvent(event));
  },
  3000
);

export const editRecipeTime = (
  recipeId: UserRecipeId,
  location: RecipeEditFieldLocation,
  time?: RecipeTime,
  setWaiting?: SetWaitingHandler
): ThunkAction<void> => {
  return async (dispatch, getState, deps) => {
    log.info("Thunk: editRecipeTime", { id: recipeId });
    const existing = getState().recipes.entities[recipeId];
    if (!existing) {
      log.error(`Attempted to edit time for recipe ${recipeId} but recipe not found.`);
      return;
    }

    const initialTime = existing.time;

    const resp = await deps.api.withThrow(setWaiting).editRecipeTime({ recipeId, time, version: existing.version });
    dispatch(recipeEdited(resp.data));

    const event = reportRecipeTimeEdited({ recipe: resp.data, originalTime: initialTime, newTime: time, location });
    dispatch(analyticsEvent(event));
  };
};

export const editRecipeRating = (
  recipeId: UserRecipeId,
  location: RecipeEditFieldLocation,
  rating: RecipeRating | null,
  setWaiting?: SetWaitingHandler
): ThunkAction<void> => {
  return async (dispatch, getState, deps) => {
    log.info("Thunk: editRecipeRating", { id: recipeId });
    const existing = getState().recipes.entities[recipeId];
    if (!existing) {
      log.error(`Attempted to edit rating for recipe ${recipeId} but recipe not found.`);
      return;
    }

    const originalRating = existing?.rating;
    const newRating = rating;

    const resp = await deps.api.withThrow(setWaiting).editRecipeRating({ recipeId, rating, version: existing.version });
    dispatch(recipeEdited(resp.data));

    const event = reportRecipeRatingEdited({ recipe: resp.data, originalRating, newRating, location });
    dispatch(analyticsEvent(event));
  };
};

export const editRecipeTag = (
  args: EditUserRecipeTagArgs,
  location: RecipeEditFieldLocation,
  setWaiting?: SetWaitingHandler
): ThunkAction<void> => {
  return async (dispatch, _getState, deps) => {
    log.info("Thunk: editRecipeTag", { args });
    const resp = await deps.api.withThrow(setWaiting).editUserRecipeTag(args);
    dispatch(recipeEdited(resp.data));

    const event = reportRecipeTagAddedRemoved({ update: args, recipe: resp.data, location });
    dispatch(analyticsEvent(event));
  };
};

/**
 * Create a new collection and add the specified recipe to it
 */
export const createCollectionAndAddRecipe = (
  args: UpdateRecipeCollectionsArgs,
  recipeId: UserRecipeId | undefined,
  setWaiting?: SetWaitingHandler
): ThunkAction<void> => {
  return async (dispatch, getState, _deps) => {
    log.info("Thunk: createCollectionAndAddRecipe");
    const collectionOp = args.collection?.op;
    if (collectionOp?.type !== "create") {
      log.errorCaught("createCollectionAndAddRecipe can only be called with create args", { args });
      return;
    }

    try {
      setWaiting?.(true);
      await dispatch(editCollections(args));
      // if we don't have a recipe to add to the collection, we're done
      if (!recipeId) {
        return;
      }

      const state = getState();
      // find the new collection
      const newCollection = state.recipes.collections?.collections.find(
        c => c.type === "tag" && c.tagType === "user" && c.name === collectionOp.name
      );
      if (!newCollection) {
        log.error("New collection not found after create", { args });
        return;
      }

      if (newCollection.type !== "tag") {
        log.error("New collection is not of type tag. This should not happen", { args, newCollection });
        return;
      }

      const tag = getRecipeTagForCollection(newCollection);
      if (!tag) {
        log.error("No tag returned from getRecipeTagForCollection. This shouldn't happen", { args, newCollection });
        return;
      }

      await dispatch(editRecipeTag({ collectionId: newCollection.id, tag, action: "add", recipeId }, "recipeDetail"));
    } finally {
      setWaiting?.(false);
    }
  };
};

export const editCollections = (
  args: UpdateRecipeCollectionsArgs,
  setWaiting?: SetWaitingHandler
): ThunkAction<void> => {
  return async (dispatch, getState, deps) => {
    log.info("Thunk: editCollections", { args });
    try {
      setWaiting?.(true);
      const resp = await deps.api.withThrow().editCollections(args);

      // before we dispatch the manifest, check to see if we need to update recipes based on a collection rename completed
      // this is only important in the case that a user renames a collection multiple times without the recipes being updated in the app
      // Specifically
      // 1. User renames collection from A -> B. At this point, the manifest will be returned with the names A and B since the rename op hasn't completed.
      // 2. Rename op completes on backend but app recipes aren't all updated yet.
      // 3. User renames recipe again from B -> C. At this point, the collection will have the names B and C only, since the rename from A -> B has completed.
      //    Any recipes that haven't been updated and still have tag A will now appear to be removed from the collection. This is bad (but a refresh fixes it).
      // To prevent this, all we have to do is get the latest recipes, which will also have the latest manifest.
      // We do this for any colleciton and not just the relevant collection because the change could have happened on a different device.
      // Currently, loading recipes and this thunk are the only place we dispatch the manifest, so this logic should be complete for the multiple rename scenario.
      const latestCompletedRename = resp.data.collections
        .filter(discriminate("type", "tag"))
        .reduce((ts, c) => (c.lastRenameCompleted && c.lastRenameCompleted > ts ? c.lastRenameCompleted : ts), 0);
      const recipesVersion = getState().recipes.meta.version ?? 0;
      if (latestCompletedRename > recipesVersion) {
        log.info(
          "Calling partial loadRecipes from editCollections because a recent rename completed and the library is not updated."
        );
        await dispatch(loadRecipes("partial"));
      } else {
        dispatch(collectionManifestRecieved(resp.data));
      }
    } catch (err) {
      await handleCollectionInvalidErrAndRethrow(err, dispatch);
    } finally {
      setWaiting?.(false);
    }
  };
};

export const bulkEditRecipes = (args: BulkEditRecipesArgs, setWaiting?: SetWaitingHandler): ThunkAction<void> => {
  return async (dispatch, _getState, deps) => {
    try {
      log.info("Thunk: bulkEditRecipes", { args });
      setWaiting?.(true);
      await deps.api.withThrow().bulkEditRecipes(args);
      await dispatch(loadRecipes("partial"));
    } catch (err) {
      await handleCollectionInvalidErrAndRethrow(err, dispatch);
    } finally {
      setWaiting?.(false);
    }
  };
};

export const editRecipeAttribution = (
  args: EditUserRecipeAttributionArgs,
  screen: AnalyticsEventPropertyMap["Recipe Edit Source From"],
  setWaiting?: SetWaitingHandler
): ThunkAction<void> => {
  return async (dispatch, getState, deps) => {
    log.info("Thunk: editRecipeAttribution", { args });
    const existingRecipe = getState().recipes.entities[args.recipeId];
    const resp = await deps.api.withThrow(setWaiting).editRecipeAttribution(args);
    dispatch(singleRecipeReceived(resp.data));
    dispatch(analyticsEvent(reportRecipeSourceEdited({ existingRecipe, updatedRecipe: resp.data, screen })));
    if (args.type === "book" && args.bookId) {
      dispatch(attributionSet({ type: "book", bookId: args.bookId }));
    } else if (args.type === "userEntered" && args.attribution) {
      dispatch(attributionSet({ type: "userEntered", attribution: args.attribution }));
    }
  };
};

export const archiveRecipe = (args: ArchiveRecipeArgs, setWaiting?: SetWaitingHandler): ThunkAction<void> => {
  return async (dispatch, getState, deps) => {
    log.info("Thunk: archiveRecipe", { args });
    const recipe = getState().recipes.entities[args.recipeId];

    const { data } = await deps.api.withThrow(setWaiting).archiveRecipe(args);

    if (recipe) {
      dispatch(analyticsEvent(reportRecipeDeleted({ recipe })));
    }

    dispatch(singleRecipeReceived(data));
  };
};

/**
 * Returns local recipe IDs that match an optional query and orders them the same way as the recipe list
 */
export const getLocalRecipes = (search?: string): SyncThunkAction<UserRecipeId[]> => {
  return (_dispatch, getState, _deps) => {
    const state = getState();
    const recipeList = selectRecipeListSections(state);
    const ids = [
      ...recipeList.cookingSessionRecipes.map(r => r.recipeId),
      ...recipeList.groceryListRecipes.map(r => r.recipeId),
      ...recipeList.otherRecipes.map(r => r.recipeId),
    ];

    if (!search) {
      return ids;
    }

    const recipes = filterOutFalsy(ids.map(id => state.recipes.entities[id]));

    const searchScores = searchRecipes(recipes, search);

    return ids
      .filter(id => searchScores.hasOwnProperty(id))
      .sort((a, b) => {
        const aScore = searchScores[a] ?? 0;
        const bScore = searchScores[b] ?? 0;
        return bScore - aScore;
      });
  };
};

export const saveSharedRecipe = (
  args: SaveSharedRecipeArgs,
  sharedVia: "userShared" | "socialPostComment",
  setWaiting?: SetWaitingHandler
): ThunkAction<void> => {
  return async (dispatch, _getState, deps) => {
    try {
      log.info("Thunk: saveSharedRecipe", { args });
      setWaiting?.(true);
      const resp = await deps.api.withThrow().saveSharedRecipe(args);
      dispatch(recipeAdded(resp.data));
      const event = reportRecipeAdded({ addedVia: sharedVia, recipe: resp.data });
      dispatch(analyticsEvent(event));
    } catch (err) {
      if (isStructuredRecipeError(err) && err.data.code === "recipes/recipeConflict") {
        log.warn("Caught recipes/recipeConflict in saveSharedRecipe thunk", { args });
        return;
      }

      throw err;
    } finally {
      setWaiting?.(false);
    }
  };
};

export const shareRecipe = (
  args: ShareRecipeArgs,
  recipients: DeglazeUser[],
  setWaiting?: SetWaitingHandler
): ThunkAction<void> => {
  return async (dispatch, getState, deps) => {
    log.info("Thunk: shareRecipe", { args });

    try {
      setWaiting?.(true);
      await deps.api.withThrow(setWaiting).shareRecipe(args);
      const recipe = getState().recipes.entities[args.recipeId];
      const recipientUsernames = recipients.map(r => r.username);
      const event = reportRecipeShared({ recipe, recipients: args.recipientIds.length, recipientUsernames });
      dispatch(analyticsEvent(event));
    } finally {
      setWaiting?.(false);
    }
  };
};

export const unarchiveRecipe = (args: ArchiveRecipeArgs, setWaiting?: SetWaitingHandler): ThunkAction<void> => {
  return async (dispatch, _getState, deps) => {
    log.info("Thunk: unarchiveRecipe", { args });
    const { data } = await deps.api.withThrow(setWaiting).unarchiveRecipe(args);
    dispatch(singleRecipeReceived(data));
  };
};

let lastPullToRefreshCompleted = 0 as EpochMs;
export const pullToRefreshRecipes = (): ThunkAction<void> => {
  return async (dispatch, _getState, _deps) => {
    try {
      log.info("Thunk: pullToRefresh");
      // if the user pulls to refresh twice in relatively quick sucession, do a full reload
      // in case something is hosed.
      const full = secondsBetween(defaultTimeProvider(), lastPullToRefreshCompleted) < 60;
      await Promise.all([
        dispatch(loadRecipes(full ? "full" : "partial")),
        dispatch(loadGroceryLists()),
        dispatch(loadCookingSessions()),
      ]);
      lastPullToRefreshCompleted = defaultTimeProvider();
    } catch (err) {
      log.errorCaught("Error in pullToRefreshRecipes", err);
    }
  };
};

export const loadRecipes = (partialOrFull: "partial" | "full"): ThunkAction<void> => {
  return async (dispatch, getState, deps) => {
    log.info(`Thunk: loadRecipes ${partialOrFull}`);
    const state = getState();
    const sinceVersion = partialOrFull === "full" ? undefined : state.recipes.meta.version;
    const isFull = sinceVersion === undefined;

    try {
      if (isLoading("recipes.meta", state, s => s.recipes.meta)) {
        log.info("loadRecipes called, but status is already loading");
        return;
      }

      const startTime = deps.time.epochMs();
      dispatch(recipesRequested(startTime));

      // fetch active recipes individually so that they have scaling info.
      // We could do this after we got the list of updated recipes and only fetch what has changed
      // but this should be faster, and we typically expect this list to be very small.
      // there can be dups here if the recipe is on multiple screens, so de-dup
      const activeRecipeIds = [...new Set(state.recipes.activeRecipeIds)];
      const activeRecipesP = Promise.all(
        activeRecipeIds.map(recipeId => {
          return deps.api
            .withThrow()
            .getUserRecipe({ recipeId })
            .catch(err => {
              log.errorCaught(`Error fetching active recipe ${recipeId} in loadRecipes`, err);
              return undefined;
            });
        })
      );

      const results: UserRecipes[] = [];
      let collections: AppCollectionManifest | undefined;
      let next: NextRecipesStart | undefined;
      do {
        const { data } = await deps.api.withThrow().getRecipes({ sinceVersion, start: next });
        next = data.next;
        results.push(data);

        // we want the most recent version - in the case of multiple pages
        collections = data.collections;
      } while (next);

      if (results.length > 1) {
        log.logRemote(`Multiple calls required to fetch user recipes: ${results.length}`);
      }

      // dispatch active recipe updates. This should not throw since each promise has a catch and returns undefined
      const activeRecipes = filterOutFalsy(await activeRecipesP);
      log.info(`loadRecipes got ${activeRecipes.length} active recipes for ${activeRecipeIds.length} active ID(s)`);
      activeRecipes.forEach(r => dispatch(singleRecipeReceived(r.data)));

      const actionCreator = isFull ? recipesFullReceived : recipesPartialReceived;

      dispatch(actionCreator({ startTime, data: results }));
      if (collections) {
        dispatch(collectionManifestRecieved(collections));
      }

      if (isFull) {
        const event = reportRecipesReceived({
          recipeCount: results.reduce((a, b) => a + b.recipes.filter(r => !r.deleted && !r.archived).length, 0),
        });
        dispatch(analyticsEvent(event));
      }
    } catch (err) {
      log.errorCaught("Unexpected error fetching recipes", err);
      dispatch(recipesErrored());
    }
  };
};

export const recipeLibraryFiltersChanged = (sessionId: LibraryFilterSessionId): SyncThunkAction<void> => {
  return (dispatch, getState, _deps) => {
    const state = getState();

    const filters = selectLibraryFilterSession(state.recipes, sessionId)?.filters;
    if (filters) {
      const event = reportRecipeLibraryFiltered({
        filters,
        tagManifest: state.recipes.tagManifest,
      });
      dispatch(analyticsEvent(event));
    }
  };
};

export const searchRecipeLibrary = (args: {
  query: string;
  sessionId: LibraryFilterSessionId;
}): SyncThunkAction<void> => {
  return (dispatch, getState, _deps) => {
    dispatch(setSearchFilter({ query: args.query, sessionId: args.sessionId }));

    const state = getState();
    const filters = selectLibraryFilterSession(state.recipes, args.sessionId)?.filters;
    if (filters) {
      debouncedLibrarySearchAnaltyicsEvent(filters, state.recipes.tagManifest, dispatch);
    }
  };
};

const debouncedLibrarySearchAnaltyicsEvent = debounce(
  (filters: RecipeFilters, tagManifest: RecipeTagManifest, dispatch: AppDispatch) => {
    const event = reportRecipeLibrarySearched({ filters, tagManifest });
    dispatch(analyticsEvent(event));
  },
  2000
);

export const reportRecipeViewedTime = (
  recipeId: UserRecipeId,
  setWaiting?: (v: boolean) => void
): ThunkAction<{ type: "success" | "error" }> => {
  return async (dispatch, _gs, deps) => {
    log.info("Thunk: reportRecipeViewedTime");

    try {
      const resp = await deps.api.withThrow(setWaiting).reportRecipeView({ recipeId });
      dispatch(singleRecipeReceived(resp.data));
      return { type: "success" };
    } catch (err) {
      log.errorCaught(`Error reporting recipe view for ${recipeId}`, err);
      return { type: "error" };
    }
  };
};

export const reportViewScreenRecipeDetailViewed = (
  context: ViewRecipeScreenComponentProps["context"],
  recipe: AppRecipe,
  search?: { sessionId: GlobalSearchSessionId; resultIndex?: number }
): SyncThunkAction<void> => {
  return (dispatch, getState, _deps) => {
    const state = getState();
    const searchSession =
      context === "search" && search?.sessionId ? selectSearchSession(state.search, search.sessionId) : undefined;
    const filters = searchSession ? { filters: searchSession.filters, context: "search" as const } : undefined;
    const knownEntityId = searchSession?.authorId ?? searchSession?.publisherId;
    const recipeInLibrary = !!selectLibraryRecipe(state, recipe.id);
    const tagManifest = state.recipes.tagManifest;
    const event = reportRecipeDetailViewed({
      recipe,
      location: switchReturn(context, {
        search: "Search Results",
        post: "Social Post",
        share: "User Shared Recipe",
      }),
      filters,
      tagManifest,
      recipeInLibrary,
      knownEntityId,
      searchResultIndex: search?.resultIndex,
    });
    dispatch(analyticsEvent(event));
  };
};

export const reportLibraryRecipeDetailView = (recipe: AppUserRecipe): SyncThunkAction<void> => {
  return (dispatch, getState, _deps) => {
    log.info("Thunk: reportLibraryRecipeDetailView");
    const state = getState();
    const event = reportRecipeDetailViewed({
      recipe,
      tagManifest: state.recipes.tagManifest,
      location: "Library",
      recipeInLibrary: true,
    });
    dispatch(analyticsEvent(event));
  };
};

export const reportRecipeCooked = (
  recipeId: UserRecipeId,
  cookingSessionId: string,
  startTime: EpochMs
): ThunkAction<void> => {
  return async (dispatch, _gs, deps) => {
    log.info("Thunk: reportRecipeCooked");
    const api = deps.api.withThrow();
    await api.reportRecipeCooked({ recipeId, cookingSessionId, startTime });

    // update the recipe since the last cooked time has changed.
    // This will ensure it doesn't remain in the "recently shopped" section of the recipe list if the cooking session
    // is completed.
    // User impact is very minimal if this fails (just the potential for it to show up in the wrong section of the recipe
    // list until recipes are fetched, so just fail silently since the main work is already done.
    dispatch(getAndStoreUserRecipe({ recipeId })).catch(err => {
      log.errorCaught("Unexpected error getting recipe in reportRecipeCooked", err);
    });
  };
};

export const loadRecipesScript = (): ThunkAction<void> => {
  return async (dispatch, getState, deps) => {
    try {
      log.info("Thunk: loadRecipesScript");
      const state = getState();
      if (isLoading("recipes.script", state, s => s.recipes.script)) {
        log.info("loadRecipesScript called, but status is already loading");
        return;
      }

      const startTime = deps.time.epochMs();
      dispatch(recipesScriptRequested(startTime));

      const resp = await deps.api.withThrow().getRecipesScript();
      dispatch(recipesScriptReceived({ startTime, data: resp.data }));
    } catch (err) {
      log.errorCaught("Unexpected error in loadRecipesScript", err);
      dispatch(recipesScriptErrored());
    }
  };
};

export const reportRecipePaywallHit = (recipe: AppRecipeBase, context: PaywallLocation): SyncThunkAction<void> => {
  return dispatch => {
    const event = reportPaywallHit({ recipe, context });
    dispatch(analyticsEvent(event));
  };
};

/**
 * Alerts the user that a site's paywall is up and that they need to sign in to see the full recipe
 */
export const alertWebRecipePaywallIsUp = (
  recipe: AppRecipeBase,
  context: Extract<PaywallLocation, "Reader Mode" | "Edit Recipe">,
  onConfirmAlert: () => void
): SyncThunkAction<void> => {
  return dispatch => {
    const publisherName = recipe.publisher?.name;

    Alert.alert(strings.paywallAlert.title(publisherName), strings.paywallAlert.body(publisherName), [
      {
        type: "save",
        text: strings.paywallAlert.confirmButton,
        onPress: onConfirmAlert,
      },
    ]);

    dispatch(reportRecipePaywallHit(recipe, context));
  };
};

// if we get collection invalid error, load recipes to get the latest collection data
async function handleCollectionInvalidErrAndRethrow(err: unknown, dispatch: AppDispatch) {
  if (isStructuredRecipeError(err) && err.data.code === "recipes/collectionInvalidError") {
    await dispatch(loadRecipes("partial")).catch(err => {
      log.errorCaught("Error dispaching loadRecipes in handleCollectionInvalidErrAndRethrow", err);
    });
  }

  throw err;
}

/**
 * Adds a newly created recipe to a collection if a valid collection is specified and
 * adds the new recipe to redux.
 * @param recipeId
 * @param collectionId
 */
const addNewRecipeToCollectionAndRedux = (
  newRecipe: AppUserRecipe,
  collectionId: RecipeCollectionId | undefined
): ThunkAction<void> => {
  return async (dispatch, getState, deps) => {
    let taggedRecipe: AppUserRecipe | undefined;
    if (collectionId) {
      try {
        const state = getState();
        const collections = selectCollectionsById(state);
        const targetCollection = collections[collectionId];

        // Only add to collection if it’s a tag–type collection.
        if (targetCollection?.type === "tag") {
          const tag = getRecipeTagForCollection(targetCollection);

          if (tag) {
            const tagResp = await deps.api.withThrow().editUserRecipeTag({
              tag,
              action: "add",
              recipeId: newRecipe.id,
              collectionId: targetCollection.id,
            });
            taggedRecipe = tagResp.data;
          }
        }
      } catch (err) {
        log.errorCaught("addRecipeToCollection: error calling editUserRecipeTag()", err, {
          recipeId: newRecipe.id,
          collectionId,
        });
      }
    }

    // always add the new recipe, even if there is no valid collection or the tagging call fails
    dispatch(recipeAdded(taggedRecipe ?? newRecipe));
  };
};
